interface IProps {
	color?: string;
}

export const Home: React.FC<IProps> = ({ color = 'currentColor' }) => {
	return (
		<svg viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.15668 2.08845C7.06518 2.01487 6.93482 2.01487 6.84332 2.08845L1.84332 6.10961C1.78432 6.15707 1.75 6.22871 1.75 6.30443V12.6667C1.75 12.8047 1.86193 12.9167 2 12.9167H4.2C4.33807 12.9167 4.45 12.8047 4.45 12.6667V10.4444C4.45 9.47794 5.2335 8.69444 6.2 8.69444H7.8C8.7665 8.69444 9.55 9.47794 9.55 10.4444V12.6667C9.55 12.8047 9.66193 12.9167 9.8 12.9167H12C12.1381 12.9167 12.25 12.8047 12.25 12.6667V6.30443C12.25 6.22871 12.2157 6.15707 12.1567 6.10961L7.15668 2.08845ZM5.90327 0.919564C6.54371 0.404498 7.45629 0.404497 8.09673 0.919564L13.0967 4.94073C13.5098 5.27291 13.75 5.77439 13.75 6.30443V12.6667C13.75 13.6332 12.9665 14.4167 12 14.4167H9.8C8.8335 14.4167 8.05 13.6332 8.05 12.6667V10.4444C8.05 10.3064 7.93807 10.1944 7.8 10.1944H6.2C6.06193 10.1944 5.95 10.3064 5.95 10.4444V12.6667C5.95 13.6332 5.1665 14.4167 4.2 14.4167H2C1.0335 14.4167 0.25 13.6332 0.25 12.6667V6.30443C0.25 5.77439 0.490229 5.27291 0.903267 4.94073L5.90327 0.919564Z"
				fill={color}
			/>
		</svg>
	);
};
