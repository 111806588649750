export const resolvedRelations = [
	'insights_filter.featured',
	'insight.categories',
	'insights_overview.insights',
	'insights_overview.featured_insight',
	'faq_group.faqs',
	'testimonials.testimonials',
	'contact.location_data',
	'settings.au_primary_location',
	'settings.us_primary_location',
	'settings.nz_primary_location',
	'i_build_testimonial.testimonial',
	'footer_contact.location_data',
	'location_group.locations',
];
