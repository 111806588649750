import Head from 'next/head';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { ISeo } from '@/utils/interfaces/common';
import { useEffect, useState } from 'react';
import { ILanguage } from '@/components/Providers/LanguageProvider';
import { findLangBasedOnLocale } from '@/utils/helpers/findLangBasedOnLocale';
import { removeQuerystring } from '@/utils/helpers/removeQuerystring';
import { pathOr } from 'ramda';

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

interface IProps {
	name: string;
	seo: ISeo;
	og_type: string;
	locale: string | undefined;
	asPath: string;
}

export const SEO: React.FC<IProps> = ({
	name,
	seo,
	og_type,
	locale = 'au',
	asPath,
}) => {
	// State for the currently selected locale
	const [selectedLang, setSelectedLang] = useState<ILanguage>(
		findLangBasedOnLocale(locale),
	);
	useEffect(() => {
		setSelectedLang(findLangBasedOnLocale(locale));
	}, [locale]);

	const title = pathOr(null, ['title'], seo);
	const description = pathOr(null, ['description'], seo);
	const og_title = pathOr(null, ['og_title'], seo);
	const og_description = pathOr(null, ['og_description'], seo);
	const og_image = pathOr(null, ['og_image'], seo);
	const twitter_title = pathOr(null, ['twitter_title'], seo);
	const twitter_description = pathOr(null, ['twitter_description'], seo);
	const twitter_image = pathOr(null, ['twitter_description'], seo);

	const url = removeQuerystring(`${baseUrl}/${locale}${asPath}`);

	return (
		<Head>
			<link rel="canonical" href={url} key="canonical" />
			<title>{title || `${name} | canibuild`}</title>
			{description && isStringWithValue(description) && (
				<meta name="description" content={description} />
			)}
			{og_title && isStringWithValue(og_title) && (
				<meta property="og:title" content={og_title} />
			)}
			{og_description && isStringWithValue(og_description) && (
				<meta property="og:description" content={og_description} />
			)}
			{og_type && isStringWithValue(og_type) && (
				<meta property="og:type" content={og_type} />
			)}
			<meta property="og:url" content={url} />
			{og_image && isStringWithValue(og_image) && (
				<meta property="og:image" content={og_image} />
			)}

			<meta property="og:locale" content={selectedLang.og_locale} />
			<meta property="og:site_name" content="canibuild" />

			{twitter_title && isStringWithValue(twitter_title) && (
				<meta name="twitter:title" content={twitter_title} />
			)}
			{twitter_description && isStringWithValue(twitter_description) && (
				<meta
					name="twitter:description"
					content={twitter_description}
				/>
			)}
			{twitter_image && isStringWithValue(twitter_image) && (
				<meta name="twitter:image" content={twitter_image} />
			)}
			<meta name="twitter:url" content={url} />
		</Head>
	);
};
