import { UseFormRegister, FieldValues, useController } from 'react-hook-form';

interface IProps {
	name: string;
	value: string;
	label: string;
	register: UseFormRegister<any>;
}

export const FormRadio: React.FC<IProps> = ({ register, ...props }) => {
	return (
		<div className="form-radio">
			<input
				{...register(props.name)}
				type="radio"
				value={props.value}
				id={props.value}
			/>
			<label htmlFor={props.value}>
				<span>{props.label}</span>
			</label>
		</div>
	);
};
