import { isFieldRequired } from '@/utils/helpers/isFieldRequired';
import { IFormInput } from '@/utils/interfaces/components';
import { useField } from 'formik';
import { SbError } from './SbError';
import { SbFormLabel } from './SbFormLabel';

interface IProps {
	blok: IFormInput;
}

export const SbFormInput: React.FC<IProps> = ({ blok }) => {
	const [field, meta, helpers] = useField(blok.name);
	const hasError = meta.touched && meta.error;
	const isRequired = isFieldRequired(blok.validations);

	const inputClasses = [
		'block',
		'w-full',
		'px-6',
		'py-4',
		'mt-1',
		'text-base',
		'leading-6',
		'border',
		'border-transparent',
		'focus:border-green',
		'focus:shadow-soft',
		'bg-light',
	];

	if (hasError) {
		inputClasses.push('border-error focus:border-error');
	}

	return (
		<div>
			<SbFormLabel required={isRequired}>{blok.label}</SbFormLabel>
			<input
				type={blok.type}
				placeholder={blok.placeholder}
				className={inputClasses.join(' ')}
				{...field}
			/>
			{hasError ? <SbError>{meta.error}</SbError> : null}
		</div>
	);
};
