interface IProps {
	className: string;
}

export const SmallEmblem: React.FC<IProps> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 368 644"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M282.045 21.6188L268.548 0L0.0342286 166.106L13.5209 187.793C38.5672 228.106 51.8374 274.594 51.8374 322.024C51.8374 369.454 38.5672 415.943 13.5209 456.256L0 477.948L268.563 644L282.059 622.386C338.231 532.217 368 428.168 368 322.002C368 215.836 338.231 111.788 282.059 21.6188H282.045Z"
				fill="url(#paint0_linear_388_15086)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_388_15086"
					x1="0"
					y1="322"
					x2="368"
					y2="322"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#22B573" />
					<stop offset="1" stopColor="#1BD0BC" />
				</linearGradient>
			</defs>
		</svg>
	);
};
