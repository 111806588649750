interface IProps {}

export const WorkerV1Dark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M39.978 49.488a14.845 14.845 0 0 1-14.524-11.4 54.096 54.096 0 0 1-.88-7.514 1.153 1.153 0 0 1 2.301-.115c.118 2.38.393 4.75.823 7.095a12.658 12.658 0 0 0 24.565 0c.434-2.344.711-4.714.828-7.095a1.154 1.154 0 0 1 2.006-.716 1.152 1.152 0 0 1 .296.83 54.41 54.41 0 0 1-.886 7.516 14.846 14.846 0 0 1-14.53 11.399Z"
				fill="#fff"
			/>
			<path
				d="M30.471 53.055a1.15 1.15 0 0 1-.854-.384 1.15 1.15 0 0 1-.181-1.267c1.467-3.061 1.099-5.179 1.093-5.225a1.151 1.151 0 0 1 2.261-.443c0 .115.576 2.877-1.277 6.663a1.15 1.15 0 0 1-1.042.656ZM49.483 53.055a1.15 1.15 0 0 1-1.041-.656c-1.824-3.803-1.3-6.548-1.278-6.663a1.152 1.152 0 0 1 2.262.443c0 .058-.363 2.187 1.093 5.225a1.15 1.15 0 0 1-.54 1.536 1.15 1.15 0 0 1-.495.115Z"
				fill="#fff"
			/>
			<path
				d="M76.804 78a1.15 1.15 0 0 1-1.15-1.151c0-.132-.121-13.574-8.839-17.965l-16.6-7.112a1.153 1.153 0 0 1 .908-2.117l16.63 7.13h.063c4.184 2.088 7.262 6.237 8.908 11.997a32.15 32.15 0 0 1 1.231 8.055 1.15 1.15 0 0 1-1.15 1.151V78ZM3.15 78A1.15 1.15 0 0 1 2 76.849c.067-2.727.48-5.434 1.231-8.056 1.646-5.754 4.725-9.909 8.908-11.997h.063l16.63-7.13a1.152 1.152 0 1 1 .909 2.118l-16.6 7.112C4.421 63.286 4.3 76.734 4.3 76.86A1.151 1.151 0 0 1 3.152 78Z"
				fill="#fff"
			/>
			<path
				d="M39.978 78a1.15 1.15 0 0 1-1.151-1.151c0-12.02-5.098-16.388-6.963-17.58l-7.705 7.7a1.151 1.151 0 0 1-1.95-.972l1.962-13.741a1.16 1.16 0 0 1 1.743-.837 1.161 1.161 0 0 1 .559 1.165L25 62.9l5.869-5.875a1.151 1.151 0 0 1 1.272-.242c.368.156 9.01 4.028 9.01 20.065a1.151 1.151 0 0 1-1.173 1.15Z"
				fill="#fff"
			/>
			<path
				d="m25.31 52.417-1.962 13.74 8.315-8.314-2.377-7.13-3.976 1.704Z"
				fill="#22B573"
			/>
			<path
				d="M23.348 67.308c-.18.001-.357-.04-.518-.12a1.151 1.151 0 0 1-.622-1.192l1.963-13.74a1.151 1.151 0 0 1 .684-.898l3.976-1.726a1.15 1.15 0 0 1 1.548.696l2.377 7.13a1.151 1.151 0 0 1-.282 1.15l-8.315 8.315a1.152 1.152 0 0 1-.811.385Zm3.01-14.086-1.382 9.679 5.369-5.369-1.726-5.265-2.262.955Z"
				fill="#fff"
			/>
			<path
				d="M31.663 59.011a1.15 1.15 0 0 1-1.094-.788l-2.376-7.13a1.152 1.152 0 1 1 2.186-.725l2.377 7.13a1.15 1.15 0 0 1-1.093 1.513ZM39.977 78a1.15 1.15 0 0 1-1.15-1.151c0-16.02 8.63-19.91 9.01-20.065a1.15 1.15 0 0 1 1.272.242l5.87 5.875-1.445-10.145a1.16 1.16 0 0 1 .987-1.315 1.161 1.161 0 0 1 1.315.987l1.933 13.569a1.15 1.15 0 0 1-1.95.972l-7.705-7.705c-1.859 1.191-6.963 5.559-6.963 17.585a1.151 1.151 0 0 1-1.174 1.15Z"
				fill="#fff"
			/>
			<path
				d="M48.292 59.011a1.15 1.15 0 0 1-1.093-1.513l2.376-7.13a1.153 1.153 0 0 1 1.847-.509 1.15 1.15 0 0 1 .34 1.234l-2.377 7.13a1.151 1.151 0 0 1-1.093.788ZM63.737 78H50.669a1.15 1.15 0 0 1 0-2.302h13.068a1.15 1.15 0 0 1 0 2.302Z"
				fill="#fff"
			/>
			<path
				d="M54.23 78a1.15 1.15 0 0 1-1.15-1.151v-4.753a1.15 1.15 0 1 1 2.302 0v4.753a1.151 1.151 0 0 1-1.151 1.15ZM58.967 78a1.148 1.148 0 0 1-1.094-1.514l1.191-3.567a1.151 1.151 0 1 1 2.181.73l-1.185 3.562a1.15 1.15 0 0 1-1.093.789Z"
				fill="#fff"
			/>
			<path
				d="M39.977 29.29c-8.17 0-18.476-.914-21.175-5.293a1.186 1.186 0 0 1-.173-.576v-1.237a1.15 1.15 0 0 1 2.302 0v.835c1.916 2.48 8.97 3.953 19.046 3.953 10.076 0 17.13-1.473 19.047-3.953v-.835a1.15 1.15 0 0 1 2.301 0v1.191a1.186 1.186 0 0 1-.172.576c-1.496 2.462-6.382 5.34-21.176 5.34Z"
				fill="#22B573"
			/>
			<path
				d="M39.977 23.335a59.43 59.43 0 0 1-10.898-1.208 1.151 1.151 0 1 1 .414-2.262 47.632 47.632 0 0 0 20.969 0 1.15 1.15 0 1 1 .414 2.262 59.43 59.43 0 0 1-10.899 1.208ZM43.54 10.285a1.15 1.15 0 0 1-1.151-1.151V4.53a14.742 14.742 0 0 0-4.822-.034v4.603a1.151 1.151 0 0 1-2.302 0V4.496a2.336 2.336 0 0 1 1.98-2.302 19.329 19.329 0 0 1 5.466 0 2.336 2.336 0 0 1 1.98 2.302v4.603a1.15 1.15 0 0 1-1.151 1.186Z"
				fill="#22B573"
			/>
			<path
				d="M56.607 20.976a1.15 1.15 0 0 1-1.15-1.151c0-6.692-5.105-12.567-12.142-13.96a1.153 1.153 0 1 1 .449-2.261c8.113 1.611 13.994 8.436 13.994 16.221a1.151 1.151 0 0 1-1.15 1.15ZM23.348 20.976a1.15 1.15 0 0 1-1.15-1.151c0-7.785 5.88-14.61 13.993-16.221a1.153 1.153 0 1 1 .45 2.261c-7.038 1.393-12.142 7.268-12.142 13.96a1.15 1.15 0 0 1-1.15 1.15ZM48.292 57.843l8.315 8.315-1.962-13.741-3.976-1.704-2.377 7.13Z"
				fill="#22B573"
			/>
			<path
				d="M56.607 67.308a1.15 1.15 0 0 1-.811-.339l-8.338-8.315a1.15 1.15 0 0 1-.282-1.15l2.377-7.13a1.15 1.15 0 0 1 1.547-.696l3.977 1.726a1.151 1.151 0 0 1 .684.898l1.962 13.74a1.151 1.151 0 0 1-.62 1.192c-.158.06-.327.086-.496.075Zm-6.997-9.782 5.369 5.37-1.381-9.68-2.233-.955-1.755 5.265Z"
				fill="#fff"
			/>
		</svg>
	);
};
