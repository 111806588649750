import { Icon } from '@/components/Shared/Icon';
import { Check } from '@/components/Shared/Icons/Check';
import { ChevronRight } from '@/components/Shared/Icons/ChevronRight';
import { TAddon } from '@/utils/interfaces/cibApiResponse';
import { pl } from 'date-fns/locale';
import { useRouter } from 'next/router';
import { pluck } from 'ramda';
import { useEffect, useState } from 'react';

type TFeatureMapping = {
	[key: string]: string[];
};

const featureMappingAus: TFeatureMapping = {
	'30': [
		'2 Users',
		'2 Uploads',
		'1 build type',
		'1 State',
		'Google Imagery',
		'All Basic Features',
		'In-App Purchases',
		'Standard Branding',
		'Standard Support',
	],
	'100': [
		'Unlimited Users',
		'Unlimited Uploads',
		'1 build type',
		'1 State',
		'High-Resolution Imagery',
		'All Basic Features',
		'In-App Purchases',
		'Custom Branding',
		'Priority Support',
	],
	'300': [
		'Unlimited Users',
		'Unlimited Uploads',
		'1 build type',
		'1 State',
		'High-Resolution Imagery',
		'All Basic Features',
		'In-App Purchases',
		'Custom Branding',
		'Priority Support',
	],
};

const featureMappingUS: TFeatureMapping = {
	'30': [
		'Unlimited Users',
		'Unlimited Uploads',
		'1 Build Type',
		'1 State',
		'High Resolution Imagery',
		'All Basic Features',
		'In-App Purchases',
		'Custom Branding',
		'Priority Support',
	],
	'50': [
		'Unlimited Users',
		'Unlimited Uploads',
		'1 Build Type',
		'1 State',
		'High Resolution Imagery',
		'All Basic Features',
		'In-App Purchases',
		'Custom Branding',
		'Priority Support',
	],
	'100': [
		'Unlimited Users',
		'Unlimited Uploads',
		'1 Build Type',
		'1 State',
		'High Resolution Imagery',
		'All Basic Features',
		'In-App Purchases',
		'Custom Branding',
		'Priority Support',
	],
	'300': [
		'Unlimited Users',
		'Unlimited Uploads',
		'1 Build Type',
		'1 State',
		'High Resolution Imagery',
		'All Basic Features',
		'In-App Purchases',
		'Custom Branding',
		'Priority Support',
	],
};

interface IProps {
	label: string;
	addOns: TAddon[];
	searchesAmount: string;
}

export const DropdownChecklist: React.FC<IProps> = ({
	label,
	searchesAmount,
	addOns,
	children,
}) => {
	const { locale } = useRouter();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [features, setFeatures] = useState<string[]>();
	const classes = ['dropdown-checklist__trigger-icon-svg'];

	if (isOpen) {
		classes.push('dropdown-checklist__trigger-icon-svg--active');
	}

	useEffect(() => {
		let addOnsData: string[] = [];
		let plansData: string[] = [];

		if (addOns !== undefined && addOns.length > 0) {
			addOnsData = pluck('name', addOns);
		} else {
			addOnsData = [];
		}

		if (locale === 'en-us') {
			plansData = featureMappingUS[searchesAmount];
		} else {
			plansData = featureMappingAus[searchesAmount];
		}

		if (plansData !== undefined && plansData.length > 0) {
			setFeatures([...plansData, ...addOnsData]);
		}

		return () => {};
	}, [searchesAmount, addOns]);

	if (features === undefined) {
		return null;
	}

	return (
		<div className="dropdown-checklist">
			<div className="dropdown-checklist__header">
				<button
					onClick={() => {
						setIsOpen(!isOpen);
					}}
					type="button"
					className="dropdown-checklist__trigger"
				>
					<span className="dropdown-checklist__trigger-label">
						{label}
					</span>
					<span className="dropdown-checklist__trigger-icon">
						<span className="dropdown-checklist__trigger-icon-label">
							{isOpen ? 'hide' : 'show'}
						</span>

						<Icon
							className={classes.join(' ')}
							icon={<ChevronRight />}
						/>
					</span>
				</button>
			</div>

			{isOpen && features && features.length > 0 && (
				<>
					<div className="dropdown-checklist__list-container">
						<div className="dropdown-checklist__list">
							{features.map((feature) => {
								return (
									<div key={feature}>
										<span className="dropdown-checklist__list-icon">
											<Icon
												className="w-3 h-3 text-green"
												icon={<Check />}
											/>
										</span>
										<span className="dropdown-checklist__list-label">
											{feature}
										</span>
									</div>
								);
							})}
						</div>
					</div>

					{children}
				</>
			)}
		</div>
	);
};
