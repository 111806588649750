import { IMenuItem } from '@/utils/interfaces/components';
import { Icon } from '../Icon';
import { Caret } from '@/components/Shared/Icons/Caret';
import { MegaMenu } from '@/components/Shared/Menu/MegaMenu';
import { useEffect, useRef } from 'react';
import { ChevronRight } from '@/components/Shared/Icons/ChevronRight';
import { SbLink } from '@/components/Shared/SbLink';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';

interface IProps {
	blok: IMenuItem;
	isActive: boolean;
	handleClick: () => void;
	handleClose: () => void;
}

export const MenuItem: React.FC<IProps> = ({
	blok,
	isActive,
	handleClick,
	handleClose,
}) => {
	const classes = ['cta', 'cta--nav', 'cta--small'];
	const ref = useRef<HTMLDivElement>(null);

	const handleClickOutside = ({ target }: MouseEvent): void => {
		if (null !== ref.current && ref.current.contains(target as Node)) {
			return;
		}
		handleClose();
	};

	useEffect(() => {
		if (isActive) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isActive]);

	return (
		<>
			{blok.mega_menu && isArrayWithValue(blok.mega_menu) ? (
				<div ref={ref}
					 className="lg:flex lg:gap-x-5 xl:gap-x-7 lg:items-center"
					 onMouseLeave={() => {
						 handleClose()
					 }}>
					<button
						className={classes.join(' ')}
						onClick={() => {
							handleClick();
						}}
						onMouseEnter={() => {
							handleClick();
						}}
						onFocus={() => {
							handleClose();
						}}
					>
						<span className="cta-label">{blok.label}</span>
						<Icon
							className={`hidden lg:block text-green ml-2 transition-transform ${
								isActive ? 'lg:-rotate-90' : 'lg:rotate-90'
							}`}
							icon={<Caret />}
						/>
						<Icon
							className="lg:hidden text-green"
							icon={<ChevronRight />}
						/>
					</button>
					{blok.mega_menu.map((menu, key) => (
						<MegaMenu
							blok={menu}
							key={key}
							isActive={isActive}
							handleClose={handleClose}
						/>
					))}
				</div>
			) : (
				<div className="lg:flex lg:gap-x-5 xl:gap-x-7 lg:items-center">
					<SbLink link={blok.link}>
						<a
							className={classes.join(' ')}
							onMouseEnter={() => {
								handleClose();
							}}
							onFocus={() => {
								handleClose();
							}}
						>
							<span className="cta-label">{blok.label}</span>
						</a>
					</SbLink>
				</div>
			)}
		</>
	);
};
