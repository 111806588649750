interface IProps {
    color?: string;
}

export const Caret: React.FC<IProps> = ({color = 'currentColor'}) => {
    return (
        <svg width="6"
             height="7"
             viewBox="0 0 6 7"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 3.5L0.25 6.9641V0.0358984L5.5 3.5Z" fill={color}/>
        </svg>
    );
};
