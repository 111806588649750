const currencyMap: { [key: string]: string } = {
	'en-au': 'AUD',
	'en-us': 'USD',
	'en-nz': 'NZD',
};

export function formatNumber(number: number, locale: string): string {
	const decimal = (number / 100).toFixed(2);

	const formattedNumber = new Intl.NumberFormat(locale, {
		currency: currencyMap[locale],
		style: 'currency',
		minimumFractionDigits: 0,
	}).format(parseInt(decimal));

	return formattedNumber;
}
