interface IProps {
	align?: 'center' | 'left' | 'right';
	type?: 'default' | 'narrow' | 'wide' | 'x-wide';
}

export const Container: React.FC<IProps> = ({
	type = 'default',
	align = 'left',
	children,
}) => {
	const classes = ['container', 'relative'];

	if (align === 'center') {
		classes.push('text-center');
	}

	if (type === 'wide') {
		classes.push('max-w-[1351px]');
	}

	if (type === 'x-wide') {
		classes.push('max-w-[1472px]');
	}

	if (type === 'narrow') {
		classes.push('max-w-[720px]');
	}

	return <div className={classes.join(' ')}>{children}</div>;
};
