interface IProps {}

export const Electrician: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M40 78a38 38 0 1 1 38-38c0 14.745-7.64 27.406-20.428 33.872a12.706 12.706 0 0 1-12.46-.576 13.052 13.052 0 0 1-6.264-11.164V59a1.151 1.151 0 1 1 2.303 0v3.115a10.756 10.756 0 0 0 5.182 9.212 10.405 10.405 0 0 0 10.209.466C68.534 65.753 75.697 53.858 75.697 40A35.697 35.697 0 1 0 40 75.697 1.151 1.151 0 1 1 40 78Z"
				fill="#22B573"
			/>
			<path
				d="M26.924 35.244v11.89A11.884 11.884 0 0 0 38.814 59h2.372a11.886 11.886 0 0 0 11.89-11.884V35.244"
				fill="#22B573"
			/>
			<path
				d="M41.187 60.151h-2.373a13.053 13.053 0 0 1-13.04-13.035V35.244a1.152 1.152 0 0 1 2.302 0v11.89a10.744 10.744 0 0 0 10.738 10.715h2.373a10.744 10.744 0 0 0 10.737-10.733V35.244a1.152 1.152 0 0 1 2.303 0v11.89a13.052 13.052 0 0 1-13.04 13.017Z"
				fill="#224946"
			/>
			<path
				d="M56.64 36.396H49.51a1.151 1.151 0 0 1-1.151-1.152V21.576a1.826 1.826 0 0 0-3.645 0v13.668a1.152 1.152 0 0 1-1.151 1.152h-7.128a1.152 1.152 0 0 1-1.152-1.152V21.576a1.825 1.825 0 0 0-3.644 0v13.668a1.152 1.152 0 0 1-1.152 1.152H23.36a1.151 1.151 0 1 1 0-2.303h5.977V21.576a4.128 4.128 0 0 1 8.25 0v12.517h4.825V21.576a4.128 4.128 0 0 1 8.25 0v12.517h5.977a1.152 1.152 0 0 1 0 2.303Z"
				fill="#224946"
			/>
		</svg>
	);
};
