const mapLocalCountry: { [key: string]: string } = {
	'en-au': 'Australia',
	'en-us': 'United States',
	'en-nz': 'New Zealand',
};

const mapLocaleCurrency: { [key: string]: string } = {
	'en-au': 'AUD',
	'en-us': 'USD',
	'en-nz': 'NZD',
};

const mapLocaleInternationalCode: { [key: string]: string } = {
	'en-au': '61',
	'en-us': '1',
	'en-nz': '64',
};

export function mapLocalToCountry(locale: string): string {
	return mapLocalCountry[locale];
}

export function mapLocaleToCurrency(locale: string): string {
	return mapLocaleCurrency[locale] ? mapLocaleCurrency[locale] : 'USD';
}

export function mapLocaleToInternationalCode(locale: string): string {
	return mapLocaleInternationalCode[locale]
		? mapLocaleInternationalCode[locale]
		: '';
}
