import {ISocialLink} from '@/utils/interfaces/components';
import React from "react";
import {SbLink} from "@/components/Shared/SbLink";
import {Icon} from "@/components/Shared/Icon";
import {Facebook} from "@/components/Shared/Icons/Social/Facebook";
import {Twitter} from "@/components/Shared/Icons/Social/Twitter";
import {Youtube} from "@/components/Shared/Icons/Social/Youtube";
import {Instagram} from "@/components/Shared/Icons/Social/Instagram";
import {LinkedIn} from "@/components/Shared/Icons/Social/Linkedin";

interface IProps {
    blok: ISocialLink;
}

type MapKindToComponentT = {
    [key: string]: React.FC<any>;
};

const socialComponents: MapKindToComponentT = {
    facebook: Facebook,
    twitter: Twitter,
    youtube: Youtube,
    instagram: Instagram,
    linkedin: LinkedIn,
};
export const SocialLink: React.FC<IProps> = ({blok}) => {
    const SocialIcon = socialComponents[blok.type];
    return (
        <SbLink link={blok.link}>
            <a target="_blank">
                <Icon
                    className="w-10 h-10 border border-green text-green-dark rounded-full transition-colors hover:bg-green hover:text-white hover:border-green"
                    icon={<SocialIcon/>}/>
            </a>
        </SbLink>
    );
}