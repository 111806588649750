interface IProps {
	submit?: boolean;
}

export const FormCol: React.FC<IProps> = ({ children, submit }) => {
	const classes = ['form-step__form-col'];

	if (submit) {
		classes.push('form-step__form-col--submit');
	}

	return <div className={classes.join(' ')}>{children}</div>;
};
