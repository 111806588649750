interface IProps {}

export const Instagram: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 36 36"
			 xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.5 17.7c0-3 0-4.5.764-5.551.247-.34.545-.638.885-.885C13.2 10.5 14.7 10.5 17.7 10.5h.6c3 0 4.5 0 5.551.764.34.247.638.545.885.885.764 1.051.764 2.551.764 5.551v.6c0 3 0 4.5-.764 5.551-.247.34-.545.638-.885.885-1.051.764-2.551.764-5.551.764h-.6c-3 0-4.5 0-5.551-.764a4.003 4.003 0 0 1-.885-.885C10.5 22.8 10.5 21.3 10.5 18.3v-.6Zm7.5-3.67a3.97 3.97 0 1 0 0 7.94 3.97 3.97 0 0 0 0-7.94Zm0 6.452A2.485 2.485 0 0 1 15.518 18 2.484 2.484 0 0 1 18 15.518 2.484 2.484 0 0 1 20.482 18 2.485 2.485 0 0 1 18 20.482Zm4.797-6.75a.53.53 0 1 1-1.058 0 .53.53 0 0 1 1.058 0Z"
				fill="currentColor"
			/>
		</svg>
	);
};
