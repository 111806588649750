interface IProps {}

export const Right: React.FC<IProps> = () => {
	return (
		<svg
			viewBox="0 0 704 736"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M696.187 -137.139L691.144 -176.526L203.151 -115.726L208.135 -76.2489C217.368 -2.88159 205.92 71.5803 175.081 138.754C144.241 205.928 95.235 263.147 33.5784 303.969L0.339849 325.907L272.431 735.566L305.584 713.724C443.705 622.514 553.486 494.493 622.516 344.132C691.546 193.772 717.072 27.0694 696.208 -137.129L696.187 -137.139Z"
				fill="url(#paint0_linear_388_14940)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_388_14940"
					x1="101.739"
					y1="105.041"
					x2="622.517"
					y2="344.129"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#22B573" />
					<stop offset="1" stopColor="#1BD0BC" />
				</linearGradient>
			</defs>
		</svg>
	);
};
