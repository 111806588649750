interface IProps {}

export const WindDark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M71.903 33.108v16.135c-6.138 0-17.674.185-21.287 0-5.129-.253-5.129-1.648-5.129-1.648V32.717l13.208-13.208"
				fill="#22B573"
			/>
			<path
				d="M56.407 50.453c-2.5 0-4.61 0-5.849-.08-5.416-.271-6.223-1.873-6.223-2.8V32.716a1.15 1.15 0 0 1 .34-.819l13.202-13.207a1.153 1.153 0 0 1 1.63 1.63L46.64 33.19v14.049c.276.19 1.245.668 4.034.813 2.431.12 8.643.074 14.164.034l5.912-.035V33.069a1.152 1.152 0 1 1 2.305 0v16.135a1.152 1.152 0 0 1-1.152 1.152c-1.948 0-4.438 0-7.048.04-2.824.035-5.837.058-8.448.058Z"
				fill="#fff"
			/>
			<path
				d="M4.245 42.26a1.153 1.153 0 0 1-1.112-.848c-3.561-13.058 1.475-25.165 12.245-29.44 8.027-3.187 13.79-1.925 17.207-.3 4.08 1.942 6.667 5.227 7.428 7.63.893 2.829.784 5.364-.317 7.14a5.394 5.394 0 0 1-3.901 2.46 4.506 4.506 0 0 1-4.806-2.092 4.126 4.126 0 0 1 .438-4.737 1.152 1.152 0 0 1 1.984 1.086c-.045.144-.118.279-.215.395a1.855 1.855 0 0 0-.207 2.12 2.218 2.218 0 0 0 2.437.952 3.094 3.094 0 0 0 2.305-1.395c.726-1.152.755-3.089.075-5.238-.42-1.337-2.265-4.356-6.218-6.24-3.002-1.424-8.108-2.519-15.368.362C6.58 17.942 2.108 28.92 5.352 40.801a1.153 1.153 0 0 1-1.107 1.458Z"
				fill="#22B573"
			/>
			<path
				d="M24.88 47.192a1.153 1.153 0 0 1-.881-.41c-5.002-5.94-9.433-14.607-6.863-21.107 1.93-4.898 6.76-8.068 11.23-7.319a1.168 1.168 0 0 1-.374 2.305c-3.365-.576-7.197 2.04-8.713 5.89-1.44 3.642-.495 10.493 6.483 18.78a1.152 1.152 0 0 1-.881 1.89v-.03ZM67.972 66.197h-8.257c-22.98 0-29.965-5.728-34.125-9.151a46 46 0 0 0-1.233-.986 1.152 1.152 0 1 1 1.383-1.844c.414.312.84.663 1.313 1.05 3.93 3.226 10.505 8.643 32.662 8.643h8.257a1.152 1.152 0 1 1 0 2.305v-.017ZM24.148 69.498a1.087 1.087 0 0 1-.42-.08C15.315 66.116 9.719 61.62 6.619 55.674a1.153 1.153 0 1 1 2.04-1.066c2.835 5.434 8.039 9.577 15.91 12.678a1.152 1.152 0 0 1-.42 2.224v-.012ZM75.204 56.124h-23.13c-11.998 0-18.734-6.604-20.826-10.511a1.152 1.152 0 1 1 2.029-1.09c1.728 3.216 7.871 9.296 18.797 9.296h23.113a1.152 1.152 0 1 1 0 2.305h.017Z"
				fill="#22B573"
			/>
			<path
				d="M76.852 38.819a1.153 1.153 0 0 1-.812-.34l-17.345-17.34L41.35 38.48a1.153 1.153 0 1 1-1.631-1.625l18.157-18.163a1.187 1.187 0 0 1 1.631 0l18.158 18.175a1.152 1.152 0 0 1 0 1.625c-.217.212-.51.33-.813.328Z"
				fill="#fff"
			/>
			<path
				d="M45.487 48.725a1.152 1.152 0 0 1-1.152-1.153V32.717a1.152 1.152 0 1 1 2.305 0v14.855a1.152 1.152 0 0 1-1.153 1.153ZM71.903 50.373a1.153 1.153 0 0 1-1.153-1.153V32.717a1.152 1.152 0 1 1 2.305 0V49.22a1.153 1.153 0 0 1-1.153 1.153ZM66.947 28.913a1.153 1.153 0 0 1-1.153-1.152v-9.912a1.153 1.153 0 1 1 2.305 0v9.912a1.152 1.152 0 0 1-1.152 1.152Z"
				fill="#fff"
			/>
		</svg>
	);
};
