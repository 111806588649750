import {Ref} from "react";
import {SwiperArrow} from "@/components/Shared/Swiper/SwiperArrow";

interface IProps {
	prevRef: Ref<HTMLDivElement>;
	nextRef: Ref<HTMLDivElement>;
	paginationRef: Ref<HTMLDivElement>;
}

export const SwiperNav: React.FC<IProps> = ({ prevRef, nextRef, paginationRef }) => {
	return (
		<div className="flex items-center justify-end mt-5">
			<div className="relative bottom-0 w-auto text-white text-base md:text-lg" ref={paginationRef} />
			<nav className="flex justify-end ml-4 gap-x-1">
				<SwiperArrow arrowRef={prevRef} isPrev />
				<SwiperArrow arrowRef={nextRef} />
			</nav>
		</div>
	);
};
