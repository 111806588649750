import { formatTitle } from '@/utils/helpers/formatTitle';
import { createElement } from 'react';

interface IProps {
	tag: string;
	text: string;
	className?: string;
}

export const FormattedText: React.FC<IProps> = ({
	text,
	tag,
	className = '',
}) => {
	return createElement(tag, {
		className,
		dangerouslySetInnerHTML: { __html: formatTitle(text) },
	});
};
