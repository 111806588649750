import { Icon } from '@/components/Shared/Icon';
import { Left } from '@/components/Shared/Icons/BrandBackgrounds/IBuild/Left';
import { Right } from '@/components/Shared/Icons/BrandBackgrounds/IBuild/Right';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { IIBuildTabs } from '@/utils/interfaces/bloks';
import { Fragment } from 'react';
import { FormattedText } from '../Shared/FormattedText';
import { IBuildTabsDesktop } from '../Shared/IBuildTabs/IBuildTabs';
import { IBuildTabsAccordion } from '../Shared/IBuildTabs/IBuildTabsAccordion';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';

interface IProps {
	blok: IIBuildTabs;
	preview: boolean;
}

export const IBuildTabs: React.FC<IProps> = ({ blok, preview }) => {
	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative bg-white py-14 md:py-32"
		>
			<Icon
				className="absolute bottom-0 left-0 opacity-20 lg:w-[553px] lg:h-[612px]"
				icon={<Left strokeColor="#A9ABAC" />}
			/>
			<Icon
				className="absolute top-0 right-0 opacity-20 lg:w-[609px] lg:h-[1081px]"
				icon={<Right strokeColor="#A9ABAC" />}
			/>
			<Container>
				<div className="mb-6 md:mb-10">
					<FormattedText tag="h3" text={blok.heading} />
				</div>

				{isArrayWithValue(blok.tabs) && (
					<Fragment>
						<div className="block md:hidden">
							<IBuildTabsAccordion tabs={blok.tabs} />
						</div>

						<div className="hidden md:block">
							<IBuildTabsDesktop tabs={blok.tabs} />
						</div>
					</Fragment>
				)}
			</Container>
		</SbSection>
	);
};
