interface IProps {}

export const US: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 0h36v24H0V0Z" fill="#fff" />
			<path
				d="M0 0h36v1.846H0V0Zm0 3.691h36v1.846H0V3.69Zm0 3.691h36v1.846H0V7.382Zm0 3.692h36v1.845H0v-1.845Zm0 3.698h36v1.845H0v-1.845Zm0 3.691h36v1.846H0v-1.846Zm0 3.691h36V24H0v-1.846Z"
				fill="#D80027"
			/>
			<path d="M0 0h18v12.92H0V0Z" fill="#2E52B2" />
			<path
				d="m3.355 9.747-.281-.898-.309.898H1.84l.75.54-.28.899.765-.554.744.554-.288-.898.765-.54h-.94Zm3.95 0-.287-.898-.295.898h-.926l.75.54-.28.899.75-.554.759.554-.281-.898.75-.54h-.94Zm3.965 0-.301-.898-.281.898h-.947l.772.54-.295.899.75-.554.773.554-.295-.898.75-.54h-.926Zm3.944 0-.28-.898-.295.898h-.933l.757.54-.28.899.75-.554.758.554-.301-.898.772-.54h-.948ZM7.018 5.284l-.295.898h-.926l.75.555-.28.884.75-.547.759.547-.281-.884.75-.555h-.94l-.287-.898Zm-3.944 0-.309.898H1.84l.75.555-.28.884.765-.547.744.547-.288-.884.765-.555h-.94l-.281-.898Zm7.895 0-.281.898h-.947l.772.555-.295.884.75-.547.773.547-.295-.884.75-.555h-.926l-.301-.898Zm3.965 0-.295.898h-.933l.757.555-.28.884.75-.547.758.547-.301-.884.772-.555h-.948l-.28-.898Zm-11.86-3.55-.309.883H1.84l.75.555-.28.891.765-.554.744.554-.288-.891.765-.555h-.94l-.281-.884Zm3.944 0-.295.883h-.926l.75.555-.28.891.75-.554.759.554-.281-.891.75-.555h-.94l-.287-.884Zm3.95 0-.28.883h-.947l.772.555-.295.891.75-.554.773.554-.295-.891.75-.555h-.926l-.301-.884Zm3.966 0-.295.883h-.933l.757.555-.28.891.75-.554.758.554-.301-.891.772-.555h-.948l-.28-.884Z"
				fill="#fff"
			/>
		</svg>
	);
};
