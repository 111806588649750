export const GOOGLE_MAP_STYLE = [
	{
		featureType: 'administrative',
		elementType: 'all',
		stylers: [
			{
				lightness: '0',
			},
		],
	},
	{
		featureType: 'administrative',
		elementType: 'geometry',
		stylers: [
			{
				lightness: '0',
			},
		],
	},
	{
		featureType: 'administrative',
		elementType: 'geometry.stroke',
		stylers: [
			{
				lightness: '0',
			},
		],
	},
	{
		featureType: 'administrative',
		elementType: 'labels',
		stylers: [
			{
				lightness: '0',
			},
		],
	},
	{
		featureType: 'administrative',
		elementType: 'labels.text.fill',
		stylers: [
			{
				color: '#444444',
			},
		],
	},
	{
		featureType: 'administrative.province',
		elementType: 'labels',
		stylers: [
			{
				lightness: '40',
			},
		],
	},
	{
		featureType: 'administrative.locality',
		elementType: 'labels',
		stylers: [
			{
				lightness: '25',
			},
		],
	},
	{
		featureType: 'administrative.neighborhood',
		elementType: 'labels',
		stylers: [
			{
				lightness: '40',
			},
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'landscape',
		elementType: 'all',
		stylers: [
			{
				color: '#f2f2f2',
			},
		],
	},
	{
		featureType: 'landscape.man_made',
		elementType: 'all',
		stylers: [
			{
				lightness: '100',
			},
		],
	},
	{
		featureType: 'landscape.natural',
		elementType: 'all',
		stylers: [
			{
				lightness: '0',
			},
			{
				color: '#ffffff',
			},
		],
	},
	{
		featureType: 'landscape.natural.landcover',
		elementType: 'all',
		stylers: [
			{
				lightness: '0',
			},
		],
	},
	{
		featureType: 'landscape.natural.landcover',
		elementType: 'geometry.fill',
		stylers: [
			{
				lightness: '0',
			},
			{
				saturation: '0',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'all',
		stylers: [
			{
				lightness: '27',
			},
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#e5f2e6',
			},
			{
				saturation: '1',
			},
			{
				lightness: '0',
			},
			{
				gamma: '1',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'labels',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [
			{
				saturation: -100,
			},
			{
				lightness: '45',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'geometry.stroke',
		stylers: [
			{
				lightness: '-5',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.fill',
		stylers: [
			{
				lightness: '-5',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.stroke',
		stylers: [
			{
				lightness: '0',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'geometry.fill',
		stylers: [
			{
				lightness: '-10',
			},
			{
				weight: '1.00',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'geometry.stroke',
		stylers: [
			{
				lightness: '0',
			},
			{
				saturation: '0',
			},
		],
	},
	{
		featureType: 'road.arterial',
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'road.local',
		elementType: 'geometry.fill',
		stylers: [
			{
				saturation: '0',
			},
			{
				lightness: '-7',
			},
		],
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'all',
		stylers: [
			{
				color: '#DEE7EB',
			},
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry.fill',
		stylers: [
			{
				saturation: '0',
			},
			{
				lightness: '0',
			},
		],
	},
];
