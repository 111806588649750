import { IFaqPage, IStoryBase } from '@/utils/interfaces/stories';
import { render } from 'storyblok-rich-text-react-renderer';

interface IProps {
	blok: IStoryBase<IFaqPage>;
}

export const FAQ: React.FC<IProps> = ({ blok }) => {
	return (
		<div className="mb-8 md:mb-10 break-inside-avoid-column">
			<h6 className="mb-2">{blok.content.question}</h6>
			<div className="text-gray-dark/80">
				{render(blok.content.answer)}
			</div>
		</div>
	);
};
