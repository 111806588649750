import { ICalendlyForm } from '@/utils/interfaces/components';
import { useEffect, useRef, useState } from 'react';
import { InlineWidget } from 'react-calendly';
import QS from 'query-string'; // Import query-string for parsing and building query strings
import { useRouter } from 'next/router'; // Import useRouter from Next.js

interface IProps {
	blok: ICalendlyForm;
}

export const FormCalendly: React.FC<IProps> = ({ blok }) => {
	const router = useRouter();
	const ref = useRef<HTMLDivElement>(null);

	const [queryParams, setQueryParams] = useState<any>({});

	// Effect to parse query parameters from the URL
	useEffect(() => {
		if (router.isReady) {  
			// Ensure router is ready before accessing query parameters
			const parsedParams = QS.parse(router.asPath.split('?')[1]); // Parse the query params
			setQueryParams(parsedParams); // Update the state with parsed query params
		}
	}, [router.isReady, router.asPath]); // Add router.asPath to the dependency array

	// Default values for UTM parameters
	const utmSource = queryParams.utm_source || null;
	const utmMedium = queryParams.utm_medium || null;
	const utmCampaign = queryParams.utm_campaign || null;
	const utmTerm = queryParams.utm_term || null;
	const utmContent = queryParams.utm_content || null;

	// Build query string for the Calendly URL, including UTM parameters and blok properties
	const queryString = QS.stringify({
		text_color: blok.text_color,	
		primary_color: blok.primary_color,
		utm_source: utmSource,
		utm_medium: utmMedium,
		utm_campaign: utmCampaign,
		utm_term: utmTerm, // Add utm_term to the query string
		utm_content: utmContent,
	});

	return (
		<div>
			<div ref={ref} className="h-[1200px]">
				<InlineWidget
					styles={{
						width: '100%',
						height: '100%',
					}}
					url={`${blok.url}?${queryString}`} // Append the query string to the Calendly URL
				/>
				{console.log(queryString)} {/* For debugging */}
			</div>
		</div>
	);
};
