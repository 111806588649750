import {
	ISelfSubscribeImage,
	ISelfSubscribeVideo,
} from '@/utils/interfaces/bloks';
import { ISbBase } from '@/utils/interfaces/common';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { StoryblokComponent } from '@storyblok/react';
import { Ref, useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import { SwiperNav } from '@/components/Shared/Swiper/SwiperNav';

interface IProps {
	blok: ISelfSubscribeCarousel;
}

interface ISelfSubscribeCarousel extends ISbBase {
	slides: ISelfSubscribeImage[] | ISelfSubscribeVideo[];
}

export const SelfSubscribeCarousel: React.FC<IProps> = ({ blok }) => {
	const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
	const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
	const [paginationRef, setPaginationRef] = useState<HTMLElement | null>(
		null,
	);

	return (
		<div className="self-subscribe-media self-subscribe-media--image">
			{blok.slides && blok.slides.length && (
				<Swiper
					modules={[Pagination]}
					pagination={{
						el: paginationRef,
						clickable: true,
					}}
					slidesPerView={1}
				>
					{blok.slides.map((slide) => {
						return (
							<SwiperSlide
								className="flex items-center justify-center w-full "
								key={slide._uid}
							>
								<div className="max-w-[795px] mx-auto">
									<StoryblokComponent blok={slide} />
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			)}

			<CarouselPagination
				paginationRef={(node) => setPaginationRef(node)}
			/>
		</div>
	);
};

interface IPaginationProps {
	paginationRef: Ref<HTMLDivElement>;
}

const CarouselPagination: React.FC<IPaginationProps> = ({ paginationRef }) => {
	return (
		<div className="self-subscribe-media__pagination">
			<div
				className="self-subscribe-media__bullets"
				ref={paginationRef}
			/>
		</div>
	);
};
