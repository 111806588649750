interface IProps {
    strokeColor: string;
}

export const Right: React.FC<IProps> = ({strokeColor}) => {
    return (
        <svg
            viewBox="0 0 609 1081"
            fill="none"
            className="w-full h-full"
            xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M920.565 227.497C927.421 230.632 934.302 233.866 940.899 237.541C959.597 248.207 981.58 251.583 1002.62 247.02C1006.39 246.273 1010.2 245.642 1014.09 245.187C1033.6 243.108 1052.25 246.903 1067.78 258.436C1091.6 277.114 1100.35 300.255 1094.73 328.609C1090.99 347.754 1081.52 367.852 1069.02 385.689C1059.44 399.364 1048.4 411.952 1036.09 423.229C1030.37 425.536 1024.78 428.225 1019.24 430.828C1012.1 434.437 1004.6 437.26 996.853 439.247C973.325 444.83 953.05 455.112 933.631 470.678C925.111 477.867 914.942 482.832 904.033 485.128C896.994 486.601 889.738 486.719 882.654 485.476C872.888 483.643 863.481 480.246 854.797 475.415C837.432 466.005 821.063 454.755 805.404 443.788C790.75 436.159 776.674 427.468 763.287 417.786C758.137 414.302 752.844 410.902 748.051 408.033C722.093 398.711 696.523 384.905 676.142 362.331C673.135 358.99 670.303 355.496 667.658 351.861C642.561 316.823 633.031 265.684 667.086 226.631C687.99 202.638 714.647 194.924 744.469 199.092C758.7 201.07 772.544 206.529 786.449 211.366C797.107 215.084 807.381 219.866 817.919 223.891C828.807 228.045 839.534 228.737 850.462 224.478C873.72 215.571 897.308 218.143 920.565 227.497Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M928.148 216.557C935.78 219.936 943.456 223.459 950.809 227.493C972.613 239.4 995.215 242.723 1019.34 237.452C1023.57 236.533 1027.79 235.794 1032.03 235.265C1053.61 232.748 1074.23 236.747 1091.68 249.501C1118.27 269.881 1128.24 295.57 1122.24 327.212C1118.24 348.685 1107.77 371.185 1093.84 391.171C1083.2 406.512 1070.81 420.56 1056.92 433.031C1050.47 435.539 1044.17 438.408 1037.82 441.24C1029.69 445.149 1021.17 448.177 1012.4 450.273C985.764 456.12 963.184 467.617 941.722 485.486C932.339 493.725 921.047 499.493 908.871 502.265C901.024 504.025 892.912 504.263 884.975 502.964C874.067 501.05 863.563 497.302 853.909 491.877C834.569 481.337 816.445 468.53 799.146 455.918C783.105 446.964 768.077 437.357 753.253 427.519C747.488 423.889 741.576 420.4 736.051 417.501C706.306 407.671 677.268 392.632 654.221 367.62C650.816 363.919 647.608 360.042 644.61 356.005C616.193 317.178 605.002 259.87 643.054 216.194C666.499 189.25 696.429 180.703 729.943 185.512C745.922 187.789 761.434 193.986 777.018 199.42C789.005 203.607 800.549 209.016 812.409 213.525C824.677 218.208 836.732 218.894 849.064 213.946C875.236 203.483 901.829 206.105 928.148 216.557Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M935.684 205.54C944.121 209.164 952.569 212.987 960.678 217.381C984.674 230.383 1009.51 233.891 1036.01 227.813C1040.65 226.757 1045.28 225.904 1049.92 225.295C1073.5 222.335 1096.14 226.524 1115.53 240.517C1144.8 262.625 1156.05 290.822 1149.67 325.78C1145.38 349.528 1133.91 374.465 1118.56 396.664C1106.86 413.664 1093.13 429.173 1077.67 442.844C1070.46 445.533 1063.35 448.619 1056.31 451.661C1047.21 455.86 1037.68 459.087 1027.9 461.29C998.192 467.387 973.236 480.126 949.784 500.291C939.517 509.566 927.095 516.13 913.647 519.386C904.997 521.43 896.035 521.789 887.249 520.443C875.211 518.45 863.615 514.362 852.988 508.366C831.697 496.687 811.797 482.303 792.869 468.042C775.762 457.822 759.533 447.425 743.178 437.254C736.825 433.482 730.27 429.912 724.091 426.938C690.583 416.592 658.046 400.346 632.328 372.884C628.524 368.826 624.943 364.564 621.601 360.118C589.858 317.518 577.002 254.031 619.032 205.752C644.971 175.875 678.228 166.462 715.428 171.9C733.145 174.48 750.341 181.422 767.614 187.477C780.918 192.136 793.739 198.156 806.918 203.177C820.558 208.366 833.96 209.064 847.711 203.395C876.739 191.4 906.365 194.142 935.684 205.54Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M943.239 194.569C952.468 198.443 961.707 202.544 970.548 207.307C996.754 221.413 1023.87 225.139 1052.69 218.207C1057.74 217.01 1062.8 216.044 1067.82 215.359C1093.44 211.946 1118.07 216.347 1139.4 231.568C1171.46 255.357 1183.91 286.11 1177.1 324.372C1172.54 350.389 1160.08 377.758 1143.32 402.073C1130.55 420.734 1115.47 437.703 1098.44 452.573C1090.48 455.445 1082.64 458.783 1074.82 462.005C1064.55 466.311 1054.14 470.171 1043.44 472.224C1010.72 478.559 983.335 492.549 957.875 515.002C946.739 525.326 933.192 532.694 918.474 536.431C909.017 538.765 899.197 539.243 889.558 537.839C876.375 535.775 863.676 531.337 852.077 524.741C828.817 511.916 807.151 495.979 786.566 480.067C768.374 468.603 750.95 457.398 733.092 446.898C726.247 443.002 719.2 439.468 711.983 436.312C674.705 425.466 638.692 408.004 610.292 378.097C606.091 373.678 602.133 369.034 598.438 364.184C563.375 317.795 548.911 248.134 594.885 195.238C623.428 162.398 659.924 152.166 700.794 158.227C720.264 161.117 739.128 168.797 758.059 175.432C772.708 180.565 786.796 187.201 801.296 192.706C816.299 198.416 831.026 199.136 846.107 192.795C878.252 179.312 910.893 182.126 943.239 194.569Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M950.791 183.587C960.892 187.632 970.781 192.187 980.42 197.234C1008.83 212.443 1038.13 216.309 1069.38 208.614C1074.85 207.275 1080.3 206.189 1085.73 205.411C1113.37 201.558 1140.01 206.154 1163.23 222.633C1198.02 248.115 1211.73 281.413 1204.51 322.989C1199.64 351.284 1186.21 381.082 1168.06 407.543C1154.25 427.86 1137.83 446.285 1119.24 462.349C1110.54 465.398 1101.94 468.975 1093.37 472.391C1082.09 476.943 1070.71 481.07 1059 483.205C1023.15 489.809 993.459 505.03 965.979 529.776C952.777 541.885 938.373 549.703 923.304 553.516C913.054 556.146 902.39 556.761 891.906 555.329C877.581 553.184 863.78 548.396 851.204 541.21C825.992 527.246 802.539 509.737 780.342 492.183C761.106 479.444 742.458 467.458 723.087 456.605C715.616 452.544 707.915 448.919 700.024 445.749C658.954 434.383 619.466 415.705 588.393 383.376C583.803 378.57 579.467 373.526 575.405 368.266C537.034 318.084 520.851 242.278 570.857 184.745C601.957 148.96 641.722 137.885 686.271 144.591C707.473 147.773 728.036 156.232 748.633 163.43C764.598 169.036 779.963 176.282 795.783 182.3C812.161 188.527 828.267 189.234 844.755 182.176C879.768 167.224 915.432 170.134 950.791 183.587Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M958.344 172.616C969.251 176.892 979.92 181.75 990.306 187.167C1020.91 203.473 1052.44 207.524 1086.06 199.008C1091.94 197.533 1097.81 196.328 1103.63 195.475C1133.3 191.186 1161.96 195.972 1187.13 213.669C1224.66 240.855 1239.58 276.622 1232 321.556C1226.87 352.116 1212.42 384.361 1192.83 413.016C1177.94 434.991 1160.17 454.876 1140 472.14C1130.55 475.382 1121.19 479.169 1111.87 482.807C1099.61 487.63 1087.23 491.987 1074.51 494.2C1035.6 501.065 1003.53 517.517 974.065 544.543C959.868 557.785 944.369 566.385 928.125 570.617C917.059 573.516 905.536 574.239 894.195 572.746C878.728 570.511 863.823 565.37 850.268 557.595C823.088 542.485 797.868 523.423 774.048 504.231C753.749 490.238 733.896 477.45 713.005 466.26C704.903 462.034 696.544 458.32 687.977 455.139C643.143 443.257 600.168 423.367 566.424 388.588C561.437 383.438 556.734 378.022 552.334 372.362C510.637 318.407 492.789 236.422 546.796 174.277C580.42 135.534 623.482 123.617 671.706 130.957C694.669 134.433 716.89 143.607 739.18 151.437C756.463 157.516 773.11 165.384 790.251 171.887C807.993 178.635 825.462 179.389 843.335 171.596C881.281 155.136 919.957 158.147 958.344 172.616Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M965.896 161.634C977.597 166.159 989.041 171.32 1000.18 177.095C1032.98 194.496 1066.74 198.732 1102.75 189.415C1108.96 187.796 1115.25 186.495 1121.59 185.516C1153.28 180.779 1183.94 185.76 1211.04 204.702C1251.31 233.587 1267.46 272.064 1259.48 320.134C1254.07 352.975 1238.62 387.657 1217.61 418.421C1201.09 442.637 1181.47 464.65 1160.79 481.865C1150.58 485.29 1140.48 489.311 1130.41 493.142C1117.16 498.242 1103.78 502.828 1090.06 505.13C1048.09 512.241 1013.64 529.936 982.162 559.266C966.962 573.619 950.336 582.958 932.937 587.656C921.07 590.844 908.695 591.688 896.506 590.143C881.015 588.17 865.177 582.811 849.318 573.906C820.186 557.657 793.195 537.03 767.69 516.184C746.325 500.926 725.259 487.352 702.875 475.856C694.138 471.469 685.123 467.659 675.887 464.453C627.272 452.075 580.809 430.906 544.406 393.74C539.026 388.226 533.947 382.427 529.189 376.368C484.161 318.561 464.676 230.467 522.655 163.707C558.823 121.985 605.172 109.271 657.079 117.228C681.782 121.019 705.684 130.926 729.648 139.343C748.264 145.902 766.201 154.444 784.673 161.386C803.772 168.604 822.593 169.382 841.864 160.917C882.796 143.048 924.476 146.137 965.896 161.634Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M973.463 150.659C985.955 155.423 998.17 160.885 1010.05 167.021C1045.07 185.521 1081.04 189.935 1119.45 179.868C1126.07 178.115 1132.77 176.698 1139.53 175.622C1173.23 170.449 1205.92 175.632 1234.94 195.807C1277.95 226.401 1295.39 267.282 1286.97 318.786C1281.26 353.89 1264.82 391.021 1242.42 423.987C1224.8 449.898 1203.81 473.478 1181.57 491.77C1170.61 495.377 1159.75 499.62 1148.93 503.661C1134.69 509.025 1120.32 513.844 1105.6 516.24C1060.54 523.565 1023.71 542.492 990.245 574.157C974.057 589.627 956.347 599.81 937.748 604.83C925.076 608.31 911.846 609.278 898.803 607.68C882.245 605.65 865.321 599.955 848.442 590.471C817.358 573.083 788.559 550.866 761.481 528.375C739.054 511.863 716.783 497.486 692.876 485.614C683.505 481.071 673.835 477.171 663.934 473.941C611.532 461.056 561.617 438.729 522.473 399.074C516.695 393.201 511.239 387.019 506.129 380.556C457.798 319.034 436.636 224.698 498.589 153.324C537.327 108.692 586.943 95.0942 642.524 103.685C668.977 107.775 694.593 118.401 720.183 127.451C740.127 134.478 759.265 143.607 779.105 151.083C799.598 158.865 819.748 159.609 840.403 150.436C884.298 130.965 929.018 134.157 973.463 150.659Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M981.014 139.677C994.308 144.68 1007.3 150.445 1019.93 156.943C1057.15 176.551 1095.35 181.15 1136.11 170.205C1143.14 168.334 1150.26 166.82 1157.44 165.67C1193.16 160.05 1227.9 165.425 1258.81 186.841C1304.57 219.136 1323.29 262.58 1314.41 317.361C1308.43 354.746 1290.98 394.302 1267.18 429.437C1248.46 457.038 1226.07 482.08 1202.39 501.521C1190.68 505.31 1179.09 509.781 1167.5 514.021C1152.27 519.65 1136.91 524.733 1121.19 527.195C1073.08 534.812 1033.88 554.982 998.405 588.936C981.23 605.523 962.407 616.481 942.641 621.972C929.157 625.716 915.072 626.79 901.176 625.132C883.562 623.04 865.553 617.009 847.572 606.912C814.519 588.378 783.963 564.631 755.243 540.456C731.789 522.676 708.276 507.511 682.85 495.302C672.841 490.593 662.511 486.6 651.938 483.353C595.772 469.95 542.365 446.4 500.637 404.274C494.464 398.043 488.643 391.473 483.201 384.596C431.487 319.23 408.613 218.8 474.609 142.783C515.895 95.2518 568.754 80.7122 628.022 90.0004C656.207 94.3813 683.461 105.772 710.777 115.4C732.055 122.907 752.516 132.628 773.613 140.628C795.476 148.915 816.975 149.671 839.03 139.798C885.813 118.877 933.543 122.13 981.014 139.677Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M988.569 128.706C1002.66 133.948 1016.43 140.011 1029.81 146.866C1069.25 167.588 1109.67 172.349 1152.8 160.607C1160.23 158.574 1167.75 156.929 1175.35 155.678C1213.12 149.612 1249.78 155.199 1282.68 177.836C1331.17 211.823 1351.09 257.78 1341.85 315.908C1335.59 355.55 1317.16 397.552 1291.93 434.851C1272.1 464.147 1248.35 490.7 1223.17 511.25C1210.7 515.222 1198.36 519.903 1186.02 524.365C1169.79 530.259 1153.46 535.573 1136.73 538.13C1085.55 545.997 1043.98 567.404 1006.48 603.612C988.307 621.322 968.382 633.077 947.473 638.961C933.191 643.007 918.255 644.216 903.508 642.519C884.831 640.381 865.744 633.999 846.684 623.278C811.616 603.617 779.34 578.298 748.928 552.486C724.377 533.466 699.683 517.489 672.759 504.934C662.105 500.078 651.113 495.998 639.87 492.724C579.981 478.803 523.04 454.06 478.606 409.498C472.033 402.906 465.828 395.957 460.019 388.683C405.014 319.598 380.559 212.928 450.505 132.306C494.329 81.7839 550.51 66.5007 613.442 76.3598C643.391 81.0462 672.313 93.1745 701.309 103.401C723.904 111.38 745.647 121.724 768.065 130.208C791.298 139.029 814.14 139.785 837.583 129.216C887.351 106.847 938.083 110.178 988.569 128.706Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M996.061 117.748C1010.95 123.224 1025.49 129.59 1039.61 136.809C1081.24 158.6 1123.89 163.573 1169.41 151.006C1177.24 148.835 1185.18 147.076 1193.2 145.737C1232.96 139.194 1271.68 145.009 1306.48 168.887C1357.7 204.579 1378.88 253.07 1369.29 314.487C1362.76 356.463 1343.31 400.843 1316.67 440.322C1295.75 471.296 1270.61 499.369 1243.93 521.041C1230.68 525.204 1217.61 530.11 1204.52 534.753C1187.3 540.912 1169.97 546.479 1152.22 549.067C1097.98 557.179 1054.03 579.846 1014.53 618.342C995.365 637.169 974.327 649.698 952.236 656.018C937.147 660.349 921.355 661.677 905.754 659.928C886.02 657.727 865.836 651.014 845.693 639.659C808.795 618.823 774.627 591.975 742.633 564.495C717.019 544.222 691.124 527.454 662.676 514.55C651.376 509.536 639.717 505.369 627.798 502.085C564.07 487.663 503.717 461.692 456.588 414.69C449.622 407.733 443.041 400.402 436.875 392.728C378.614 319.91 352.459 207.008 426.365 121.776C472.731 68.3024 532.197 52.1824 598.815 62.6706C630.513 67.6555 661.108 80.5336 691.795 91.3529C715.718 99.8 738.727 110.759 762.488 119.814C787.091 129.14 811.352 129.949 836.11 118.605C888.83 94.706 942.621 98.186 996.061 117.748Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M1003.68 106.754C1019.36 112.472 1034.69 119.144 1049.56 126.731C1093.39 149.629 1138.2 154.736 1186.15 141.405C1194.39 139.096 1202.74 137.22 1211.18 135.784C1252.99 128.836 1293.7 134.81 1330.43 159.933C1384.39 197.329 1406.8 248.358 1396.75 313.081C1389.93 357.29 1369.49 404.12 1341.46 445.79C1319.42 478.459 1292.9 508.041 1264.72 530.834C1250.73 535.118 1236.91 540.301 1223.01 545.174C1204.82 551.592 1186.5 557.408 1167.76 560.125C1110.44 568.498 1064.11 592.441 1022.62 633.177C1002.45 653.126 980.349 666.506 957.049 673.192C941.163 677.803 924.527 679.255 908.082 677.466C887.268 675.213 866.006 668.148 844.784 656.169C805.859 634.212 769.983 605.787 736.356 576.646C709.675 555.107 682.591 537.544 652.6 524.313C640.683 519.136 628.392 514.865 615.832 511.538C548.323 496.62 484.494 469.433 434.695 419.981C427.366 412.652 420.441 404.929 413.952 396.847C352.343 320.17 324.528 201.168 402.459 111.315C451.389 54.9479 514.112 37.921 584.41 49.0548C617.84 54.3313 650.12 67.9542 682.463 79.3808C707.761 88.277 732.005 99.8773 757.069 109.37C783.045 119.241 808.586 119.994 834.796 108.01C890.331 82.5825 947.149 86.1985 1003.68 106.754Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M1011.23 95.7712C1027.7 101.736 1043.79 108.716 1059.4 116.671C1105.42 140.662 1152.55 146.005 1202.8 131.826C1211.44 129.377 1220.2 127.384 1229.06 125.858C1272.88 118.461 1315.62 124.638 1354.27 150.993C1410.96 190.094 1434.63 243.644 1424.18 311.688C1417.07 358.16 1395.64 407.462 1366.19 451.265C1343.05 485.629 1315.16 516.726 1285.47 540.629C1270.75 545.142 1256.15 550.5 1241.56 555.56C1222.36 562.247 1203.05 568.3 1183.31 571.106C1122.92 579.729 1074.24 604.861 1030.72 647.934C1009.57 669.017 986.328 683.114 961.885 690.261C945.195 695.171 927.702 696.747 910.404 694.897C888.534 692.582 866.176 685.186 843.886 672.58C802.997 649.488 765.346 619.487 730.09 588.696C702.347 565.904 674.034 547.547 642.547 533.971C629.989 528.639 617.039 524.279 603.813 520.93C532.541 505.496 465.219 477.085 412.754 425.195C404.984 417.53 397.643 409.44 390.768 400.963C325.839 320.496 296.352 195.331 378.256 100.864C429.719 41.494 495.716 23.6352 569.719 35.4449C604.903 41.0316 638.827 55.4019 672.873 67.4168C699.457 76.8116 724.998 89.0013 751.388 98.9901C778.73 109.354 805.629 110.144 833.234 97.4476C891.859 70.5293 951.686 74.2065 1011.23 95.7712Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M1018.79 84.796C1036.07 90.9977 1052.94 98.2746 1069.31 106.584C1117.54 131.678 1166.89 137.194 1219.51 122.206C1228.56 119.619 1237.74 117.515 1247.01 115.903C1292.87 108.066 1337.61 114.426 1378.2 142.021C1437.61 182.83 1462.53 238.915 1451.67 310.261C1444.28 359.014 1421.85 410.753 1390.98 456.717C1366.74 492.77 1337.49 525.384 1306.29 550.396C1290.81 555.091 1275.47 560.683 1260.12 565.941C1239.94 572.9 1219.64 579.189 1198.89 582.078C1135.44 590.946 1084.38 617.326 1038.85 662.699C1016.71 684.888 992.361 699.782 966.744 707.348C949.249 712.532 930.905 714.225 912.757 712.331C889.819 709.958 866.392 702.234 843.018 688.993C800.173 664.75 760.802 633.177 723.843 600.754C695.05 576.703 665.519 557.549 632.524 543.631C619.34 538.14 605.748 533.685 591.871 530.307C516.812 514.365 446.008 484.687 390.879 430.398C382.731 422.361 375.03 413.882 367.812 405C299.569 320.757 268.421 189.424 354.303 90.286C408.312 27.9767 477.675 9.31575 555.267 21.7129C592.199 27.598 627.807 42.6456 663.547 55.3611C691.448 65.2285 718.3 78.0429 746.007 88.5721C774.708 99.4464 802.966 100.243 831.96 86.8516C893.375 58.4415 956.214 62.2193 1018.79 84.796Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M1026.35 73.8374C1044.43 80.278 1062.08 87.8571 1079.2 96.5295C1129.63 122.731 1181.21 128.432 1236.21 112.637C1245.66 109.905 1255.24 107.681 1264.93 105.974C1312.83 97.6969 1359.56 104.268 1402.06 133.1C1464.22 175.598 1490.34 234.234 1479.1 308.892C1471.43 359.903 1448.01 414.091 1415.73 462.211C1390.39 499.971 1359.76 534.077 1327.06 560.21C1310.83 565.087 1294.73 570.901 1278.63 576.365C1257.45 583.553 1236.17 590.131 1214.42 593.097C1147.9 602.227 1094.47 629.832 1046.94 677.495C1023.78 700.81 998.351 716.481 971.559 724.467C953.259 729.942 934.06 731.758 915.059 729.812C891.023 727.407 866.552 719.316 842.088 705.458C797.305 680.111 756.074 646.955 717.556 612.854C687.69 587.554 656.965 567.592 622.45 553.338C608.612 547.7 594.352 543.156 579.803 539.746C500.974 523.304 426.701 492.461 368.883 435.676C360.333 427.282 352.256 418.421 344.689 409.132C273.126 321.1 240.318 183.599 330.215 79.884C386.76 14.6117 459.346 -4.87405 540.675 8.14508C579.356 14.3288 616.649 30.189 654.042 43.4042C683.272 53.7396 711.389 67.1693 740.392 78.1526C770.48 89.5392 800.075 90.353 830.457 76.2657C894.877 46.3584 960.688 50.2395 1026.35 73.8374Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M1033.89 62.8317C1052.77 69.5167 1071.2 77.4037 1089.06 86.4443C1141.7 113.726 1195.5 119.617 1252.88 103.008C1262.74 100.137 1272.74 97.7997 1282.85 96.0054C1332.75 87.285 1381.5 94.0579 1425.95 124.113C1490.85 168.315 1518.25 229.476 1506.58 307.441C1498.62 360.738 1474.21 417.347 1440.51 467.639C1414.08 507.089 1382.07 542.767 1347.85 569.958C1330.9 575.02 1314.04 581.06 1297.17 586.739C1275.01 594.215 1252.72 600.994 1229.98 604.05C1160.4 613.424 1104.59 642.273 1055.06 692.248C1030.98 716.652 1004.37 733.142 976.423 741.605C957.312 747.351 937.257 749.273 917.403 747.262C892.345 744.78 866.743 736.373 841.207 721.875C794.446 695.359 751.475 660.68 711.316 624.935C680.383 598.369 648.441 577.61 612.441 563.073C597.957 557.25 583.032 552.588 567.808 549.129C485.191 532.18 407.443 500.121 346.954 440.885C338.013 432.128 329.563 422.883 321.644 413.191C246.755 321.386 212.282 177.706 306.182 69.4184C365.284 1.20181 441.144 -19.1161 526.16 -5.46779C566.578 1.01915 605.551 17.6126 644.611 31.4303C675.146 42.1753 704.564 56.274 734.898 67.765C766.35 79.6732 797.293 80.4993 829.042 65.6977C896.39 34.2701 965.272 38.2286 1033.89 62.8317Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M1041.4 51.8803C1061.07 58.8019 1080.27 66.989 1098.89 76.3902C1153.75 104.765 1209.76 110.839 1269.51 93.4217C1279.78 90.4197 1290.19 87.9703 1300.72 86.0839C1352.65 76.9113 1403.41 83.8791 1449.78 115.179C1517.42 161.085 1546.06 224.768 1533.98 306.047C1525.74 361.613 1500.34 420.671 1465.22 473.135C1437.69 514.264 1404.29 551.41 1368.59 579.758C1350.86 585.011 1333.28 591.288 1315.66 597.148C1292.5 604.89 1269.23 611.916 1245.48 615.055C1172.82 624.679 1114.64 654.783 1063.1 707.022C1037.97 732.568 1010.31 749.819 981.178 758.639C961.27 764.687 940.364 766.745 919.659 764.693C893.545 762.15 866.867 753.43 840.243 738.286C791.525 710.619 746.771 674.381 704.984 636.986C672.998 609.19 639.834 587.621 602.303 572.685C587.185 566.726 571.611 561.998 555.732 558.546C469.406 541.044 388.147 507.783 324.992 446.109C315.66 436.988 306.838 427.358 298.567 417.264C220.358 321.67 184.225 171.823 282.091 58.8694C343.738 -12.2869 422.89 -33.4581 511.58 -19.1759C553.747 -12.3904 594.393 4.95279 635.132 19.3683C667.012 30.656 697.685 45.307 729.347 57.3337C762.174 69.7588 794.466 70.5973 827.627 55.0906C897.905 22.1824 969.811 26.2371 1041.4 51.8803Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M1049 40.879C1069.48 48.0541 1089.47 56.5592 1108.84 66.34C1165.88 95.8324 1224.13 102.082 1286.27 83.8558C1296.94 80.7203 1307.77 78.1628 1318.71 76.1943C1372.68 66.6324 1425.45 73.7554 1473.74 106.288C1544.1 153.875 1574 220.119 1561.53 304.703C1553 362.543 1526.59 424.028 1490.08 478.682C1461.42 521.448 1426.62 560.175 1389.46 589.625C1370.98 595.061 1352.65 601.547 1334.31 607.616C1310.16 615.622 1285.91 622.892 1261.15 626.113C1185.43 635.994 1124.87 667.329 1071.32 721.868C1045.2 748.536 1016.44 766.569 986.126 775.813C965.413 782.141 943.655 784.316 922.101 782.213C894.919 779.612 867.157 770.461 839.464 754.785C788.778 725.972 742.246 688.185 698.825 649.129C665.763 620.045 631.419 597.704 592.369 582.431C576.609 576.319 560.379 571.496 543.838 568.011C453.672 550.037 368.972 515.545 303.123 451.431C293.39 441.953 284.191 431.94 275.571 421.44C194.054 322.052 156.261 166.037 258.088 48.4738C322.192 -25.7083 404.647 -47.7374 497.069 -32.8168C540.973 -25.7281 583.303 -7.63995 625.722 7.3733C658.954 19.1196 690.892 34.3861 723.843 46.8832C758.03 59.8183 791.681 60.6643 826.225 44.4502C899.42 10.0942 974.35 14.2449 1049 40.879Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M1056.56 29.9088C1077.7 37.4833 1098.76 45.9319 1118.72 56.2061C1177.97 86.79 1238.46 93.2243 1302.94 74.1433C1314.01 70.8591 1325.25 68.1779 1336.61 66.1115C1392.6 56.0623 1447.4 63.4736 1497.6 97.1917C1570.71 146.507 1601.83 215.226 1588.97 303.171C1580.16 363.28 1552.76 427.214 1514.83 484.001C1485.1 528.555 1448.94 568.686 1410.23 599.264C1391 604.877 1371.91 611.59 1352.78 617.883C1327.62 626.158 1302.4 633.66 1276.64 636.97C1197.83 647.055 1134.93 679.675 1079.35 736.512C1052.23 764.302 1022.36 783.121 990.88 792.779C969.364 799.398 946.754 801.7 924.346 799.554C896.132 796.881 867.245 787.465 838.472 771.098C785.835 741.146 737.527 701.783 692.478 661.078C658.354 630.739 622.793 607.601 582.234 591.986C565.835 585.72 548.95 580.807 531.75 577.296C437.808 558.81 349.621 523.107 281.101 456.543C270.974 446.703 261.404 436.306 252.433 425.401C167.59 322.241 128.12 160.051 233.972 37.8664C300.693 -39.2439 386.423 -62.0373 482.463 -46.4873C528.118 -39.1282 572.135 -20.2554 616.224 -4.66766C650.749 7.56079 683.97 23.4495 718.278 36.4169C753.844 49.8805 788.839 50.7273 824.771 33.8176C900.923 -1.98879 978.876 2.25805 1056.56 29.9088Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M1064.12 18.9217C1086.05 26.7627 1107.9 35.4893 1128.56 46.1469C1190.01 77.834 1252.72 84.4579 1319.61 64.6105C1331.09 61.1794 1342.74 58.3805 1354.52 56.2267C1412.51 45.7347 1469.33 53.3246 1521.46 88.2986C1597.32 139.313 1629.69 210.594 1616.4 301.79C1607.31 364.185 1578.91 430.544 1539.51 489.523C1508.67 535.732 1471.13 577.423 1430.93 609.118C1410.95 614.913 1391.12 621.843 1371.23 628.334C1345.11 636.876 1318.87 644.624 1292.11 648.04C1210.25 658.433 1144.94 692.237 1087.4 751.361C1059.3 780.331 1028.28 799.887 995.693 809.925C973.375 816.828 949.914 819.252 926.658 817.058C897.352 814.336 867.357 804.594 837.559 787.569C782.957 756.483 732.884 715.567 686.201 673.201C651.064 641.562 614.273 617.63 572.141 601.647C555.106 595.227 537.572 590.222 519.714 586.682C421.986 567.688 330.272 530.776 259.133 461.755C248.609 451.555 238.662 440.775 229.338 429.467C141.199 322.576 100.062 154.167 209.881 27.3568C279.167 -52.6465 368.158 -76.3352 467.885 -60.156C515.286 -52.4703 560.969 -32.8992 606.735 -16.6857C642.591 -4.01732 677.091 12.5217 712.716 26.0296C749.642 40.0031 785.985 40.8622 823.317 23.2521C902.437 -14.077 983.414 -9.73393 1064.12 18.9217Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M1071.67 7.95118C1094.4 16.0257 1117.02 25.0513 1138.44 36.0688C1202.09 68.8638 1267.03 75.661 1336.3 55.0052C1348.18 51.4441 1360.24 48.5295 1372.43 46.2743C1432.46 35.3417 1491.26 43.108 1545.34 79.3448C1623.93 132.052 1657.54 205.86 1643.85 300.388C1634.49 365.053 1605.1 433.845 1564.33 494.965C1532.39 542.864 1493.48 586.059 1451.78 618.875C1431.04 624.853 1410.45 632.004 1389.81 638.706C1362.69 647.513 1335.52 655.49 1307.69 658.984C1222.78 669.617 1155.15 704.646 1095.54 766.081C1066.43 796.11 1034.37 816.532 1000.53 826.994C977.393 834.197 953.058 836.747 928.931 834.496C898.573 831.724 867.536 821.616 836.577 804.001C780.023 771.776 728.162 729.288 679.839 685.277C643.598 652.415 605.619 627.688 562.02 611.4C544.34 604.826 526.146 599.728 507.623 596.159C406.136 576.66 310.975 538.466 237.102 467.075C226.181 456.514 215.857 445.352 206.179 433.641C114.702 322.914 71.9231 148.384 185.739 16.9499C257.57 -65.993 349.842 -90.5302 453.244 -73.7171C502.398 -65.7211 549.749 -45.4121 597.194 -28.6008C634.382 -15.4247 670.136 1.66675 707.071 15.6637C745.376 30.1658 783.079 31.0325 821.79 12.7034C903.952 -26.1651 987.942 -21.7212 1071.67 7.95118Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M1472.56 628.657C1422.84 642.907 1373.93 663.696 1323.21 669.912C1235.23 680.794 1165.18 717.08 1103.62 780.797C1027.25 859.803 931.037 874.698 835.731 820.337C777.209 786.966 723.582 742.925 673.625 697.253C621.607 649.7 565.934 618.816 495.667 605.472C375.036 582.545 263.151 534.207 183.198 437.62C88.4017 323.104 43.9553 142.424 161.756 6.37015C236.14 -79.5288 331.684 -104.898 438.768 -87.4675C489.613 -79.1495 538.69 -58.126 587.874 -40.7404C626.394 -27.0847 663.428 -9.34292 701.68 5.12668C741.356 20.1341 780.396 21.0179 820.486 1.96977C905.492 -38.358 992.5 -33.8298 1079.25 -3.13608C1102.77 5.18839 1126.21 14.5504 1148.37 25.9606C1214.22 59.8471 1281.39 66.8292 1353.03 45.3533C1427.92 22.9144 1502.06 25.3643 1569.26 70.3443C1650.59 124.756 1685.46 201.109 1671.35 298.928C1654.1 418.364 1570.92 551.469 1472.56 628.657Z"
                    stroke={strokeColor}
                    strokeMiterlimit="10"/>
                <path
                    d="M928.148 216.557C935.78 219.936 943.456 223.459 950.809 227.493C972.613 239.4 995.215 242.723 1019.34 237.452C1023.57 236.533 1027.79 235.794 1032.03 235.265C1053.61 232.748 1074.23 236.747 1091.68 249.501C1118.27 269.881 1128.24 295.57 1122.24 327.212C1118.24 348.685 1107.77 371.185 1093.84 391.171C1083.2 406.512 1070.81 420.56 1056.92 433.031C1050.47 435.539 1044.17 438.408 1037.82 441.24C1029.69 445.149 1021.17 448.177 1012.4 450.273C985.764 456.12 963.184 467.617 941.722 485.486C932.339 493.725 921.047 499.493 908.871 502.265C901.024 504.025 892.912 504.263 884.975 502.964C874.067 501.05 863.563 497.302 853.909 491.877C834.569 481.337 816.445 468.53 799.146 455.918C783.105 446.964 768.077 437.357 753.253 427.519C747.488 423.889 741.576 420.4 736.051 417.501C706.306 407.671 677.268 392.632 654.221 367.62C650.816 363.919 647.608 360.042 644.61 356.005C616.193 317.178 605.002 259.87 643.054 216.194C666.499 189.25 696.429 180.703 729.943 185.512C745.922 187.789 761.434 193.986 777.018 199.42C789.005 203.607 800.549 209.016 812.409 213.525C824.677 218.208 836.732 218.894 849.064 213.946C875.236 203.483 901.829 206.105 928.148 216.557Z"
                    stroke={strokeColor}
                    strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M958.344 172.616C969.251 176.892 979.92 181.75 990.306 187.167C1020.91 203.473 1052.44 207.524 1086.06 199.008C1091.94 197.533 1097.81 196.328 1103.63 195.475C1133.3 191.186 1161.96 195.972 1187.13 213.669C1224.66 240.855 1239.58 276.622 1232 321.556C1226.87 352.116 1212.42 384.361 1192.83 413.016C1177.94 434.991 1160.17 454.876 1140 472.14C1130.55 475.382 1121.19 479.169 1111.87 482.807C1099.61 487.63 1087.23 491.987 1074.51 494.2C1035.6 501.065 1003.53 517.517 974.065 544.543C959.868 557.785 944.369 566.385 928.125 570.617C917.059 573.516 905.536 574.239 894.195 572.746C878.728 570.511 863.823 565.37 850.268 557.595C823.088 542.485 797.868 523.423 774.048 504.231C753.749 490.238 733.896 477.45 713.005 466.26C704.903 462.034 696.544 458.32 687.977 455.139C643.143 443.257 600.168 423.367 566.424 388.588C561.437 383.438 556.734 378.022 552.334 372.362C510.637 318.407 492.789 236.422 546.796 174.277C580.42 135.534 623.482 123.617 671.706 130.957C694.669 134.433 716.89 143.607 739.18 151.437C756.463 157.516 773.11 165.384 790.251 171.887C807.993 178.635 825.462 179.389 843.335 171.596C881.281 155.136 919.957 158.147 958.344 172.616Z"
                    stroke={strokeColor}
                    strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M988.569 128.706C1002.66 133.948 1016.43 140.011 1029.81 146.866C1069.25 167.588 1109.67 172.349 1152.8 160.607C1160.23 158.574 1167.75 156.929 1175.35 155.678C1213.12 149.612 1249.78 155.199 1282.68 177.836C1331.17 211.823 1351.09 257.78 1341.85 315.908C1335.59 355.55 1317.16 397.552 1291.93 434.851C1272.1 464.147 1248.35 490.7 1223.17 511.25C1210.7 515.222 1198.36 519.903 1186.02 524.365C1169.79 530.259 1153.46 535.573 1136.73 538.13C1085.55 545.997 1043.98 567.404 1006.48 603.612C988.307 621.322 968.382 633.077 947.473 638.961C933.191 643.007 918.255 644.216 903.508 642.519C884.831 640.381 865.744 633.999 846.684 623.278C811.616 603.617 779.34 578.298 748.928 552.486C724.377 533.466 699.683 517.489 672.759 504.934C662.105 500.078 651.113 495.998 639.87 492.724C579.981 478.803 523.04 454.06 478.606 409.498C472.033 402.906 465.828 395.957 460.019 388.683C405.014 319.598 380.559 212.928 450.505 132.306C494.329 81.7839 550.51 66.5007 613.442 76.3598C643.391 81.0462 672.313 93.1745 701.309 103.401C723.904 111.38 745.647 121.724 768.065 130.208C791.298 139.029 814.14 139.785 837.583 129.216C887.351 106.847 938.083 110.178 988.569 128.706Z"
                    stroke={strokeColor}
                    strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M1018.79 84.796C1036.07 90.9977 1052.94 98.2746 1069.31 106.584C1117.54 131.678 1166.89 137.194 1219.51 122.206C1228.56 119.619 1237.74 117.515 1247.01 115.903C1292.87 108.066 1337.61 114.426 1378.2 142.021C1437.61 182.83 1462.53 238.915 1451.67 310.261C1444.28 359.014 1421.85 410.753 1390.98 456.717C1366.74 492.77 1337.49 525.384 1306.29 550.396C1290.81 555.091 1275.47 560.683 1260.12 565.941C1239.94 572.9 1219.64 579.189 1198.89 582.078C1135.44 590.946 1084.38 617.326 1038.85 662.699C1016.71 684.888 992.361 699.782 966.744 707.348C949.249 712.532 930.905 714.225 912.757 712.331C889.819 709.958 866.392 702.234 843.018 688.993C800.173 664.75 760.802 633.177 723.843 600.754C695.05 576.703 665.519 557.549 632.524 543.631C619.34 538.14 605.748 533.685 591.871 530.307C516.812 514.365 446.008 484.687 390.879 430.398C382.731 422.361 375.03 413.882 367.812 405C299.569 320.757 268.421 189.424 354.303 90.286C408.312 27.9767 477.675 9.31575 555.267 21.7129C592.199 27.598 627.807 42.6456 663.547 55.3611C691.448 65.2285 718.3 78.0429 746.007 88.5721C774.708 99.4464 802.966 100.243 831.96 86.8516C893.375 58.4415 956.214 62.2193 1018.79 84.796Z"
                    stroke={strokeColor}
                    strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M1049 40.879C1069.48 48.0541 1089.47 56.5592 1108.84 66.34C1165.88 95.8324 1224.13 102.082 1286.27 83.8558C1296.94 80.7203 1307.77 78.1628 1318.71 76.1943C1372.68 66.6324 1425.45 73.7554 1473.74 106.288C1544.1 153.875 1574 220.119 1561.53 304.703C1553 362.543 1526.59 424.028 1490.08 478.682C1461.42 521.448 1426.62 560.175 1389.46 589.625C1370.98 595.061 1352.65 601.547 1334.31 607.616C1310.16 615.622 1285.91 622.892 1261.15 626.113C1185.43 635.994 1124.87 667.329 1071.32 721.868C1045.2 748.536 1016.44 766.569 986.126 775.813C965.413 782.141 943.655 784.316 922.101 782.213C894.919 779.612 867.157 770.461 839.464 754.785C788.778 725.972 742.246 688.185 698.825 649.129C665.763 620.045 631.419 597.704 592.369 582.431C576.609 576.319 560.379 571.496 543.838 568.011C453.672 550.037 368.972 515.545 303.123 451.431C293.39 441.953 284.191 431.94 275.571 421.44C194.054 322.052 156.261 166.037 258.088 48.4738C322.192 -25.7083 404.647 -47.7374 497.069 -32.8168C540.973 -25.7281 583.303 -7.63995 625.722 7.3733C658.954 19.1196 690.892 34.3861 723.843 46.8832C758.03 59.8183 791.681 60.6643 826.225 44.4502C899.42 10.0942 974.35 14.2449 1049 40.879Z"
                    stroke={strokeColor}
                    strokeWidth="3" strokeMiterlimit="10"/>
                <path
                    d="M1472.56 628.657C1422.84 642.907 1373.93 663.696 1323.21 669.912C1235.23 680.794 1165.18 717.08 1103.62 780.797C1027.25 859.803 931.037 874.698 835.731 820.337C777.209 786.966 723.582 742.925 673.625 697.253C621.607 649.7 565.934 618.816 495.667 605.472C375.036 582.545 263.151 534.207 183.198 437.62C88.4017 323.104 43.9553 142.424 161.756 6.37015C236.14 -79.5288 331.684 -104.898 438.768 -87.4675C489.613 -79.1495 538.69 -58.126 587.874 -40.7404C626.394 -27.0847 663.428 -9.34292 701.68 5.12668C741.356 20.1341 780.396 21.0179 820.486 1.96977C905.492 -38.358 992.5 -33.8298 1079.25 -3.13608C1102.77 5.18839 1126.21 14.5504 1148.37 25.9606C1214.22 59.8471 1281.39 66.8292 1353.03 45.3533C1427.92 22.9144 1502.06 25.3643 1569.26 70.3443C1650.59 124.756 1685.46 201.109 1671.35 298.928C1654.1 418.364 1570.92 551.469 1472.56 628.657Z"
                    stroke={strokeColor}
                    strokeWidth="3" strokeMiterlimit="10"/>
        </svg>
    );
};


