import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { IImageCarousel } from '@/utils/interfaces/bloks';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FormattedText } from '../Shared/FormattedText';
import { SbImage } from '../Shared/Image/SbImage';
import { SbImageWrapper } from '../Shared/Image/SbImageWrapper';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';

interface IProps {
	blok: IImageCarousel;
	preview: boolean;
}

export const ImageCarousel: React.FC<IProps> = ({ blok, preview }) => {
	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative py-14 md:py-32 section-grey-gradient overflow-hidden"
		>
			<div className="absolute bottom-0 right-0 z-0">
				<img
					alt="Canibuild | background"
					src="/brand/backgrounds/sectionBackground.png"
				/>
			</div>

			<Container align="center" type="narrow">
				<FormattedText tag="h2" text={blok.heading} />
				<span className="block mt-4 lg:text-xl max-w-[800px] mx-auto lg:mt-6">
					{blok.sub_heading}
				</span>
			</Container>

			{isArrayWithValue(blok.images) && (
				<div className="block mt-10 lg:mt-19">
					{blok.images.length === 1 ? (
						<Container align="center">
							<div className="block max-w-[864px] mx-auto">
								<SbImage
									src={blok.images[0].filename}
									alt={blok.images[0].alt}
								/>
							</div>
						</Container>
					) : (
						<Swiper
							slidesPerView="auto"
							watchOverflow={true}
							spaceBetween={24}
							speed={5000}
							loop={true}
							autoplay={{
								delay: 0,
								disableOnInteraction: false,
							}}
							modules={[Autoplay]}
						>
							{blok.images.map((image, key) => (
								<SwiperSlide
									className="flex items-center justify-center w-auto first:pl-6 last:pr-6"
									key={key}
								>
									<SbImageWrapper shadow={true} rounded="xl">
										<figure
											itemProp="associatedMedia"
											itemScope
											itemType="http://schema.org/ImageObject"
										>
											<img
												src={image.filename}
												loading="lazy"
												className="max-h-[200px] lg:max-h-[452px] w-auto shadow-md"
												alt={image.alt}
											/>
										</figure>
									</SbImageWrapper>
								</SwiperSlide>
							))}
						</Swiper>
					)}
				</div>
			)}
		</SbSection>
	);
};
