import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { ISbLink } from '@/utils/interfaces/common';
import { IFeatureTab } from '@/utils/interfaces/components';
import { Disclosure, Transition } from '@headlessui/react';
import Link from 'next/link';
import { CTA } from '../CTA/CallToAction';
import { FormattedText } from '../FormattedText';
import { Icon } from '../Icon';
import { Arrow } from '../Icons/Arrow';
import { BrandIcon } from '../Icons/BrandIcon';
import { ChevronRight } from '../Icons/ChevronRight';
import { SbImage } from '../Image/SbImage';
import { SbImageWrapper } from '../Image/SbImageWrapper';

interface IProps {
	tabs: IFeatureTab[];
	cta: ISbLink;
	ctaLabel: string;
}

export const FeatureTabsAccordion: React.FC<IProps> = ({
	tabs,
	cta,
	ctaLabel,
}) => {
	return (
		<div>
			{tabs.map((tab, key) => {
				return (
					<Disclosure key={key}>
						{({ open }) => (
							<div className={`${open ? 'mb-4' : 'mb-1'}`}>
								<Disclosure.Button
									className={`flex items-center p-4  w-full font-medium ${
										open
											? 'bg-green-dark text-white '
											: 'bg-gray-light text-green-dark '
									}`}
								>
									<span className="w-8 h-8 mr-4">
										<BrandIcon
											dark={open}
											label={tab.icon}
										/>
									</span>

									<div className="flex-grow text-left">
										{tab.label}
									</div>
									<div className="relative">
										<Icon
											className={`w-3 absolute-center h-3  ${
												open
													? 'rotate-[270deg]'
													: 'rotate-90'
											}`}
											icon={<ChevronRight />}
										/>
									</div>
								</Disclosure.Button>
								<Transition
									enter="transition duration-100 ease-out"
									enterFrom="transform scale-95 opacity-0"
									enterTo="transform scale-100 opacity-100"
									leave="transition duration-75 ease-out"
									leaveFrom="transform scale-100 opacity-100"
									leaveTo="transform scale-95 opacity-0"
								>
									<Disclosure.Panel>
										<div className="bg-gray-light">
											<div className="relative px-8 py-4">
												<div className="absolute top-0 left-0 w-full h-[103px] bg-white"></div>
												<div className="relative z-20">
													<SbImageWrapper
														shadow={true}
														rounded="lg"
													>
														<SbImage
															src={
																tab.image
																	.filename
															}
															alt={tab.image.alt}
															layout="intrinsic"
															objectFit="contain"
														/>
													</SbImageWrapper>
												</div>
											</div>
											<div className="p-4 text-left ">
												{tab.sub_heading &&
													isStringWithValue(
														tab.sub_heading,
													) && (
														<span className="text-sm font-bold text-green">
															{tab.heading}
														</span>
													)}

												<FormattedText
													tag="h3"
													text={tab.heading}
													className="mt-2 dark:text-white"
												/>

												<span className="block mt-6 text-base opacity-80 dark:text-white">
													{tab.description}
												</span>

												<div className="mt-4">
													{tab.ctas.map(
														(cta, key) => {
															return (
																<CTA
																	blok={cta}
																	key={key}
																/>
															);
														},
													)}
												</div>
											</div>
										</div>
									</Disclosure.Panel>
								</Transition>
							</div>
						)}
					</Disclosure>
				);
			})}

			<Link href={cta.cached_url}>
				<a className="flex items-center w-full p-4 font-medium transition-colors duration-200 bg-gray-light text-green-dark hover:bg-green-dark hover:text-white">
					<span className="relative w-8 h-8 mr-4 text-green">
						<Icon
							className={`absolute-center w-3 h-3`}
							icon={<Arrow />}
						/>
					</span>

					<div className="flex-grow text-left">{ctaLabel}</div>
				</a>
			</Link>
		</div>
	);
};
