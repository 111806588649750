import { AlertContext } from '@/components/Providers/AlertProvider';
import { UserAccontApiRequest } from '@/utils/interfaces/cibApiResponse';
import axios from 'axios';
import { pathOr } from 'ramda';
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { z } from 'zod';
import { BASE_URL, IFormStepProps } from '../SelfSubscribeContainer';
import { CostSummary } from '../Shared/CostSummary';
import { DropdownChecklist } from '../Shared/DropdownChecklist';
import { FormCol } from '../Shared/FormCol';
import { FormFormProvider } from '../Shared/FormFormProvider';
import { FormInput } from '../Shared/FormInput';
import { FormInputPassword } from '../Shared/FormInputPassword';
import { FormRow } from '../Shared/FormRow';
import { FormStepLayout } from '../Shared/FormStepLayout';
import { useZodForm } from '../Shared/UseZodForm';
import { mapLocaleToInternationalCode } from '@/utils/helpers/mapLocalToCountry';
import { useRouter } from 'next/router';

const schema = z
	.object({
		firstName: z.string().min(1, 'First name is required'),
		lastName: z.string().min(1, 'Last name is required'),
		email: z.string().email('Please enter a valid email address.'),
		phone: z
			.string()
			.min(1, 'Phone number is required')
			.min(12, 'Please provide a valid phone number'),
		password: z.string().min(1, 'Password required'),
		passwordConfirm: z.string().min(1, 'Password required'),
	})
	.refine((data) => data.password === data.passwordConfirm, {
		message: "Passwords don't match",
		path: ['passwordConfirm'], // path of error
	});

type FormData = z.infer<typeof schema>;

async function sendData(data: UserAccontApiRequest) {
	const { data: response } = await axios.post(`${BASE_URL}/signup`, data);
	return response.data;
}

async function sendVerficationCode(data: UserAccontApiRequest) {
	const { data: response } = await axios.post('/api/verify-handler', data);
	return response.data;
}

export const CreateAccount: React.FC<IFormStepProps> = ({
	children,
	blok,
	...props
}) => {
	const { locale } = useRouter();
	const alert = useContext(AlertContext);
	const createAccount = useMutation(sendData, {});
	const sendVerify = useMutation(sendVerficationCode, {});
	const [countryCode] = useState<string>(
		mapLocaleToInternationalCode(locale as string),
	);

	const methods = useZodForm({
		schema,
		defaultValues: {
			firstName: pathOr('', ['formData', 'userData', 'firstName'], props),
			lastName: pathOr('', ['formData', 'userData', 'lastName'], props),
			email: pathOr('', ['formData', 'userData', 'email'], props),
			phone: pathOr(
				`+${countryCode}`,
				['formData', 'userData', 'mobile'],
				props,
			),
			password: pathOr('', ['formData', 'userData', 'password'], props),
			passwordConfirm: pathOr(
				'',
				['formData', 'userData', 'password'],
				props,
			),
		},
	});

	const {
		register,
		setError,
		formState: { isSubmitting },
	} = methods;

	async function onSubmit(data: FormData) {
		const payload: UserAccontApiRequest = {
			data: {
				addons: props.formData.planData?.addons.map(
					(addon) => addon.id,
				),
				billingCycle: props.formData.planData?.billingCycle,
				country: props.country,
				email: data.email,
				mobile: data.phone.replace('+', ''),
				name: `${data.firstName} ${data.lastName}`,
				password: data.password,
				searches: parseInt(props.formData.planData?.searches as string),
				planId: props.formData.planData?.planId,
			},
			step: 'account',
		};

		await new Promise((resolve) => {
			createAccount
				.mutateAsync(payload)
				.then((createAccountData) => {
					sendVerify
						.mutateAsync(payload)
						.then((sendVerifyData) => {
							// Split name back in to firstName and lastName;
							const { userData, verifyCode } = sendVerifyData;
							userData['firstName'] = userData.name
								.split(' ')
								.slice(0, -1)
								.join(' ');
							userData['lastName'] = userData.name
								.split(' ')
								.slice(-1)
								.join(' ');
							props.send({
								event: 'NEXT',
								data: {
									userData: userData,
									verifyCode: verifyCode,
									sessionId: createAccountData.sessionId,
								},
							});
						})
						.catch((e) => {
							alert.error(
								'There was an error sending your verification email',
								10,
							);
						});
				})
				.catch((err) => {
					const message = pathOr(
						null,
						['response', 'data', 'message'],
						err,
					);

					if (message === 'E-mail already exists') {
						setError('email', {
							type: 'alreadyExists',
							message: 'Email is already in use',
						});
					} else if (
						message == 'Mobile number need to have 11 digits'
					) {
						setError('phone', {
							type: 'invalid',
							message,
						});
					} else if (message !== null) {
						alert.error(message, 10);
					}

					resolve(null);
				});
		});
	}

	const heading = pathOr(null, ['createAccount_heading'], blok);
	const description = pathOr(null, ['createAccount_description'], blok);
	const media = pathOr(null, ['createAccount_media'], blok);
	const demoLink = pathOr(null, ['demo_link'], blok);

	return (
		<FormStepLayout media={media} link={demoLink}>
			<div className="form-step">
				<div className="form-step__heading">
					<h4>{heading}</h4>
				</div>

				{description && (
					<div className="form-step__description">
						<span>{description}</span>
					</div>
				)}

				{props.formData.planData?.searches && (
					<div className="form-step__dropdown">
						<DropdownChecklist
							addOns={props.formData.planData?.addons}
							label="Plan summary"
							searchesAmount={props.formData.planData?.searches}
						>
							<CostSummary
								discount={props.formData.planData?.discount}
								cost={props.formData.planData?.cost}
								billingCycle={
									props.formData.planData?.billingCycle
								}
							/>
						</DropdownChecklist>
					</div>
				)}

				<div className="form-step__form">
					<FormFormProvider
						form={methods}
						onSubmit={(data) => onSubmit(data)}
					>
						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="First name*"
										disabled={isSubmitting}
										{...register('firstName', {
											required: true,
										})}
									/>
								</div>
							</FormCol>

							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Last name*"
										disabled={isSubmitting}
										{...register('lastName', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Email*"
										disabled={isSubmitting}
										{...register('email', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Phone*"
										disabled={isSubmitting}
										{...register('phone', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInputPassword
										type="text"
										label="Create password*"
										disabled={isSubmitting}
										{...register('password', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInputPassword
										type="text"
										label="Confirm password*"
										disabled={isSubmitting}
										{...register('passwordConfirm', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol submit={true}>
								<div className="form-step__form-row form-step__form-row--submit">
									<button
										className="cta cta--primary cta--large"
										disabled={isSubmitting}
										type="submit"
									>
										<span className="cta-label">
											Continue
										</span>
									</button>

									{props.previousEnabled && (
										<button
											className="cta cta--form-nav"
											type="button"
											onClick={() =>
												props.send({
													event: 'PREVIOUS',
													data: {},
												})
											}
										>
											<span className="cta-label">
												Previous
											</span>
										</button>
									)}
								</div>
							</FormCol>
						</FormRow>
					</FormFormProvider>
				</div>
			</div>
		</FormStepLayout>
	);
};
