import { Icon } from '@/components/Shared/Icon';
import { Blockquote } from '@/components/Shared/Icons/Blockquote';
import { WysiwygContainer } from '@/components/Shared/Layout/WysiwygContainer';
import { IWysiwygBlockquote } from '@/utils/interfaces/bloks';

interface IProps {
	blok: IWysiwygBlockquote;
}

export const WysiwygBlockquote: React.FC<IProps> = ({ blok }) => {
	return (
		<WysiwygContainer narrow={false}>
			<div className="relative py-10 text-center lg:pt-19 lg:pb-14">
				<div className="w-full h-[1px] green-gradient absolute top-0 left-0"></div>
				<div className="absolute top-0 -translate-x-1/2 -translate-y-1/2 text-none left-1/2">
					<Icon
						icon={<Blockquote />}
						className="w-8 h-8 lg:w-12 lg:h-12 text-none"
					/>
				</div>
				<span className="text-lg leading-6 lg:text-3xl lg:leading-9">
					{blok.quote}
				</span>

				<div className="absolute z-10 px-8 -translate-x-1/2 -translate-y-[55%] bg-white whitespace-nowrap left-1/2 top-full">
					<span className="text-sm">{blok.author}, </span>
					<span className="text-sm text-green">{blok.position}</span>
				</div>
				<div className="w-full h-[1px] green-gradient absolute bottom-0 left-0 lg:w-8/12 lg:left-1/2 lg:-translate-x-1/2"></div>
			</div>
		</WysiwygContainer>
	);
};
