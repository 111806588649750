import { handleEmailClick } from '@/utils/handleMailto';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { ISbLink } from '@/utils/interfaces/common';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

interface IProps {
	link: ISbLink;
	newTab?: boolean;
	passHref?: boolean;
}

export const SbLink: React.FC<IProps> = ({ link, children, ...props }) => {
	let href = '/';

	const { locale } = useRouter();
	const [urlLocale, setUrlLocale] = useState<string | undefined>(locale);
	const { cached_url, email } = link;

	switch (link.linktype) {
		case 'url':
			href = `${cached_url}`;
			break;

		case 'email':
			href = `mailto:${email}`;
			break;

		case 'story':
			href = `/${cached_url}`;
			break;

		default:
			break;
	}

	useEffect(() => {
		if (isStringWithValue(cached_url)) {
			const linkLocale = cached_url.split('/')[0];

			// This check to see if this is a locale string
			if (new RegExp('en-').test(linkLocale)) {
				setUrlLocale(linkLocale);
			}
		}
	}, []);

	if (props.newTab) {
		return (
			<Link href={href} locale={urlLocale} {...props}>
				<a target="_blank" rel="noopener noreferrer">
					{children}
				</a>
			</Link>
		);
	}

	if (link.linktype === 'email' && email) {
		return (
			<button onClick={() => handleEmailClick(email)} {...props}>
				{children}
			</button>
		);
	}

	return (
		<Link href={href} locale={urlLocale} {...props}>
			{children}
		</Link>
	);
};
