import { SbLink } from '../SbLink';
import { IMegaMenuItem } from '@/utils/interfaces/components';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import React from 'react';

interface IProps {
	blok: IMegaMenuItem;
}

export const MegaMenuItem: React.FC<IProps> = ({ blok }) => {
	const classes = ['cta', 'cta--nav'];
	return (
		<div className="mb-4 last:mb-0 last:odd:col-span-2">
			<SbLink
				link={blok.link}
				newTab={blok.new_tab}
				passHref={blok.new_tab}
			>
				{blok.new_tab ? (
					<span className={classes.join(' ')}>
						<span className="cta-label">{blok.label}</span>
					</span>
				) : (
					<a className={classes.join(' ')}>
						<span className="cta-label">{blok.label}</span>
					</a>
				)}
			</SbLink>

			{blok.description && isStringWithValue(blok.description) && (
				<span className="block text-sm text-gray-dark/60 lg:text-sm lg:mt-2">
					{blok.description}
				</span>
			)}
			{blok.menu_items && isArrayWithValue(blok.menu_items) && (
				<>
					<hr className="my-4 xl:my-6 border-gray-light" />
					<div className="grid lg:grid-cols-2 gap-x-8 xl:gap-x-16">
						{blok.menu_items.map((item, key) => (
							<MegaMenuItem blok={item} key={key} />
						))}
					</div>
				</>
			)}
		</div>
	);
};
