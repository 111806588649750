interface IProps {
	align?: 'center' | 'left' | 'right';
}

export const CtaContainer: React.FC<IProps> = ({
	align = 'center',
	children,
}) => {
	const classes = [
		'flex flex-col items-start gap-2 md:items-center md:flex-row flex-wrap',
	];

	if (align === 'center') {
		classes.push('md:justify-center');
	}
	return <div className={classes.join(' ')}>{children}</div>;
};
