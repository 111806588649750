interface IProps {
    strokeColor: string;
}

export const Left: React.FC<IProps> = ({strokeColor}) => {
    return (

        <svg viewBox="0 0 553 612" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M-122.298 501.943C-116.65 507.762 -111.014 513.727 -105.854 520.034C-90.5359 538.688 -71.3786 549.897 -47.7223 553.918C-43.5779 554.627 -39.5068 555.495 -35.4968 556.559C-15.1414 562.133 1.98642 573.232 13.0749 591.066C29.6282 619.095 29.2737 645.865 12.3659 672.194C0.95949 690.09 -16.6574 706.555 -36.4749 719.5C-51.6332 729.456 -67.9011 737.612 -84.9489 743.8C-91.6729 743.715 -98.3969 744.008 -105.145 744.253C-113.889 744.821 -122.668 744.453 -131.332 743.153C-157.458 738.74 -181.982 740.891 -207.815 749.191C-219.264 753.205 -231.535 754.297 -243.513 752.369C-251.224 751.103 -258.62 748.369 -265.299 744.314C-274.432 738.625 -282.534 731.429 -289.261 723.032C-302.856 706.506 -314.531 688.378 -325.534 670.727C-336.732 656.829 -346.782 642.711 -356.562 628.458C-360.438 623.092 -364.496 617.799 -368.421 613.179C-391.649 593.511 -412.347 569.406 -424.022 538.492C-425.745 533.92 -427.226 529.26 -428.46 524.533C-439.952 479.22 -429.206 423.516 -379.045 397.993C-348.127 382.237 -318.052 385.415 -289.603 401.93C-276.033 409.789 -264.309 421.011 -252.242 431.572C-242.963 439.701 -234.528 448.771 -225.481 457.144C-216.128 465.823 -205.516 470.822 -192.606 470.847C-165.221 470.932 -142.213 482.96 -122.298 501.943Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-111.502 494.756C-105.218 501.088 -98.9955 507.603 -93.2862 514.51C-76.3906 534.948 -55.2894 547.134 -29.2003 551.29C-24.6402 552.024 -20.1534 552.941 -15.7523 554.078C6.57144 559.982 25.4476 571.985 37.832 591.641C56.1702 622.2 56.0601 651.696 37.5997 680.873C25.1052 700.712 5.70341 719.011 -16.1924 733.435C-32.9142 744.501 -50.9253 753.482 -69.8256 760.18C-77.2953 759.985 -84.8262 760.18 -92.2715 760.364C-102.001 760.837 -111.754 760.284 -121.368 758.714C-150.355 753.409 -177.471 755.816 -205.932 765.461C-218.554 770.087 -232.132 771.486 -245.432 769.532C-253.969 768.229 -262.175 765.295 -269.602 760.889C-279.724 754.717 -288.687 746.82 -296.082 737.554C-311.022 719.292 -323.724 699.099 -335.595 679.37C-347.295 663.944 -358.139 648.676 -369.178 633.568C-373.531 627.859 -378.14 622.261 -382.626 617.335C-409.058 595.833 -432.47 569.369 -445.661 535.278C-447.614 530.238 -449.291 525.097 -450.686 519.876C-463.804 469.954 -452.312 408.078 -396.894 379.854C-362.662 362.362 -329.275 365.968 -297.733 384.389C-282.708 393.153 -269.736 405.658 -256.374 417.393C-246.08 426.426 -236.715 436.51 -226.666 445.825C-216.262 455.457 -204.44 460.958 -189.99 460.848C-159.475 460.591 -133.777 473.829 -111.502 494.756Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-100.708 487.617C-93.8003 494.462 -86.9663 501.515 -80.7313 509.021C-62.2464 531.256 -39.1647 544.469 -10.6794 548.699C-5.69139 549.457 -0.788975 550.422 3.99118 551.632C28.3076 557.866 48.9076 570.787 62.5879 592.252C82.8332 625.341 82.8699 657.575 62.8324 689.576C49.2621 711.359 28.0876 731.491 4.15011 747.308C-14.1352 759.483 -33.8901 769.291 -54.6422 776.499C-62.8577 776.193 -71.1343 776.352 -79.3498 776.413C-90.1694 776.56 -100.952 776.254 -111.344 774.213C-143.13 768.028 -172.887 770.68 -203.989 781.657C-217.775 786.912 -232.659 788.627 -247.279 786.644C-256.648 785.31 -265.67 782.171 -273.845 777.403C-284.973 770.751 -294.802 762.137 -302.856 751.978C-319.152 731.968 -332.882 709.733 -345.645 687.914C-357.871 670.972 -369.497 654.543 -381.771 638.591C-386.523 632.592 -391.587 626.847 -396.943 621.38C-426.59 598.058 -452.691 569.234 -467.411 531.965C-469.59 526.456 -471.468 520.833 -473.034 515.12C-487.766 460.578 -475.48 392.554 -414.829 361.603C-377.175 342.388 -340.572 346.434 -305.949 366.75C-289.457 376.431 -275.251 390.207 -260.605 403.066C-249.272 413.016 -238.99 424.115 -227.926 434.346C-216.483 444.944 -203.475 450.946 -187.582 450.714C-153.717 450.249 -125.33 464.649 -100.708 487.617Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-89.9123 480.467C-82.2812 487.783 -75.0263 495.481 -68.1754 503.533C-48.1012 527.564 -23.1123 541.695 7.84255 546.12C13.2584 546.902 18.5643 547.904 23.7357 549.176C50.0449 555.752 72.3808 569.577 87.3081 592.863C109.4 628.47 109.644 663.455 88.0294 698.292C73.3588 722.018 50.4239 743.984 24.457 761.231C4.62079 774.514 -16.8659 785.149 -39.4578 792.866C-48.4069 792.451 -57.4537 792.548 -66.4149 792.512C-78.237 792.512 -89.9856 792.096 -101.319 789.761C-136.015 782.684 -168.302 785.605 -202.057 797.915C-218.353 804.026 -234.173 805.835 -249.137 803.794C-259.329 802.438 -269.16 799.117 -278.087 794.015C-290.214 786.876 -300.91 777.546 -309.628 766.5C-327.27 744.754 -342.038 720.453 -355.657 696.568C-368.36 678.098 -380.805 660.521 -394.314 643.701C-399.57 637.326 -405.191 631.261 -411.149 625.537C-444.023 600.368 -472.814 569.186 -489.062 528.762C-491.451 522.763 -493.524 516.643 -495.272 510.427C-511.605 451.264 -498.634 377.079 -432.666 343.415C-391.637 322.476 -351.782 326.95 -314.078 349.185C-296.132 359.758 -280.679 374.854 -264.737 388.826C-252.389 399.68 -241.178 411.794 -229.111 422.967C-216.617 434.53 -202.362 441.021 -184.941 440.654C-147.959 439.908 -116.882 455.494 -89.9123 480.467Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-79.1181 473.328C-70.8456 481.145 -62.9978 489.4 -55.6086 498.056C-33.9573 523.873 -7.02451 538.981 26.3633 543.528C32.1948 544.335 37.9286 545.386 43.4789 546.731C71.7687 553.649 95.8528 568.379 112.1 593.474C136.038 631.612 136.478 669.261 113.323 706.995C97.5888 732.665 72.8445 756.464 44.787 775.166C23.3768 789.552 0.137909 801.01 -24.3114 809.234C-34.0062 808.72 -43.811 808.732 -53.5302 808.622C-66.3303 808.512 -79.0693 807.938 -91.3314 805.31C-128.888 797.352 -163.767 800.518 -200.138 814.16C-217.743 820.932 -234.834 823.046 -251.008 820.956C-262.033 819.546 -272.678 816.009 -282.354 810.541C-295.479 802.906 -307.039 792.857 -316.427 780.924C-335.425 757.43 -351.221 731.088 -365.708 705.137C-378.911 685.151 -392.151 666.412 -406.907 648.737C-412.672 641.984 -418.854 635.599 -425.417 629.619C-461.494 602.617 -492.987 569.076 -510.751 525.474C-513.372 519.021 -515.641 512.431 -517.548 505.733C-535.507 441.962 -521.79 361.604 -450.54 325.238C-406.162 302.551 -363.03 307.465 -322.246 331.607C-302.82 343.085 -286.132 359.428 -268.894 374.585C-255.532 386.344 -243.392 399.485 -230.311 411.574C-216.777 424.103 -201.312 431.132 -182.374 430.606C-142.202 429.567 -108.447 446.338 -79.1181 473.328Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-68.3232 466.177C-59.4253 474.507 -50.9894 483.317 -43.0531 492.568C-19.8247 520.169 9.05183 536.255 44.8847 540.949C51.0705 541.748 57.2116 542.862 63.284 544.286C93.5543 551.535 119.375 567.169 136.906 594.085C162.693 634.749 163.256 675.287 138.593 715.698C121.783 743.324 95.2537 768.957 65.1423 789.04C41.4616 804.857 15.7758 817.558 -9.11526 825.552C-19.5558 824.929 -30.1186 824.88 -40.5836 824.672C-54.374 824.452 -68.1032 823.718 -81.2945 820.809C-121.7 811.959 -159.171 815.382 -198.194 830.368C-217.107 837.776 -235.482 840.147 -252.867 838.057C-264.718 836.615 -276.175 832.879 -286.597 827.056C-299.837 819.648 -312.16 809.062 -323.212 795.274C-343.555 770.044 -360.378 741.649 -375.782 713.596C-389.486 692.082 -403.534 672.194 -419.524 653.7C-425.802 646.571 -432.54 639.863 -439.696 633.616C-479.001 604.793 -513.172 568.831 -532.463 522.112C-535.306 515.189 -537.775 508.117 -539.86 500.929C-559.421 432.476 -544.958 346.019 -468.451 306.94C-420.698 282.492 -374.315 287.883 -330.437 313.919C-309.556 326.314 -291.622 343.928 -273.088 360.223C-258.698 372.898 -245.642 387.115 -231.521 400.084C-216.936 413.53 -200.261 421.072 -179.82 420.448C-136.444 419.226 -100.012 437.158 -68.3232 466.177Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-57.5158 459.038C-47.9908 467.871 -38.9704 477.233 -30.4975 487.079C-5.66752 516.477 25.1407 533.529 63.4064 538.419C70.0055 539.244 76.5586 540.403 83.0405 541.89C115.291 549.481 142.86 566.032 161.675 594.758C189.304 637.956 190.16 681.13 163.851 724.475C145.953 754.032 117.639 781.51 85.4734 803.073C60.1789 820.015 32.696 833.632 6.00777 842.03C-5.17854 841.296 -16.4993 841.174 -27.7223 840.881C-42.5029 840.538 -57.1979 839.658 -71.3305 836.468C-114.596 826.689 -154.659 830.356 -196.324 846.724C-216.532 854.779 -236.191 857.517 -254.774 855.28C-267.456 853.809 -279.729 849.873 -290.9 843.692C-305.082 835.844 -318.261 824.562 -330.022 809.881C-351.71 782.916 -369.583 752.43 -385.806 722.324C-400.012 699.294 -414.854 678.245 -432.08 658.858C-438.872 651.359 -446.167 644.33 -453.915 637.821C-496.447 607.165 -533.307 568.905 -554.163 518.947C-557.235 511.554 -559.904 504.001 -562.159 496.321C-583.346 423.321 -568.125 330.629 -486.361 288.836C-435.234 262.702 -385.586 268.484 -338.628 296.427C-316.28 309.727 -297.062 328.612 -277.293 346.068C-261.877 359.648 -247.952 374.83 -232.792 388.777C-217.156 403.238 -199.27 411.232 -177.325 410.474C-130.697 408.885 -91.5637 428.015 -57.5158 459.038Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-46.7195 451.888C-36.5593 461.227 -26.9464 471.145 -17.9285 481.591C8.4785 512.786 41.2305 530.816 81.9291 535.767C88.9429 536.636 95.9081 537.86 102.798 539.434C137.029 547.355 166.37 564.822 186.445 595.357C215.933 641.085 217.008 687.034 189.098 733.166C170.124 764.679 140.025 793.979 105.805 816.984C78.9094 835.05 49.6294 849.474 21.2296 858.385C9.29759 857.542 -2.76895 857.358 -14.7499 856.967C-30.5208 856.503 -46.2061 855.5 -61.2679 852.004C-107.382 841.382 -150.037 845.306 -194.342 863.006C-215.847 871.709 -236.789 874.74 -256.594 872.503C-270.105 870.976 -283.187 866.824 -295.105 860.28C-310.215 851.992 -324.25 840.013 -336.781 824.379C-359.826 795.666 -378.727 763.163 -395.818 730.941C-410.488 706.396 -426.162 684.186 -444.634 663.944C-451.941 656.063 -459.797 648.71 -468.144 641.941C-513.879 609.451 -553.441 568.819 -575.728 515.695C-579.025 507.837 -581.882 499.802 -584.286 491.627C-607.123 413.934 -591.23 315.13 -504.136 270.623C-449.659 242.802 -396.747 248.914 -346.72 278.825C-322.917 293.017 -302.501 313.186 -281.387 331.791C-264.944 346.288 -250.041 362.484 -233.94 377.36C-217.253 392.774 -198.156 401.27 -174.696 400.39C-124.938 398.544 -83.1147 418.823 -46.7195 451.888Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-35.9256 444.749C-25.1333 454.594 -14.9279 465.063 -5.36195 476.103C22.6344 509.106 57.3302 528.09 100.462 533.187C107.89 534.055 115.267 535.307 122.565 536.94C158.802 545.203 189.805 563.563 211.224 595.919C242.558 644.166 243.805 692.816 214.354 741.832C194.304 775.276 162.433 806.422 126.135 830.857C97.6254 850.048 66.5727 865.34 36.4125 874.703C23.7347 873.75 10.9101 873.481 -1.82883 873.017C-18.5899 872.43 -35.241 871.281 -51.2442 867.504C-100.219 855.989 -145.454 860.17 -192.399 879.165C-215.212 888.516 -237.438 891.865 -258.417 889.567C-272.756 888.022 -286.653 883.683 -299.323 876.794C-315.375 868.078 -330.254 855.39 -343.531 838.803C-367.982 808.341 -387.86 773.797 -405.881 739.485C-421.089 713.425 -437.532 690.053 -457.228 668.955C-465.063 660.707 -473.484 653.035 -482.424 645.999C-531.326 611.687 -573.638 568.697 -597.478 512.395C-601.005 504.066 -604.07 495.55 -606.659 486.884C-631.11 404.643 -614.374 299.642 -522.047 252.422C-464.196 222.829 -408.02 229.478 -354.9 261.235C-329.618 276.344 -307.967 297.784 -285.557 317.538C-268.1 332.939 -252.268 350.162 -235.152 365.955C-217.425 382.347 -197.118 391.332 -172.154 390.329C-119.181 388.263 -74.6804 409.704 -35.9256 444.749Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-25.1913 437.598C-13.7698 447.943 -2.97974 458.964 7.1206 470.602C36.7062 505.366 73.3338 525.352 118.91 530.571C126.757 531.462 134.551 532.763 142.261 534.47C180.466 543.027 213.242 562.34 235.92 596.505C269.1 647.282 270.555 698.671 239.588 750.523C218.45 785.972 184.793 818.89 146.442 844.78C116.331 865.083 83.4809 881.243 51.5602 891.071C38.1122 890.007 24.5541 889.677 11.0694 889.103C-6.68196 888.394 -24.3233 887.11 -41.2556 882.991C-93.0794 870.584 -140.918 875.033 -190.504 895.361C-214.613 905.36 -238.122 909.003 -260.324 906.668C-275.494 905.085 -290.206 900.542 -303.626 893.295C-320.608 884.14 -336.354 870.755 -350.377 853.202C-376.05 821.017 -397.078 784.407 -415.917 748.018C-431.627 720.441 -448.865 695.92 -469.807 673.954C-478.167 665.328 -487.157 657.337 -496.703 650.045C-548.882 613.888 -593.823 568.55 -619.203 509.07C-622.951 500.27 -626.216 491.272 -628.983 482.117C-655.011 395.329 -637.541 284.094 -539.969 234.16C-478.756 202.831 -419.316 209.921 -363.103 243.585C-336.354 259.597 -313.468 282.321 -289.751 303.224C-271.266 319.53 -254.517 337.768 -236.399 354.563C-217.62 371.908 -196.055 381.455 -169.623 380.233C-113.435 377.861 -66.2322 400.548 -25.1913 437.598Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-14.3342 430.46C-2.27729 441.315 9.10544 452.896 19.7504 465.138C50.9131 501.699 89.4356 522.589 137.482 528.004C145.746 528.919 153.956 530.265 162.079 532.038C202.277 540.974 236.789 561.155 260.751 597.141C295.777 650.449 297.428 704.575 264.847 759.239C242.633 796.595 207.191 831.359 166.786 858.715C135.061 880.143 100.414 897.158 66.7329 907.451C52.5758 906.228 38.2353 905.874 23.9437 905.214C5.2143 904.383 -13.4051 902.965 -31.2787 898.601C-85.9633 885.314 -136.418 890.044 -188.608 911.668C-214.025 922.315 -238.806 926.336 -262.23 923.891C-278.22 922.272 -293.738 917.534 -307.904 909.944C-325.839 900.349 -342.429 886.255 -357.198 867.748C-384.289 833.815 -406.283 795.164 -425.99 756.684C-442.201 727.58 -460.222 701.91 -482.435 679.089C-491.291 670.092 -500.813 661.777 -510.921 654.213C-566.327 616.235 -613.957 568.538 -640.853 505.88C-644.793 496.612 -648.225 487.137 -651.135 477.496C-678.776 386.015 -660.585 268.68 -557.708 216.045C-493.133 183.041 -430.428 190.497 -371.135 226.08C-342.93 242.986 -318.797 266.993 -293.808 289.044C-274.248 306.255 -256.619 325.52 -237.486 343.183C-217.668 361.518 -194.929 371.481 -166.957 370.209C-107.676 367.483 -57.7958 391.393 -14.3342 430.46Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-3.5402 423.309C9.13509 434.671 21.0941 446.808 32.2682 459.649C65.0202 497.995 105.535 519.936 155.966 525.425C164.644 526.36 173.266 527.751 181.798 529.593C223.976 538.859 260.237 559.957 285.482 597.752C322.354 653.59 324.212 710.442 290.067 767.954C266.765 807.241 229.538 843.875 187.079 872.637C153.74 895.19 117.309 913.073 81.8669 923.818C66.9641 922.534 51.8656 922.058 36.8772 921.312C17.1453 920.358 -2.45214 918.794 -21.2549 914.149C-78.8002 899.969 -131.798 904.92 -186.666 927.913C-213.39 939.22 -239.455 943.473 -264.077 941.029C-280.9 939.387 -297.234 934.449 -312.148 926.495C-331.012 916.459 -348.47 901.669 -363.972 882.209C-392.42 846.54 -415.441 805.823 -436.016 765.265C-452.728 734.645 -471.568 707.814 -495.004 684.137C-504.381 674.768 -514.464 666.133 -525.164 658.308C-583.773 618.496 -634.106 568.428 -662.518 502.603C-666.733 492.872 -670.407 482.915 -673.521 472.778C-702.776 376.675 -683.851 253.18 -575.717 197.831C-507.768 163.043 -441.811 170.939 -379.425 208.478C-349.753 226.3 -324.41 251.579 -298.101 274.779C-277.562 292.907 -258.979 313.174 -238.832 331.766C-217.963 351.042 -194.013 361.53 -164.525 360.125C-101.919 357.179 -49.349 382.237 -3.5402 423.309Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M7.26702 416.17C20.5787 428.037 33.1343 440.726 44.8604 454.161C79.2017 494.304 121.661 517.211 174.524 522.834C183.616 523.791 192.651 525.231 201.591 527.149C245.761 536.756 283.758 558.747 310.3 598.364C349.006 656.731 351.072 716.322 315.349 776.658C290.971 817.9 251.96 856.368 207.435 886.56C172.494 910.238 134.277 928.989 97.0753 940.186C81.4267 938.792 65.5703 938.254 49.8239 937.411C29.1139 936.347 8.53847 934.636 -11.2057 929.698C-71.5995 914.626 -127.201 919.833 -184.71 944.17C-212.73 956.113 -240.079 960.685 -265.911 958.191C-283.559 956.503 -300.702 951.361 -316.366 943.058C-336.171 932.582 -354.472 917.107 -370.72 896.682C-400.513 859.265 -424.512 816.507 -446.029 773.858C-463.23 741.722 -482.876 713.73 -507.547 689.197C-517.431 679.458 -528.058 670.504 -539.334 662.415C-601.17 620.77 -654.18 568.293 -684.12 499.34C-688.54 489.136 -692.397 478.698 -695.673 468.072C-726.542 367.361 -706.871 237.705 -593.456 179.594C-522.145 143.082 -452.863 151.479 -387.457 190.852C-356.318 209.578 -329.704 236.079 -302.123 260.526C-280.569 279.559 -261.033 300.865 -239.895 320.422C-217.987 340.652 -192.815 351.642 -161.823 350.114C-96.1604 346.838 -40.9135 373.082 7.26702 416.17Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M18.0619 409.044C32.0083 421.417 45.1566 434.661 57.4279 448.697C93.3463 490.636 137.749 514.521 193.045 520.279C202.551 521.252 211.999 522.733 221.347 524.716C267.51 534.666 307.231 557.573 335.044 598.999C375.608 659.885 377.833 722.213 340.57 785.397C315.116 828.571 274.32 868.885 227.741 900.508C191.187 925.322 151.185 944.916 112.222 956.577C95.8281 955.074 79.2137 954.463 62.7215 953.534C41.0213 952.311 19.4555 950.502 -1.21773 945.271C-64.4723 929.319 -122.653 934.77 -182.803 960.44C-212.144 973.03 -240.751 977.908 -267.794 975.365C-286.273 973.647 -304.232 968.306 -320.645 959.645C-341.428 948.742 -360.537 932.558 -377.542 911.179C-408.68 872.063 -433.779 827.202 -456.091 782.476C-473.806 748.824 -494.234 719.671 -520.152 694.282C-530.572 684.172 -541.768 674.895 -553.638 666.535C-618.69 623.067 -674.401 568.281 -705.857 496.1C-710.51 485.43 -714.567 474.51 -718.009 463.39C-750.492 358.059 -730.076 222.242 -611.379 161.466C-536.705 123.206 -464.221 132.031 -395.672 173.323C-363.067 192.954 -335.23 220.8 -306.341 246.298C-283.761 266.235 -263.307 288.555 -241.167 308.993C-218.195 330.189 -191.825 341.679 -159.33 340.029C-90.4147 336.496 -32.5271 363.914 18.0619 409.044Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M28.8584 401.868C43.4357 414.752 57.1726 428.557 69.9849 443.196C107.493 486.908 153.827 511.771 211.568 517.663C221.493 518.659 231.357 520.189 241.117 522.247C289.249 532.527 330.717 556.351 359.839 599.586C402.249 663.002 404.706 728.068 365.853 794.076C339.312 839.206 296.743 881.341 248.098 914.406C209.942 940.344 168.131 960.856 127.42 972.92C110.304 971.319 92.9198 970.634 75.6575 969.62C52.967 968.3 30.4233 966.307 8.82086 960.794C-57.2823 943.95 -118.055 949.659 -180.845 976.685C-211.409 989.923 -241.386 995.106 -269.639 992.576C-288.947 990.807 -307.716 985.249 -324.873 976.221C-346.549 964.877 -366.562 947.996 -384.301 925.651C-416.797 884.763 -442.91 837.898 -466.102 791.094C-484.318 755.914 -505.554 725.599 -532.706 699.416C-543.641 688.905 -555.394 679.279 -567.855 670.63C-636.134 625.329 -694.535 568.183 -727.507 492.824C-732.381 481.684 -736.633 470.282 -740.246 458.671C-774.355 348.732 -753.193 206.742 -629.226 143.301C-551.179 103.318 -475.442 112.584 -403.801 155.782C-369.741 176.318 -340.656 205.434 -310.484 232.082C-286.877 252.862 -265.494 276.258 -242.339 297.637C-218.328 319.799 -190.748 331.79 -156.761 329.993C-84.6551 326.155 -24.029 354.758 28.8584 401.868Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M39.6046 394.73C54.8124 408.116 69.138 422.472 82.4915 437.708C121.613 483.217 169.867 509.045 230.041 515.071C240.379 516.097 250.655 517.676 260.824 519.802C310.949 530.412 354.154 555.141 384.558 600.197C428.815 666.143 431.48 733.936 391.05 802.779C363.433 849.853 319.079 893.833 268.368 928.341C228.61 955.391 185.014 976.722 142.543 989.288C124.657 987.576 106.539 986.843 88.5309 985.718C64.8501 984.276 41.3283 982.149 18.7846 976.343C-50.1793 958.606 -113.544 964.584 -178.975 992.918C-210.895 1006.8 -242.095 1012.29 -271.546 1009.65C-291.682 1007.87 -311.267 1002.12 -329.177 992.747C-351.782 980.963 -372.651 963.41 -391.135 940.087C-424.976 897.451 -452.128 848.532 -476.188 799.65C-494.905 762.979 -516.948 731.491 -545.335 704.391C-556.791 693.528 -569.106 683.607 -582.158 674.724C-653.58 627.578 -714.707 568.073 -749.195 489.548C-754.288 477.937 -758.737 466.054 -762.521 453.953C-798.244 339.393 -776.336 191.243 -647.1 125.039C-565.691 83.3323 -486.677 93.0257 -411.955 138.131C-376.428 159.571 -346.109 189.971 -314.641 217.768C-290.019 239.526 -267.707 263.876 -243.55 286.22C-218.487 309.348 -189.696 321.84 -154.181 319.921C-78.8969 315.814 -15.5812 345.603 39.6046 394.73Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M50.4473 387.579C66.2912 401.487 81.2095 416.416 95.1069 432.269C135.781 479.574 186.003 506.381 248.61 512.541C259.363 513.594 270.051 515.226 280.628 517.431C332.733 528.432 377.686 554.028 409.375 600.894C455.477 669.346 458.338 739.901 416.356 811.581C387.65 860.598 341.511 906.4 288.746 942.374C247.375 970.488 201.945 992.735 157.762 1005.77C139.131 1003.94 120.255 1003.13 101.525 1001.93C76.8543 1000.36 52.3667 998.101 28.8816 992.002C-42.9309 973.385 -108.887 979.607 -176.959 1009.29C-210.188 1023.82 -242.658 1029.61 -273.332 1026.92C-294.295 1025.1 -314.69 1019.15 -333.347 1009.42C-356.893 997.197 -378.581 978.861 -397.836 954.671C-433.033 910.287 -461.225 859.326 -486.153 808.354C-505.371 770.13 -528.196 737.518 -557.831 709.562C-569.81 698.327 -582.68 688.083 -596.317 678.93C-671.027 629.962 -734.807 568.098 -770.835 486.395C-776.161 474.317 -780.81 461.952 -784.76 449.357C-822.085 330.177 -799.431 175.866 -664.951 106.949C-580.228 63.408 -497.926 73.5292 -420.074 120.566C-383.092 142.911 -351.526 174.594 -318.762 203.54C-293.088 226.203 -269.86 251.567 -244.712 274.804C-218.611 298.884 -188.597 311.878 -151.579 309.824C-73.14 305.473 -7.13467 336.447 50.4473 387.579Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M61.2441 380.44C77.5407 394.95 93.446 410.217 107.701 426.731C149.964 475.821 202.13 503.606 267.17 509.852C278.337 510.916 289.437 512.585 300.423 514.852C354.521 526.146 401.21 552.745 434.182 601.37C482.131 672.378 485.199 745.609 441.64 820.174C411.858 871.134 363.935 918.782 309.116 956.162C266.131 985.498 218.965 1008.52 172.973 1022C153.608 1020.07 133.961 1019.17 114.437 1017.89C88.7636 1016.2 63.3102 1013.8 38.8959 1007.42C-35.7773 987.858 -104.313 994.385 -175.05 1025.4C-209.587 1040.58 -243.341 1046.68 -275.213 1043.94C-297.005 1042.08 -318.215 1035.94 -337.624 1025.86C-362.075 1013.2 -384.68 994.214 -404.656 969.009C-441.198 922.889 -470.417 869.863 -496.213 816.812C-515.933 777.073 -539.565 743.299 -570.434 714.488C-582.932 702.882 -596.36 692.318 -610.582 682.902C-688.508 632.101 -754.99 567.878 -792.534 482.997C-798.082 470.449 -802.927 457.603 -807.046 444.517C-845.996 320.728 -822.609 160.244 -682.823 88.6253C-594.677 43.3978 -509.148 54.0202 -428.264 102.939C-389.803 126.164 -357.002 159.168 -322.941 189.238C-296.278 212.805 -272.12 239.196 -245.921 263.35C-218.768 288.409 -187.544 301.891 -149.022 299.715C-67.3923 295.132 1.30263 327.292 61.2441 380.44Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M72.0502 373.29C88.958 388.325 105.475 404.13 120.219 421.243C164.071 472.13 218.169 500.892 285.654 507.322C297.239 508.401 308.756 510.115 320.154 512.456C376.22 524.081 424.657 551.572 458.913 602.031C508.72 675.568 511.984 751.563 466.86 828.902C435.99 881.818 386.281 931.287 329.347 970.109C284.761 1000.53 235.786 1024.46 188.045 1038.4C167.934 1036.36 147.542 1035.4 127.26 1034.01C100.621 1032.22 74.1651 1029.66 48.8095 1023.01C-28.7244 1002.62 -99.8523 1009.35 -173.193 1041.7C-209.038 1057.59 -244.101 1063.94 -277.109 1061.14C-299.727 1059.25 -321.747 1052.9 -341.904 1042.47C-367.321 1029.37 -390.806 1009.69 -411.467 983.518C-449.366 935.663 -479.612 880.595 -506.276 825.455C-526.436 784.175 -550.887 749.24 -583.04 719.524C-596.055 707.548 -610.035 696.665 -624.838 686.985C-705.991 634.35 -775.212 567.756 -814.223 479.709C-819.998 466.692 -825.043 453.364 -829.334 439.786C-869.898 311.438 -845.753 144.744 -700.71 70.4001C-609.202 23.498 -520.408 34.4993 -436.432 85.3252C-396.516 109.479 -362.468 143.718 -327.124 174.961C-299.421 199.408 -274.346 226.851 -247.157 251.97C-218.965 277.982 -186.531 291.966 -146.492 289.668C-61.6354 284.791 9.74916 318.137 72.0502 373.29Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M82.8453 366.151C100.389 381.687 117.492 398.042 132.786 415.754C178.216 468.438 234.258 498.166 304.175 504.73C316.173 505.84 328.103 507.598 339.91 509.999C397.969 521.966 448.13 550.337 483.682 602.642C535.334 678.697 538.794 757.418 492.105 837.617C460.16 892.477 408.679 943.779 349.727 984.032C303.54 1015.58 252.779 1040.36 203.278 1054.77C182.422 1052.62 161.272 1051.58 140.232 1050.11C112.602 1048.19 85.2171 1045.5 58.8712 1038.54C-21.4991 1017.26 -95.1698 1024.24 -171.212 1057.92C-208.353 1074.4 -244.663 1081.15 -278.955 1078.28C-302.421 1076.36 -325.274 1069.81 -346.195 1059.01C-372.541 1045.48 -396.833 1025.09 -418.326 997.967C-457.569 948.375 -488.854 891.242 -516.398 834.023C-537.108 791.24 -562.342 755.156 -595.705 724.634C-609.245 712.283 -623.786 701.077 -639.179 691.129C-723.535 636.672 -795.396 567.67 -836.009 476.494C-842.011 463.008 -847.255 449.199 -851.719 435.129C-893.896 302.111 -869.005 129.306 -718.681 52.2478C-623.811 3.62213 -531.754 15.0513 -444.696 67.7841C-403.313 92.8548 -368.03 128.364 -331.39 160.757C-302.673 186.146 -276.669 214.541 -248.477 240.578C-219.234 267.567 -185.577 282.052 -144.035 279.608C-55.8771 274.449 18.1848 308.981 82.8453 366.151Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M218.451 1071.14C168.473 1065.91 116.845 1066.86 68.8842 1054.02C-14.3468 1031.85 -90.6461 1039.09 -169.28 1074.12C-266.803 1117.55 -358.91 1096 -425.025 1012.38C-465.626 961.039 -497.938 901.864 -526.35 842.555C-555.935 780.801 -594.873 732.738 -653.336 695.175C-753.695 630.67 -836.938 546.45 -873.871 430.362C-917.662 292.723 -892.038 113.769 -736.444 33.9979C-638.2 -16.3635 -542.891 -4.49432 -452.74 50.1331C-409.951 76.1084 -373.372 112.889 -335.376 146.431C-305.631 172.736 -278.71 202.183 -249.504 229.124C-219.209 257.067 -184.354 272.053 -141.308 269.462C-50.0574 264.022 26.6941 299.728 93.7019 358.915C111.869 374.964 129.584 391.918 145.403 410.254C192.423 464.722 250.408 495.428 322.758 502.115C398.397 509.119 464.305 538.272 508.512 603.228C562.011 681.814 565.678 763.285 517.412 846.296C458.461 947.642 335.13 1037.34 218.451 1071.14Z"
                stroke={strokeColor}
                strokeMiterlimit="10"/>
            <path
                d="M-122.298 501.943C-116.65 507.762 -111.014 513.727 -105.854 520.034C-90.5359 538.688 -71.3786 549.897 -47.7223 553.918C-43.5779 554.627 -39.5068 555.495 -35.4968 556.559C-15.1414 562.133 1.98642 573.232 13.0749 591.066C29.6282 619.095 29.2737 645.865 12.3659 672.194C0.95949 690.09 -16.6574 706.555 -36.4749 719.5C-51.6332 729.456 -67.9011 737.612 -84.9489 743.8C-91.6729 743.715 -98.3969 744.008 -105.145 744.253C-113.889 744.821 -122.668 744.453 -131.332 743.153C-157.458 738.74 -181.982 740.891 -207.815 749.191C-219.264 753.205 -231.535 754.297 -243.513 752.369C-251.224 751.103 -258.62 748.369 -265.299 744.314C-274.432 738.625 -282.534 731.429 -289.261 723.032C-302.856 706.506 -314.531 688.378 -325.534 670.727C-336.732 656.829 -346.782 642.711 -356.562 628.458C-360.438 623.092 -364.496 617.799 -368.421 613.179C-391.649 593.511 -412.347 569.406 -424.022 538.492C-425.745 533.92 -427.226 529.26 -428.46 524.533C-439.952 479.22 -429.206 423.516 -379.045 397.993C-348.127 382.237 -318.052 385.415 -289.603 401.93C-276.033 409.789 -264.309 421.011 -252.242 431.572C-242.963 439.701 -234.528 448.771 -225.481 457.144C-216.128 465.823 -205.516 470.822 -192.606 470.847C-165.221 470.932 -142.213 482.96 -122.298 501.943Z"
                stroke={strokeColor}
                strokeWidth="3"
                strokeMiterlimit="10"/>
            <path
                d="M-79.1181 473.328C-70.8456 481.145 -62.9978 489.4 -55.6086 498.056C-33.9573 523.873 -7.02451 538.981 26.3633 543.528C32.1948 544.335 37.9286 545.386 43.4789 546.731C71.7687 553.649 95.8528 568.379 112.1 593.474C136.038 631.612 136.478 669.261 113.323 706.995C97.5888 732.665 72.8445 756.464 44.787 775.166C23.3768 789.552 0.137909 801.01 -24.3114 809.234C-34.0062 808.72 -43.811 808.732 -53.5302 808.622C-66.3303 808.512 -79.0693 807.938 -91.3314 805.31C-128.888 797.352 -163.767 800.518 -200.138 814.16C-217.743 820.932 -234.834 823.046 -251.008 820.956C-262.033 819.546 -272.678 816.009 -282.354 810.541C-295.479 802.906 -307.039 792.857 -316.427 780.924C-335.425 757.43 -351.221 731.088 -365.708 705.137C-378.911 685.151 -392.151 666.412 -406.907 648.737C-412.672 641.984 -418.854 635.599 -425.417 629.619C-461.494 602.617 -492.987 569.076 -510.751 525.474C-513.372 519.021 -515.641 512.431 -517.548 505.733C-535.507 441.962 -521.79 361.604 -450.54 325.238C-406.162 302.551 -363.03 307.465 -322.246 331.607C-302.82 343.085 -286.132 359.428 -268.894 374.585C-255.532 386.344 -243.392 399.485 -230.311 411.574C-216.777 424.103 -201.312 431.132 -182.374 430.606C-142.202 429.567 -108.447 446.338 -79.1181 473.328Z"
                stroke={strokeColor}
                strokeWidth="3"
                strokeMiterlimit="10"/>
            <path
                d="M-35.9256 444.749C-25.1333 454.594 -14.9279 465.063 -5.36195 476.103C22.6344 509.106 57.3302 528.09 100.462 533.187C107.89 534.055 115.267 535.307 122.565 536.94C158.802 545.203 189.805 563.563 211.224 595.919C242.558 644.166 243.805 692.816 214.354 741.832C194.304 775.276 162.433 806.422 126.135 830.857C97.6254 850.048 66.5727 865.34 36.4125 874.703C23.7347 873.75 10.9101 873.481 -1.82883 873.017C-18.5899 872.43 -35.241 871.281 -51.2442 867.504C-100.219 855.989 -145.454 860.17 -192.399 879.165C-215.212 888.516 -237.438 891.865 -258.417 889.567C-272.756 888.022 -286.653 883.683 -299.323 876.794C-315.375 868.078 -330.254 855.39 -343.531 838.803C-367.982 808.341 -387.86 773.797 -405.881 739.485C-421.089 713.425 -437.532 690.053 -457.228 668.955C-465.063 660.707 -473.484 653.035 -482.424 645.999C-531.326 611.687 -573.638 568.697 -597.478 512.395C-601.005 504.066 -604.07 495.55 -606.659 486.884C-631.11 404.643 -614.374 299.642 -522.047 252.422C-464.196 222.829 -408.02 229.478 -354.9 261.235C-329.618 276.344 -307.967 297.784 -285.557 317.538C-268.1 332.939 -252.268 350.162 -235.152 365.955C-217.425 382.347 -197.118 391.332 -172.154 390.329C-119.181 388.263 -74.6804 409.704 -35.9256 444.749Z"
                stroke={strokeColor}
                strokeWidth="3"
                strokeMiterlimit="10"/>
            <path
                d="M7.26702 416.17C20.5787 428.037 33.1343 440.726 44.8604 454.161C79.2017 494.304 121.661 517.211 174.524 522.834C183.616 523.791 192.651 525.231 201.591 527.149C245.761 536.756 283.758 558.747 310.3 598.364C349.006 656.731 351.072 716.322 315.349 776.658C290.971 817.9 251.96 856.368 207.435 886.56C172.494 910.238 134.277 928.989 97.0753 940.186C81.4267 938.792 65.5703 938.254 49.8239 937.411C29.1139 936.347 8.53847 934.636 -11.2057 929.698C-71.5995 914.626 -127.201 919.833 -184.71 944.17C-212.73 956.113 -240.079 960.685 -265.911 958.191C-283.559 956.503 -300.702 951.361 -316.366 943.058C-336.171 932.582 -354.472 917.107 -370.72 896.682C-400.513 859.265 -424.512 816.507 -446.029 773.858C-463.23 741.722 -482.876 713.73 -507.547 689.197C-517.431 679.458 -528.058 670.504 -539.334 662.415C-601.17 620.77 -654.18 568.293 -684.12 499.34C-688.54 489.136 -692.397 478.698 -695.673 468.072C-726.542 367.361 -706.871 237.705 -593.456 179.594C-522.145 143.082 -452.863 151.479 -387.457 190.852C-356.318 209.578 -329.704 236.079 -302.123 260.526C-280.569 279.559 -261.033 300.865 -239.895 320.422C-217.987 340.652 -192.815 351.642 -161.823 350.114C-96.1604 346.838 -40.9135 373.082 7.26702 416.17Z"
                stroke={strokeColor}
                strokeWidth="3"
                strokeMiterlimit="10"/>
            <path
                d="M50.4473 387.579C66.2912 401.487 81.2095 416.416 95.1069 432.269C135.781 479.574 186.003 506.381 248.61 512.541C259.363 513.594 270.051 515.226 280.628 517.431C332.733 528.432 377.686 554.028 409.375 600.894C455.477 669.346 458.338 739.901 416.356 811.581C387.65 860.598 341.511 906.4 288.746 942.374C247.375 970.488 201.945 992.735 157.762 1005.77C139.131 1003.94 120.255 1003.13 101.525 1001.93C76.8543 1000.36 52.3667 998.101 28.8816 992.002C-42.9309 973.385 -108.887 979.607 -176.959 1009.29C-210.188 1023.82 -242.658 1029.61 -273.332 1026.92C-294.295 1025.1 -314.69 1019.15 -333.347 1009.42C-356.893 997.197 -378.581 978.861 -397.836 954.671C-433.033 910.287 -461.225 859.326 -486.153 808.354C-505.371 770.13 -528.196 737.518 -557.831 709.562C-569.81 698.327 -582.68 688.083 -596.317 678.93C-671.027 629.962 -734.807 568.098 -770.835 486.395C-776.161 474.317 -780.81 461.952 -784.76 449.357C-822.085 330.177 -799.431 175.866 -664.951 106.949C-580.228 63.408 -497.926 73.5292 -420.074 120.566C-383.092 142.911 -351.526 174.594 -318.762 203.54C-293.088 226.203 -269.86 251.567 -244.712 274.804C-218.611 298.884 -188.597 311.878 -151.579 309.824C-73.14 305.473 -7.13467 336.447 50.4473 387.579Z"
                stroke={strokeColor}
                strokeWidth="3"
                strokeMiterlimit="10"/>
            <path
                d="M218.451 1071.14C168.473 1065.91 116.845 1066.86 68.8842 1054.02C-14.3468 1031.85 -90.6461 1039.09 -169.28 1074.12C-266.803 1117.55 -358.91 1096 -425.025 1012.38C-465.626 961.039 -497.938 901.864 -526.35 842.555C-555.935 780.801 -594.873 732.738 -653.336 695.175C-753.695 630.67 -836.938 546.45 -873.871 430.362C-917.662 292.723 -892.038 113.769 -736.444 33.9979C-638.2 -16.3635 -542.891 -4.49432 -452.74 50.1331C-409.951 76.1084 -373.372 112.889 -335.376 146.431C-305.631 172.736 -278.71 202.183 -249.504 229.124C-219.209 257.067 -184.354 272.053 -141.308 269.462C-50.0574 264.022 26.6941 299.728 93.7019 358.915C111.869 374.964 129.584 391.918 145.403 410.254C192.423 464.722 250.408 495.428 322.758 502.115C398.397 509.119 464.305 538.272 508.512 603.228C562.011 681.814 565.678 763.285 517.412 846.296C458.461 947.642 335.13 1037.34 218.451 1071.14Z"
                stroke={strokeColor}
                strokeWidth="3"
                strokeMiterlimit="10"/>
        </svg>

    );
};
