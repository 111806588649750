interface IProps {}

export const Weather: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M43.758 74.228a1.151 1.151 0 0 1-.817-1.962 24.744 24.744 0 0 1 17.615-7.297h4.454v-4.455A24.748 24.748 0 0 1 72.307 42.9l2.918-2.918-2.918-2.917a24.748 24.748 0 0 1-7.297-17.616v-4.454h-4.454a24.747 24.747 0 0 1-17.615-7.297 1.152 1.152 0 1 1 1.628-1.628 22.444 22.444 0 0 0 15.987 6.623h5.605a1.15 1.15 0 0 1 1.151 1.151v5.605a22.444 22.444 0 0 0 6.624 15.987l3.73 3.735a1.151 1.151 0 0 1 0 1.623l-3.73 3.735a22.443 22.443 0 0 0-6.624 15.986v5.605a1.15 1.15 0 0 1-1.15 1.151h-5.606a22.445 22.445 0 0 0-15.987 6.624 1.15 1.15 0 0 1-.811.334Z"
				fill="#22B573"
			/>
			<path
				d="M43.586 59.795a1.158 1.158 0 0 1-1.258-1.044 1.156 1.156 0 0 1 1.045-1.258 17.84 17.84 0 0 0 0-35.07 1.15 1.15 0 1 1 .431-2.261 20.142 20.142 0 0 1 0 39.593l-.218.04Z"
				fill="#22B573"
			/>
			<path
				d="M36.461 19.748a1.15 1.15 0 0 1-.817-.34l-7.13-7.124a1.15 1.15 0 0 1 1.623-1.629l7.13 7.124a1.152 1.152 0 0 1-.812 1.969h.006ZM29.331 69.647a1.151 1.151 0 0 1-.811-1.968l7.124-7.124a1.152 1.152 0 1 1 1.629 1.628l-7.13 7.148a1.15 1.15 0 0 1-.812.316Z"
				fill="#224946"
			/>
			<path
				d="M36.461 77.963a1.15 1.15 0 0 1-1.15-1.151v-28.63a9.45 9.45 0 0 1 0-16.401V3.15a1.15 1.15 0 1 1 2.301 0v29.35a1.15 1.15 0 0 1-.656 1.035 7.153 7.153 0 0 0 0 12.937 1.151 1.151 0 0 1 .656 1.036v29.349a1.151 1.151 0 0 1-1.15 1.105ZM9.984 36.137a1.15 1.15 0 0 1-.3-2.261l13.495-3.62-3.614-13.495a1.15 1.15 0 1 1 2.222-.575L25.7 30.79a1.15 1.15 0 0 1-.811 1.41l-14.606 3.913c-.098.022-.199.03-.3.023Z"
				fill="#224946"
			/>
			<path
				d="M31.811 36.362c-.202 0-.4-.054-.575-.156L2.617 19.592a1.15 1.15 0 1 1 1.151-1.99l28.619 16.625a1.15 1.15 0 0 1 .42 1.577 1.15 1.15 0 0 1-.996.558ZM20.676 64.652a1.15 1.15 0 0 1-1.11-1.45l3.613-13.495-13.495-3.62a1.15 1.15 0 0 1 .576-2.221l14.605 3.913a1.15 1.15 0 0 1 .812 1.41l-3.914 14.606a1.151 1.151 0 0 1-1.087.857Z"
				fill="#224946"
			/>
			<path
				d="M3.193 62.517a1.15 1.15 0 0 1-.576-2.147L31.23 43.758a1.151 1.151 0 1 1 1.151 1.99L3.768 62.363a1.15 1.15 0 0 1-.575.155Z"
				fill="#224946"
			/>
		</svg>
	);
};
