import { ICta } from '@/utils/interfaces/components';
import { Icon } from '../Icon';
import { Arrow } from '../Icons/Arrow';
import { SbLink } from '../SbLink';

interface IProps {
	blok: ICta;
}

export const CtaSecondary: React.FC<IProps> = ({ blok }) => {
	const classes = ['cta', 'cta--secondary'];

	// Tailwind doesnt do well with dynamic values
	if (blok.size === 'large') {
		classes.push('cta--large');
	} else if (blok.size === 'medium') {
		classes.push('cta--medium');
	} else if (blok.size === 'small') {
		classes.push('cta--small');
	}

	if (blok.icon) {
		classes.push('cta--icon');
	}

	return (
		<SbLink link={blok.link}>
			<a className={classes.join(' ')}>
				<span className="cta-label">{blok.label}</span>

				{blok.icon && (
					<div className="cta-icon">
						<Icon icon={<Arrow />} />
					</div>
				)}

				<div className="cta-overlay"></div>
			</a>
		</SbLink>
	);
};
