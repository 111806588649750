import {
	useMenuState,
	useMenuToggle,
} from '@/components/Providers/HeaderProvider';

interface IProps {}

export const Hamburger: React.FC<IProps> = () => {
	const isActive = useMenuState();
	const toggleMenu = useMenuToggle();
	return (
		<div
			className={`hamburger ${isActive ? 'is-active' : ''}`}
			onClick={toggleMenu}
		>
			<div className="hamburger-box">
				<div className="hamburger-inner" />
			</div>
		</div>
	);
};
