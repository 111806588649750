import { AlertContext } from '@/components/Providers/AlertProvider';
import axios from 'axios';
import { pathOr } from 'ramda';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { z } from 'zod';
import { IFormStepProps } from '../SelfSubscribeContainer';
import { FormCol } from '../Shared/FormCol';
import { FormFormProvider } from '../Shared/FormFormProvider';
import { FormInput } from '../Shared/FormInput';
import { FormRow } from '../Shared/FormRow';
import { FormStepLayout } from '../Shared/FormStepLayout';
import { useZodForm } from '../Shared/UseZodForm';

const sendData = async (data: FormData) => {
	const { data: response } = await axios.post(
		'/api/custom-plans-handler',
		data,
	);
	return response.data;
};

const schema = z.object({
	firstName: z.string().min(1, 'First name is required'),
	lastName: z.string().min(1, 'Last name is required'),
	email: z.string().email('Please enter a valid email address.'),
	phone: z.string().min(1, 'Phone number is required'),
	searches: z.string().min(1, 'Number of searches is required'),
	companyName: z.string().min(1, 'Company name is required'),
});

type FormData = z.infer<typeof schema>;

export const CustomForm: React.FC<IFormStepProps> = ({
	children,
	blok,

	...props
}) => {
	const alert = useContext(AlertContext);
	const { mutate, isLoading } = useMutation(sendData, {
		onSuccess: (data) => {
			alert.success(
				`Your message request has been sent successfully`,
				10,
			);
			props.send({ event: 'NEXT', data: {} });
		},
		onError: () => {
			alert.error(
				`There was an issue submitting your form, please send an email to ${process.env.NEXT_MANDRILL_CUSTOM_TO_EMAIL}`,
				10,
			);
		},
	});

	const methods = useZodForm({
		schema,
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			searches: '',
			companyName: '',
		},
	});

	const {
		register,
		formState: { isSubmitting },
	} = methods;

	const heading = pathOr(null, ['customForm_heading'], blok);
	const description = pathOr(null, ['customForm_description'], blok);
	const media = pathOr(null, ['customForm_media'], blok);
	const demoLink = pathOr(null, ['demo_link'], blok);

	return (
		<FormStepLayout media={media} link={demoLink}>
			<div className="form-step">
				<div className="form-step__heading">
					<h4>{heading}</h4>
				</div>

				{description && (
					<div className="form-step__description">
						<span>{description}</span>
					</div>
				)}

				<div className="form-step__form">
					<FormFormProvider
						form={methods}
						onSubmit={(data) => mutate(data)}
					>
						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="First name*"
										disabled={isSubmitting}
										{...register('firstName', {
											required: true,
										})}
									/>
								</div>
							</FormCol>

							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Last name*"
										disabled={isSubmitting}
										{...register('lastName', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Email*"
										disabled={isSubmitting}
										{...register('email', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Phone*"
										disabled={isSubmitting}
										{...register('phone', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Number of searches*"
										disabled={isSubmitting}
										{...register('searches', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Company name*"
										disabled={isSubmitting}
										{...register('companyName', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol submit={true}>
								<div className="form-step__form-row form-step__form-row--submit">
									<button
										className="cta cta--primary cta--large"
										disabled={isLoading || isSubmitting}
										type="submit"
									>
										<span className="cta-label">
											Submit
										</span>
									</button>

									{props.previousEnabled && (
										<button
											className="cta cta--form-nav"
											onClick={() =>
												props.send({
													event: 'PREVIOUS',
													data: {},
												})
											}
										>
											<span className="cta-label">
												Previous
											</span>
										</button>
									)}
								</div>
							</FormCol>
						</FormRow>
					</FormFormProvider>
				</div>
			</div>
		</FormStepLayout>
	);
};
