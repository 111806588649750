import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { IStoryPage } from '@/utils/interfaces/stories';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { StoryAlternatesHrefLang } from '../Shared/StoryAlternatesHrefLang';
import { SEO } from '@/components/Shared/SEO';
import { removeLocale } from '@/utils/helpers/removeLocale';
import { Footer } from '@/components/Shared/Layout/Footer/Footer';
import { Header } from '../Shared/Layout/Header';

interface IProps {
	blok: IStoryPage;
	story: any;
	preview: boolean;
}

export const Page: React.FC<IProps> = ({ blok, preview, story }) => {
	const { locale, asPath } = useRouter();
	const path = removeLocale(asPath);
	return (
		<Fragment>
			<StoryAlternatesHrefLang
				locale={locale}
				asPath={path}
				story={story}
			/>
			<SEO
				name={story.name}
				seo={blok.seo}
				og_type="website"
				asPath={path}
				locale={locale}
			/>

			<Header />

			{isArrayWithValue(blok.body) ? (
				<main {...storyblokEditable(blok)}>
					{blok.body.map((nestedBlok: any) => (
						<StoryblokComponent
							blok={nestedBlok}
							preview={preview}
							key={nestedBlok._uid}
						/>
					))}
				</main>
			) : (
				<main></main>
			)}
			{!blok.hide_footer && <Footer />}
		</Fragment>
	);
};
