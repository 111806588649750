import { CTA } from '@/components/Shared/CTA/CallToAction';
import { PricingPanelFeature } from '@/components/Shared/PricingPanelFeature';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { IPricingPanel } from '@/utils/interfaces/components';
import React from 'react';
import { useSettings } from '../Providers/SettingsProvider';

interface IProps {
	blok: IPricingPanel;
	showYearly: boolean;
}

export const PricingPanel: React.FC<IProps> = ({ blok, showYearly }) => {
	const { currency_label, currency_icon } = useSettings();

	return (
		<div className="relative text-left shadow-soft group snap-start grow shrink-0 basis-0 w-10/12 min-w-[288px]">
			<div
				className={`text-white p-8 ${
					blok.is_highlighted ? 'bg-green' : 'bg-green-dark'
				}`}
			>
				{blok.badge && isStringWithValue(blok.badge) && (
					<span className="inline-block bg-white rounded-full float-right text-green-dark font-bold text-xs lg:text-sm py-1.5 px-3">
						{blok.badge}
					</span>
				)}
				<h5 className="mb-2 text-inherit">{blok.heading}</h5>
				<p className="text-green-light/80">{blok.sub_heading}</p>
			</div>
			<div className="p-8">
				<div className="flex items-end justify-center mb-6 leading-none">
					<span className="text-right">
						<span
							className={`block text-lg font-bold ${
								blok.price_per_year == 'Custom'
									? 'invisible'
									: 'block'
							}`}
						>
							{currency_icon}
						</span>
						<span
							className={`text-sm pr-1 ${
								blok.price_per_year == 'Custom'
									? 'invisible'
									: 'block'
							}`}
						>
							{currency_label}
						</span>
					</span>
					<span className="font-bold leading-none text-transparent text-7xl bg-clip-text bg-gradient-to-r from-green to-teal">
						{showYearly
							? blok.price_per_year
							: blok.price_per_month}
					</span>

					<span
						className={`text-sm ${
							blok.price_per_year == 'Custom'
								? 'invisible'
								: 'block'
						}`}
					>
						/Month
					</span>
				</div>
				{blok.cta.map((cta, key) => {
					return <CTA blok={cta} key={key} />;
				})}
				<hr className="my-5 border-gray-light" />
				{blok.callout && isStringWithValue(blok.callout) && (
					<div className="p-3 my-4 text-xs font-bold text-center rounded text-green-dark bg-green-light md:text-sm">
						{blok.callout}
					</div>
				)}
				<p className="mb-2 font-bold text-green-dark">Plan includes:</p>
				{blok.features && isArrayWithValue(blok.features) && (
					<ul className="list-none">
						{blok.features.map((feature, key) => (
							<PricingPanelFeature blok={feature} key={key} />
						))}
					</ul>
				)}
			</div>
		</div>
	);
};
