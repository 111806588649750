interface IProps {}

export const Plans: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22.18 40.423a1.104 1.104 0 0 1-1.105-1.105V16.67a1.105 1.105 0 0 1 2.21 0v22.647a1.105 1.105 0 0 1-1.106 1.105ZM25.094 73.768c6.975-2.889 10.288-10.886 7.398-17.862-2.89-6.975-10.886-10.288-17.862-7.398-6.975 2.89-10.288 10.886-7.398 17.862 2.889 6.975 10.886 10.288 17.862 7.398Z"
				fill="#22B573"
			/>
			<path
				d="M19.86 75.912a14.78 14.78 0 1 1 14.803-14.776A14.793 14.793 0 0 1 19.86 75.912Zm0-27.348a12.571 12.571 0 1 0 12.594 12.572A12.588 12.588 0 0 0 19.86 48.564Z"
				fill="#224946"
			/>
			<path
				d="M75.295 40.423H8.051a1.105 1.105 0 0 1 0-2.21h67.244a1.105 1.105 0 0 1 0 2.21Z"
				fill="#22B573"
			/>
			<path
				d="M75.295 74.045H36.133a1.104 1.104 0 1 1 0-2.21H74.19V6.805H23.285v9.888a1.104 1.104 0 0 1-1.105 1.105H9.155v26.58a1.104 1.104 0 1 1-2.21 0V16.692a1.105 1.105 0 0 1 1.106-1.105h13.024V5.701a1.105 1.105 0 0 1 1.105-1.105h53.115A1.105 1.105 0 0 1 76.4 5.701v67.24a1.105 1.105 0 0 1-1.105 1.104Z"
				fill="#22B573"
			/>
			<path
				d="M49.809 40.423a1.105 1.105 0 0 1-1.105-1.105V5.7a1.105 1.105 0 0 1 2.21 0v33.617a1.105 1.105 0 0 1-1.105 1.105Z"
				fill="#22B573"
			/>
			<path
				d="M51.4 19.477h-3.187a1.104 1.104 0 1 1 0-2.21H51.4a1.105 1.105 0 0 1 0 2.21ZM51.4 27.751h-3.187a1.104 1.104 0 1 1 0-2.21H51.4a1.105 1.105 0 0 1 0 2.21ZM40.131 8.397a1.105 1.105 0 0 1-1.105-1.105V4.105a1.105 1.105 0 1 1 2.21 0v3.187a1.105 1.105 0 0 1-1.105 1.105ZM31.857 8.397a1.105 1.105 0 0 1-1.105-1.105V4.105a1.105 1.105 0 0 1 2.21 0v3.187a1.105 1.105 0 0 1-1.105 1.105ZM57.52 74.045a1.105 1.105 0 0 1-1.105-1.105V39.318a1.104 1.104 0 1 1 2.21 0V72.94a1.105 1.105 0 0 1-1.105 1.105Z"
				fill="#22B573"
			/>
			<path
				d="M59.116 53.1h-3.192a1.105 1.105 0 0 1 0-2.21h3.192a1.105 1.105 0 0 1 0 2.21ZM59.116 61.368h-3.192a1.105 1.105 0 1 1 0-2.21h3.192a1.105 1.105 0 0 1 0 2.21Z"
				fill="#22B573"
			/>
			<path
				d="M19.86 67.472a1.105 1.105 0 0 1-.906-.475l-6.076-8.711a1.107 1.107 0 1 1 1.812-1.27l5.192 7.423 5.186-7.423a1.107 1.107 0 0 1 1.812 1.27l-6.076 8.71a1.104 1.104 0 0 1-.944.476Z"
				fill="#224946"
			/>
			<path
				d="M34.48 45.4a1.105 1.105 0 0 1-1.104-1.105v-4.972a1.105 1.105 0 0 1 2.21 0v4.972a1.105 1.105 0 0 1-1.105 1.104Z"
				fill="#22B573"
			/>
		</svg>
	);
};
