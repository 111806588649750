interface IProps {}

export const Plumbing: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28.11 78h-1.186A20.191 20.191 0 0 1 6.773 57.849V21.144L4.44 12.98a1.151 1.151 0 0 1 .184-1.014 1.152 1.152 0 0 1 .922-.454h19a1.153 1.153 0 0 1 .92.454 1.152 1.152 0 0 1 .185 1.014l-2.35 8.164v36.705a3.61 3.61 0 0 0 3.605 3.604h1.186a3.604 3.604 0 0 0 3.604-3.605V51.89a1.152 1.152 0 0 1 1.152-1.151h14.267a1.152 1.152 0 0 1 1.152 1.151v5.96A20.197 20.197 0 0 1 28.11 78ZM7.055 13.815l1.958 6.851c.03.103.045.21.046.317V57.83A17.889 17.889 0 0 0 26.907 75.68h1.186A17.888 17.888 0 0 0 45.942 57.83v-4.79H34.018v4.808a5.913 5.913 0 0 1-5.908 5.907h-1.186A5.914 5.914 0 0 1 21 57.849V21c0-.107.016-.214.046-.317l1.957-6.851-15.948-.017Z"
				fill="#224946"
			/>
			<path
				d="M3.151 3.152h23.773v8.36a1.152 1.152 0 0 1-1.151 1.151H4.303a1.152 1.152 0 0 1-1.152-1.152v-8.36Z"
				fill="#22B573"
			/>
			<path
				d="M25.773 13.815H4.303A2.303 2.303 0 0 1 2 11.512v-8.36A1.151 1.151 0 0 1 3.152 2h23.772a1.152 1.152 0 0 1 1.152 1.152v8.36a2.303 2.303 0 0 1-2.303 2.303ZM4.303 4.303v7.208h21.47V4.303H4.303ZM13.85 22.151H7.906a1.151 1.151 0 1 1 0-2.302h5.942a1.151 1.151 0 1 1 0 2.302Z"
				fill="#224946"
			/>
			<path
				d="M50.122 42.378H29.878a.576.576 0 0 0-.576.576v8.36c0 .318.258.575.576.575h20.244a.576.576 0 0 0 .575-.575v-8.36a.576.576 0 0 0-.575-.576Z"
				fill="#22B573"
			/>
			<path
				d="M50.122 53.04H29.878a1.727 1.727 0 0 1-1.727-1.726v-8.36a1.727 1.727 0 0 1 1.727-1.728h20.244a1.727 1.727 0 0 1 1.727 1.728v8.36a1.727 1.727 0 0 1-1.727 1.727Zm-19.668-2.302h19.092v-7.209H30.454v7.209Z"
				fill="#224946"
			/>
			<path
				d="M47.134 43.53H32.866a1.152 1.152 0 0 1-1.151-1.152v-5.942a20.186 20.186 0 0 1 20.007-20.151l8.17-2.338a1.152 1.152 0 0 1 1.469 1.111v19a1.15 1.15 0 0 1-1.469 1.106l-8.158-2.332a3.604 3.604 0 0 0-3.455 3.604v5.942a1.152 1.152 0 0 1-1.145 1.151Zm-13.116-2.304h11.964v-4.79a5.913 5.913 0 0 1 5.907-5.907.945.945 0 0 1 .311.046l6.858 1.94V16.567L52.2 18.524a1.153 1.153 0 0 1-.31.04 17.895 17.895 0 0 0-17.85 17.85l-.022 4.812Z"
				fill="#224946"
			/>
			<path
				d="M69.715 34.058V12.663h-7.128a2.372 2.372 0 0 0-2.378 2.378v19c0 1.313 2.378 2.378 2.378 2.378h6.944l.184-2.378"
				fill="#22B573"
			/>
			<path
				d="M69.53 37.588h-6.943c-.163 0-.324-.035-.472-.104-.513-.23-3.057-1.468-3.057-3.455v-19a3.535 3.535 0 0 1 3.529-3.529h7.128a1.151 1.151 0 0 1 1.151 1.152v21.481l-.184 2.378a1.152 1.152 0 0 1-1.151 1.077Zm-6.684-2.303h5.62l.097-1.273V13.815h-5.976a1.226 1.226 0 0 0-1.226 1.226v19c.028.259.702.84 1.485 1.244ZM51.89 24.512a1.151 1.151 0 0 1-1.152-1.151v-5.948a1.151 1.151 0 1 1 2.303 0v5.948a1.152 1.152 0 0 1-1.152 1.151Z"
				fill="#224946"
			/>
			<path
				d="M69.715 56.605a8.29 8.29 0 0 1-8.28-8.285 8.825 8.825 0 0 1 4.089-7.75 6.972 6.972 0 0 0 3.04-5.919v-1.785a1.152 1.152 0 0 1 1.888-.88C70.763 32.238 78 38.387 78 48.32a8.297 8.297 0 0 1-8.285 8.285Zm1.1-20.935a9.143 9.143 0 0 1-4.03 6.829 6.535 6.535 0 0 0-3.04 5.82 5.983 5.983 0 1 0 11.958 0c-.006-5.82-2.885-10.27-4.888-12.649Z"
				fill="#22B573"
			/>
		</svg>
	);
};
