interface IProps {}

export const AU: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#a)">
				<path d="M0 0h36v24H0V0Z" fill="#10338C" />
				<path
					d="M15.593 11.979c.021-.021.035-.042.056-.063a.456.456 0 0 1-.056.063Zm-2.4 2.94.772 1.607 1.733-.4-.771 1.6 1.396 1.11-1.74.392.007 1.782-1.397-1.115-1.39 1.116.008-1.783-1.74-.393 1.396-1.109-.78-1.6 1.741.4.765-1.607Zm13.888 2 .365.765.828-.19-.372.766.667.526-.828.182v.857l-.66-.534-.667.534.007-.857-.828-.182.667-.526-.372-.765.828.19.365-.766Zm-3.41-8.14.364.765.828-.19-.372.765.667.527-.828.19v.848l-.66-.533-.666.533.007-.849-.835-.19.666-.526-.372-.765.828.19.372-.765Zm3.41-4.646.365.765.828-.19-.372.766.667.526-.828.19v.849l-.66-.534-.667.534.007-.85L25.593 6l.667-.526-.372-.765.828.19.365-.766Zm2.982 3.488.365.765.828-.19-.372.765.667.527-.828.182v.856l-.66-.533-.666.533V9.67l-.828-.182.666-.527-.372-.765.828.19.372-.765Zm-2.133 4.063.288.891h.933l-.758.548.295.891-.758-.554-.758.554.288-.891-.751-.548h.933l.288-.89Z"
					fill="#fff"
				/>
				<path
					d="M17.88 0v2.147L14.717 3.91h3.165V8.07h-4.147l4.147 2.302v1.607h-1.874l-5.158-2.87v2.87H6.948v-3.41l-6.134 3.41h-.898V9.832L3.088 8.07H-.084V3.91h4.147L-.084 1.6V0H1.79l5.158 2.863V0h3.901v3.41L16.99 0h.892Z"
					fill="#fff"
				/>
				<path
					d="M10.021 0H7.776v4.863h-7.86V7.11h7.86v4.87h2.245v-4.87h7.86V4.863h-7.86V0Z"
					fill="#D80027"
				/>
				<path
					d="m10.85 8.07 7.031 3.909V10.87l-5.038-2.8H10.85Z"
					fill="#0052B4"
				/>
				<path
					d="m10.85 8.07 7.031 3.909V10.87l-5.038-2.8H10.85Z"
					fill="#fff"
				/>
				<path
					d="m10.85 8.07 7.03 3.909V10.87l-5.038-2.8H10.85Zm-5.888 0-5.046 2.8v1.109L6.948 8.07H4.962Z"
					fill="#D80027"
				/>
				<path
					d="M6.948 3.909-.084 0v1.102l5.046 2.807h1.986Z"
					fill="#0052B4"
				/>
				<path
					d="M6.948 3.909-.084 0v1.102l5.046 2.807h1.986Z"
					fill="#fff"
				/>
				<path
					d="M6.948 3.909-.084 0v1.102l5.046 2.807h1.986Zm5.894 0 5.039-2.807V0l-7.032 3.909h1.993Z"
					fill="#D80027"
				/>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h36v24H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};
