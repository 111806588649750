import { UtilityIcon } from '@/components/Shared/Icons/UtilityIcon';
import { IFooterContact } from '@/utils/interfaces/components';

import { pathOr } from 'ramda';
import React from 'react';

interface IProps {
	blok: IFooterContact;
}

export const FooterContact: React.FC<IProps> = ({ blok }) => {
	const address = pathOr(null, ['location_data', 'content', 'address'], blok);
	const phone_number = pathOr(
		null,
		['location_data', 'content', 'phone_number'],
		blok,
	);
	const lat = pathOr(null, ['location_data', 'content', 'lat'], blok);
	const lng = pathOr(null, ['location_data', 'content', 'lng'], blok);

	const classes = [
		'relative',
		'after:w-full',
		'after:h-0.5',
		'after:scale-x-0',
		'after:bg-gradient-to-r',
		'after:from-green',
		'to-[#1BD0BC]',
		'after:origin-left',
		'after:left-0',
		'after:bottom-[-2px]',
		'after:absolute',
		'hover:after:scale-x-100',
		'after:transition-all',
	];

	console.log(blok);

	return (
		<div>
			{blok.phone_number && (
				<div className="relative flex items-center mb-4">
					<div className="absolute h-5 mr-2 min-w-5 right-full">
						<UtilityIcon className="w-5 text-green" icon="phone" />
					</div>
					<div>
						<a
							href={`tel:${blok.phone_number}`}
							className={classes.join(' ')}
							rel="noreferrer"
						>
							<span>{blok.phone_number}</span>
						</a>
					</div>
				</div>
			)}

			{blok.address && (
				<div className="relative flex">
					<div className="absolute h-5 mr-2 right-full min-w-5">
						<UtilityIcon className="w-5 text-green" icon="pin" />
					</div>
					<div>
						<a
							href={`https://maps.google.com/?q="${blok.address}"`}
							target="_blank"
							className=""
							rel="noreferrer"
						>
							<span className="whitespace-pre-wrap">
								{blok.address}
							</span>
						</a>
					</div>
				</div>
			)}
		</div>
	);
};
