import { IMegaMenuColumn } from '@/utils/interfaces/components';
import { MegaMenuItem } from '@/components/Shared/Menu/MegaMenuItem';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { CTA } from '@/components/Shared/CTA/CallToAction';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { Fragment } from 'react';

interface IProps {
	blok: IMegaMenuColumn;
}

export const MegaMenuColumn: React.FC<IProps> = ({ blok }) => {
	const classes = [
		'relative lg:before:absolute lg:before:-left-4 lg:before:xl:-left-8 lg:before:top-0 lg:before:bottom-0 lg:before:w-px lg:before:bg-gray-light first:lg:before:hidden border-t border-gray-light pt-4 mt-4 lg:border-t-0 lg:pt-0 lg:mt-0',
	];

	if (blok.is_wide) {
		classes.push('col-span-2');
	}

	const MenuItems = () => {
		return (
			<>
				{blok.items.map((item, key) => (
					<MegaMenuItem blok={item} key={key} />
				))}
			</>
		);
	};

	return (
		<div className={classes.join(' ')}>
			{blok.heading && isStringWithValue(blok.heading) && (
				<p className="mb-4 font-bold text-green lg:text-lg xl:mb-6">
					{blok.heading}
				</p>
			)}
			{blok.is_wide ? (
				<div className="grid lg:grid-cols-2 gap-x-8 xl:gap-x-16">
					<MenuItems />
				</div>
			) : (
				<MenuItems />
			)}
			{blok.cta && isArrayWithValue(blok.cta) && (
				<div className="flex flex-row flex-wrap gap-4">
					{blok.cta.map((cta, key) => {
						return (
							<div className="inline-flex w-auto" key={key}>
								<CTA blok={cta} />
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
