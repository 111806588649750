import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { Fragment } from 'react';
import { StoryblokComponent } from '@storyblok/react';
import { IFormItems, IFormRow } from '@/utils/interfaces/components';

interface IProps {
	blok: IFormGroup;
}

interface IFormGroup {
	form_rows: IFormRow[];
	heading: string;
}

export const SbFormGroup: React.FC<IProps> = ({ blok }) => {
	return (
		<div className="flex-col w-full md:flex">
			<div className="py-2 mb-2 border-b md:mb-4 md:mt-2 border-green">
				<h6>{blok.heading}</h6>
			</div>
			{blok.form_rows && isArrayWithValue(blok.form_rows) && (
				<Fragment>
					{blok.form_rows.map((row, key) => {
						return <StoryblokComponent key={key} blok={row} />;
					})}
				</Fragment>
			)}
		</div>
	);
};
