import { IHeroBlok } from '@/utils/interfaces/bloks';
import { HeroHome } from '@/components/Bloks/Hero/HeroHome';
import { HeroStandard } from '@/components/Bloks/Hero/HeroStandard';
import { HeroSimple } from '@/components/Bloks/Hero/HeroSimple';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';

interface IProps {
	blok: IHeroBlok;
	preview: boolean;
}

export const Hero: React.FC<IProps> = ({ blok, preview }) => {
	if (blok.style === 'home') {
		return <HeroHome blok={blok} preview={preview} />;
	} else if (isStringWithValue(blok.image.filename)) {
		return <HeroStandard blok={blok} preview={preview} />;
	}
	return <HeroSimple blok={blok} preview={preview} />;
};
