import React from 'react';
import { Home } from '@/components/Shared/Icons/Home';
import { Pin } from '@/components/Shared/Icons/Pin';
import { Phone } from '@/components/Shared/Icons/Phone';

type MapKindToComponentT = {
	[key: string]: React.FC<any>;
};

const iconMap: MapKindToComponentT = {
	home: Home,
	pin: Pin,
	phone: Phone,
};

interface IProps {
	icon: string;
	className?: string;
}

export const UtilityIcon: React.FC<IProps> = ({ icon, className }) => {
	if (icon && typeof iconMap[icon] !== 'undefined') {
		const Component = iconMap[icon];

		return (
			<span className={className}>
				<Component />
			</span>
		);
	}

	return null;
};
