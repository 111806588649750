import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { IInsightPage, IStoryBase } from '@/utils/interfaces/stories';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { StoryAlternatesHrefLang } from '../Shared/StoryAlternatesHrefLang';
import { InsightHero } from './InsightHero';
import { InsightsRelated } from './InsightsRelated';
import { SEO } from "@/components/Shared/SEO";
import { removeLocale } from "@/utils/helpers/removeLocale";
import { Footer } from "@/components/Shared/Layout/Footer/Footer";

interface IProps {
	blok: IInsightPage;
	story: IStoryBase<IInsightPage>;
}

export const Insight: React.FC<IProps> = ({ blok, story }) => {
	const { locale, asPath } = useRouter();
	const path = removeLocale(asPath);

	return (
		<Fragment>
			<StoryAlternatesHrefLang
				asPath={path}
				locale={locale}
				story={story}
			/>
			<SEO
				name={story.name}
				seo={blok.seo}
				og_type="article"
				asPath={path}
				locale={locale}
			/>
			{isArrayWithValue(blok.body) ? (
				<article className="bg-white" {...storyblokEditable(blok)}>
					<InsightHero story={story} />

					{blok.body.map((nestedBlok: any) => (
						<StoryblokComponent
							blok={nestedBlok}
							key={nestedBlok._uid}
						/>
					))}

					<InsightsRelated story={story} blok={blok} />
				</article>
			) : (
				<main></main>
			)}
			{
				!blok.hide_footer && <Footer/>
			}
		</Fragment>
	);
};
