export function getImageDimensionsFromUrl(url: string): {
	w: number;
	h: number;
} {
	if (url) {
		return {
			w: parseInt(url.split('/')[5].split('x')[0]),
			h: parseInt(url.split('/')[5].split('x')[1]),
		};
	}

	return {
		w: 0,
		h: 0,
	};
}
