interface IProps {
	progress: number;
}

export const ProgressBar: React.FC<IProps> = ({ progress }) => {
	return (
		<div className="progress-bar">
			<div className="progress-bar__container">
				<div
					className="progress-bar__bar"
					style={{ width: `${progress}%` }}
				></div>
			</div>
		</div>
	);
};
