import { AlertContext } from '@/components/Providers/AlertProvider';
import {
	IBuildTypesResponse,
	ISpecialisationResponse,
	SelectData,
	selectSchema,
	UserAccontApiResponse,
	UserUpdateApiRequest,
} from '@/utils/interfaces/cibApiResponse';
import axios from 'axios';
import { pathOr } from 'ramda';
import { useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { z } from 'zod';
import { BASE_URL, IFormStepProps } from '../SelfSubscribeContainer';
import { FormCol } from '../Shared/FormCol';
import { FormFormProvider } from '../Shared/FormFormProvider';
import { FormInput } from '../Shared/FormInput';
import { FormRow } from '../Shared/FormRow';
import { FormSelect } from '../Shared/FormSelect';
import { FormStepLayout } from '../Shared/FormStepLayout';
import { useZodForm } from '../Shared/UseZodForm';
import { useRouter } from 'next/router';
import { mapLocaleToInternationalCode } from '@/utils/helpers/mapLocalToCountry';

const schema = z.object({
	tradingName: z.string().min(1, 'Company name is required'),
	billingName: z.string().min(1, 'Company billing name is required'),
	phone: z
		.string()
		.min(1, 'Phone number is required')
		.min(12, 'Please provide a valid phone number'),
	companyAddress: z.string().min(1, 'Company address is required'),
	companyWebsite: z.string().min(1, 'Company website is required'),
	buildType: selectSchema,
	specialisation: selectSchema,
	subSpecialisation: selectSchema,
	buildsPerYear: selectSchema,
});

type FormData = z.infer<typeof schema>;

async function sendData(data: UserUpdateApiRequest) {
	const { data: response } = await axios.post(`${BASE_URL}/signup`, data);
	return response.data;
}

export const UpdateAccount: React.FC<IFormStepProps> = ({
	children,
	blok,
	...props
}) => {
	const { locale } = useRouter();
	const [buildTypes] = useState(null);
	const [countryCode] = useState<string>(
		mapLocaleToInternationalCode(locale as string),
	);
	const alert = useContext(AlertContext);

	const { mutateAsync: updateAccount } = useMutation(sendData, {
		onSuccess: (data: UserAccontApiResponse) => {
			if (props.formData) {
				props.send({ event: 'NEXT', data: {} });
			}
		},
		onError: (error) => {
			const errMessage = pathOr(
				'',
				['response', 'data', 'message'],
				error,
			);

			alert.error(errMessage, 10);
		},
	});

	const {
		data: buildTypesData,
		isSuccess: buildTypesIsSuccess,
		isLoading: buildTypesIsLoading,
	} = useQuery<IBuildTypesResponse>({
		queryKey: ['buildTypes', buildTypes],
		queryFn: () => {
			return fetch(`${BASE_URL}/build-types`).then((res) => res.json());
		},
	});

	const {
		data: specializationData,
		isSuccess: specializationIsSuccess,
		isLoading: specializationIsLoading,
	} = useQuery<ISpecialisationResponse>({
		queryKey: ['specialisation', buildTypes],
		queryFn: () => {
			return fetch(`${BASE_URL}/specialization`).then((res) =>
				res.json(),
			);
		},
	});

	const {
		data: additionalSpecializationData,
		isSuccess: additionalSpecializationIsSuccess,
		isLoading: additionalSpecializationIsLoading,
	} = useQuery<ISpecialisationResponse>({
		queryKey: ['specialisation', buildTypes],
		queryFn: () => {
			return fetch(`${BASE_URL}/additional-specialization`).then((res) =>
				res.json(),
			);
		},
	});

	const methods = useZodForm({
		schema,
		defaultValues: {
			tradingName: '',
			billingName: '',
			companyAddress: '',
			companyWebsite: '',
			phone: `+${countryCode}`,
			buildType: { label: '', value: '' },
			specialisation: { label: '', value: '' },
			subSpecialisation: { label: '', value: '' },
			buildsPerYear: { label: '', value: '' },
		},
	});

	const {
		register,
		control,
		setError,
		formState: { isSubmitting },
	} = methods;

	async function onSubmit(data: FormData) {
		if (props.formData) {
			const payload: UserUpdateApiRequest = {
				data: {
					tradingName: data.tradingName,
					billingName: data.billingName,
					phone: data.phone.replace('+', ''),
					companyAddress: data.companyAddress,
					companyWebsite: data.companyWebsite,
					buildType: [data.buildType.value],
					specialisation: [data.specialisation.value],
					subSpecialisation: [data.subSpecialisation.value],
					buildsPerYear: data.buildsPerYear.value,
					sessionId: props.formData.sessionId as string,
					email: props.formData.userData?.email as string,
				},
				step: 'company',
			};

			await new Promise((resolve) => {
				updateAccount(payload)
					.then((response) => {
						resolve(null);
					})
					.catch((err) => {
						const message = pathOr(
							null,
							['response', 'data', 'message'],
							err,
						);

						if (message === 'Invalid phone number format.') {
							setError('phone', {
								type: 'invalid',
								message,
							});

							resolve(null);
						}
					});
			});
		}
	}

	const buildTypeOptions: SelectData[] | undefined = buildTypesData?.data.map(
		function (buildType) {
			return { label: buildType.name, value: buildType.name };
		},
	);

	const specialisationOptions: SelectData[] | undefined =
		specializationData?.data.map(function (buildType) {
			return { label: buildType, value: buildType };
		});

	const additionalSpecialisationOptions: SelectData[] | undefined =
		additionalSpecializationData?.data.map(function (buildType) {
			return { label: buildType, value: buildType };
		});

	const description = pathOr(null, ['updateAccount_description'], blok);
	const media = pathOr(null, ['updateAccount_media'], blok);
	const demoLink = pathOr(null, ['demo_link'], blok);

	return (
		<FormStepLayout media={media} link={demoLink}>
			<div className="form-step">
				<div className="form-step__heading">
					<h4>
						Welcome{' '}
						{props.formData.userData?.firstName
							? props.formData.userData.firstName
							: ''}
					</h4>
				</div>

				{description && (
					<div className="form-step__description">
						<span>{description}</span>
					</div>
				)}

				<div className="form-step__form">
					<FormFormProvider
						form={methods}
						onSubmit={(data) => {
							onSubmit(data);
						}}
					>
						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Trading name*"
										disabled={isSubmitting}
										{...register('tradingName', {
											required: true,
										})}
									/>
								</div>
							</FormCol>

							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Billing name*"
										disabled={isSubmitting}
										{...register('billingName', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Company address*"
										disabled={isSubmitting}
										{...register('companyAddress', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Phone number*"
										disabled={isSubmitting}
										{...register('phone', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="text"
										label="Company website*"
										disabled={isSubmitting}
										{...register('companyWebsite', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									{buildTypesIsSuccess &&
										!buildTypesIsLoading && (
											<FormSelect
												label="Build type*"
												name="buildType"
												disabled={isSubmitting}
												placeholder="Please select"
												control={control}
												options={buildTypeOptions}
											/>
										)}
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									{specializationIsSuccess &&
										!specializationIsLoading && (
											<FormSelect
												label="Specialisation"
												name="specialisation"
												disabled={isSubmitting}
												placeholder="Please select"
												menuPlacement="top"
												control={control}
												options={specialisationOptions}
											/>
										)}
								</div>
							</FormCol>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									{additionalSpecializationIsSuccess &&
										!additionalSpecializationIsLoading && (
											<FormSelect
												label="Sub specialisation*"
												name="subSpecialisation"
												disabled={isSubmitting}
												placeholder="Please select"
												menuPlacement="top"
												control={control}
												options={
													additionalSpecialisationOptions
												}
											/>
										)}
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormSelect
										label="Build per year*"
										name="buildsPerYear"
										disabled={isSubmitting}
										placeholder="Please select"
										control={control}
										menuPlacement="top"
										options={[
											{
												label: '< 25',
												value: '<25',
											},
											{
												label: '25 - 50',
												value: '25-50',
											},
											{
												label: '50 - 100',
												value: '50-100',
											},
											{
												label: '100 - 250',
												value: '100-250',
											},
											{
												label: '250 +',
												value: '250+',
											},
										]}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol submit={true}>
								<div className="form-step__form-row form-step__form-row--submit">
									<button
										className="cta cta--primary cta--large"
										disabled={isSubmitting}
										type="submit"
									>
										<span className="cta-label">
											Continue
										</span>
									</button>
								</div>
							</FormCol>
						</FormRow>
					</FormFormProvider>
				</div>
			</div>
		</FormStepLayout>
	);
};
