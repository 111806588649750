import { Icon } from '@/components/Shared/Icon';
import { House } from '@/components/Shared/Icons/BrandIcons/House';
import QS from 'query-string';
import { pathOr } from 'ramda';
import { useEffect, useState } from 'react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { IFormStepProps } from '../SelfSubscribeContainer';
import { FormStepLayout } from '../Shared/FormStepLayout';

export const TrainingSession: React.FC<IFormStepProps> = ({
	children,
	blok,
	...props
}) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [document, setDocument] = useState<any>(null);
	const [sessionBooked, setSessionBooked] = useState(false);

	const queryString = QS.stringify({
		text_color: '4d4d4d',
		primary_color: '22b573',
	});

	useCalendlyEventListener({
		onProfilePageViewed: () => {},
		onDateAndTimeSelected: () => {},
		onEventTypeViewed: () => {},
		onEventScheduled: () => {
			setSessionBooked(true);
		},
	});

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const documentRoot = window.document.getElementById('__next');

			setDocument(documentRoot);
		}
	}, []);

	const heading = pathOr(null, ['trainingSession_heading'], blok);
	const description = pathOr(null, ['trainingSession_description'], blok);
	const media = pathOr(null, ['trainingSession_media'], blok);
	const demoLink = pathOr(null, ['demo_link'], blok);

	return (
		<FormStepLayout media={media} link={demoLink}>
			<div className="form-step form-step--training">
				<div className="flex flex-col justify-center flex-grow">
					<div>
						<div className="form-step__icon-container">
							<Icon
								className="form-step__icon"
								icon={<House />}
							/>
						</div>

						<div className="form-step__heading">
							<h4>{heading}</h4>
						</div>

						{description && (
							<div className="form-step__description">
								<span>{description}</span>
							</div>
						)}
					</div>

					<div className="form-step__training-modal-trigger">
						<div className="form-step__form-row form-step__form-row--submit">
							<button
								className="cta cta--primary cta--medium"
								type="button"
								disabled={sessionBooked}
								onClick={() => {
									setIsModalOpen(true);
								}}
							>
								<span className="cta-label">
									{sessionBooked
										? 'Session has been scheduled'
										: 'Book session'}
								</span>
							</button>
						</div>
					</div>
				</div>

				<div className="">
					<div className="form-step__form-row form-step__form-row--submit">
						{props.nextEnabled && (
							<button
								className="cta cta--tertiary cta--medium"
								type="button"
								onClick={() =>
									props.send({ event: 'NEXT', data: {} })
								}
							>
								<span className="cta-label">
									{sessionBooked ? 'Next' : 'Skip'}
								</span>
							</button>
						)}

						{props.previousEnabled && (
							<button
								className="cta cta--form-nav"
								onClick={() =>
									props.send({ event: 'PREVIOUS', data: {} })
								}
							>
								<span className="cta-label">Previous</span>
							</button>
						)}
					</div>
				</div>
			</div>

			{document && (
				<PopupModal
					url={`https://calendly.com/canibuild/canibuild-demo?${queryString}`}
					onModalClose={() => setIsModalOpen(false)}
					open={isModalOpen}
					rootElement={document}
				/>
			)}
		</FormStepLayout>
	);
};
