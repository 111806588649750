import { StoryblokContext } from '@/components/Providers/StoryblokProvider';
import { buildParams } from '@/utils/helpers/buildParams';
import {
	ILocationsPage,
	ISettingPage,
	IStoryApiResponse,
	IStoryBase,
} from '@/utils/interfaces/stories';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLanguage } from './LanguageProvider';

interface IProps {}

export type ISettingsContext = {
	enabled_live_chat: boolean;
	currency_label: string;
	currency_icon: string;
};

type SettingResponse = IStoryApiResponse<
	ISettingPage,
	IStoryBase<ILocationsPage>[]
>;

const SettingsContext = createContext<ISettingsContext>({
	enabled_live_chat: false,
	currency_label: '',
	currency_icon: '',
});

export function useSettings() {
	return useContext(SettingsContext);
}

export const SettingsClientProvider: React.FC<IProps> = ({ children }) => {
	const { locale } = useRouter();

	const { storyblokClient } = useContext(StoryblokContext);
	const { currentLanguage } = useLanguage();
	const [settingResponse, setSettingsResponse] =
		useState<SettingResponse | null>(null);
	const [settings, setSettings] = useState<ISettingsContext>({
		enabled_live_chat: false,
		currency_label: '',
		currency_icon: '',
	});

	async function fetchSettings() {
		if (storyblokClient) {
			const { data } = await storyblokClient.get(
				`cdn/stories/${locale}/global/settings`,
				buildParams(locale),
			);
			return data;
		}
		return null;
	}

	useEffect(() => {
		if (currentLanguage && settingResponse === null) {
			const fetchData = async () => {
				const data: SettingResponse = await fetchSettings();

				setSettingsResponse(data);
				setSettings(buildSiteSetting(data));
			};

			// call the function
			fetchData()
				// make sure to catch any error
				.catch(console.error);
		}
	}, [locale]);

	useEffect(() => {
		if (settingResponse && currentLanguage) {
			setSettings(buildSiteSetting(settingResponse));
		}
	}, [currentLanguage]);

	return (
		<SettingsContext.Provider value={settings}>
			{children}
		</SettingsContext.Provider>
	);
};

function buildSiteSetting(response: SettingResponse): ISettingsContext {
	return {
		enabled_live_chat: response.story.content.enabled_live_chat,
		currency_label: response.story.content.currency_label,
		currency_icon: response.story.content.currency_icon,
	};
}
