import React from 'react';
import { useFooter } from '@/components/Providers/FooterProvider';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { CTA } from '@/components/Shared/CTA/CallToAction';
import { SbImage } from '@/components/Shared/Image/SbImage';
import { render } from 'storyblok-rich-text-react-renderer';
import { SbLink } from '@/components/Shared/SbLink';
import { FooterColumn } from '@/components/Shared/Layout/Footer/FooterColumn';
import { Container } from '@/components/Shared/Layout/Container';
import { SocialLink } from '@/components/Shared/Layout/Footer/SocialLink';

interface IProps {}

export const Footer: React.FC<IProps> = () => {
	const footerContext = useFooter();
	const {
		menu,
		social_heading,
		social_links,
		cta_heading,
		cta_body,
		ctas,
		logo,
		tagline,
		mandatories,
	} = footerContext;

	return (
		<footer>
			<div className="py-14 md:py-24 bg-light">
				<Container>
					<div className="grid gap-12 md:grid-cols-3">
						<div className="md:col-span-2">
							{isArrayWithValue(menu) && (
								<nav className="grid grid-cols-2 gap-6 lg:grid-cols-4">
									{menu.map((column, key) => (
										<FooterColumn blok={column} key={key} />
									))}
								</nav>
							)}
						</div>
						<div className="md:col-span-1">
							{isStringWithValue(cta_heading) && (
								<h5 className="mb-6">{cta_heading}</h5>
							)}
							{isStringWithValue(cta_body) && (
								<p className="mb-6">{cta_body}</p>
							)}
							{isArrayWithValue(ctas) && (
								<div className="flex flex-wrap gap-2">
									{ctas.map((cta, key) => {
										return (
											<div key={key}>
												<CTA blok={cta} />
											</div>
										);
									})}
								</div>
							)}
						</div>
					</div>
					<hr className="my-10 lg:my-14 border-gray" />
					<div className="gap-12 sm:grid sm:grid-cols-2 lg:grid-cols-3">
						<div className="col-span-1">
							{isStringWithValue(logo.filename) && (
								<div className="relative w-full h-10 mb-4 overflow-hidden md:h-14">
									<SbImage
										src={logo.filename}
										alt={logo.alt}
										layout="fill"
										objectFit="contain"
										objectPosition="left"
									/>
								</div>
							)}
							{isStringWithValue(logo.filename) && (
								<div className="wyswiyg-content">
									{render(tagline)}
								</div>
							)}
						</div>
						<div className="col-span-1 lg:col-start-3">
							{isStringWithValue(social_heading) && (
								<h6>{social_heading}</h6>
							)}
							{isArrayWithValue(social_links) && (
								<nav className="flex gap-3 mt-3">
									{social_links.map((social, key) => (
										<SocialLink blok={social} key={key} />
									))}
								</nav>
							)}
						</div>
					</div>
				</Container>
			</div>
			<div className="py-6 text-white md:py-8 bg-green-dark">
				<Container>
					<div className="items-center justify-between md:flex">
						<span className="mb-1 text-sm lg:text-base md:mb-0">
							Copyright © {new Date().getFullYear()} Canibuild
						</span>

						<nav className="flex">
							{mandatories.map((mandatory, key) => {
								return (
									<SbLink link={mandatory.link} key={key}>
										<a className="flex text-sm lg:text-base first:before:hidden before:block before:h-full before:w-px before:bg-white before:mx-6">
											{mandatory.label}
										</a>
									</SbLink>
								);
							})}
						</nav>
					</div>
				</Container>
			</div>
		</footer>
	);
};
