import { IWysiwyg } from '@/utils/interfaces/bloks';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

interface IProps {
	blok: IWysiwyg;
}

export const WYSIWYG: React.FC<IProps> = ({ blok }) => {
	return (
		<article className="bg-white pb-14" {...storyblokEditable(blok)}>
			{blok.content.map((nestedBlok: any) => (
				<StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
			))}
		</article>
	);
};
