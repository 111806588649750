import { InsightCard } from '@/components/Shared/Tiles/InsightCard';
import { IInsightPage, IStoryBase } from '@/utils/interfaces/stories';

interface IProps {
	insights: IStoryBase<IInsightPage>[] | null;
}

export const InsightsFilterResults: React.FC<IProps> = ({ insights }) => {
	return (
		<div className="grid grid-cols-1 md:grid-cols-2">
			{insights?.map((resource, key) => {
				return <InsightCard blok={resource} key={key} />;
			})}
		</div>
	);
};
