interface IProps {
	narrow: boolean;
}

export const WysiwygContainer: React.FC<IProps> = ({ narrow, children }) => {
	return (
		<div
			className={`container w-full relative my-10 lg:my-20 ${
				narrow ? 'lg:max-w-[800px]' : 'lg:max-w-[1008px]'
			}`}
		>
			{children}
		</div>
	);
};
