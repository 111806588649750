interface IProps {}

export const MapPinActive: React.FC<IProps> = () => {
	return (
		<svg
			width="34"
			height="39"
			viewBox="0 0 34 39"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.0168 33.7692C27.9665 32.344 34 25.3864 34 17.0182C34 7.6193 26.3888 0 17 0C7.61116 0 0 7.6193 0 17.0182C0 25.3864 6.03348 32.344 13.9832 33.7692L17 39L20.0168 33.7692Z"
				fill="url(#paint0_linear_388_5583)"
			/>
			<path
				d="M19.0814 19.0212C18.6364 19.7247 17.9691 20.2671 17.1807 20.566C16.3923 20.865 15.5259 20.9042 14.7126 20.6778C13.8994 20.4514 13.1838 19.9717 12.6742 19.3114C12.1647 18.6511 11.889 17.8462 11.889 17.0188C11.889 16.1913 12.1647 15.3864 12.6742 14.7261C13.1838 14.0658 13.8994 13.5861 14.7126 13.3597C15.5259 13.1333 16.3923 13.1726 17.1807 13.4715C17.9691 13.7705 18.6364 14.3128 19.0814 15.0164L19.2863 15.3401L23.3737 12.8598L23.1676 12.5361C22.1716 10.9632 20.6789 9.75087 18.9155 9.08287C17.1522 8.41487 15.2144 8.32762 13.3959 8.83434C11.5773 9.34106 9.9771 10.4141 8.83776 11.8909C7.69841 13.3677 7.08203 15.1677 7.08203 17.0182C7.08203 18.8686 7.69841 20.6687 8.83776 22.1454C9.9771 23.6222 11.5773 24.6953 13.3959 25.202C15.2144 25.7087 17.1522 25.6215 18.9155 24.9535C20.6789 24.2855 22.1716 23.0732 23.1676 21.5002L23.3737 21.1766L19.2863 18.6975L19.0814 19.0212ZM22.294 21.417C21.3356 22.7776 19.9577 23.8027 18.3616 24.3424C16.7656 24.8822 15.035 24.9084 13.4226 24.4172C11.8103 23.926 10.4007 22.9432 9.39964 21.6122C8.39863 20.2813 7.85869 18.672 7.85869 17.0194C7.85869 15.3668 8.39863 13.7574 9.39964 12.4265C10.4007 11.0955 11.8103 10.1127 13.4226 9.62149C15.035 9.1303 16.7656 9.15651 18.3616 9.69629C19.9577 10.2361 21.3356 11.2611 22.294 12.6217L19.5236 14.2999C18.9392 13.5288 18.1213 12.9577 17.1861 12.6677C16.251 12.3778 15.2462 12.3839 14.3147 12.685C13.3833 12.9861 12.5726 13.5671 11.9979 14.3451C11.4233 15.1232 11.114 16.0588 11.114 17.0188C11.114 17.9788 11.4233 18.9144 11.9979 19.6924C12.5726 20.4705 13.3833 21.0514 14.3147 21.3525C15.2462 21.6537 16.251 21.6597 17.1861 21.3698C18.1213 21.0799 18.9392 20.5088 19.5236 19.7377L22.294 21.417Z"
				fill="white"
			/>
			<path
				d="M26.3004 12.3911L26.0918 12.0546L21.9582 14.6126L22.1656 14.9468C22.551 15.5679 22.7552 16.284 22.7552 17.0146C22.7552 17.7451 22.551 18.4612 22.1656 19.0823L21.957 19.4165L26.0918 21.9818L26.3004 21.6489C27.1653 20.26 27.6237 18.6572 27.6237 17.0218C27.6237 15.3864 27.1653 13.7837 26.3004 12.3947V12.3911ZM25.8286 20.8922L23.0256 19.1586C23.3633 18.4957 23.5393 17.7625 23.5393 17.0188C23.5393 16.2751 23.3633 15.5419 23.0256 14.879L25.8286 13.1441C26.487 14.3296 26.8324 15.6627 26.8324 17.0182C26.8324 18.3736 26.487 19.7068 25.8286 20.8922V20.8922Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_388_5583"
					x1="0"
					y1="19.5"
					x2="34"
					y2="19.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#22B573" />
					<stop offset="1" stopColor="#1BD0BC" />
				</linearGradient>
			</defs>
		</svg>
	);
};
