import { WysiwygContainer } from '@/components/Shared/Layout/WysiwygContainer';
import { IWysiwygContent } from '@/utils/interfaces/bloks';
import { render } from 'storyblok-rich-text-react-renderer';

interface IProps {
	blok: IWysiwygContent;
}

export const WysiwygContent: React.FC<IProps> = ({ blok }) => {
	return (
		<WysiwygContainer narrow={true}>
			<div className="wyswiyg-content">{render(blok.content)}</div>
		</WysiwygContainer>
	);
};
