interface IProps {}

export const RulerDark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.151 76.849h69.817a3.892 3.892 0 0 0 3.88-3.881V57.422a3.892 3.892 0 0 0-3.88-3.88H3.15v23.306Z"
				fill="#22B573"
			/>
			<path
				d="M72.968 78H3.152A1.151 1.151 0 0 1 2 76.849V53.57a1.151 1.151 0 0 1 1.152-1.152h69.816A5.037 5.037 0 0 1 78 57.451v15.546A5.037 5.037 0 0 1 72.968 78ZM4.303 75.697h68.665a2.729 2.729 0 0 0 2.729-2.73V57.423a2.729 2.729 0 0 0-2.73-2.729H4.304v21.004Z"
				fill="#fff"
			/>
			<path
				d="M70.642 48.516a1.151 1.151 0 0 1-1.152-1.152V25.03H56.121a1.151 1.151 0 0 1-1.151-1.151v-13.37H3.152a1.152 1.152 0 1 1 0-2.302h52.97a1.152 1.152 0 0 1 1.15 1.151v13.37h13.352a1.152 1.152 0 0 1 1.152 1.15v23.503a1.151 1.151 0 0 1-1.134 1.135Z"
				fill="#22B573"
			/>
			<path
				d="M76.849 48.515a1.152 1.152 0 0 1-1.152-1.151V18.806H62.345a1.152 1.152 0 0 1-1.151-1.151V4.303H3.152a1.152 1.152 0 1 1 0-2.303h59.193a1.152 1.152 0 0 1 1.152 1.152v13.351h13.352A1.152 1.152 0 0 1 78 17.655v29.709a1.152 1.152 0 0 1-1.151 1.151ZM8.713 59.633a1.151 1.151 0 0 1-1.151-1.151V53.57a1.152 1.152 0 0 1 2.303 0v4.91a1.151 1.151 0 0 1-1.152 1.152ZM17.655 59.633a1.151 1.151 0 0 1-1.152-1.151V53.57a1.151 1.151 0 1 1 2.303 0v4.91a1.152 1.152 0 0 1-1.151 1.152ZM26.591 59.633a1.152 1.152 0 0 1-1.152-1.151V53.57a1.152 1.152 0 0 1 2.303 0v4.91a1.151 1.151 0 0 1-1.151 1.152ZM35.532 59.633a1.152 1.152 0 0 1-1.152-1.151V53.57a1.151 1.151 0 1 1 2.303 0v4.91a1.152 1.152 0 0 1-1.151 1.152ZM44.468 59.633a1.151 1.151 0 0 1-1.152-1.151V53.57a1.151 1.151 0 1 1 2.303 0v4.91a1.152 1.152 0 0 1-1.151 1.152ZM53.41 59.633a1.151 1.151 0 0 1-1.152-1.151V53.57a1.152 1.152 0 1 1 2.303 0v4.91a1.151 1.151 0 0 1-1.152 1.152ZM62.345 59.633a1.152 1.152 0 0 1-1.151-1.151V53.57a1.152 1.152 0 0 1 2.303 0v4.91a1.152 1.152 0 0 1-1.152 1.152ZM62.345 67.135a1.93 1.93 0 1 1 1.93-1.928 1.934 1.934 0 0 1-1.93 1.928Zm0-2.303a.374.374 0 1 0 0 .749.374.374 0 0 0 0-.749Z"
				fill="#fff"
			/>
			<path
				d="M29.25 25.03H15.38a1.151 1.151 0 1 1 0-2.303h13.87a1.152 1.152 0 0 1 0 2.303ZM3.152 48.452A1.152 1.152 0 0 1 2 47.301V9.358a1.152 1.152 0 0 1 2.303 0v37.943a1.152 1.152 0 0 1-1.151 1.151Z"
				fill="#22B573"
			/>
			<path
				d="M42.58 39.868H3.151a1.152 1.152 0 0 1 0-2.303h39.427a1.151 1.151 0 1 1 0 2.303Z"
				fill="#22B573"
			/>
			<path
				d="M22.313 39.868a1.151 1.151 0 0 1-1.152-1.152V9.352a1.152 1.152 0 0 1 2.303 0v29.364a1.152 1.152 0 0 1-1.151 1.152ZM42.58 48.515a1.152 1.152 0 0 1-1.152-1.151v-16a1.152 1.152 0 0 1 2.303 0v16.029a1.152 1.152 0 0 1-1.152 1.122ZM56.121 25.03H38.123a1.152 1.152 0 1 1 0-2.303h17.998a1.151 1.151 0 1 1 0 2.303Z"
				fill="#22B573"
			/>
		</svg>
	);
};
