interface IProps {}

export const HidePassword: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.212 3.478a.75.75 0 0 1 1.06-.016l2.93 2.84C8.585 5.396 10.22 4.705 12 4.705c1.953 0 3.732.832 5.196 1.867 1.468 1.038 2.676 2.32 3.504 3.318l.082.098c.506.61.968 1.163.968 2.012 0 .85-.462 1.403-.968 2.012l-.082.099c-.667.803-1.58 1.79-2.676 2.686l2.747 2.665a.75.75 0 0 1-1.044 1.076l-16.5-16a.75.75 0 0 1-.015-1.06Zm13.727 12.267c1.055-.84 1.949-1.8 2.606-2.592.632-.762.705-.9.705-1.153 0-.252-.073-.39-.705-1.152-.78-.942-1.894-2.117-3.215-3.05-1.326-.938-2.804-1.593-4.33-1.593-1.286 0-2.537.465-3.691 1.171l1.957 1.898A3.357 3.357 0 0 1 12 8.796c1.795 0 3.298 1.408 3.298 3.204 0 .618-.18 1.193-.489 1.68l2.13 2.065Zm-3.252-3.153c.072-.185.111-.384.111-.592 0-.915-.778-1.704-1.798-1.704-.206 0-.402.032-.585.092l2.272 2.204ZM6.053 8.099a.75.75 0 0 1-.026 1.06 19.703 19.703 0 0 0-1.572 1.689c-.632.762-.705.9-.705 1.152 0 .253.073.391.705 1.153.78.941 1.894 2.116 3.215 3.05 1.326.938 2.804 1.593 4.33 1.593.925 0 1.833-.24 2.704-.641a.75.75 0 0 1 .627 1.362c-1.021.471-2.144.78-3.331.78-1.953 0-3.732-.834-5.196-1.869-1.468-1.038-2.676-2.32-3.504-3.317l-.081-.099C2.71 13.403 2.25 12.85 2.25 12s.461-1.403.969-2.012L3.3 9.89a21.206 21.206 0 0 1 1.693-1.818.75.75 0 0 1 1.06.027Zm3.296 3.654a.75.75 0 0 1 .887.58c.136.653.676 1.192 1.39 1.335a.75.75 0 0 1-.295 1.47c-1.275-.255-2.299-1.23-2.563-2.498a.75.75 0 0 1 .58-.887Z"
				fill="#224946"
			/>
		</svg>
	);
};
