import { useRouter } from 'next/router';

interface IProps {
	number: string;
	classes: string;
}

const localeMap = {
	'en-us': '+1',
	'en-nz': '+64',
	'en-au': '+61',
};

export const LocalePhonenumber: React.FC<IProps> = ({ number, classes }) => {
	const router = useRouter();
	const locale = router.locale as any;
	const tel = number.replace(/ /g, "")

	/* @ts-ignore */
	const internationalCode = localeMap[locale];

	return (
		<a href={`tel:${tel}`} className={classes}>
			{number}
		</a>
	);
};
