import { Breadcrumbs } from '@/components/Shared/Breadcrumbs';
import { DateFormat } from '@/components/Shared/DateFormat';
import { Icon } from '@/components/Shared/Icon';
import { CutoutDesktop } from '@/components/Shared/Icons/BrandBackgrounds/ResourceHero/CutoutDesktop';
import { CutoutMobile } from '@/components/Shared/Icons/BrandBackgrounds/ResourceHero/CutoutMobile';
import { CutoutTablet } from '@/components/Shared/Icons/BrandBackgrounds/ResourceHero/CutoutTablet';
import { Container } from '@/components/Shared/Layout/Container';
import { SbImage } from '@/components/Shared/Image/SbImage';
import { ShareButtonsWithLabel } from '@/components/Shared/ShareButtonsWithLabel';
import { IInsightPage, IStoryBase } from '@/utils/interfaces/stories';

interface IProps {
	story: IStoryBase<IInsightPage>;
}

export const InsightHero: React.FC<IProps> = ({ story }) => {
	const hero = story.content.hero_image;

	return (
		<div className="relative overflow-hidden bg-green-dark lg:bg-transparent">
			<div className="relative">
				<div className="relative h-[268px] sm:h-[380px] w-full overflow-hidden lg:w-6/12 xl:w-5/12 lg:absolute right-0 top-0 bottom-0 lg:h-full">
					<SbImage
						src={hero.filename}
						alt={hero.alt}
						layout="fill"
						objectFit="cover"
						width={2600}
						height={1463}
					/>
					<div className="absolute md:bottom-[-50px] lg:hidden bottom-[-20px] left-0 z-10 w-full ">
						<Icon
							icon={<CutoutMobile />}
							className="w-full text-none md:hidden"
						/>
					</div>

					<CutoutTablet className="absolute bottom-[-1px] z-10 w-full hidden md:block lg:hidden" />
					<CutoutDesktop className="hidden lg:block absolute z-10 bottom-0 left-0 w-[220px]" />
				</div>

				<div className="absolute top-0 bottom-0 left-0 hidden w-6/12 h-full xl:w-7/12 lg:block bg-green-dark"></div>

				<div className="relative lg:py-48">
					<div className="px-4 pt-4 md:pt-0 sm:container lg:py-0">
						<Breadcrumbs />
					</div>
					<Container>
						<div className="mt-2 pb-14 lg:py-0 lg:w-6/12 xl:w-7/12">
							<div></div>
							<div className="">
								<h1 className="text-white">{story.name}</h1>
							</div>
						</div>
					</Container>
				</div>
			</div>

			<div className="relative z-10 py-4 bg-gray-light">
				<Container>
					<div className="grid grid-cols-1 lg:flex">
						<div className="flex flex-wrap items-center pb-4 space-x-3 border-b border-opacity-50 border-b-gray lg:border-b-0 lg:p-0 lg:flex-grow">
							{story.content.categories && (
								<span className="w-auto cta cta--secondary cta--chip">
									<span className="cta-label">
										{story.content.categories.name}
									</span>
								</span>
							)}
							<div className="flex items-center">
								<span className="w-1 h-1 rounded-full bg-gray"></span>
							</div>
							<span className="text-sm text">
								<DateFormat
									date={story.first_published_at}
									formatString="d MMMM yyyy"
								/>
							</span>
							<div className="flex items-center">
								<span className="w-1 h-1 rounded-full bg-gray"></span>
							</div>
							<span className="text-sm text-green">
								{story.content.read_time} min read
							</span>
						</div>

						<div className="pt-4 lg:p-0">
							<ShareButtonsWithLabel label="Share" />
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};
