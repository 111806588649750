import { removeLocale } from '@/utils/helpers/removeLocale';
import { ISelfSubscribe } from '@/utils/interfaces/bloks';
import { IStoryBase, IStoryPage } from '@/utils/interfaces/stories';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { SelfSubscribeContainer } from '../SelfSubscribe/SelfSubscribeContainer';
import { SEO } from '../Shared/SEO';
import { StoryAlternatesHrefLang } from '../Shared/StoryAlternatesHrefLang';

interface IProps {
	blok: ISelfSubscribe;
	preview: boolean;
	story: IStoryBase<ISelfSubscribe>;
}

export const SelfSubscribePage: React.FC<IProps> = ({
	blok,
	story,
	preview,
}) => {
	const { locale, asPath } = useRouter();
	const path = removeLocale(asPath);

	return (
		<Fragment>
			<StoryAlternatesHrefLang
				locale={locale}
				asPath={path}
				story={story}
			/>
			<SEO
				name={story.name}
				seo={blok.seo}
				og_type="website"
				asPath={path}
				locale={locale}
			/>

			<SelfSubscribeContainer blok={blok} preview={preview} />
		</Fragment>
	);
};
