import { StoryblokContext } from '@/components/Providers/StoryblokProvider';
import { buildParams } from '@/utils/helpers/buildParams';
import { ISbFile } from '@/utils/interfaces/common';
import {
	ICta,
	IFooterColumn,
	ILink,
	ISocialLink,
} from '@/utils/interfaces/components';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';

interface IProps {}

export type IFooterContext = {
	menu: IFooterColumn[];
	social_heading: string;
	social_links: ISocialLink[];
	cta_heading: string;
	cta_body: string;
	ctas: ICta[];
	logo: ISbFile;
	tagline: string;
	mandatories: ILink[];
};

const FooterContext = createContext<IFooterContext>(undefined!);

export function useFooter() {
	return useContext(FooterContext);
}

export const FooterClientProvider: React.FC<IProps> = ({ children }) => {
	const { locale } = useRouter();

	const { storyblokClient } = useContext(StoryblokContext);
	const [footerContent, setFooterContent] = useState<IFooterContext>({
		social_heading: '',
		social_links: [],
		cta_heading: '',
		cta_body: '',
		ctas: [],
		logo: {} as ISbFile,
		menu: {} as IFooterColumn[],
		tagline: '',
		mandatories: [],
	});

	async function fetchFooter() {
		if (storyblokClient) {
			const { data } = await storyblokClient.get(
				`cdn/stories/${locale}/global/footer`,
				buildParams(locale),
			);
			return data;
		}
		return null;
	}

	useEffect(() => {
		const fetchData = async () => {
			const data = await fetchFooter();
			setFooterContent(data.story.content);
		};

		// call the function
		fetchData()
			// make sure to catch any error
			.catch(console.error);
	}, [locale]);

	return (
		<FooterContext.Provider value={footerContent}>
			{children}
		</FooterContext.Provider>
	);
};
