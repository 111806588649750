import { isFieldRequired } from '@/utils/helpers/isFieldRequired';
import { IFormSelect, IFormSelectOption } from '@/utils/interfaces/components';
import { Field, FieldInputProps, FormikProps } from 'formik';
import Select from 'react-select';
import { SbFormLabel } from './SbFormLabel';

interface IProps {
	blok: IFormSelect;
}

interface ISelectProps {
	form: FormikProps<{}>;
	field: FieldInputProps<any>;
	options: [];
}

interface ISelectOptions {
	value: string;
	label: string;
}

const CustomSelect: React.FC<ISelectProps> = (props) => {
	function handleChange(value: IFormSelectOption) {
		props.form.setFieldValue(props.field.name, value.value);
	}

	function handleBlur() {
		props.form.setFieldTouched(props.field.name, true);
	}

	return (
		<Select
			options={props.options}
			{...props.form}
			onChange={handleChange}
			onBlur={handleBlur}
			value={props.field.value ? props.field.value.value : null}
			classNamePrefix="react-select"
		/>
	);
};

export const SbFormSelect: React.FC<IProps> = ({ blok }) => {
	const options = buildOptionsFromBlok(blok.options);
	const isRequired = isFieldRequired(blok.validations);

	return (
		<div>
			<SbFormLabel required={isRequired}>{blok.label}</SbFormLabel>

			<Field
				name={blok.name}
				options={options}
				component={CustomSelect}
			/>
		</div>
	);
};

function buildOptionsFromBlok(options: IFormSelectOption[]): ISelectOptions[] {
	const optionsCopy: IFormSelectOption[] = JSON.parse(
		JSON.stringify(options),
	);

	optionsCopy.map((option) => {
		return {
			label: option.label,
			value: option.value,
		};
	});

	return optionsCopy;
}
