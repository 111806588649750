import { Icon } from '@/components/Shared/Icon';
import { SmallEmblem } from '@/components/Shared/Icons/BrandBackgrounds/ImageLeftRight/SmallEmblem';
import { PlayButton } from '@/components/Shared/CTA/Play';
import { SbImage } from '@/components/Shared/Image/SbImage';
import { Modal } from '@/components/Shared/Modal/BaseModal';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { ISelfSubscribeVideo } from '@/utils/interfaces/bloks';
import { Fragment, useState } from 'react';

interface IProps {
	blok: ISelfSubscribeVideo;
}

export const SelfSubscribeVideo: React.FC<IProps> = ({ blok }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const smallEmblemClasses = [
		'h-[295px]',
		'w-[169px]',
		'md:h-[422px]',
		'md:w-[240px]',
		'lg:w-[318px]',
		'lg:h-[555px]',
		'xl:w-[340px]',
		'xl:h-[602px]',
		'absolute-center',
	];

	const smallEmblem = (
		<Icon
			className={smallEmblemClasses.join(' ')}
			icon={<SmallEmblem className="w-full" />}
		/>
	);

	return (
		<div className="self-subscribe-media self-subscribe-media--image-graphic">
			<div className="self-subscribe-media__graphic">
				{smallEmblem && <>{smallEmblem}</>}

				<div className="self-subscribe-media__image">
					{isModalOpen ? (
						<Fragment>
							<video autoPlay controls width="250">
								<source
									src={blok.video.filename}
									type="video/mp4"
								/>
							</video>
						</Fragment>
					) : (
						<SbImage
							src={blok.image.filename}
							alt={blok.image.alt}
							disablePngBg={true}
							layout="fill"
							objectFit="cover"
						/>
					)}
				</div>

				{!isModalOpen && (
					<PlayButton onClick={() => setIsModalOpen(true)} />
				)}
			</div>

			<div className="self-subscribe-media__content">
				{blok.heading && isStringWithValue(blok.heading) && (
					<span className="self-subscribe-media__heading">
						{blok.heading}
					</span>
				)}

				{blok.description && isStringWithValue(blok.description) && (
					<span className="self-subscribe-media__description">
						{blok.description}
					</span>
				)}
			</div>
		</div>
	);
};
