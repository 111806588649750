interface IProps {}

export const SitePlansDark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M24.553 78.023a1.152 1.152 0 0 1-1.151-1.152c0-5.385-1.682-9.215-3.79-11.853l-4.654 1.077a6.439 6.439 0 0 1 2.42 5.017c0 4.538-3.646 6.91-7.246 6.91C5.571 78.023 2 73.866 2 68.55c0-5.76 3.582-10.004 8.68-10.592a.91.91 0 0 1 .212-.063c2.062-.392 50.48-9.52 56.442-9.52h.144C77.878 49.67 78 65.491 78 66.159a1.152 1.152 0 0 1-.922 1.151L24.784 77.983a1.041 1.041 0 0 1-.23.04Zm-12.67-17.854c-4.475 0-7.602 3.456-7.602 8.357 0 4.031 2.557 7.17 5.828 7.17 2.379 0 4.942-1.44 4.942-4.607a4.274 4.274 0 0 0-3.946-4.164 1.16 1.16 0 0 1-.259-2.304l6.911-1.59c-2.338-2.004-4.763-2.839-5.851-2.839l-.023-.023Zm4.4-.968c4.13 2.235 8.904 7.367 9.365 16.247l50.014-10.234c-.185-3.202-1.337-13.61-8.398-14.56-4.906.087-38.195 6.192-50.958 8.57l-.023-.023Z"
				fill="#fff"
			/>
			<path
				d="M32.876 68.514a1.152 1.152 0 0 1-1.118-.875c-.702-2.81-1.699-4.562-2.966-5.184a1.15 1.15 0 0 1 .589-2.201c.156.018.306.067.442.145 2.304 1.152 3.375 3.91 3.888 5.639l34.705-6.716a7.136 7.136 0 0 0-1.872-3.023 1.152 1.152 0 1 1 1.63-1.63c2.684 2.684 2.713 5.454 2.713 5.57a1.151 1.151 0 0 1-.933 1.151l-36.86 7.136a.944.944 0 0 1-.218-.012Z"
				fill="#fff"
			/>
			<path
				d="m11.013 76.83 13.298-3.455a16.749 16.749 0 0 0-4.273-9.664l-8.944 2.096s4.832.962 5.08 4.608c.224 3.49-.928 5.298-5.16 6.416Z"
				fill="#22B573"
			/>
			<path
				d="M11.014 77.983a1.152 1.152 0 0 1-.288-2.27c3.916-1.013 4.492-2.424 4.302-5.183-.185-2.69-4.124-3.53-4.164-3.536a1.152 1.152 0 0 1 0-2.252l8.944-2.09a1.151 1.151 0 0 1 1.1.328 17.854 17.854 0 0 1 4.607 10.366 1.152 1.152 0 0 1-.864 1.152l-13.298 3.427c-.11.035-.224.054-.34.058Zm3.737-11.819a5.46 5.46 0 0 1 2.575 4.182 8.022 8.022 0 0 1-.513 3.8l6.232-1.606a15.798 15.798 0 0 0-3.404-7.522l-4.89 1.146Z"
				fill="#fff"
			/>
			<path
				d="M4.333 55.43a1.15 1.15 0 0 1-.928-.473C3.348 54.877 2 52.976 2 49.526c.001-.305.123-.597.34-.812L14.227 36.82a1.152 1.152 0 1 1 1.63 1.63L4.338 49.969a7.36 7.36 0 0 0 .968 3.623 1.152 1.152 0 0 1-.282 1.6 1.152 1.152 0 0 1-.691.237Z"
				fill="#22B573"
			/>
			<path
				d="M35.128 47.458c-13.08 0-19.006-3.87-20.826-5.373a1.15 1.15 0 0 1-.409-.887v-7.13c0-.576.11-14.974 10.05-21.189a1.154 1.154 0 0 1 1.776.777 1.151 1.151 0 0 1-.555 1.176c-8.87 5.546-8.967 19.103-8.967 19.236v6.583c7.107 5.43 22.046 5.183 33.98 3.052l4.498-4.504c.142-.14.319-.242.512-.293l13.045-3.542c.1-.028.202-.042.305-.04h5.938a45.255 45.255 0 0 0-3.6-15.09C66.142 9.683 57.75 4.333 45.945 4.333a1.152 1.152 0 1 1 0-2.304c15.976 0 23.532 9.428 27.068 17.33a46.464 46.464 0 0 1 3.819 17.116 1.152 1.152 0 0 1-1.152 1.152h-6.98l-12.636 3.456-4.533 4.503c-.157.16-.357.27-.576.317a88.769 88.769 0 0 1-15.826 1.555Z"
				fill="#22B573"
			/>
			<path
				d="M28.735 28.764a3.7 3.7 0 0 1-.864-.11l-4.936-1.232a3.513 3.513 0 0 1-2.592-4.164l.703-3.283A22.357 22.357 0 0 1 40.121 2.34 49.623 49.623 0 0 1 45.955 2a1.152 1.152 0 0 1 0 2.304 46.111 46.111 0 0 0-5.558.328A20.03 20.03 0 0 0 23.31 20.459l-.702 3.282a1.227 1.227 0 0 0 .904 1.446l4.93 1.232a1.22 1.22 0 0 0 1.492-.915A22.076 22.076 0 0 1 49.889 8.52c7.423-.473 13.494.575 18.043 3.144a1.156 1.156 0 1 1-1.151 2.004c-4.153-2.338-9.791-3.3-16.766-2.85a19.76 19.76 0 0 0-17.854 15.204 3.542 3.542 0 0 1-3.455 2.742h.029Z"
				fill="#22B573"
			/>
			<path
				d="M36.447 39.971h-.122a1.151 1.151 0 0 1-1.025-1.267c2.46-23.406 27.11-22.461 27.363-22.432a1.153 1.153 0 1 1-.115 2.303 30.95 30.95 0 0 0-11.772 2.172c-7.816 3.19-12.25 9.312-13.183 18.199a1.152 1.152 0 0 1-1.147 1.025ZM72.114 44.734a1.152 1.152 0 0 1-1.077-1.56c1.037-2.713 0-6.336 0-6.4a1.153 1.153 0 1 1 2.212-.656c.051.179 1.284 4.429 0 7.873a1.152 1.152 0 0 1-1.135.743Z"
				fill="#22B573"
			/>
		</svg>
	);
};
