import { handleEmailClick } from '@/utils/handleMailto';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { ILocationsPage, IStoryBase } from '@/utils/interfaces/stories';
import { LocalePhonenumber } from '../LocalePhoneNumber';

interface IProps {
	location: IStoryBase<ILocationsPage>;
}

export const LocationBlock: React.FC<IProps> = ({ location }) => {
	const { content, name } = location;

	return (
		<div>
			<h5 className="py-3 mb-3 border-b border-gray-light">{name}</h5>
			<div className="space-y-2">
				<div>
					<span className="whitespace-pre">{content.address}</span>
				</div>

				{isStringWithValue(content.phone_number) && (
					<div>
						<LocalePhonenumber
							number={content.phone_number}
							classes="link link--static-underline"
						/>
					</div>
				)}
				<div>
					<button
						type="button"
						onClick={() => handleEmailClick(content.email)}
						className="link link--static-underline"
					>
						<span>Email</span>
					</button>
				</div>
			</div>
		</div>
	);
};
