import React from "react";

interface IProps {
    title: string;
    isActive?: boolean;
    onClick?: React.MouseEventHandler
}

export const TabUnderline: React.FC<IProps> = ({title, isActive, onClick}) => {
    const classes = ["relative transition cursor-pointer md:text-xl font-bold py-3 before:h-[3px] before:bg-gray before:block before:absolute before:bottom-0 before:left-0 before:w-full after:absolute after:bottom-0 after:left-0 after:h-[3px] after:bg-gradient-to-r after:from-green after:to-teal after:block after:transition-all after:duration-500"]
    if (isActive) {
        classes.push('text-green-dark after:w-full')
    } else {
        classes.push('text-gray after:w-0')
    }
    return (
        <div className={classes.join(' ')} onClick={onClick}>
            {title}
        </div>
    );
};
