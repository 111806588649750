import NextBreadcrumbs from 'nextjs-breadcrumbs';
import { Icon } from '@/components/Shared/Icon';
import { Home } from '@/components/Shared/Icons/Home';
import { ChevronRight } from '@/components/Shared/Icons/ChevronRight';

interface IProps {
	className?: string;
}

export const Breadcrumbs: React.FC<IProps> = ({ className }) => {
	const capitalize = (str: string) =>
		str.charAt(0).toUpperCase() + str.slice(1);
	const capitalizeWithExclusions = (title = '') => {
		const exludeWords = [
			'and',
			'as',
			'but',
			'for',
			'if',
			'nor',
			'or',
			'so',
			'yet',
			'a',
			'an',
			'the',
			'as',
			'at',
			'by',
			'for',
			'in',
			'of',
			'off',
			'on',
			'per',
			'to',
			'up',
			'via',
		];
		return capitalize(
			title.replace(/\b\w+\b/g, (m) =>
				exludeWords.includes(m) ? m : capitalize(m),
			),
		);
	};

	return (
		<NextBreadcrumbs
			containerClassName={className}
			listClassName="breadcrumbs__container"
			activeItemClassName="breadcrumbs__activeItem"
			inactiveItemClassName="breadcrumbs__inactiveItem"
			useDefaultStyle={false}
			transformLabel={(label: string) => {
				if (label === 'Home') {
					return (
						<div className="breadcrumbs__item">
							<Icon
								className="w-4 h-4 text-green"
								icon={<Home />}
							/>
						</div>
					);
				}

				return (
					<span className="breadcrumbs__item">
						<Icon
							className="breadcrumbs__icon"
							icon={<ChevronRight />}
						/>{' '}
						<span className="breadcrumbs__label ">
							{capitalizeWithExclusions(label).replaceAll(
								'-',
								' ',
							)}
						</span>
					</span>
				);
			}}
		/>
	);
};
