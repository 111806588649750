interface IProps {
	color?: string;
}

export const Close: React.FC<IProps> = ({ color = 'currentColor' }) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g fill="none" fillRule="evenodd">
				<polygon points="0 0 24 0 24 24 0 24" />
				<polygon
					fill={color}
					fillRule="nonzero"
					points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"
				/>
			</g>
		</svg>
	);
};
