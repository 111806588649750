import { format } from 'date-fns-tz';

interface IProps {
	date: Date;
	formatString: string;
}

export const DateFormat: React.FC<IProps> = ({ date, formatString }) => {
	return <span>{format(new Date(date), formatString)}</span>;
};
