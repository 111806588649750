interface IProps {
	required: boolean;
}

export const SbFormLabel: React.FC<IProps> = ({ required, children }) => {
	return (
		<label className="w-full text-xs font-bold text-green-dark">
			<span>{children}</span>

			{required && <span className="text-error"> *</span>}
		</label>
	);
};
