import { IIBuildList } from '@/utils/interfaces/bloks';
import {IBuildListItem} from "@/components/Bloks/IBuild/IBuildListItem";

interface IProps {
	blok: IIBuildList;
}

export const IBuildList: React.FC<IProps> = ({ blok }) => {
	return (
		<div>
			<h4 className="mb-6 lg:mb-10">{blok.heading}</h4>
				{blok.list.map((list_item, key) => {
					return <IBuildListItem blok={list_item} key={key} count={key + 1}/>;
				})}
		</div>
	);
};
