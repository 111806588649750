interface IProps {}

export const PlanMeasurementsDark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.3 7.516a1.152 1.152 0 0 1-1.152-1.152V4.303H4.303v2.061a1.152 1.152 0 0 1-2.303 0V3.152A1.152 1.152 0 0 1 3.152 2H18.3a1.151 1.151 0 0 1 1.151 1.152v3.212A1.151 1.151 0 0 1 18.3 7.516Z"
				fill="#22B573"
			/>
			<path
				d="M33.454 7.516a1.152 1.152 0 0 1-1.152-1.152V4.303h-12.85v2.061a1.152 1.152 0 0 1-2.304 0V3.152A1.151 1.151 0 0 1 18.3 2h15.154a1.152 1.152 0 0 1 1.151 1.152v3.212a1.152 1.152 0 0 1-1.151 1.152Z"
				fill="#22B573"
			/>
			<path
				d="M48.601 7.516a1.151 1.151 0 0 1-1.151-1.152V4.303H34.605v2.061a1.152 1.152 0 0 1-2.303 0V3.152A1.152 1.152 0 0 1 33.453 2h15.148a1.152 1.152 0 0 1 1.152 1.152v3.212A1.152 1.152 0 0 1 48.6 7.516Z"
				fill="#22B573"
			/>
			<path
				d="M63.756 7.516a1.152 1.152 0 0 1-1.152-1.152V4.303h-12.85v2.061a1.151 1.151 0 1 1-2.304 0V3.152A1.151 1.151 0 0 1 48.602 2h15.154a1.152 1.152 0 0 1 1.151 1.152v3.212a1.152 1.152 0 0 1-1.151 1.152ZM76.849 32.515h-3.213a1.151 1.151 0 1 1 0-2.303h2.061V17.39h-2.061a1.151 1.151 0 1 1 0-2.303h3.213A1.152 1.152 0 0 1 78 16.238v15.126a1.151 1.151 0 0 1-1.151 1.151Z"
				fill="#22B573"
			/>
			<path
				d="M76.849 47.686h-3.213a1.151 1.151 0 1 1 0-2.303h2.061V32.515h-2.061a1.151 1.151 0 1 1 0-2.303h3.213A1.151 1.151 0 0 1 78 31.364v15.148a1.151 1.151 0 0 1-1.151 1.174Z"
				fill="#22B573"
			/>
			<path
				d="M76.849 62.834h-3.213a1.151 1.151 0 1 1 0-2.303h2.061V47.686h-2.061a1.152 1.152 0 0 1 0-2.303h3.213A1.152 1.152 0 0 1 78 46.535v15.148a1.151 1.151 0 0 1-1.151 1.151Z"
				fill="#22B573"
			/>
			<path
				d="M76.849 78h-3.213a1.151 1.151 0 1 1 0-2.303h2.061V62.834h-2.061a1.151 1.151 0 1 1 0-2.303h3.213A1.151 1.151 0 0 1 78 61.683v15.165A1.151 1.151 0 0 1 76.85 78Z"
				fill="#22B573"
			/>
			<path
				d="M63.767 78H3.152A1.152 1.152 0 0 1 2 76.848V16.233a1.152 1.152 0 0 1 1.152-1.152h60.615a1.152 1.152 0 0 1 1.152 1.152v60.615A1.152 1.152 0 0 1 63.767 78ZM4.303 75.697h58.313V17.384H4.303v58.313Z"
				fill="#fff"
			/>
			<path
				d="M9.358 70.636V51.768h7.905v-6.2H9.358V22.434H57.56v23.134H27.373v6.2H57.56v18.868H9.358Z"
				fill="#22B573"
			/>
			<path
				d="M57.56 71.787H9.358a1.151 1.151 0 0 1-1.151-1.151V51.768a1.152 1.152 0 0 1 1.151-1.151h6.754v-3.898H9.358a1.151 1.151 0 0 1-1.151-1.151V22.434a1.152 1.152 0 0 1 1.151-1.152H57.56a1.152 1.152 0 0 1 1.152 1.152v23.134a1.152 1.152 0 0 1-1.152 1.151H28.525v3.898H57.56a1.152 1.152 0 0 1 1.152 1.151v18.868a1.152 1.152 0 0 1-1.152 1.151Zm-47.05-2.303h45.899V52.92H27.373a1.151 1.151 0 0 1-1.151-1.152v-6.2a1.152 1.152 0 0 1 1.151-1.152H56.41v-20.83h-45.9v20.83h6.754a1.151 1.151 0 0 1 1.152 1.152v6.2a1.151 1.151 0 0 1-1.152 1.152H10.51v16.564Z"
				fill="#fff"
			/>
			<path
				d="M34.432 46.719a1.152 1.152 0 0 1-1.151-1.151V22.434a1.152 1.152 0 0 1 2.303 0v23.134a1.151 1.151 0 0 1-1.152 1.151ZM43.454 71.787a1.152 1.152 0 0 1-1.151-1.151V51.768a1.152 1.152 0 0 1 2.303 0v18.868a1.152 1.152 0 0 1-1.152 1.151Z"
				fill="#fff"
			/>
		</svg>
	);
};
