import { IInsightPage, IStoryBase } from '@/utils/interfaces/stories';
import { pathOr } from 'ramda';
import { Fragment } from 'react';
import { DateFormat } from '../DateFormat';

interface IProps {
	blok: IStoryBase<IInsightPage>;
}

export const InsightCardMeta: React.FC<IProps> = ({ blok }) => {
	return (
		<Fragment>
			<span className="text-sm text leading-none">
				<DateFormat
					date={blok.first_published_at}
					formatString="d MMMM yyyy"
				/>
			</span>
			<div className="flex items-center">
				<span className="w-1 h-1 rounded-full bg-gray"></span>
			</div>
			{pathOr(false, ['content', 'read_time'], blok) && (
				<span className="text-sm text-green leading-none">
					{blok.content.read_time} min read
				</span>
			)}
		</Fragment>
	);
};
