import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { IInsightsOverview } from '@/utils/interfaces/bloks';
import { CTA } from '../Shared/CTA/CallToAction';
import { FormattedText } from '../Shared/FormattedText';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';
import { InsightCard } from '../Shared/Tiles/InsightCard';
import { InsightFeatureCard } from '../Shared/Tiles/InsightFeatureCard';

interface IProps {
	blok: IInsightsOverview;
	preview: boolean;
}

export const InsightsOverview: React.FC<IProps> = ({ blok, preview }) => {
	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative py-14 md:py-32 section-grey-gradient"
		>
			<Container>
				<div className="flex justify-between">
					{blok.heading && isStringWithValue(blok.heading) && (
						<FormattedText tag="h2" text={blok.heading} />
					)}

					<div>
						{blok.cta && isArrayWithValue(blok.cta) && (
							<div className="hidden lg:block">
								{blok.cta.map((cta, key) => (
									<CTA blok={cta} key={key} />
								))}
							</div>
						)}
					</div>
				</div>

				<div className="grid grid-cols-1 mt-8 lg:grid-cols-2 lg:gap-8 lg:mt-16">
					<div>
						{blok.featured_insight && (
							<div>
								<InsightFeatureCard
									blok={blok.featured_insight}
								/>
							</div>
						)}
					</div>

					{blok.insights && isArrayWithValue(blok.insights) && (
						<div className="mt-4 lg:mt-0 lg:pl-6 resource_overview-resources">
							{blok.insights.map((insight, key) => (
								<InsightCard blok={insight} key={key} />
							))}
						</div>
					)}
				</div>

				<div className="flex items-center justify-center mt-8 lg:hidden">
					{blok.cta && isArrayWithValue(blok.cta) && (
						<div className="mx-auto">
							{blok.cta.map((cta, key) => (
								<CTA blok={cta} key={key} />
							))}
						</div>
					)}
				</div>
			</Container>
		</SbSection>
	);
};
