import { GOOGLE_MAP_STYLE } from '@/utils/google/googleMapsStyle';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { ICoordinates } from '@/utils/interfaces/common';
import { ILocationsPage, IStoryBase } from '@/utils/interfaces/stories';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { pathOr } from 'ramda';
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { MapPin } from '../Icons/MapPin';
import { MapPinActive } from '../Icons/MapPinActive';
import { SbMarker } from './SbMarker';

interface IProps {
	zoom: number;
	center: { lat: number; lng: number };
	locations: IStoryBase<ILocationsPage>[];
	activeLocation: number | null;
	setActiveLocation: Dispatch<SetStateAction<number | null>>;
}

const containerStyle = {
	width: '100%',
	height: '100%',
};

const markerSvgString = encodeURIComponent(renderToStaticMarkup(<MapPin />));
const markerSvgActiveString = encodeURIComponent(
	renderToStaticMarkup(<MapPinActive />),
);
const markerIcon = `data:image/svg+xml,${markerSvgString}`;
const markerIconActive = `data:image/svg+xml,${markerSvgActiveString}`;

export const SbMap: React.FC<IProps> = ({
	zoom,
	center,
	locations,
	activeLocation,
	setActiveLocation,
}) => {
	const [locationsCoords, setLocationsCoords] = useState<ICoordinates[]>();

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API as string,
	});

	const [map, setMap] = useState(null);

	const onLoad = useCallback(function callback(map) {
		setMap(map);
	}, []);

	const onUnmount = useCallback(function callback() {
		setMap(null);
	}, []);

	useEffect(() => {
		const coordinates = pluckLatLng(locations);

		setLocationsCoords(coordinates);
	}, []);

	return isLoaded && locationsCoords && isArrayWithValue(locationsCoords) ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={zoom}
			options={{
				styles: GOOGLE_MAP_STYLE,
				disableDefaultUI: true,
				zoomControl: true,
			}}
			onLoad={onLoad}
			onUnmount={onUnmount}
		>
			{locationsCoords.map((coordinates, key) => {
				return (
					<SbMarker
						key={key}
						active={coordinates.id === activeLocation}
						setActiveLocation={setActiveLocation}
						icon={
							coordinates.id === activeLocation
								? markerIconActive
								: markerIcon
						}
						center={coordinates}
						location={locations[key]}
					/>
				);
			})}
		</GoogleMap>
	) : (
		<></>
	);
};

function pluckLatLng(locations: IStoryBase<ILocationsPage>[]): ICoordinates[] {
	const coordinates: ICoordinates[] = [];

	locations.forEach((location) => {
		const lat = pathOr(null, ['content', 'lat'], location);
		const lng = pathOr(null, ['content', 'lng'], location);

		if (lat && lng) {
			coordinates.push({
				id: location.id,
				lat: parseFloat(lat),
				lng: parseFloat(lng),
			});
		}
	});

	return coordinates;
}
