import { isFieldRequired } from '@/utils/helpers/isFieldRequired';
import { IFormInput, IFormTextarea } from '@/utils/interfaces/components';
import { useField } from 'formik';
import { SbError } from './SbError';
import { SbFormLabel } from './SbFormLabel';

interface IProps {
	blok: IFormTextarea;
}

export const SbFormTextarea: React.FC<IProps> = ({ blok }) => {
	const [field, meta, helpers] = useField(blok.name);
	const hasError = meta.touched && meta.error;
	const isRequired = isFieldRequired(blok.validations);

	return (
		<div>
			<SbFormLabel required={isRequired}>{blok.label}</SbFormLabel>
			<textarea
				className="block min-h-[200px] w-full px-6 py-4 mt-1 text-base leading-6 border border-transparent focus:border-green focus:shadow-soft bg-light"
				{...field}
			/>

			{hasError ? <SbError>{meta.error}</SbError> : null}
		</div>
	);
};
