import { HeroContent } from '@/components/Bloks/Hero/HeroContent';
import { SbSection } from '@/components/Shared/Layout/SbSection';
import { SbImage } from '@/components/Shared/Image/SbImage';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { IHeroBlok } from '@/utils/interfaces/bloks';

interface IProps {
	blok: IHeroBlok;
	preview: boolean;
}

export const HeroHome: React.FC<IProps> = ({ blok, preview }) => {
	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative lg:flex items-center bg-green-dark text-white bg-no-repeat bg-hero-home-mobile bg-right-top md:bg-hero-home md:bg-right-bottom md:bg-h-100 lg:min-h-[780px]"
		>
			{isStringWithValue(blok.image.filename) && (
				<div className="inset-0 items-center pt-6 lg:absolute lg:flex lg:py-12">
					<div className="relative h-80 lg:h-full lg:w-1/2 lg:ml-auto">
						<SbImage
							src={blok.image.filename}
							layout="fill"
							objectFit="contain"
							alt={blok.image.alt}
						/>
					</div>
				</div>
			)}
			<HeroContent blok={blok} isHome />
		</SbSection>
	);
};
