import {isArrayWithValue} from '@/utils/helpers/isArrayWithValue';
import {IStoryBase} from '@/utils/interfaces/stories';
import Head from 'next/head';
import {LANGUAGE_DEFAULT} from "@/components/Providers/LanguageProvider";
import {removeQuerystring} from "@/utils/helpers/removeQuerystring";

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

interface IProps {
    story: IStoryBase<any>;
    locale: string | undefined;
    asPath: string;
}

export const StoryAlternatesHrefLang: React.FC<IProps> = ({
                                                              story,
                                                              locale,
                                                              asPath,
                                                          }) => {
    const {alternates} = story;

    if (!isArrayWithValue(alternates)) {
        return null;
    }

    return (
        <Head>
            <link
                rel="alternate"
                href={removeQuerystring(`${baseUrl}/${locale}${asPath}`)}
                hrefLang={locale}
                key="lang-current"
            />
            {alternates.map((alternate) => {
                const alternateLocale = alternate.full_slug.split('/')[0];
                const href = removeQuerystring(`${baseUrl}/${alternate.full_slug}`);

                return (
                    <link
                        key={alternate.full_slug}
                        rel="alternate"
                        href={href}
                        hrefLang={alternateLocale}
                    />
                );
            })}

            <link
                rel="alternate"
                href={removeQuerystring(`${baseUrl}/${LANGUAGE_DEFAULT.locale}${asPath}`)}
                hrefLang="x-default"
                key="lang-default"
            />
        </Head>
    );
};