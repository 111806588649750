interface IProps {
    color?: string;
}

export const ChevronRight: React.FC<IProps> = ({color = 'currentColor'}) => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.03033 1.71967C4.73744 1.42678 4.26256 1.42678 3.96967 1.71967C3.67678 2.01256 3.67678 2.48744 3.96967 2.78033L5.03033 1.71967ZM8.25 6L8.78033 6.53033C8.92098 6.38968 9 6.19891 9 6C9 5.80109 8.92098 5.61032 8.78033 5.46967L8.25 6ZM3.96967 9.21967C3.67678 9.51256 3.67678 9.98744 3.96967 10.2803C4.26256 10.5732 4.73744 10.5732 5.03033 10.2803L3.96967 9.21967ZM3.96967 2.78033L7.71967 6.53033L8.78033 5.46967L5.03033 1.71967L3.96967 2.78033ZM7.71967 5.46967L3.96967 9.21967L5.03033 10.2803L8.78033 6.53033L7.71967 5.46967Z"
                fill={color}
            />
        </svg>
    );
};

