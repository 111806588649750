interface IProps {}

export const PlanningPermissionsDark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M54.722 46.178a1.151 1.151 0 0 1-1.151-1.152V11.53a1.152 1.152 0 1 1 2.303 0v33.497a1.151 1.151 0 0 1-1.152 1.152ZM54.722 78H6.152A1.151 1.151 0 0 1 5 76.849V9.853A7.865 7.865 0 0 1 12.853 2a1.152 1.152 0 0 1 0 2.303 5.556 5.556 0 0 0-5.55 5.55v65.844h46.268V55.073a1.151 1.151 0 1 1 2.303 0V76.85A1.151 1.151 0 0 1 54.722 78Z"
				fill="#fff"
			/>
			<path
				d="M19.55 9.853v1.676h45.225V9.853a6.702 6.702 0 0 0-6.702-6.702h-45.22a6.702 6.702 0 0 1 6.697 6.702Z"
				fill="#22B573"
			/>
			<path
				d="M64.775 12.68H19.549a1.152 1.152 0 0 1-1.151-1.151V9.853a5.55 5.55 0 0 0-5.545-5.55 1.152 1.152 0 1 1 0-2.303h45.22a7.865 7.865 0 0 1 7.854 7.853v1.676a1.152 1.152 0 0 1-1.152 1.151Zm-44.074-2.303h42.922v-.524a5.556 5.556 0 0 0-5.55-5.55H18.398a7.83 7.83 0 0 1 2.303 5.55v.524ZM46.35 26.078H21.226a1.152 1.152 0 0 1 0-2.303H46.35a1.152 1.152 0 1 1 0 2.303ZM46.35 34.45H21.226a1.152 1.152 0 1 1 0-2.303H46.35a1.152 1.152 0 1 1 0 2.303ZM46.35 42.827H21.226a1.152 1.152 0 1 1 0-2.303H46.35a1.152 1.152 0 0 1 0 2.303ZM17.874 26.078h-3.35a1.152 1.152 0 0 1 0-2.303h3.35a1.152 1.152 0 1 1 0 2.303ZM17.874 34.45h-3.35a1.151 1.151 0 1 1 0-2.303h3.35a1.152 1.152 0 1 1 0 2.303ZM17.874 42.827h-3.35a1.151 1.151 0 1 1 0-2.303h3.35a1.152 1.152 0 0 1 0 2.303Z"
				fill="#fff"
			/>
			<path
				d="M37.974 67.953a1.151 1.151 0 0 1-1.117-1.434l1.675-6.701c.05-.202.157-.385.306-.53l31.85-31.828a1.15 1.15 0 0 1 .812-.334 1.151 1.151 0 0 1 .818.334l5.02 5.026a1.15 1.15 0 0 1 0 1.63L45.516 65.938c-.146.15-.331.256-.535.305l-6.696 1.675a1.183 1.183 0 0 1-.311.035Zm2.717-7.266-1.151 4.531 4.531-1.151 30.78-30.786-3.397-3.397-30.763 30.803ZM26.251 60.1v-3.351h-3.35l-1.676-1.676-1.676 1.676H16.2v3.35l-1.676 1.676 1.675 1.676v3.35h3.351l1.676 1.67 1.675-1.67h3.351v-3.35l1.676-1.676L26.25 60.1Z"
				fill="#22B573"
			/>
			<path
				d="M21.225 69.623c-.304 0-.596-.12-.812-.334l-1.341-1.336h-2.88a1.151 1.151 0 0 1-1.15-1.151v-2.88l-1.336-1.34a1.151 1.151 0 0 1-.252-1.255c.058-.14.144-.268.252-.375l1.335-1.336v-2.879a1.152 1.152 0 0 1 1.152-1.151h2.878l1.342-1.336a1.151 1.151 0 0 1 1.624 0l1.341 1.336h2.879a1.152 1.152 0 0 1 1.151 1.151v2.88l1.336 1.335a1.154 1.154 0 0 1 0 1.63l-1.335 1.34v2.88a1.152 1.152 0 0 1-1.152 1.151h-2.879l-1.341 1.336a1.152 1.152 0 0 1-.812.334ZM17.35 65.65h2.2a1.15 1.15 0 0 1 .817.334l.858.864.863-.864c.216-.214.508-.334.812-.334h2.2v-2.2a1.151 1.151 0 0 1 .334-.817l.863-.858-.863-.864a1.151 1.151 0 0 1-.334-.811v-2.2h-2.2a1.15 1.15 0 0 1-.811-.34l-.864-.857-.858.858a1.151 1.151 0 0 1-.818.34H17.35V60.1c0 .304-.12.596-.334.811l-.864.864.864.858a1.15 1.15 0 0 1 .334.818v2.199Z"
				fill="#fff"
			/>
		</svg>
	);
};
