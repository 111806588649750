interface IProps {}

export const Twitter: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 36 36"
			 xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.875 13.33a6.68 6.68 0 0 1-1.855.486 3.134 3.134 0 0 0 1.42-1.71 6.689 6.689 0 0 1-2.053.751 3.29 3.29 0 0 0-2.358-.977c-1.785 0-3.231 1.385-3.231 3.093 0 .242.028.478.083.705-2.684-.13-5.065-1.361-6.659-3.233A2.97 2.97 0 0 0 10.785 14c0 1.074.571 2.02 1.437 2.575a3.34 3.34 0 0 1-1.463-.389v.04c0 1.498 1.114 2.748 2.591 3.033-.27.07-.556.108-.851.108-.209 0-.41-.02-.608-.057.411 1.23 1.604 2.124 3.017 2.148a6.668 6.668 0 0 1-4.012 1.322c-.261 0-.518-.015-.771-.042a9.43 9.43 0 0 0 4.953 1.392c5.943 0 9.192-4.714 9.192-8.802l-.011-.4a6.312 6.312 0 0 0 1.616-1.599Z"
				fill="currentColor"
			/>
		</svg>
	);
};
