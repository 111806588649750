interface IProps {}

export const Protractor: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M76.848 76.849H3.151V3.151l73.697 73.697Z"
				fill="#22B573"
			/>
			<path
				d="M76.848 78H3.152A1.151 1.151 0 0 1 2 76.849V3.151a1.152 1.152 0 0 1 .708-1.065 1.152 1.152 0 0 1 1.255.253L77.66 76.037a1.151 1.151 0 0 1 .253 1.255 1.152 1.152 0 0 1-1.065.708ZM4.303 75.697h69.764L4.303 5.933v69.764Z"
				fill="#224946"
			/>
			<path
				d="M6.83 24.017H3.153a1.152 1.152 0 0 1 0-2.303H6.83a1.152 1.152 0 0 1 0 2.303ZM6.83 35.44H3.153a1.152 1.152 0 0 1 0-2.303H6.83a1.151 1.151 0 1 1 0 2.303ZM6.83 46.863H3.153a1.152 1.152 0 0 1 0-2.303H6.83a1.152 1.152 0 0 1 0 2.303ZM6.83 58.286H3.153a1.152 1.152 0 1 1 0-2.303H6.83a1.151 1.151 0 1 1 0 2.303ZM57.134 78a1.151 1.151 0 0 1-1.152-1.151v-3.68a1.151 1.151 0 1 1 2.303 0v3.68A1.152 1.152 0 0 1 57.134 78ZM45.711 78a1.151 1.151 0 0 1-1.151-1.151v-3.68a1.151 1.151 0 1 1 2.303 0v3.68A1.152 1.152 0 0 1 45.71 78ZM34.288 78a1.151 1.151 0 0 1-1.151-1.151v-3.68a1.151 1.151 0 1 1 2.303 0v3.68A1.152 1.152 0 0 1 34.288 78ZM22.865 78a1.151 1.151 0 0 1-1.151-1.151v-3.68a1.151 1.151 0 1 1 2.303 0v3.68A1.152 1.152 0 0 1 22.865 78Z"
				fill="#224946"
			/>
			<path
				d="M20.925 32.884a18.522 18.522 0 0 0 26.191 26.19l-26.19-26.19Z"
				fill="#fff"
			/>
			<path
				d="M34.173 65.472a19.645 19.645 0 0 1-14.071-33.394 1.151 1.151 0 0 1 1.635-.012l26.191 26.191a1.15 1.15 0 0 1 0 1.641 19.617 19.617 0 0 1-13.755 5.574ZM20.965 34.548a17.371 17.371 0 0 0 24.487 24.487L20.965 34.548Z"
				fill="#224946"
			/>
			<path
				d="M54.083 20.424a1.152 1.152 0 0 1-1.152-1.151V4.303H11.926a1.151 1.151 0 0 1 0-2.303h42.157a1.152 1.152 0 0 1 1.151 1.152v16.12a1.152 1.152 0 0 1-1.151 1.152Z"
				fill="#22B573"
			/>
			<path
				d="M76.848 69.225a1.151 1.151 0 0 1-1.151-1.151v-47.65H48.06a1.152 1.152 0 0 1 0-2.303h28.788a1.152 1.152 0 0 1 1.151 1.152v48.795a1.154 1.154 0 0 1-1.151 1.157ZM38.014 20.424h-9.955a1.152 1.152 0 0 1 0-2.303h9.954a1.152 1.152 0 1 1 0 2.303Z"
				fill="#22B573"
			/>
			<path
				d="M61.43 20.424a1.152 1.152 0 0 1-1.152-1.151v-2.93a1.152 1.152 0 0 1 2.303 0v2.93a1.152 1.152 0 0 1-1.152 1.151ZM69.502 20.424a1.152 1.152 0 0 1-1.152-1.151v-2.93a1.152 1.152 0 1 1 2.303 0v2.93a1.152 1.152 0 0 1-1.151 1.151ZM76.848 41.152H54.083A1.152 1.152 0 0 1 52.93 40V29.17a1.152 1.152 0 0 1 2.303 0v9.679h21.614a1.152 1.152 0 0 1 0 2.303Z"
				fill="#22B573"
			/>
		</svg>
	);
};
