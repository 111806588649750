import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { Fragment } from 'react';
import { StoryblokComponent } from '@storyblok/react';
import { IFormItems, IFormRow } from '@/utils/interfaces/components';

interface IProps {
	blok: IFormRow;
}

export const SbFormRow: React.FC<IProps> = ({ blok }) => {
	return (
		<div className="flex flex-col w-full md:flex-row md:space-x-4">
			{blok.items && isArrayWithValue(blok.items) && (
				<Fragment>
					{blok.items.map((row, key) => {
						return (
							<div key={key} className="flex-grow mb-2 md:mb-4">
								<StoryblokComponent key={key} blok={row} />
							</div>
						);
					})}
				</Fragment>
			)}
		</div>
	);
};
