import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { IFeatureCards } from '@/utils/interfaces/bloks';
import { StoryblokComponent } from '@storyblok/react';
import { CTA } from '../Shared/CTA/CallToAction';
import { FormattedText } from '../Shared/FormattedText';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';

interface IProps {
	blok: IFeatureCards;
	preview: boolean;
}

type MapKindToComponentT = {
	[key: string]: string[];
};

const lengthMap: MapKindToComponentT = {
	'1': ['col-span-6'],
	'2': ['lg:col-span-6', 'lg:col-span-6'],
	'3': [
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
	],
	'4': [
		'lg:col-span-3',
		'lg:col-span-3',
		'lg:col-span-3',
		'lg:col-span-3',
		'lg:col-span-3',
		'lg:col-span-3',
		'lg:col-span-3',
		'lg:col-span-3',
	],
	'5': [
		'lg:col-span-8',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
	],
	'6': [
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
	],
	'7': [
		'lg:col-span-8',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-8',
	],
	'8': [
		'lg:col-span-8',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
		'lg:col-span-4',
	],
};

export const FeatureTiles: React.FC<IProps> = ({ blok, preview }) => {
	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative bg-light py-14 md:py-32"
		>
			<Container align="center">
				<FormattedText tag="h2" text={blok.heading} />
				<span className="block mt-4 lg:text-xl max-w-[800px] mx-auto lg:mt-6">
					{blok.sub_heading}
				</span>

				{isArrayWithValue(blok.features) && (
					<div className="grid grid-cols-12 gap-4 mt-10 md:mt-14 lg:gap-8">
						{blok.features.map((feature, key) => {
							const length: string =
								blok.features.length.toString();
							const classes = lengthMap[length];

							return (
								<div
									key={key}
									className={`col-span-12 sm:col-span-6 ${classes[key]}`}
								>
									<StoryblokComponent blok={feature} />
								</div>
							);
						})}
					</div>
				)}

				{isArrayWithValue(blok.cta) && (
					<div className="flex justify-center mt-10 md:mt-14">
						{blok.cta.map((cta, key) => {
							return <CTA blok={cta} key={key} />;
						})}
					</div>
				)}
			</Container>
		</SbSection>
	);
};
