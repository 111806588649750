import {ILogoCarousel} from '@/utils/interfaces/bloks';
import {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FormattedText} from '../Shared/FormattedText';
import {Container} from '../Shared/Layout/Container';
import {SbSection} from '../Shared/Layout/SbSection';
import {useEffect, useState} from "react";
import {isArrayWithValue} from "@/utils/helpers/isArrayWithValue";
import {ISbFile} from "@/utils/interfaces/common";

interface IProps {
    blok: ILogoCarousel;
    preview: boolean;
}

export const LogoCarousel: React.FC<IProps> = ({blok, preview}) => {
    const [logos, setLogos] = useState<ISbFile[]>([]);

    useEffect(() => {
        blok.logos && setLogos(blok.logos);
    }, []);

    return (
        <SbSection
            blok={blok}
            preview={preview}
            classNames="relative bg-gray-lightest py-14 md:py-32 overflow-hidden"
        >
            <Container align="center">
                <FormattedText
                    tag="h3"
                    className="text-4xl"
                    text={blok.heading}
                />

                <span className="block mt-4 lg:text-xl max-w-[800px] mx-auto lg:mt-4">
					{blok.sub_heading}
				</span>
            </Container>

            <div className="block mt-6 lg:mt-19">
                <Swiper
                    watchOverflow={true}
                    spaceBetween={12}
                    slidesPerView="auto"
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: false,
                    }}
                    speed={3000}
                    loop={true}
                    loopedSlides={10}
                    centeredSlides={true}
                    initialSlide={Math.floor(logos.length / 2)}
                    className="py-3 overflow-y-visible"
                    modules={[Autoplay]}
                    breakpoints={{
                        800: {
                            spaceBetween: 20,
                        },
                    }}
                >
                    {logos && isArrayWithValue(logos) && logos.map((image, key) => (
                        <SwiperSlide
                            className="flex w-auto items-center justify-center h-full overflow-y-visible first:ml-3"
                            key={key}
                        >
                            <div
                                className="flex items-center justify-center bg-white shadow-soft overflow-y-visible h-19 w-[117px] md:w-[176px] lg:h-[108px] p-3 md:px-6">
                                <img
                                    src={image.filename}
                                    loading="lazy"
                                    className="box-border max-h-full max-w-full object-contain"
                                    alt={image.alt}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </SbSection>
    );
};
