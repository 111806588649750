import { ICoordinates } from '@/utils/interfaces/common';
import { ILocationsPage, IStoryBase } from '@/utils/interfaces/stories';
import { InfoBox, Marker } from '@react-google-maps/api';
import { Dispatch, SetStateAction } from 'react';
import { SbInfoBox } from './SbInfoBox';

interface IProps {
	icon: string;
	center: ICoordinates;
	active: boolean;
	location: IStoryBase<ILocationsPage>;
	setActiveLocation: Dispatch<SetStateAction<number | null>>;
}

export const SbMarker: React.FC<IProps> = ({
	icon,
	center,
	active,
	location,
	setActiveLocation,
}) => {
	const options = {
		closeBoxURL: '',
		enableEventPropagation: true,
		pixelOffset: new google.maps.Size(40, -130),
	};

	return (
		<Marker
			icon={icon}
			animation={google.maps.Animation.DROP}
			position={center}
			onClick={() => {
				setActiveLocation(active ? null : (center.id as number));
			}}
		>
			{active ? (
				<InfoBox
					options={options}
					position={new google.maps.LatLng(center.lat, center.lng)}
				>
					<div
						className="hidden sm:block"
						style={{
							width: '384px',
						}}
					>
						<SbInfoBox location={location} />
					</div>
				</InfoBox>
			) : (
				<></>
			)}
		</Marker>
	);
};
