import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { IImageLeftRight } from '@/utils/interfaces/bloks';
import { CTA } from '../Shared/CTA/CallToAction';
import { FormattedText } from '../Shared/FormattedText';
import { Icon } from '../Shared/Icon';
import { Left } from '../Shared/Icons/BrandBackgrounds/ImageLeftRight/Left';
import { Right } from '../Shared/Icons/BrandBackgrounds/ImageLeftRight/Right';
import { SmallEmblem } from '../Shared/Icons/BrandBackgrounds/ImageLeftRight/SmallEmblem';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';
import { SbImage } from '../Shared/Image/SbImage';
import { SbImageWrapper } from '../Shared/Image/SbImageWrapper';

interface IProps {
	blok: IImageLeftRight;
	preview: boolean;
}

export const ImageLeftRight: React.FC<IProps> = ({ blok, preview }) => {
	let largeEmblem = null;
	let smallEmblem = null;

	const containerClasses = ['relative', 'pb-14', 'lg:py-47'];
	const flexControlClasses = [
		'relative',
		'flex',
		'flex-col-reverse',
		'w-full',
		'lg:flex-row',
	];
	const textContainerClasses = [
		'justify-center',
		'md:flex',
		'md:flex-col',
		'xl:flex-1',
	];
	const imageContainerClasses = [
		'justify-center',
		'relative',
		'md:flex',
		'md:flex-col',
		'lg:min-w-[536px]',
		'xl:flex-1',
		'xl:min-w-[636px]',
	];

	if (blok.bg_colour === 'dark-green') {
		containerClasses.push('dark', 'bg-green-dark');
	} else if (blok.bg_colour === 'white') {
		containerClasses.push('bg-white');
	} else if (blok.bg_colour === 'grey') {
		containerClasses.push('bg-gray-light');
	}

	if (blok.layout === 'image-left') {
		flexControlClasses.push('lg:flex-row-reverse');
		textContainerClasses.push(
			'lg:ml-20',
			'lg:mr-6',
			'xl:ml-40',
			'xl:mr-14',
		);
	} else {
		textContainerClasses.push('lg:mr-30', 'lg:mr-40', 'xl:ml-14');
	}

	if (blok.emblem === 'large') {
		if (blok.layout === 'image-left') {
			largeEmblem = (
				<Icon
					className="absolute top-0 left-6 w-[324px] h-[336px] sm:w-[474px] sm:h-[486px] lg:h-[585px] lg:w-[553px] lg:left-[2%] xl:h-[735px] xl:w-[703px] xl:left-[5.5%]"
					icon={<Left />}
				/>
			);
		} else {
			largeEmblem = (
				<Icon
					className="absolute top-0 right-6 w-[324px] h-[336px] sm:w-[474px] sm:h-[486px] lg:h-[585px] lg:w-[553px] lg:right-[2%] xl:h-[735px] xl:w-[703px] xl:right-[5.5%]"
					icon={<Right />}
				/>
			);
		}
	} else {
		const smallEmblemClasses = [
			'h-[295px]',
			'w-[169px]',
			'md:h-[422px]',
			'md:w-[240px]',
			'lg:w-[318px]',
			'lg:h-[555px]',
			'xl:w-[368px]',
			'xl:h-[644px]',
			'absolute-center',
		];

		if (blok.layout === 'image-left') {
			smallEmblemClasses.push('rotate-180');
		}

		smallEmblem = (
			<Icon
				className={smallEmblemClasses.join(' ')}
				icon={<SmallEmblem className="w-full" />}
			/>
		);
	}

	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames={containerClasses.join(' ')}
		>
			{largeEmblem && <>{largeEmblem}</>}

			<Container type="wide">
				<div className={flexControlClasses.join(' ')}>
					<div className={textContainerClasses.join(' ')}>
						<span className="text-sm font-bold text-green">
							{blok.sub_heading}
						</span>

						<FormattedText
							tag="h3"
							text={blok.heading}
							className="mt-2 dark:text-white"
						/>

						<span className="block mt-6 text-base opacity-80 dark:text-white">
							{blok.description}
						</span>

						<div className="mt-4">
							{blok.ctas.map((cta, key) => {
								return <CTA blok={cta} key={key} />;
							})}
						</div>
					</div>
					<div className={imageContainerClasses.join(' ')}>
						<div className="max-w-[290px] mx-auto py-20 sm:max-w-[490px] lg:py-0 xl:max-w-none">
							{blok.image &&
								isStringWithValue(blok.image.filename) && (
									<div className="relative z-20">
										<SbImageWrapper
											shadow={false}
											rounded="lg"
										>
											<SbImage
												src={blok.image.filename}
												alt={blok.image.alt}
												disablePngBg={true}
												layout="intrinsic"
												objectFit="contain"
											/>
										</SbImageWrapper>
									</div>
								)}

							{smallEmblem && <>{smallEmblem}</>}
						</div>
					</div>
				</div>
			</Container>
		</SbSection>
	);
};
