interface IProps {}

export const DesktopApp: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M39.527 47.477a1.152 1.152 0 0 1-.576-.16L33.1 43.935a1.152 1.152 0 0 1-.576-1.002v-11.46L28.942 29.4v10.136a1.152 1.152 0 0 1-1.728.996l-5.909-3.42a1.153 1.153 0 0 1-.575-.997v-12.14a1.152 1.152 0 0 1 2.303 0v11.478l3.576 2.067V27.384a1.152 1.152 0 0 1 1.728-1.002l.45.254c.549.322 2.364 1.372 5.447 3.15a1.15 1.15 0 0 1 .576.996v11.466l3.565 2.062v-8.218a1.152 1.152 0 1 1 2.304 0v8.224l15.382-8.863v-5.73a1.152 1.152 0 0 1 2.304 0v6.398a1.151 1.151 0 0 1-.576.996L40.103 47.305a1.152 1.152 0 0 1-.576.172Zm27.804-4.36c-.202 0-.4-.053-.576-.155l-5.517-3.179a1.153 1.153 0 1 1 1.152-1.998l5.517 3.185a1.151 1.151 0 0 1-.576 2.148Zm-55.522 0a1.152 1.152 0 0 1-.576-2.147l5.517-3.185a1.153 1.153 0 1 1 1.152 1.998l-5.517 3.18c-.183.1-.39.15-.6.143l.024.012Zm30.315-9.37a1.152 1.152 0 0 1-.979-.546L29.846 14.899l-9.721 5.609a1.152 1.152 0 1 1-1.152-1.993l10.666-6.168L47.29 2.155a1.152 1.152 0 0 1 1.555.392L60.703 21.81a1.152 1.152 0 0 1-.403 1.6L42.666 33.599a1.152 1.152 0 0 1-.565.138l.023.012Zm-10.28-20 10.672 17.277 15.641-9.036L47.503 4.711l-15.659 9.036Z"
				fill="#22B573"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M35.386 61.057c.346 6.657-4.71 10.305-15.169 10.942v4.85h38.62v-4.844c-10.462-.637-15.67-4.285-15.624-10.942"
				fill="#22B573"
			/>
			<path
				d="M58.837 78h-38.62a1.152 1.152 0 0 1-1.152-1.152v-4.843a1.152 1.152 0 0 1 1.083-1.152c9.79-.576 14.398-3.784 14.087-9.733a1.155 1.155 0 0 1 1.536-1.147 1.155 1.155 0 0 1 .767 1.026c.375 7.136-4.722 11.196-15.163 12.094v2.603H57.69v-2.62c-10.418-.875-15.676-4.918-15.624-12.025a1.152 1.152 0 0 1 2.303 0c-.04 5.984 4.717 9.186 14.542 9.79a1.152 1.152 0 0 1 1.083 1.152v4.855A1.153 1.153 0 0 1 58.837 78Z"
				fill="#224946"
			/>
			<path
				d="M70.591 62.209H8.463A4.475 4.475 0 0 1 4 57.75V12.923a4.48 4.48 0 0 1 4.463-4.457H26.5a1.152 1.152 0 1 1 0 2.304H8.463a2.17 2.17 0 0 0-2.16 2.153v44.805a2.171 2.171 0 0 0 2.16 2.154h62.128a2.171 2.171 0 0 0 2.16-2.154V12.923a2.17 2.17 0 0 0-2.16-2.153H58.423a1.152 1.152 0 1 1 0-2.304H70.59a4.48 4.48 0 0 1 4.463 4.457v44.805a4.474 4.474 0 0 1-4.463 4.458v.023Z"
				fill="#224946"
			/>
			<path
				d="M42.142 57.043h-5.183a1.152 1.152 0 1 1 0-2.304h5.183a1.152 1.152 0 0 1 0 2.304ZM67.493 51.9H11.631a1.152 1.152 0 1 1 0-2.303h55.862a1.152 1.152 0 0 1 0 2.303Z"
				fill="#224946"
			/>
		</svg>
	);
};
