import { Cutout } from '@/components/Shared/Icons/BrandBackgrounds/WebWidget/Cutout';
import { formatTitle } from '@/utils/helpers/formatTitle';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { IWebWidget } from '@/utils/interfaces/bloks';
import { storyblokEditable } from '@storyblok/react';
import { useState } from 'react';
import { Icon } from '../Shared/Icon';
import { Desktop } from '../Shared/Icons/WebWidget/Desktop';
import { Mobile } from '../Shared/Icons/WebWidget/Mobile';
import { Tablet } from '../Shared/Icons/WebWidget/Tablet';
import { SbImage } from '../Shared/Image/SbImage';
import { Container } from '../Shared/Layout/Container';

interface IProps {
	blok: IWebWidget;
}

enum WidgetSize {
	Desktop,
	Tablet,
	Mobile,
}

const SCREENS = [
	{
		size: WidgetSize.Desktop,
		icon: <Desktop />,
	},
	{
		size: WidgetSize.Tablet,
		icon: <Tablet />,
	},
	{
		size: WidgetSize.Mobile,
		icon: <Mobile />,
	},
];

export const WebWidget: React.FC<IProps> = ({ blok }) => {
	const [widgetSize, setWidgetSize] = useState<WidgetSize>(
		WidgetSize.Desktop,
	);

	return (
		<section
			{...storyblokEditable(blok)}
			className="relative text-white dark bg-green-dark py-14 md:py-32"
		>
			<Cutout className="hidden md:block absolute bottom-0 left-0 w-full max-w-[1351px]" />
			<Container align="center">
				<h2
					className="mb-4 dark:text-white"
					dangerouslySetInnerHTML={{
						__html: formatTitle(blok.heading),
					}}
				/>

				{blok.description && isStringWithValue(blok.description) && (
					<span className="block lg:text-xl max-w-[760px] mx-auto lg:mt-6 text-white text-opacity-80">
						{blok.description}
					</span>
				)}

				<div className="text-center mx-auto mt-10 mb-8 lg:mt-[60px] lg:mb-12">
					<div className="flex justify-center space-x-[2px]">
						{SCREENS.map((screen, key) => {
							const classes = [
								'flex',
								'items-center',
								'justify-center',
								'bg-white',
								'w-12',
								'h-12',
								'lg:w-15',
								'lg:h-15',
								'bg-opacity-15',
							];

							if (key === 0) {
								classes.push('rounded-l-md');
							}

							if (key === SCREENS.length - 1) {
								classes.push('rounded-r-md');
							}

							if (widgetSize === screen.size) {
								classes.push('green-gradient');
							}

							return (
								<button
									key={key}
									className={classes.join(' ')}
									onClick={() => setWidgetSize(screen.size)}
								>
									<Icon
										className="w-6 h-6"
										icon={screen.icon}
									/>
								</button>
							);
						})}
					</div>
				</div>

				<div className="flex justify-center w-full">
					{widgetSize === WidgetSize.Desktop &&
						isStringWithValue(blok.desktop_image.filename) && (
							<div>
								<div className="border-[10px] bg-black border-black rounded-[20px] text-none shadow-hard">
									<SbImage
										src={blok.desktop_image.filename}
										alt={blok.desktop_image.alt}
										width="1176"
										height="662"
										className="rounded-[10px] text-none"
									/>
								</div>
							</div>
						)}

					{widgetSize === WidgetSize.Tablet &&
						isStringWithValue(blok.tablet_image.filename) && (
							<div>
								<div className="border-[20px] bg-black border-black rounded-[30px] text-none shadow-hard">
									<SbImage
										src={blok.tablet_image.filename}
										alt={blok.tablet_image.alt}
										width="882"
										height="662"
										className="rounded-[20px] text-none"
									/>
								</div>
							</div>
						)}

					{widgetSize === WidgetSize.Mobile &&
						isStringWithValue(blok.mobile_image.filename) && (
							<div>
								<div className="border-[20px] bg-black border-black rounded-[40px] text-none shadow-hard">
									<SbImage
										src={blok.mobile_image.filename}
										alt={blok.mobile_image.alt}
										width="308"
										height="662"
										className="rounded-[30px] text-none"
									/>
								</div>
							</div>
						)}
				</div>
			</Container>
		</section>
	);
};
