import { isSbLinkValid } from '@/utils/helpers/isSbLinkValid';
import { ICard } from '@/utils/interfaces/components';
import { Fragment } from 'react';
import { FormattedText } from '../FormattedText';
import { Icon } from '../Icon';
import { Arrow } from '../Icons/Arrow';
import { SbImage } from '../Image/SbImage';
import { SbLink } from '../SbLink';

interface IProps {
	blok: ICard;
}

export const Card: React.FC<IProps> = ({ blok }) => {
	function CardContent() {
		return (
			<Fragment>
				<div className="z-0 absolute-fill bg-green-dark nick-test">
					<Icon
						className="absolute w-[12px] h-[10px] text-white bottom-3 right-3"
						icon={<Arrow />}
					/>
				</div>
				<div
					className={`relative h-full text-left bg-white shadow-hard z-10 transition-all ${
						isSbLinkValid(blok.link)
							? 'group-hover:rounded-br-[90px]'
							: ''
					}`}
				>
					<div className="h-[200px] w-full relative lg:h-[340px]">
						<SbImage
							src={blok.image.filename}
							alt={blok.image.alt}
							layout="fill"
							width={1600}
							height={900}
							objectFit="cover"
							className="transition-transform duration-200 group-hover:scale-110"
						/>
					</div>
					<div className="p-4 pb-6 lg:p-8">
						<FormattedText
							tag="h4"
							text={blok.title}
							className="text-xl lg:text-4xl"
						/>
						<span className="block mt-1 text-sm lg:text-base opacity-80 lg:mt-2 lg:w-3/4">
							{blok.description}
						</span>
					</div>
				</div>
			</Fragment>
		);
	}

	if (isSbLinkValid(blok.link)) {
		return (
			<SbLink link={blok.link} newTab={blok.new_tab}>
				<a className="relative block w-full h-full group">
					<CardContent />
				</a>
			</SbLink>
		);
	} else {
		return (
			<div className="relative block w-full h-full">
				<CardContent />
			</div>
		);
	}
};
