import { CTA } from '@/components/Shared/CTA/CallToAction';
import { FAQ } from '@/components/Shared/FAQ';
import { TabUnderline } from '@/components/Shared/Tabs/TabUnderline';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { IFaqs } from '@/utils/interfaces/bloks';
import { useState } from 'react';
import { FormattedText } from '../Shared/FormattedText';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';

interface IProps {
	blok: IFaqs;
	preview: boolean;
}

export const FAQs: React.FC<IProps> = ({ blok, preview }) => {
	const [activeTab, setActiveTab] = useState(0);

	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative py-14 md:py-32"
		>
			<Container>
				<div className="flex flex-wrap items-start justify-between">
					<FormattedText
						tag="h2"
						text={blok.heading}
						className="mb-6 md:mb-16"
					/>
					<div className="w-full grid-cols-3 mb-10 md:mb-0 md:grid gap-x-12 md:gap-x-24 md:order-1">
						<div>
							<div className="flex gap-3 md:flex-col mb-7">
								{blok.faqs.map((faq_group, key) => (
									<TabUnderline
										key={key}
										title={faq_group.category}
										onClick={() => setActiveTab(key)}
										isActive={activeTab === key}
									/>
								))}
							</div>
							<div className="hidden mt-16 md:block">
								{blok.sub_heading &&
									isStringWithValue(blok.sub_heading) && (
										<h5>{blok.sub_heading}</h5>
									)}
								{isArrayWithValue(blok.secondary_ctas) &&
									blok.secondary_ctas?.map((cta, key) => {
										return <CTA blok={cta} key={key} />;
									})}
							</div>
						</div>
						{isArrayWithValue(blok.faqs) && (
							<div className="col-span-2">
								{blok.faqs.map((faq_group, key) => (
									<div
										id={faq_group._uid}
										key={key}
										className={
											activeTab === key
												? 'sm:columns-2 gap-x-6 xl:gap-x-[72px]'
												: 'hidden'
										}
									>
										{faq_group.faqs.map((blok, key) => (
											<FAQ blok={blok} key={key} />
										))}
									</div>
								))}
							</div>
						)}
						<div className="md:hidden">
							{blok.sub_heading &&
								isStringWithValue(blok.sub_heading) && (
									<h5>{blok.sub_heading}</h5>
								)}
							{isArrayWithValue(blok.secondary_ctas) &&
								blok.secondary_ctas?.map((cta, key) => {
									return <CTA blok={cta} key={key} />;
								})}
						</div>
					</div>

					{isArrayWithValue(blok.ctas) &&
						blok.ctas?.map((cta, key) => {
							return <CTA blok={cta} key={key} />;
						})}
				</div>
			</Container>
		</SbSection>
	);
};
