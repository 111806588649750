import { Icon } from '../Icon';
import { Play } from '@/components/Shared/Icons/Play';

interface IProps {
	onClick: (value: boolean) => void;
}

export const PlayButton: React.FC<IProps> = ({ onClick }) => {
	return (
		<button
			onClick={() => onClick(true)}
			className="absolute w-12 h-12 text-black transition-all transform -translate-x-1/2 -translate-y-1/2 rounded-full lg:w-20 lg:h-20 top-1/2 left-1/2 green-gradient "
		>
			<Icon icon={<Play />} className="w-[14px] h-[14px] lg:w-5 lg:h-5" />
		</button>
	);
};
