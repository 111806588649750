import {IIBuildStatistics} from '@/utils/interfaces/bloks';
import {IBuildStatistic} from "@/components/Bloks/IBuild/IBuildStatistic";

interface IProps {
    blok: IIBuildStatistics;
}

export const IBuildStatistics: React.FC<IProps> = ({blok}) => {
    return (
        <div className="flex gap-8">
            {blok.statistics.map((statistic, key) => {
                return <IBuildStatistic blok={statistic} key={key}/>;
            })}
        </div>
    );
};
