import { Icon } from '@/components/Shared/Icon';
import { Check } from '@/components/Shared/Icons/Check';
import Link from 'next/link';
import { pathOr } from 'ramda';
import { IFormStepProps } from '../SelfSubscribeContainer';
import { FormStepLayout } from '../Shared/FormStepLayout';

export const Complete: React.FC<IFormStepProps> = ({ blok }) => {
	const heading = pathOr(null, ['complete_heading'], blok);
	const description = pathOr(null, ['complete_description'], blok);
	const media = pathOr(null, ['complete_media'], blok);
	const demoLink = pathOr(null, ['demo_link'], blok);

	return (
		<FormStepLayout media={media} link={demoLink}>
			<div className="form-step">
				<div>
					<div className="form-step__icon-container mt-28">
						<Icon
							className="form-step__icon form-step__icon--complete"
							icon={<Check />}
						/>
					</div>

					<div className="form-step__heading">
						<h4>{heading}</h4>
					</div>

					{description && (
						<div className="form-step__description">
							<span>{description}</span>
						</div>
					)}
					<div className="mt-7">
						<div className="form-step__form-row form-step__form-row--submit">
							<Link href="https://app.canibuild.com/">
								<a
									className="cta cta--primary cta--medium"
									target="_blank"
								>
									<span className="cta-label">Login</span>
								</a>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</FormStepLayout>
	);
};
