import { FormattedText } from '@/components/Shared/FormattedText';
import { buildParamsWithContentType } from '@/utils/helpers/buildParams';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { IInsightsFilter } from '@/utils/interfaces/bloks';
import {
	ICategoryPage,
	IInsightPage,
	IStoryBase,
} from '@/utils/interfaces/stories';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { pathOr } from 'ramda';
import { Fragment, useContext, useEffect, useState } from 'react';
import { InsightsProviderContext } from '../../Providers/InsightsProvider';
import { StoryblokContext } from '../../Providers/StoryblokProvider';
import { Container } from '../../Shared/Layout/Container';
import { SbSection } from '../../Shared/Layout/SbSection';
import { InsightFeatureCard } from '../../Shared/Tiles/InsightFeatureCard';
import { InsightsFilterBar } from './InsightsFilterBar';
import { InsightsFilterResults } from './InsightsFilterResults';

export type IInsightsData = IStoryBase<IInsightPage>[];

export interface IFilter {
	uuid: string;
	slug: string;
	name: string;
}

interface IProps {
	blok: IInsightsFilter;
	preview: boolean;
}

enum DISPLAY_MODE {
	DEFAULT,
	RESULT,
}

export const InsightsFilter: React.FC<IProps> = ({ blok, preview }) => {
	const { fetchDataByParams, fetchData } = useContext(StoryblokContext);
	const { insights, categories } = useContext(InsightsProviderContext);

	// Delay load of component to make sure values have been set to
	// avoid in defaulting to the DEFAULT mode.
	if (!isArrayWithValue(insights) && !isArrayWithValue(categories)) {
		return null;
	}

	// Need to extract to a child component to avoid the warning on
	// conditionally useEffect, useState warning
	return (
		<InsightsFilterChild
			insights={insights}
			categories={categories}
			fetchData={fetchData}
			fetchDataByParams={fetchDataByParams}
			blok={blok}
			preview={preview}
		/>
	);
};

interface IChildProps {
	insights: IStoryBase<IInsightPage>[];
	categories: IStoryBase<ICategoryPage>[];
	blok: IInsightsFilter;
	preview: boolean;
	fetchData: (params: {}) => Promise<any>;
	fetchDataByParams: (params: {}) => Promise<any>;
}

const InsightsFilterChild: React.FC<IChildProps> = ({
	insights,
	categories,
	blok,
	preview,
	fetchData,
	fetchDataByParams,
}) => {
	const { locale, query } = useRouter();

	const { defaultFilter, defaultDisplayMode } = findDefaultFilterBySlug(
		categories,
		query,
	);

	const [loading, setLoading] = useState(false);
	const [filter, setFilter] = useState<IFilter | null>(defaultFilter);
	const [filteredResults, setFilteredResults] = useState<IInsightsData>([]);
	const [display, setDisplay] = useState<DISPLAY_MODE>(defaultDisplayMode);
	const [total, setTotal] = useState<number | null>(null);

	const [pagination, setPagination] = useState({ page: 1, per_page: 12 });

	useEffect(() => {
		const params = Object.assign(
			{
				filter_query: {
					categories: {
						in: filter?.uuid,
					},
				},
			},
			buildParamsWithContentType(
				locale as string,
				'insight',
				`${locale}/resources/`,
			),
		);

		if (total === null) {
			getTotalResults(params, fetchData).then((results) => {
				setTotal(results);
			});
		}

		return () => {};
	}, [filter]);

	useEffect(() => {
		setLoading(true);
		if (filter) {
			const params = Object.assign(
				{
					filter_query: {
						categories: {
							in: filter?.uuid,
						},
					},
				},
				buildParamsWithContentType(
					locale as string,
					'insight',
					`${locale}/resources/`,
				),
				pagination,
			);

			fetchDataByParams(params)
				.then((data) => {
					if (data) {
						setFilteredResults(data as IInsightsData);
						setDisplay(DISPLAY_MODE.RESULT);
						setLoading(false);
					}
				})
				.catch((err) => {
					// No entries in results
					setLoading(false);
				});
		}
	}, [filter]);

	function updateFilter(data: IFilter | null) {
		if (data === null) {
			setFilter(null);
			setFilteredResults([]);
			setTotal(null);
			setDisplay(DISPLAY_MODE.DEFAULT);
		}

		if (data !== null && data.uuid !== filter?.uuid) {
			setFilteredResults([]);
			setTotal(null);
			setPagination({ ...pagination, page: 1 });
			setFilter(data);
		}
	}

	function loadMoreResults() {
		setLoading(true);
		const nextPageParams = {
			page: pagination.page + 1,
			per_page: pagination.per_page,
		};

		const params = Object.assign(
			{
				filter_query: {
					categories: {
						in: filter?.uuid,
					},
				},
			},
			buildParamsWithContentType(
				locale as string,
				'insight',
				`${locale}/resources/`,
			),
			nextPageParams,
		);

		fetchDataByParams(params)
			.then((data) => {
				if (data.length > 0) {
					setPagination(nextPageParams);
					setFilteredResults([
						...filteredResults,
						...(data as IInsightsData),
					]);
					setLoading(false);
				}
			})
			.catch((err) => {
				console.warn('error', err);
				setLoading(false);
			});
	}

	return (
		<SbSection
			preview={preview}
			blok={blok}
			classNames="relative bg-white pb-14 md:pb-32"
		>
			<div className="py-4 bg-gray-light lg:py-7">
				<InsightsFilterBar
					filter={filter}
					resultsCount={filteredResults.length}
					categories={categories}
					setFilter={updateFilter}
				/>
			</div>

			<Container>
				{display === DISPLAY_MODE.DEFAULT && (
					<div className="pt-14 md:pt-24">
						{blok.featured && isArrayWithValue(blok.featured) && (
							<div className="pb-10 mb-10 border-b md:pb-16 md:mb-16 border-gray-light">
								<h2>Featured</h2>

								<div className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2 lg:gap-8">
									{blok.featured.map((blok, key) => (
										<InsightFeatureCard
											blok={blok}
											key={key}
										/>
									))}
								</div>
							</div>
						)}

						<div>
							<FormattedText
								tag="h2"
								text="More from **canibuild**"
								className="lg:text-4xl"
							/>

							<div>
								{insights && isArrayWithValue(insights) && (
									<Fragment>
										<InsightsFilterResults
											insights={insights}
										/>
									</Fragment>
								)}
							</div>
						</div>
					</div>
				)}

				{display === DISPLAY_MODE.RESULT && (
					<Fragment>
						{filteredResults.length === 0 && !loading && (
							<div className="mt-6 text-center md:text-left">
								<h4>
									There are no results for{' '}
									<span className="text-green">
										{filter?.name}
									</span>
								</h4>
							</div>
						)}

						<InsightsFilterResults insights={filteredResults} />

						{filteredResults.length > 0 &&
							total &&
							filteredResults.length < total && (
								<div className="flex justify-center w-full mt-6">
									<button
										type="button"
										className="inline-flex w-auto cta cta--secondary cta--small"
										onClick={() => loadMoreResults()}
									>
										{loading ? (
											<span className="cta-label">
												Loading
											</span>
										) : (
											<span className="cta-label">
												Load More
											</span>
										)}
									</button>
								</div>
							)}
					</Fragment>
				)}
			</Container>
		</SbSection>
	);
};

async function getTotalResults(params: any, func: any) {
	const response = await func(params);
	return parseInt(response.headers.total);
}

interface IInitialValues {
	defaultFilter: IFilter | null;
	defaultDisplayMode: DISPLAY_MODE;
}

function findDefaultFilterBySlug(
	categories: IStoryBase<ICategoryPage>[],
	query: ParsedUrlQuery,
): IInitialValues {
	if (
		categories &&
		isArrayWithValue(categories) &&
		pathOr(false, ['category'], query)
	) {
		const { category } = query;

		const isQueryInCategories = categories.filter(
			(cat) => cat.slug === category,
		);

		if (isArrayWithValue(isQueryInCategories)) {
			const { name, slug, uuid } = isQueryInCategories[0];

			// setFilter({ name, slug, uuid });
			return {
				defaultFilter: { name, slug, uuid },
				defaultDisplayMode: DISPLAY_MODE.RESULT,
			};
		}
	}

	return {
		defaultFilter: null,
		defaultDisplayMode: DISPLAY_MODE.DEFAULT,
	};
}
