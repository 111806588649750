interface IProps {}

export const BrickLayerDark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="m34.692 31.502 4.387 25.575a3.563 3.563 0 0 0 5.758 2.148l19.92-16.346a3.57 3.57 0 0 0-1.001-6.086l-24.263-9.212a3.564 3.564 0 0 0-4.801 3.92Z"
				fill="#22B573"
			/>
			<path
				d="M42.608 61.194a4.788 4.788 0 0 1-1.664-.3 4.67 4.67 0 0 1-3.006-3.627l-4.381-25.575a4.722 4.722 0 0 1 6.333-5.182l24.263 9.212a4.72 4.72 0 0 1 1.324 8.06l-19.875 16.37a4.663 4.663 0 0 1-2.994 1.042Zm-6.782-29.888 4.387 25.575a2.419 2.419 0 0 0 3.915 1.457l19.875-16.369a2.419 2.419 0 0 0-.68-4.122l-24.262-9.213a2.412 2.412 0 0 0-3.236 2.666v.006Z"
				fill="#fff"
			/>
			<path
				d="M60.088 58.614a1.152 1.152 0 0 1-.892-.42l-9.069-11.009a1.152 1.152 0 1 1 1.78-1.462l9.068 11.008a1.153 1.153 0 0 1-.156 1.624 1.18 1.18 0 0 1-.73.26Z"
				fill="#fff"
			/>
			<path
				d="M72.34 78a5.686 5.686 0 0 1-2.867-.762 5.614 5.614 0 0 1-1.698-1.524l-9.212-12.436a4.893 4.893 0 0 1 .817-6.702 4.889 4.889 0 0 1 6.714.495l10.432 11.452A5.677 5.677 0 0 1 72.341 78Zm-9.885-20.244a2.585 2.585 0 0 0-1.647.576 2.591 2.591 0 0 0-.432 3.547l9.241 12.436a3.374 3.374 0 1 0 5.182-4.284L64.361 58.58a2.586 2.586 0 0 0-1.906-.824Z"
				fill="#fff"
			/>
			<path
				d="M64.959 19.756a1.152 1.152 0 0 1-1.152-1.151V4.303H16.192v14.302a1.151 1.151 0 1 1-2.303 0V3.152A1.152 1.152 0 0 1 15.041 2h49.918a1.152 1.152 0 0 1 1.151 1.152v15.453a1.152 1.152 0 0 1-1.151 1.151Z"
				fill="#22B573"
			/>
			<path
				d="M40 18.565a1.152 1.152 0 0 1-1.151-1.152V3.152a1.152 1.152 0 0 1 2.303 0v14.261A1.152 1.152 0 0 1 40 18.565Z"
				fill="#22B573"
			/>
			<path
				d="M76.849 35.21h-8.286v-2.303h7.134v-13.15H29.262v14.301a1.152 1.152 0 0 1-1.151 1.152H3.15A1.152 1.152 0 0 1 2 34.058V18.605a1.152 1.152 0 0 1 1.152-1.152h73.697A1.151 1.151 0 0 1 78 18.605v15.453a1.152 1.152 0 0 1-1.151 1.152ZM4.302 32.907h22.656v-13.15H4.303v13.15Z"
				fill="#22B573"
			/>
			<path
				d="M53.04 17.453h-2.302v8.245h2.303v-8.245ZM15.04 50.663a1.152 1.152 0 0 1-1.15-1.151V34.057a1.151 1.151 0 1 1 2.302 0v15.453a1.152 1.152 0 0 1-1.151 1.152Z"
				fill="#22B573"
			/>
			<path
				d="M34.018 66.11H3.152A1.151 1.151 0 0 1 2 64.96V49.511a1.152 1.152 0 0 1 1.152-1.152H31.64v2.303H4.303v13.145h29.715v2.303Z"
				fill="#22B573"
			/>
			<path
				d="M28.11 66.11a1.151 1.151 0 0 1-1.151-1.15V49.511a1.152 1.152 0 1 1 2.303 0v15.447a1.151 1.151 0 0 1-1.152 1.152Z"
				fill="#22B573"
			/>
		</svg>
	);
};
