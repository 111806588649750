import { IIBuildTab } from '@/utils/interfaces/components';
import { Disclosure, Transition } from '@headlessui/react';
import { Icon } from '../Icon';
import { ChevronRight } from '../Icons/ChevronRight';
import { StoryblokComponent } from '@storyblok/react';

interface IProps {
	tabs: IIBuildTab[];
}

export const IBuildTabsAccordion: React.FC<IProps> = ({ tabs }) => (
	<div>
		{tabs.map((tab, key) => {
			return (
				<Disclosure key={key}>
					{({ open }) => (
						<div className="mb-2">
							<Disclosure.Button
								className={`flex items-center w-full px-6 py-4 transition-colors duration-200 rounded-full ${
									open
										? 'bg-gradient-to-r from-green to-teal text-white'
										: 'bg-green-light text-green'
								}`}
							>
								<div className="flex-grow text-left">
									{tab.label}
								</div>
								<div className="relative">
									<Icon
										className={`block w-3 h-3 transition-transform ${
											open
												? 'rotate-[270deg]'
												: 'rotate-90'
										}`}
										icon={<ChevronRight />}
									/>
								</div>
							</Disclosure.Button>
							<Transition
								enter="transition duration-100 ease-out"
								enterFrom="transform scale-95 opacity-0"
								enterTo="transform scale-100 opacity-100"
								leave="transition duration-75 ease-out"
								leaveFrom="transform scale-100 opacity-100"
								leaveTo="transform scale-95 opacity-0"
							>
								<Disclosure.Panel>
									<div className="px-4">
										{tab.body.map((nestedBlok, key) => (
											<div
												className="py-6 border-b border-gray-dark/20 last:border-b-0"
												key={key}
											>
												<StoryblokComponent
													blok={nestedBlok}
												/>
											</div>
										))}
									</div>
								</Disclosure.Panel>
							</Transition>
						</div>
					)}
				</Disclosure>
			);
		})}
	</div>
);
