interface IProps {}

export const CutoutMobile: React.FC<IProps> = () => {
	return (
		<svg
			viewBox="0 0 600 220"
			style={{ fontSize: '0px' }}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="a"
				style={{ maskType: 'alpha' }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="600"
				height="220"
			>
				<path
					transform="matrix(0 1 1 0 0 0)"
					fill="#224946"
					d="M0 0h220v600H0z"
				/>
			</mask>
			<g mask="url(#a)">
				<path
					d="M874.667 661.739 922 632.179 558.321 44.075l-47.482 29.539c-88.264 54.857-190.047 83.921-293.892 83.921-103.846 0-205.629-29.064-293.892-83.921L-124.44 44-488 632.21l47.322 29.56C-243.258 784.8-15.45 850 216.995 850c232.444 0 460.253-65.2 657.672-188.229v-.032Z"
					fill="#224946"
					stroke="#EFF0EF"
					strokeWidth="60"
					strokeMiterlimit="10"
				/>
			</g>
		</svg>
	);
};
