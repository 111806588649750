interface IProps {
	caption: string;
}

export const Caption: React.FC<IProps> = ({ caption }) => {
	return (
		<figcaption className="block px-4 py-2 text-xs text-white lg:px-5 lg:py-4 lg:text-sm green-gradient lg:leading-tight">
			{caption}
		</figcaption>
	);
};
