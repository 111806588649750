import {IIBuildTestimonial} from '@/utils/interfaces/bloks';
import {Testimonial} from "@/components/Shared/Testimonial";

interface IProps {
    blok: IIBuildTestimonial;
}

export const IBuildTestimonial: React.FC<IProps> = ({blok}) => {
    return (
        <Testimonial blok={blok.testimonial}/>
    )
};
