interface IProps {}

export const Blueprint: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M76.843 66.377a5.907 5.907 0 0 0-5.941-5.877H69.71V8.8h1.192a5.907 5.907 0 0 1 5.941 5.878v51.7Z"
				fill="#22B573"
			/>
			<path
				d="M77.994 66.377h-2.303a4.772 4.772 0 0 0-4.796-4.732h-2.337V7.655h2.337a7.064 7.064 0 0 1 7.1 7.023v51.7Zm-7.139-7.017h.046a7.092 7.092 0 0 1 4.79 1.853V14.678a4.772 4.772 0 0 0-4.796-4.732h-.045l.005 49.414Z"
				fill="#224946"
			/>
			<path
				d="M3.151 66.308V14.01a5.941 5.941 0 0 1 5.942-5.94h1.191v52.297H9.093a5.941 5.941 0 0 0-5.942 5.941Z"
				fill="#22B573"
			/>
			<path
				d="M4.303 66.308H2V14.01a7.104 7.104 0 0 1 7.093-7.092h2.343v54.6H9.093a4.795 4.795 0 0 0-4.79 4.79Zm4.79-57.087a4.796 4.796 0 0 0-4.79 4.79v47.07a7.07 7.07 0 0 1 4.79-1.865h.04V9.22h-.04Z"
				fill="#224946"
			/>
			<path
				d="M58.996 63.896H20.998V28.311h37.998v6.65a1.152 1.152 0 0 1-2.303 0v-4.347H23.3v30.98h33.392V45.357a1.151 1.151 0 1 1 2.303 0v18.538Z"
				fill="#22B573"
			/>
			<path
				d="M36.434 37.747a1.151 1.151 0 0 1-1.152-1.151v-7.133a1.152 1.152 0 0 1 2.303 0v7.133a1.151 1.151 0 0 1-1.151 1.151ZM36.434 52.008a1.151 1.151 0 0 1-1.152-1.152v-9.505a1.152 1.152 0 0 1 2.303 0v9.505a1.151 1.151 0 0 1-1.151 1.152ZM36.434 63.896a1.151 1.151 0 0 1-1.152-1.151v-7.133a1.152 1.152 0 0 1 2.303 0v7.133a1.151 1.151 0 0 1-1.151 1.151Z"
				fill="#22B573"
			/>
			<path
				d="M36.433 47.252H22.15a1.151 1.151 0 1 1 0-2.303h14.266a1.15 1.15 0 1 1 0 2.303h.017ZM41.183 19.917a1.151 1.151 0 0 1-1.151-1.151 10.737 10.737 0 0 0-21.469 0 1.151 1.151 0 1 1-2.303 0 13.04 13.04 0 0 1 26.074 0 1.151 1.151 0 0 1-1.151 1.151Z"
				fill="#22B573"
			/>
			<path
				d="M44.712 24.673H13.89V20.4a1.151 1.151 0 1 1 2.303 0v1.969H42.41V20.4a1.151 1.151 0 1 1 2.302 0v4.272Z"
				fill="#22B573"
			/>
			<path
				d="M37.585 19.917H30.93a1.151 1.151 0 1 1 0-2.303h4.237a5.976 5.976 0 0 0-11.733 0h3.489a1.151 1.151 0 1 1 0 2.303h-5.924v-1.151a8.285 8.285 0 0 1 16.569 0l.017 1.151Z"
				fill="#22B573"
			/>
			<path
				d="M69.71 21.11H47.13a1.152 1.152 0 0 1 0-2.304h22.58a1.151 1.151 0 1 1 0 2.303Z"
				fill="#224946"
			/>
			<path
				d="M60.205 73.401H35.967l-3.529-3.523 3.53-3.53h24.237a3.528 3.528 0 1 1 0 7.053ZM36.91 71.098h23.294a1.226 1.226 0 1 0 0-2.446h-23.3l-1.22 1.226 1.226 1.22Z"
				fill="#22B573"
			/>
			<path
				d="M34.056 71.03h-3.57a1.151 1.151 0 1 1 0-2.304h3.57a1.151 1.151 0 1 1 0 2.303ZM57.844 42.503a1.152 1.152 0 0 1-1.151-1.152v-2.36a1.151 1.151 0 1 1 2.303 0v2.377a1.15 1.15 0 0 1-1.152 1.135Z"
				fill="#22B573"
			/>
			<path
				d="M25.662 73.401H9.092A7.099 7.099 0 0 1 2 66.308a1.151 1.151 0 0 1 2.303 0 4.796 4.796 0 0 0 4.79 4.79h16.569a1.151 1.151 0 1 1 0 2.303ZM70.901 73.395h-4.755a1.151 1.151 0 1 1 0-2.302h4.755a4.767 4.767 0 0 0 4.796-4.727 1.151 1.151 0 1 1 2.303 0 7.056 7.056 0 0 1-7.099 7.03Z"
				fill="#224946"
			/>
		</svg>
	);
};
