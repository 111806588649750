import { MegaMenuColumn } from '@/components/Shared/Menu/MegaMenuColumn';
import { IMegaMenu } from '@/utils/interfaces/components';
import { Container } from '@/components/Shared/Layout/Container';
import { Icon } from '@/components/Shared/Icon';
import { ChevronRight } from '@/components/Shared/Icons/ChevronRight';
import { Fragment } from 'react';

interface IProps {
	blok: IMegaMenu;
	isActive: boolean;
	handleClose: () => void;
}

export const MegaMenu: React.FC<IProps> = ({ blok, isActive, handleClose }) => {
	return (
		<Fragment>
			<div
				className={`absolute bg-white h-full overflow-y-auto top-0 left-0 right-0 z-20 lg:h-auto lg:top-full py-6 xl:py-12 transition-transform lg:transform-none lg:translate-x-0 lg:border-t lg:border-gray-light lg:shadow-lg ${
					isActive
						? 'lg:block translate-x-0'
						: 'lg:hidden translate-x-full'
				}`}
			>
				<Container>
					<button
						className="flex items-center w-full mb-4 text-xs lg:hidden"
						onClick={handleClose}
					>
						<Icon
							className="-scale-100 text-green"
							icon={<ChevronRight />}
						/>
						<span className="ml-1">Main Menu</span>
					</button>
					<div className="grid-flow-col lg:grid auto-cols-fr gap-x-8 xl:gap-x-16">
						{blok.columns.map((column, key) => (
							<MegaMenuColumn blok={column} key={key} />
						))}
					</div>
				</Container>
			</div>
		</Fragment>
	);
};
