import { resolvedRelations } from '../resolvedRelation';

export function buildParams(locale: string | undefined, startsWith?: string) {
	let params = {
		version: process.env.NODE_ENV === 'development' ? 'draft' : 'published',
		resolve_relations: resolvedRelations.join(','),
	};
	params = {
		...(!!startsWith && {
			starts_with: startsWith,
			is_startpage: false,
			sort_by: 'first_published_at:desc',
			language: locale ? locale : null,
			cv: Math.round(new Date().getTime() / 1000),
		}),
		...params,
	};
	return params;
}

export function buildParamsWithContentType(
	locale: string,
	contentType: string,
	startsWith: string,
) {
	return {
		content_type: contentType,
		starts_with: startsWith,
		is_startpage: false,
		version: process.env.NODE_ENV === 'development' ? 'draft' : 'published',
		resolve_relations: resolvedRelations.join(','),
		language: locale ? locale : null, // Keep search in the current language directory
		cv: Math.round(new Date().getTime() / 1000), // Removes cache
	};
}
