interface IProps {}

export const ChartDark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M30.057 26.775a7.859 7.859 0 1 1 7.859-7.853 7.87 7.87 0 0 1-7.86 7.853Zm0-13.41a5.556 5.556 0 1 0 0 11.113 5.556 5.556 0 0 0 0-11.113ZM14.666 53.37H3.151v23.479h11.515v-23.48Z"
				fill="#22B473"
			/>
			<path
				d="M14.667 77.89H3.152a1.042 1.042 0 0 1-1.043-1.041v-23.48a1.042 1.042 0 0 1 1.043-1.042h11.515a1.042 1.042 0 0 1 1.042 1.042v23.48a1.042 1.042 0 0 1-1.042 1.042Zm-10.473-2.1h9.43V54.41h-9.43V75.79Z"
				fill="#fff"
			/>
			<path d="M35.394 36.39H23.88v40.459h11.515V36.39Z" fill="#22B473" />
			<path
				d="M35.394 77.89H23.879a1.042 1.042 0 0 1-1.043-1.041V36.39a1.042 1.042 0 0 1 1.043-1.048h11.515a1.04 1.04 0 0 1 1.042 1.048v40.459a1.042 1.042 0 0 1-1.042 1.042Zm-10.473-2.1h9.43V37.431h-9.43V75.79Z"
				fill="#fff"
			/>
			<path
				d="M56.121 43.086H44.606V76.85h11.515V43.086Z"
				fill="#22B473"
			/>
			<path
				d="M56.121 77.89H44.606a1.042 1.042 0 0 1-1.042-1.041V43.086a1.042 1.042 0 0 1 1.042-1.042h11.515a1.042 1.042 0 0 1 1.042 1.042V76.85a1.042 1.042 0 0 1-1.042 1.042Zm-10.473-2.1h9.431V44.121h-9.43V75.79Z"
				fill="#fff"
			/>
			<path
				d="M76.849 26.758H65.333v50.09H76.85v-50.09Z"
				fill="#22B473"
			/>
			<path
				d="M76.848 77.89H65.333a1.042 1.042 0 0 1-1.042-1.041V26.758a1.042 1.042 0 0 1 1.042-1.043h11.515a1.042 1.042 0 0 1 1.043 1.043v50.09a1.042 1.042 0 0 1-1.043 1.043Zm-10.473-2.1h9.431V27.8h-9.43v47.99Z"
				fill="#fff"
			/>
			<path
				d="M49.362 32.492a1.151 1.151 0 0 1-.697-.23l-13.104-9.909a1.15 1.15 0 0 1 .524-2.083 1.152 1.152 0 0 1 .863.246l12.293 9.293L69.26 9.151a1.152 1.152 0 1 1 1.652 1.6l-20.727 21.39a1.152 1.152 0 0 1-.823.351ZM3.152 39.056a1.152 1.152 0 0 1-.68-2.079l21.154-15.545a1.152 1.152 0 0 1 1.364 1.854L3.837 38.83a1.151 1.151 0 0 1-.685.225Z"
				fill="#22B473"
			/>
			<path
				d="M73.981 15a1.151 1.151 0 0 1-.812-.34l-7.83-7.83a1.152 1.152 0 0 1 .518-1.928L76.55 2.023a1.152 1.152 0 0 1 1.41 1.41l-2.878 10.693a1.152 1.152 0 0 1-.818.817 1.29 1.29 0 0 1-.282.058ZM68.38 6.613l5.01 5.009 1.83-6.84-6.84 1.83Z"
				fill="#22B473"
			/>
		</svg>
	);
};
