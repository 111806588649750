import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from 'next-share';
import { useRouter } from 'next/router';
import { Icon } from './Icon';
import { Facebook } from './Icons/Social/Facebook';
import { LinkedIn } from './Icons/Social/Linkedin';
import { Twitter } from './Icons/Social/Twitter';

interface IProps {
	label?: string;
}

export const ShareButtonsWithLabel: React.FC<IProps> = ({ label }) => {
	const { asPath, locale } = useRouter();
	const origin =
		typeof window !== 'undefined' && window.location.origin
			? window.location.origin
			: '';

	const URL = `${origin}/${locale}${asPath}`;

	return (
		<div className="flex items-center w-full">
			{label && <span className="mr-4 text-sm">{label}</span>}

			<div className="flex space-x-3" style={{ fontSize: '0px' }}>
				<FacebookShareButton url={URL}>
					<Icon className="w-8 h-8" icon={<Facebook />} />
				</FacebookShareButton>
				<TwitterShareButton url={URL}>
					<Icon className="w-8 h-8" icon={<Twitter />} />
				</TwitterShareButton>
				<LinkedinShareButton url={URL}>
					<Icon className="w-8 h-8" icon={<LinkedIn />} />
				</LinkedinShareButton>
			</div>
		</div>
	);
};
