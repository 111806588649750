import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { ICollectionApiResponse } from '@/utils/interfaces/stories';
import { createContext } from 'react';
import StoryblokClient, { StoryblokResult } from 'storyblok-js-client';

interface IProps {}

export type IContextProps = {
	storyblokClient: StoryblokClient | null;
	fetchData: (params: {}) => Promise<any>;
	fetchDataByParams: (params: {}) => Promise<any>;
};

export const StoryblokContext = createContext<IContextProps>({
	storyblokClient: null,
	fetchData: async (params: {}) => {},
	fetchDataByParams: async (params: {}) => {},
});

export const StoryblokClientProvider: React.FC<IProps> = ({ children }) => {
	const storyblokClient = new StoryblokClient(
		{
			accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN,
		},
		'https://api.storyblok.com/v2/',
	);

	async function fetchData(params: {}): Promise<StoryblokResult> {
		return new Promise<StoryblokResult>(async (resolve, reject) => {
			if (storyblokClient) {
				const response = await storyblokClient.get(
					`cdn/stories/`,
					params,
				);

				if (response) {
					resolve(response);
				} else {
					reject(response);
				}
			}
		});
	}

	async function fetchDataByParams(params: {}) {
		return new Promise(async (resolve, reject) => {
			const { data } = await fetchData(params);

			if (data && data.stories && isArrayWithValue(data.stories)) {
				resolve(data.stories);
			}

			reject(data);
		});
	}

	return (
		<StoryblokContext.Provider
			value={{ storyblokClient, fetchDataByParams, fetchData }}
		>
			{children}
		</StoryblokContext.Provider>
	);
};
