import { ISbFormValidations } from '../interfaces/components';
import { isArrayWithValue } from './isArrayWithValue';

export function isFieldRequired(validations: ISbFormValidations[]) {
	let isRequired = false;
	if (!isArrayWithValue(validations)) {
		return isRequired;
	}

	for (let validation of validations) {
		if (validation.component === 'required') {
			isRequired = true;
		}
	}

	return isRequired;
}
