import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import Cookies from 'js-cookie';
import { find, propEq } from 'ramda';
import { Icon } from '../Shared/Icon';
import { AU } from '../Shared/Icons/County/AU';
import { NZ } from '../Shared/Icons/County/NZ';
import { US } from '../Shared/Icons/County/US';

interface IProps {}

export type ILanguage = {
	code: string;
	label: string;
	icon: JSX.Element;
	locale: string;
	og_locale: string;
};

type ILanguageProvider = {
	currentLanguage: ILanguage | null;
	setCurrentLanguage: Dispatch<SetStateAction<ILanguage | null>>;
};

export const LANG_OPTIONS = [
	{
		code: 'us',
		label: 'US',
		icon: <Icon className="h-6 w-9" icon={<US />} />,
		locale: 'en-us',
		og_locale: 'en_US',
	},
	{
		code: 'au',
		label: 'AU',
		icon: <Icon className="h-6 w-9" icon={<AU />} />,
		locale: 'en-au',
		og_locale: 'en_GB',
	},
	{
		code: 'nz',
		label: 'NZ',
		icon: <Icon className="h-6 w-9" icon={<NZ />} />,
		locale: 'en-nz',
		og_locale: 'en_GB',
	},
];

const COOKIE_KEY = 'language_preference';

export const LANGUAGE_DEFAULT = LANG_OPTIONS[0];

const LanguageContext = createContext<ILanguageProvider>({
	currentLanguage: LANGUAGE_DEFAULT,
	setCurrentLanguage: () => {},
});

export function useLanguage() {
	return useContext(LanguageContext);
}

export const LanguageClientProvider: React.FC<IProps> = ({ children }) => {
	const [currentLanguage, setCurrentLanguage] = useState<ILanguage | null>(
		getDataFromCookie(COOKIE_KEY),
	);

	useEffect(() => {
		if (currentLanguage) {
			Cookies.set(COOKIE_KEY, JSON.stringify(currentLanguage));
		}
	}, [currentLanguage]);

	return (
		<LanguageContext.Provider
			value={{ currentLanguage, setCurrentLanguage }}
		>
			{children}
		</LanguageContext.Provider>
	);
};
function getDataFromCookie(key: string): ILanguage | null {
	const cookie = Cookies.get(key);

	if (cookie) {
		const data = JSON.parse(cookie);
		const { code } = data;
		const result = find<ILanguage>(propEq('code', code))(LANG_OPTIONS);
		return result ? result : LANG_OPTIONS[0];
	} else {
		return LANG_OPTIONS[0];
	}

	return null;
}
