interface IProps {}

export const ToolsDark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M73.956 60.947 51.044 38.035l-6.64.058.052-6.645-1.311-1.312L29.97 43.311l1.312 1.312 6.644-.052-.057 6.639 22.913 22.912a9.32 9.32 0 1 0 13.174-13.175Zm-7.55 5.626a2.984 2.984 0 0 1-4.215 0L43.912 48.289a2.978 2.978 0 1 1 4.21-4.21l18.284 18.278a2.984 2.984 0 0 1 0 4.216Z"
				fill="#22B573"
			/>
			<path
				d="M67.369 78a10.432 10.432 0 0 1-7.401-3.059L36.74 51.708l.045-5.957-5.957.052-2.463-2.464 14.801-14.795L45.631 31l-.052 5.957 5.957-.045L74.77 60.14A10.46 10.46 0 0 1 67.37 78ZM39.038 50.769 61.589 73.32a8.174 8.174 0 1 0 11.56-11.56L50.596 39.21l-7.326.063.057-7.332-.16-.16-11.56 11.553.16.16 7.333-.057-.063 7.332Zm25.26 17.825a4.102 4.102 0 0 1-2.915-1.208L43.099 49.108a4.13 4.13 0 0 1 5.837-5.837l18.278 18.284a4.119 4.119 0 0 1 0 5.83 4.102 4.102 0 0 1-2.916 1.21ZM46.02 44.36a1.833 1.833 0 0 0-1.3 3.127l18.284 18.279a1.873 1.873 0 0 0 2.589 0 1.827 1.827 0 0 0 0-2.59L47.315 44.893a1.833 1.833 0 0 0-1.295-.539v.006Z"
				fill="#fff"
			/>
			<path
				d="M34.805 40.143 4.337 9.498 2 4.291 4.291 2l5.207 2.343.149.149L39.96 34.988l-5.155 5.155ZM6.262 8.175l28.548 28.72 1.925-1.924L8.181 6.245 4.8 4.727l-.063.063 1.524 3.385Z"
				fill="#fff"
			/>
			<path
				d="M16.463 76.654c-.638 0-1.275-.046-1.907-.138l-.378-.057-1.908-1.896 4.623-4.622-.676-6.107-6.106-.675-4.623 4.622-1.901-1.907-.058-.378a12.9 12.9 0 0 1 3.672-11.067 13.032 13.032 0 0 1 13.255-3.173l6.908-6.914a1.146 1.146 0 1 1 1.62 1.62l-7.955 7.98-.7-.275A10.723 10.723 0 0 0 5.707 64.344l3.563-3.563 9.022 1.003 1.002 9.021-3.563 3.563a10.723 10.723 0 0 0 10.677-14.623l-.275-.7 7.957-7.967a1.145 1.145 0 0 1 1.956.81 1.146 1.146 0 0 1-.335.811l-6.914 6.908a13.032 13.032 0 0 1-3.174 13.238 12.87 12.87 0 0 1-9.159 3.809ZM51.702 36.185a1.145 1.145 0 0 1-1.056-.704 1.147 1.147 0 0 1 .248-1.25l7.957-7.955.699.274a10.717 10.717 0 0 0 14.623-10.677l-3.563 3.563-9.021-1.002-1.003-9.022L64.15 5.85a10.717 10.717 0 0 0-10.677 14.624l.275.699-7.956 7.956a1.147 1.147 0 0 1-1.621-1.62l6.913-6.915A13.014 13.014 0 0 1 65.33 3.673l.373.051 1.907 1.908-4.623 4.622.676 6.106 6.106.676 4.623-4.622 1.907 1.907.052.372A13.014 13.014 0 0 1 59.43 28.94l-6.914 6.937c-.22.206-.513.317-.814.31Z"
				fill="#22B573"
			/>
		</svg>
	);
};
