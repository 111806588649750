import { handleEmailClick } from '@/utils/handleMailto';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { ILocationsPage, IStoryBase } from '@/utils/interfaces/stories';
import { CTA } from '../CTA/CallToAction';
import { Icon } from '../Icon';
import { Home } from '../Icons/Home';

interface IProps {
	location: IStoryBase<ILocationsPage>;
}

export const SbInfoBox: React.FC<IProps> = ({ location }) => {
	const { address, phone_number, email, cta } = location.content;

	const classes = [
		'relative',
		'inline-block',
		'text-green-dark',
		'font-medium',
		'after:w-full',
		'after:h-0.5',
		'after:scale-x-100',
		'after:bg-gradient-to-r',
		'after:from-green',
		'to-[#1BD0BC]',
		'after:origin-left',
		'after:left-0',
		'after:bottom-[-2px]',
		'after:absolute',
		'after:transition-all',
		'mt-3',
		'text-base',
	];

	return (
		<div className="font-sans bg-white shadow-hard">
			<div className="flex items-center px-6 py-4 border-b border-gray-light">
				<span className="w-5 h-5 mr-4">
					<Icon className="w-5 h-5 text-green" icon={<Home />} />
				</span>
				<div>
					<span className="text-lg text-green-dark">
						{location.name}
					</span>
				</div>
			</div>
			<div className="px-6 py-6">
				<span className="block text-base whitespace-pre-wrap">
					{address}
				</span>

				{isStringWithValue(phone_number) && (
					<div className="block">
						<a
							href={`tel:${phone_number}`}
							className={classes.join(' ')}
							rel="noreferrer"
						>
							<span>{phone_number}</span>
						</a>
					</div>
				)}

				<div>
					<button
						type="button"
						onClick={() => handleEmailClick(email)}
						className={classes.join(' ')}
					>
						<span>Email</span>
					</button>
				</div>

				{cta && isArrayWithValue(cta) && (
					<div className="mt-8">
						{cta.map((cta, key) => {
							return <CTA blok={cta} key={key} />;
						})}
					</div>
				)}
			</div>
		</div>
	);
};
