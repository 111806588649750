import { mapLocalToCountry } from '@/utils/helpers/mapLocalToCountry';
import { ISelfSubscribe } from '@/utils/interfaces/bloks';
import {
	IAddonsResponse,
	IPlansResponse,
	ISearchFormValue,
	TAddon,
	TBillingCycle,
} from '@/utils/interfaces/cibApiResponse';
import { useRouter } from 'next/router';
import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { AlertProvider } from '../Providers/AlertProvider';
import { Alert } from './Shared/Alert';
import { ProgressBar } from './Shared/ProgessBar';
import { FormComponent } from './Steps/FormComponent';

export const BASE_URL = process.env.NEXT_PUBLIC_CIB_BASE_URL as string;

type Event = 'NEXT' | 'PREVIOUS' | 'DETOUR';

interface IProps {
	blok: ISelfSubscribe;
	preview: boolean;
}

export type TFormStatePlan = {
	searchesPlan: string | null;
	planId: string | null;
	country: string;
	addons: TAddon[];
	billingCycle: TBillingCycle;
	discount: number | null;
	cost: number;
};

type MachineStateConfig = {
	on: {
		NEXT?: MachineStateAction;
		PREVIOUS?: MachineStateAction;
		DETOUR?: MachineStateAction;
	};
	progress: number;
};

type MachineConfig = {
	id: string;
	initial: string;
	states: {
		[key: string]: MachineStateConfig;
	};
};

type MachineStateAction = {
	target: string;
};

type TData = {
	planData?: {
		searches: string;
		planId: string;
		country: string;
		addons: TAddon[];
		billingCycle: TBillingCycle;
		discount: number;
		cost: number;
	};
	userData?: {
		firstName: string;
		lastName: string;
		email: string;
		phone: string;
		password: string;
		passwordConfirm: string;
	};
	verifyCode?: string;
	subscriptionKey?: string;
	sessionId?: string;
	name?: string;
	age?: number;
};

type TEventData = {
	event: Event;
	data: TData;
};

export interface IFormStepProps {
	blok: ISelfSubscribe;
	formData: TData;
	send: React.Dispatch<TEventData>;
	isLoading: boolean;
	nextEnabled: boolean;
	previousEnabled: boolean;
	country: string;
	currentState: string;
	monthlyData: ISearchFormValue[];
	annuallyData: ISearchFormValue[];
	searchesPlan: ISearchFormValue | null | undefined;
	setSearchesPlan: React.Dispatch<ISearchFormValue>;
	searchPlansLoading: boolean;
	addOnsData: TAddon[];
	addOnsLoading: boolean;
	billingCycle: TBillingCycle;
	setBillingCycle: React.Dispatch<TBillingCycle>;
}

function useMachine<Config extends MachineConfig>(config: Config) {
	type State = keyof Config['states'];

	type MachineState = {
		current: State;
		progress: number;
		nextEvents: Event[];
		data: TData | {};
	};

	const initialState: MachineState = {
		current: config.initial,
		progress: config.states[config.initial].progress,
		nextEvents: Object.keys(config.states[config.initial].on) as Event[],
		data: {},
	};

	const [machineState, send] = useReducer(
		(state: MachineState, event: TEventData) => {
			const currentStateNode = config.states[state.current as string];
			const nextState = currentStateNode.on[event.event]
				?.target as string;
			const nextStateProgress = config.states[nextState].progress;

			if (!nextState) return state;

			return {
				current: nextState,
				progress: nextStateProgress,
				nextEvents: Object.keys(config.states[nextState].on) as Event[],
				data: { ...state.data, ...event.data },
			};
		},
		initialState,
	);

	return [machineState, send] as [MachineState, React.Dispatch<TEventData>];
}

export const SelfSubscribeContainer: React.FC<IProps> = ({ blok }) => {
	const { locale } = useRouter();
	const [country] = useState<string>(mapLocalToCountry(locale as string));
	const [isLoading] = useState<boolean>(false);
	const [billingCycle, setBillingCycle] = useState<TBillingCycle>('monthly');
	const [searchesPlan, setSearchesPlan] = useState<ISearchFormValue | null>();
	const [monthlyData, setMonthlyData] = useState<ISearchFormValue[]>([]);
	const [annuallyData, setAnnuallyData] = useState<ISearchFormValue[]>([]);
	const [addOnsData, setAddOnsData] = useState<TAddon[]>([]);
	const [currentMachine, send] = useMachine({
		id: 'form',
		initial: 'buildPlan',
		states: {
			buildPlan: {
				on: {
					NEXT: {
						target: 'createAccount',
					},
					DETOUR: {
						target: 'customForm',
					},
				},
				progress: 14,
			},
			customForm: {
				on: {
					PREVIOUS: {
						target: 'buildPlan',
					},
					NEXT: {
						target: 'customFormSuccess',
					},
				},
				progress: 60,
			},
			createAccount: {
				on: {
					PREVIOUS: {
						target: 'buildPlan',
					},
					NEXT: {
						target: 'verifyAccount',
					},
				},
				progress: 28,
			},
			verifyAccount: {
				on: {
					PREVIOUS: {
						target: 'createAccount',
					},
					NEXT: {
						target: 'checkout',
					},
				},
				progress: 42,
			},
			checkout: {
				on: {
					NEXT: {
						target: 'updateAccount',
					},
				},
				progress: 56,
			},
			updateAccount: {
				on: {
					PREVIOUS: {
						target: 'checkout',
					},
					NEXT: {
						target: 'trainingSession',
					},
				},
				progress: 70,
			},
			trainingSession: {
				on: {
					PREVIOUS: {
						target: 'updateAccount',
					},
					NEXT: {
						target: 'complete',
					},
				},
				progress: 84,
			},
			customFormSuccess: {
				on: {},
				progress: 100,
			},
			complete: {
				on: {
					PREVIOUS: {
						target: 'trainingSession',
					},
				},
				progress: 100,
			},
		},
	});

	const { isFetching: monthlyDataFetching } = useQuery<IPlansResponse>({
		queryKey: 'monthly',
		queryFn: () => {
			return fetch(
				`${BASE_URL}/plans?country=${country}&billingCycle=monthly`,
			).then((res) => res.json());
		},
		onSuccess({ data }) {
			setMonthlyData(data);
		},
	});

	const { isFetching: annuallyDataFetching } = useQuery<IPlansResponse>({
		queryKey: 'annually',
		queryFn: () => {
			return fetch(
				`${BASE_URL}/plans?country=${country}&billingCycle=annually`,
			).then((res) => res.json());
		},
		onSuccess({ data }) {
			setAnnuallyData(data);
		},
	});

	const { isFetching: addOnsFetching } = useQuery<IAddonsResponse>({
		queryKey: ['addons', searchesPlan],

		queryFn: () => {
			return fetch(
				`${BASE_URL}/addons?country=${country}&billingCycle=${billingCycle}&searches=${searchesPlan?.searches}`,
			).then((res) => res.json());
		},
		onSuccess({ data }) {
			setAddOnsData(data);
		},
	});

	return (
		<AlertProvider>
			<Alert />

			<div className="self-subscribe">
				<div className="self-subscribe__progress">
					<ProgressBar progress={currentMachine.progress} />
				</div>

				<div className="self-subscribe__container">
					<FormComponent
						blok={blok}
						country={country}
						isLoading={isLoading}
						currentState={currentMachine.current}
						monthlyData={monthlyData}
						annuallyData={annuallyData}
						searchesPlan={searchesPlan}
						setSearchesPlan={setSearchesPlan}
						searchPlansLoading={
							monthlyDataFetching || annuallyDataFetching
						}
						billingCycle={billingCycle}
						setBillingCycle={setBillingCycle}
						addOnsLoading={addOnsFetching}
						addOnsData={addOnsData}
						formData={currentMachine.data}
						send={send}
						nextEnabled={
							currentMachine.nextEvents.indexOf('NEXT') > -1
						}
						previousEnabled={
							currentMachine.nextEvents.indexOf('PREVIOUS') > -1
						}
					/>
				</div>
			</div>
		</AlertProvider>
	);
};
