import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { IFeatureTabs } from '@/utils/interfaces/bloks';
import { Fragment } from 'react';
import { FeatureTabsDesktop } from '../Shared/FeatureTabs/FeatureTabs';
import { FeatureTabsAccordion } from '../Shared/FeatureTabs/FeatureTabsAccordion';
import { FormattedText } from '../Shared/FormattedText';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';

interface IProps {
	blok: IFeatureTabs;
	preview: boolean;
}

export const FeatureTabs: React.FC<IProps> = ({ blok, preview }) => {
	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative bg-white py-14 md:py-32"
		>
			<Container align="center">
				<div className="mb-10 md:mb-16">
					<FormattedText tag="h2" text={blok.heading} />
				</div>

				{isArrayWithValue(blok.tabs) && (
					<Fragment>
						<div className="block md:hidden">
							<FeatureTabsAccordion
								cta={blok.view_all_cta}
								ctaLabel={blok.cta_label}
								tabs={blok.tabs}
							/>
						</div>

						<div className="hidden md:block">
							<FeatureTabsDesktop
								tabs={blok.tabs}
								cta={blok.view_all_cta}
								ctaLabel={blok.cta_label}
								preview={preview}
							/>
						</div>
					</Fragment>
				)}
			</Container>
		</SbSection>
	);
};
