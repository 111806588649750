import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { ILocationGroup } from '@/utils/interfaces/components';
import { LocationBlock } from './LocationBlock';

interface IProps {
	blok: ILocationGroup;
}

export const LocationGroup: React.FC<IProps> = ({ blok }) => {
	return (
		<div>
			<div className="block pb-5 mb-5 border-b lg:mb-12 lg:pb-6 border-gray">
				<h4>{blok.heading}</h4>
			</div>

			{blok.locations && isArrayWithValue(blok.locations) && (
				<div className="grid grid-cols-1 sm:gap-8 sm:grid-cols-2 md:grid-cols-3 md:gap-13 lg:grid-cols-4">
					{blok.locations.map((location, key) => {
						return (
							<div className="mb-7" key={key}>
								<LocationBlock location={location} />
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
