interface IProps {
    className: string;
}

export const Bottom: React.FC<IProps> = ({className}) => {
    return (

        <svg
            viewBox="0 0 343 165"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M-325.213 417.769L-361 395.427L-86.0481 -48.9694L-50.1544 -26.6268C16.5749 14.8286 93.5271 36.7927 172.038 36.7928C250.549 36.7928 327.501 14.8286 394.231 -26.6267L430.14 -49L705 395.427L669.228 417.769C519.975 510.733 347.747 560 172.015 560C-3.71669 560 -175.944 510.733 -325.198 417.769L-325.213 417.769Z"
                fill="url(#c)"/>
            <defs>
                <linearGradient
                    id="c"
                    x1="172"
                    y1="-49"
                    x2="172"
                    y2="560"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#22B573"/>
                    <stop offset="1" stopColor="#1BD0BC"/>
                </linearGradient>
            </defs>
        </svg>


    );
};
