import { Caption } from '@/components/Shared/Caption';
import { PlayButton } from '@/components/Shared/CTA/Play';
import { WysiwygContainer } from '@/components/Shared/Layout/WysiwygContainer';
import { Modal } from '@/components/Shared/Modal/BaseModal';
import { IWysiwygVideo } from '@/utils/interfaces/bloks';
import { useState } from 'react';

interface IProps {
	blok: IWysiwygVideo;
}

export const WysiwygVideo: React.FC<IProps> = ({ blok }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	return (
		<WysiwygContainer narrow={false}>
			<figure className="inline-block text-none">
				<div className="relative">
					<video className="pointer-events-none">
						<source src={blok.video.filename} />
					</video>

					<PlayButton onClick={() => setIsModalOpen(true)} />
				</div>

				{blok.caption && <Caption caption={blok.caption} />}
			</figure>

			<Modal
				active={isModalOpen}
				type="video_modal"
				onClose={() => setIsModalOpen(false)}
			>
				<div className="relative">
					<video controls autoPlay className="w-full">
						<source src={blok.video.filename} />
					</video>
				</div>
			</Modal>
		</WysiwygContainer>
	);
};
