interface IProps {
	strokeColor: string;
}

export const Left: React.FC<IProps> = ({ strokeColor }) => {
	return (
		<svg
			viewBox="0 0 700 472"
			className="w-full h-full"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M-82.37 291.515c-5.543-3.043-11.298-4.328-15.595-3.388-8.822 1.911-13.533 6.531-16.029 13.317-2.191 5.179-3.306 11.261-3.644 17.289-.281 4.917-.623 9.646-1.318 14.084a37.428 37.428 0 0 1-.264 4.852 22.44 22.44 0 0 0-.03 6.413c.899 6.269 3.168 12.201 7.056 18.385a28.711 28.711 0 0 0 5.855 5.476 18.277 18.277 0 0 0 4.412 2.436c6.619 2.594 12.652 6.672 18.458 11.925 4.899 4.389 11.271 6.641 17.72 6.262a44.893 44.893 0 0 0 7.957-1.111 59.307 59.307 0 0 0 9.468-2.594 39.236 39.236 0 0 0 16.171-1.373c7.146-2.339 14.8-7.204 17.577-14.806 7.906-7.943 10.722-17.737 8.37-29.003a23.876 23.876 0 0 0-1.033-3.542c-2.157-5.897-6.648-10.403-12.928-13.637-2.613-1.305-5.312-2.394-7.989-3.491a25.32 25.32 0 0 1-7.138-4.202 27.183 27.183 0 0 0-10.901-5.596 319.076 319.076 0 0 0-5.044-1.266 788.355 788.355 0 0 0-5.096-1.216 28.34 28.34 0 0 1-12.878-6.631 46.11 46.11 0 0 0-13.157-8.583Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-93.365 269.271c-6.764-3.193-13.613-4.392-18.91-3.143-10.116 2.327-16.018 7.829-19.561 15.999-3.456 6.951-5.452 15.365-6.227 23.812-.6 6.806-1.006 13.516-1.37 19.714.411 2.239.677 4.513.872 6.764a34.09 34.09 0 0 0 1.632 8.837 380.488 380.488 0 0 0 9.72 25.985 33.928 33.928 0 0 0 5.979 7.744 23.107 23.107 0 0 0 4.834 3.818c7.324 4.329 14.808 9.549 22.694 15.208 7.157 4.84 15.599 7.257 24.107 6.9 3.574-.089 7.105-.326 10.619-.675 4.567-.363 9.125-.619 12.848-.829 8.31 2.894 17.514 4.383 27.491 2.133 12.016-2.814 24.809-11.01 27.744-24.711 7.764-11.284 9.02-23.517 4.165-36.505a30.95 30.95 0 0 0-1.876-4.075c-3.563-6.73-9.625-11.724-17.334-15.629-3.817-1.868-7.776-3.379-11.666-5.01-3.89-1.63-7.322-3.916-9.81-7.072-3.822-4.809-8.812-8.259-14.608-10.592a105.044 105.044 0 0 0-7.176-2.547c-2.48-.785-4.9-1.5-7.346-2.123-6.958-1.665-13.272-5.49-18.068-10.946-5.05-5.771-11.118-10.531-18.754-13.057Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-104.359 247.027c-7.986-3.349-15.941-4.445-22.245-2.886-11.4 2.735-18.501 9.114-23.088 18.673-4.724 8.66-7.619 19.444-8.816 30.338a257.258 257.258 0 0 0-1.431 25.341c.806 2.866 1.435 5.79 2.014 8.673a47.442 47.442 0 0 0 3.306 11.257 289.18 289.18 0 0 1 12.376 33.562 40.612 40.612 0 0 0 6.091 9.971 28.991 28.991 0 0 0 5.246 5.196 326.876 326.876 0 0 0 26.942 18.487c9.44 5.441 19.85 7.731 30.492 7.547 4.51-.054 8.925-.153 13.286-.242 5.418-.034 10.838.279 16.228.936 11.73 5.28 24.718 8.305 38.8 5.643 16.882-3.3 34.827-14.825 37.92-34.625 7.621-14.626 7.304-29.3-.045-44A39.647 39.647 0 0 0 30 336.277c-4.992-7.556-12.616-13.087-21.745-17.62-5.02-2.425-10.244-4.357-15.362-6.523-5.247-2.234-9.496-5.364-12.473-9.939-4.562-6.953-10.86-12.012-18.326-15.583a105.392 105.392 0 0 0-9.309-3.828c-3.244-1.156-6.384-2.185-9.606-3.064-9.558-2.589-17.25-7.599-23.262-15.268-6.216-8.041-14.056-14.465-24.277-17.425Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-115.363 224.78c-9.2-3.496-18.23-4.511-25.58-2.623-12.67 3.144-20.982 10.404-26.615 21.347-6.029 10.445-9.767 23.542-11.396 36.867a215.807 215.807 0 0 0-1.484 30.972c1.198 3.488 2.186 7.045 3.151 10.609a62.593 62.593 0 0 0 4.982 13.684c6.707 12.931 11.445 26.177 15.032 41.139a47.674 47.674 0 0 0 6.208 12.196 35.192 35.192 0 0 0 5.649 6.558 162.494 162.494 0 0 0 31.191 21.772c11.519 5.866 24.122 8.273 36.868 8.189 5.448-.017 10.745.021 15.959.19a104.473 104.473 0 0 1 19.61 2.707c15.148 7.66 31.915 12.223 50.112 9.145 21.743-3.785 44.842-18.633 48.087-44.535 7.478-17.969 5.592-35.084-4.258-51.501a49.853 49.853 0 0 0-3.575-5.149c-6.382-8.394-15.604-14.423-26.159-19.616-6.22-2.976-12.705-5.335-19.01-8.051-6.505-2.786-11.679-6.816-15.141-12.797-5.31-9.101-12.888-15.785-22.023-20.601a106.196 106.196 0 0 0-11.446-5.107c-3.888-1.47-7.866-2.865-11.856-3.991-11.854-3.312-21.252-9.723-28.407-19.598-7.463-10.311-17.089-18.4-29.899-21.806Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-126.36 202.535c-10.421-3.645-20.544-4.574-28.902-2.382-13.952 3.557-23.469 11.697-30.141 24.027-7.272 12.19-11.93 27.632-13.988 43.38a210.516 210.516 0 0 0-1.543 36.605c1.589 4.104 2.95 8.321 4.281 12.484 1.742 5.651 3.79 11.157 6.65 16.106 8.645 15.155 14.207 30.842 17.688 48.716a55.489 55.489 0 0 0 6.336 14.469 41.435 41.435 0 0 0 6.069 7.941c10.371 10.329 22.356 18.812 35.445 25.088 13.599 6.297 28.376 8.821 43.251 8.835 6.386.018 12.566.195 18.623.631 7.79.599 15.502 2.097 22.99 4.466 18.57 10.038 39.125 16.149 61.452 12.644 26.61-4.272 54.841-22.475 58.26-54.442 7.33-21.308 3.881-40.874-8.466-59.009a59.61 59.61 0 0 0-4.378-5.681c-7.78-9.224-18.581-15.765-30.563-21.602-7.454-3.53-15.17-6.321-22.706-9.565-7.771-3.348-13.86-8.269-17.817-15.665-6.043-11.248-14.913-19.546-25.72-25.587a117.064 117.064 0 0 0-13.579-6.388c-4.622-1.825-9.35-3.55-14.11-4.915-14.145-4.037-25.255-11.848-33.575-23.934-8.768-12.645-20.161-22.381-35.557-26.222Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-137.35 180.322c-11.631-3.805-22.851-4.641-32.249-2.121-15.23 3.962-25.979 12.997-33.676 26.697-8.537 13.944-14.078 31.731-16.565 49.922a215.263 215.263 0 0 0-1.596 42.235c1.982 4.725 3.699 9.589 5.417 14.395 2.253 6.499 4.832 12.84 8.321 18.534 10.574 17.376 16.965 35.501 20.315 56.302A63.51 63.51 0 0 0-180.93 403a48.383 48.383 0 0 0 6.48 9.313c10.15 11.249 23.441 21.029 39.681 28.332 15.679 6.723 32.665 9.357 49.633 9.476 7.324.054 14.387.368 21.29 1.064a106.066 106.066 0 0 1 26.374 6.243c21.984 12.42 46.328 20.064 72.737 16.156 31.477-4.76 64.871-26.256 68.432-64.355 7.187-24.65 2.179-46.629-12.673-66.512a74.095 74.095 0 0 0-5.234-6.235c-9.19-10.049-21.57-17.102-34.974-23.593-8.634-4.094-17.639-7.303-26.384-11.083-9.031-3.906-16.042-9.721-20.483-18.518-6.785-13.398-16.952-23.315-29.421-30.584a125.837 125.837 0 0 0-15.736-7.668 164.64 164.64 0 0 0-16.36-5.84c-16.435-4.762-29.25-13.974-38.733-28.248-9.889-14.955-23.074-26.375-41.049-30.626Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-148.356 158.055c-12.852-3.955-25.163-4.699-35.585-1.864-16.506 4.374-28.437 14.28-37.201 29.378-9.811 15.692-16.242 35.82-19.147 56.444a223.989 223.989 0 0 0-1.661 47.87c2.378 5.34 4.461 10.86 6.549 16.294 2.765 7.354 5.859 14.535 10.006 20.951 12.511 19.601 19.726 40.166 23.002 63.876a71.521 71.521 0 0 0 6.575 18.956 55.172 55.172 0 0 0 6.884 10.682c10.858 12.969 25.602 23.898 43.921 31.607 17.756 7.123 36.902 9.911 56.017 10.122 7.995.048 15.989.54 23.945 1.476a112.722 112.722 0 0 1 29.746 8.004c25.41 14.804 53.532 23.986 84.056 19.662 36.344-5.246 74.886-30.064 78.603-74.267 7.039-27.991.471-52.447-16.887-74.019a84.408 84.408 0 0 0-6.075-6.767c-10.597-10.883-24.547-18.443-39.382-25.591-9.835-4.646-20.105-8.295-30.061-12.603-10.28-4.467-18.223-11.167-23.155-21.388-7.526-15.548-18.988-27.072-33.128-35.579a138.178 138.178 0 0 0-17.857-8.953 178.469 178.469 0 0 0-18.592-6.771c-18.728-5.512-33.248-16.106-43.902-32.571-11.155-17.186-26.131-30.231-46.671-34.949Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-159.364 135.79c-14.067-4.108-27.471-4.765-38.943-1.599-17.792 4.775-30.92 15.564-40.719 32.081-11.082 17.446-18.426 39.924-21.736 62.969-2.619 18.209-3.212 36.679-1.719 53.503 2.765 5.963 5.227 12.122 7.682 18.18 3.279 8.208 6.89 16.222 11.671 23.381 14.431 21.792 22.486 44.824 25.65 71.449a80.005 80.005 0 0 0 6.697 21.212 62.019 62.019 0 0 0 7.303 12.058c11.564 14.704 27.751 26.771 48.164 34.894 19.845 7.577 41.174 10.492 62.398 10.757 8.898.083 17.79.73 26.627 1.936a120.4 120.4 0 0 1 33.165 9.77c28.829 17.19 60.736 27.908 95.37 23.17 41.206-5.725 84.893-33.875 88.768-84.177 6.889-31.356-1.256-58.226-21.089-81.51a98.571 98.571 0 0 0-6.916-7.314c-12.018-11.704-27.53-19.782-43.793-27.581-11.037-5.203-22.557-9.264-33.743-14.114-11.55-5.022-20.407-12.626-25.82-24.26-8.266-17.692-21.026-30.835-36.83-40.576a150.761 150.761 0 0 0-19.995-10.232 194.385 194.385 0 0 0-20.87-7.689c-21.008-6.207-37.242-18.207-49.066-36.901-12.369-19.531-29.103-34.231-52.256-39.407Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-170.371 113.562c-15.29-4.263-29.785-4.829-42.253-1.356-19.085 5.192-33.405 16.863-44.261 34.727-12.347 19.2-20.553 44.01-24.316 69.499-2.955 20.11-3.582 40.538-1.772 59.133 3.156 6.586 5.97 13.393 8.821 20.104 3.783 9.057 7.923 17.914 13.339 25.804 16.38 24.051 25.247 49.488 28.308 79.03a88.567 88.567 0 0 0 6.814 23.458 69.377 69.377 0 0 0 7.716 13.435c12.269 16.434 29.918 29.658 52.41 38.167 21.932 8.008 45.438 11.05 68.782 11.403 9.796.111 19.583.903 29.294 2.371a128.891 128.891 0 0 1 36.527 11.534c32.23 19.576 67.944 31.823 106.687 26.671 46.066-6.217 94.909-37.684 98.953-94.088 6.746-34.679-2.969-64.016-25.303-89.016a110.198 110.198 0 0 0-7.752-7.849c-13.414-12.541-30.52-21.125-48.205-29.571-12.241-5.766-25.032-10.246-37.422-15.639-12.816-5.578-22.59-14.078-28.489-27.119-9.012-19.84-23.057-34.6-40.54-45.583a163.248 163.248 0 0 0-22.127-11.513 210.759 210.759 0 0 0-23.115-8.603c-23.305-6.93-41.252-20.354-54.235-41.223-13.597-21.82-32.096-38.181-57.861-43.776Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-181.358 91.309c-16.504-4.416-32.091-4.89-45.581-1.096-20.346 5.592-35.888 18.148-47.789 37.402-13.62 20.949-22.714 48.106-26.906 76.019-3.288 22.016-3.946 44.407-1.831 64.765 3.551 7.213 6.733 14.663 9.963 22.013 4.296 9.912 8.936 19.607 15.009 28.232 18.316 26.27 28.004 54.142 30.964 86.608a97.156 97.156 0 0 0 6.932 25.701 75.947 75.947 0 0 0 8.131 14.8c12.975 18.169 32.058 32.522 56.654 41.455 24.01 8.432 49.701 11.607 75.158 12.045a215.716 215.716 0 0 1 31.967 2.802 137.983 137.983 0 0 1 39.89 13.305c35.667 21.956 75.144 35.752 117.995 30.18 50.938-6.705 104.922-41.497 109.119-103.998 6.605-38.015-4.685-69.798-29.515-96.517a124.127 124.127 0 0 0-8.596-8.388c-14.812-13.37-33.497-22.465-52.611-31.563-13.45-6.321-27.494-11.225-41.098-17.152-14.078-6.141-24.771-15.53-31.166-29.987-9.718-21.996-25.085-38.353-44.233-50.576a176.18 176.18 0 0 0-24.265-12.792 226.996 226.996 0 0 0-25.376-9.544c-25.593-7.65-45.248-22.481-59.402-45.541-14.8-24.11-35.06-42.138-63.413-48.173Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-192.356 69.065c-17.72-4.548-34.376-4.962-48.919-.843-21.628 6.005-38.358 19.434-51.322 40.078-14.885 22.701-24.866 52.191-29.489 82.541-3.623 23.918-4.314 48.266-1.883 70.397 3.941 7.828 7.483 15.937 11.091 23.92 4.783 10.767 9.995 21.303 16.682 30.652 20.253 28.495 30.766 58.806 33.621 94.184a105.635 105.635 0 0 0 7.058 27.957 83.118 83.118 0 0 0 8.539 16.179c13.683 19.89 34.245 35.356 60.903 44.739 26.092 8.864 53.967 12.171 81.542 12.692a220.965 220.965 0 0 1 34.61 3.237c15.065 2.719 29.66 7.806 43.267 15.078 39.088 24.329 82.35 39.66 129.312 33.681 55.8-7.19 114.921-45.3 119.285-113.908 6.462-41.358-6.395-75.584-33.722-104.02a139.286 139.286 0 0 0-9.434-8.929c-16.222-14.195-36.483-23.797-57.022-33.553-14.643-6.849-29.95-12.186-44.776-18.671-15.343-6.697-26.97-16.977-33.833-32.846-10.488-24.136-27.127-42.128-47.942-55.577a188.937 188.937 0 0 0-26.398-14.073 242.614 242.614 0 0 0-27.637-10.467c-27.884-8.374-49.256-24.622-64.565-49.865-15.999-26.427-38.051-46.1-68.968-52.583Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-203.355 46.823c-18.942-4.723-36.712-5.02-52.256-.592-22.899 6.414-40.849 20.735-54.842 42.756-16.156 24.456-27.025 56.294-32.075 89.073-3.96 25.813-4.681 52.131-1.942 76.029 4.333 8.451 8.243 17.202 12.232 25.83 5.317 11.608 11.02 22.973 18.377 33.059 22.185 30.721 33.527 63.471 36.279 101.767a114.62 114.62 0 0 0 7.175 30.201 90.454 90.454 0 0 0 8.951 17.552c14.389 21.625 36.39 38.256 65.143 48.014 28.176 9.288 58.236 12.727 87.929 13.331a227.213 227.213 0 0 1 37.297 3.67c16.284 3.083 32.029 8.766 46.652 16.841 42.509 26.715 89.556 43.588 140.655 37.18 60.66-7.675 124.953-49.113 129.447-123.85 6.314-44.697-8.106-81.368-37.934-111.521a153.534 153.534 0 0 0-10.276-9.461c-17.629-15.029-39.463-25.144-61.43-35.551-15.853-7.43-32.423-13.182-48.454-20.19-16.6-7.25-29.134-18.43-36.502-35.71-11.23-26.287-29.16-45.893-51.644-60.575a202.209 202.209 0 0 0-28.542-15.35 258.166 258.166 0 0 0-29.88-11.394c-30.221-9.052-53.31-26.678-69.78-54.141-17.24-28.718-41.077-50.05-74.58-56.968Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-214.355 24.574c-20.155-4.87-39.023-5.078-55.59-.33-24.182 6.822-43.337 22.022-58.369 45.431-17.429 26.205-29.188 60.384-34.663 95.598-4.295 27.714-5.053 55.977-1.996 81.66 4.721 9.074 8.993 18.469 13.366 27.735 5.824 12.464 12.053 24.665 20.016 35.485 24.12 32.94 36.285 68.13 38.934 109.344a123.45 123.45 0 0 0 7.293 32.445 97.505 97.505 0 0 0 9.345 18.93c15.094 23.354 38.538 41.123 69.383 51.289 30.263 9.717 62.5 13.285 94.305 13.973a235.102 235.102 0 0 1 39.972 4.108c17.502 3.45 34.393 9.731 50.033 18.606 45.929 29.1 96.754 47.512 151.934 40.699 65.533-8.164 134.966-52.927 139.63-133.727 6.169-48.045-9.818-87.158-42.14-119.024a167.132 167.132 0 0 0-11.116-10.007c-19.045-15.852-42.451-26.481-65.823-37.542-17.059-7.997-34.87-14.171-52.137-21.707-17.862-7.813-31.318-19.887-39.168-38.582-11.965-28.419-31.195-49.65-55.35-65.569a215.443 215.443 0 0 0-30.677-16.637c-10.47-4.664-21.21-9-32.127-12.315-32.47-9.822-57.248-28.856-74.904-58.517-18.45-30.992-44.058-53.984-80.151-61.346Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-225.357 2.332c-21.372-5.028-41.36-5.134-58.921-.079-25.461 7.227-45.825 23.314-61.901 48.112-18.695 27.958-31.338 64.477-37.246 102.121-4.63 29.615-5.416 59.854-2.054 87.293 5.117 9.688 9.753 19.734 14.5 29.64 6.34 13.311 13.089 26.351 21.693 37.918 26.058 35.164 39.047 72.794 41.591 116.921a132.048 132.048 0 0 0 7.417 34.693 104.64 104.64 0 0 0 9.792 20.297c15.806 25.087 40.701 43.997 73.633 54.574 32.327 10.115 66.769 13.84 100.687 14.613a242.47 242.47 0 0 1 42.642 4.547c18.718 3.815 36.755 10.695 53.407 20.373 49.35 31.473 103.964 51.432 163.259 44.224 70.394-8.649 144.982-56.736 149.802-143.64 6.021-51.386-11.523-92.944-46.352-126.524a180.601 180.601 0 0 0-11.953-10.543c-20.445-16.687-45.432-27.814-70.253-39.532-18.266-8.547-37.351-15.151-55.815-23.226-19.116-8.372-33.498-21.333-41.841-41.438-12.705-30.584-33.23-53.421-59.047-70.569a228.44 228.44 0 0 0-32.814-17.916c-11.203-5.019-22.692-9.679-34.382-13.239-34.755-10.549-61.252-30.986-80.067-62.842-19.706-33.33-47.098-57.98-85.777-65.778Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-236.352-19.912c-22.593-5.179-43.645-5.208-62.258.178-26.739 7.632-48.307 24.598-65.429 50.78-19.967 29.713-33.5 68.573-39.833 108.653-4.965 31.516-5.784 63.712-2.112 92.925 5.509 10.31 10.512 20.998 15.639 31.543 6.847 14.167 14.12 28.038 23.363 40.347 27.987 37.403 41.806 77.452 44.247 124.497a140.627 140.627 0 0 0 7.537 36.944 111.744 111.744 0 0 0 10.186 21.674c16.508 26.81 42.862 46.867 77.878 57.848 34.423 10.593 71.036 14.403 107.07 15.273a249.94 249.94 0 0 1 45.309 4.98c19.941 4.187 39.128 11.671 56.8 22.154 52.763 33.861 111.167 55.354 174.564 47.708 75.269-9.112 154.997-60.543 159.967-153.55 5.88-54.722-13.241-98.733-50.558-134.027a193.123 193.123 0 0 0-12.797-11.081c-21.844-17.517-48.416-29.159-74.683-41.523-19.467-9.098-39.819-16.128-59.493-24.745-20.385-8.92-35.68-22.786-44.505-44.285-13.445-32.728-35.265-57.178-62.755-75.57a241.804 241.804 0 0 0-34.947-19.197c-11.935-5.368-24.174-10.358-36.637-14.163-37.068-11.266-65.248-33.112-85.237-67.17-20.889-35.653-50.049-61.957-91.316-70.193Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-247.348-42.162c-23.79-5.336-45.957-5.266-65.593.436-28.021 8.045-50.792 25.897-68.961 53.462-21.234 31.46-35.65 72.665-42.415 115.176-5.299 33.423-6.153 67.57-2.168 98.55 5.904 10.937 11.266 22.278 16.775 33.455 7.36 15.02 15.153 29.73 25.032 42.768 29.93 39.607 44.565 82.112 46.91 132.098a149.755 149.755 0 0 0 7.654 39.188 118.876 118.876 0 0 0 10.603 23.045c17.215 28.545 45.018 49.738 82.122 61.135 36.497 11.001 75.3 14.961 113.448 15.901 16.704.425 32.598 2.111 47.976 5.414 21.157 4.553 41.487 12.632 60.17 23.909 56.189 36.239 118.376 59.268 185.879 51.216 80.126-9.628 165.012-64.351 170.143-163.47 5.732-58.062-14.957-104.515-54.771-141.528a208.313 208.313 0 0 0-13.633-11.615c-23.256-18.348-51.401-30.505-79.07-43.515-20.671-9.661-42.282-17.114-63.17-26.264-21.647-9.485-37.863-24.238-47.183-47.173-14.19-34.87-37.297-60.943-66.456-80.567a254.875 254.875 0 0 0-37.085-20.477c-12.662-5.724-25.658-11.042-38.89-15.08-39.336-12-69.244-35.239-90.398-71.49-22.132-37.93-53.068-65.89-96.919-74.574Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-258.35-64.404c-25.029-5.48-48.265-5.332-68.924.691-29.299 8.451-53.275 27.182-72.488 56.137-22.505 33.233-37.813 76.755-45.003 121.701-5.638 35.312-6.519 71.435-2.221 104.2 6.302 11.557 12.026 23.543 17.909 35.36 7.87 15.869 16.189 31.416 26.713 45.193 31.86 41.828 47.296 86.78 49.549 139.641a158.467 158.467 0 0 0 7.778 41.431 126.025 126.025 0 0 0 11.01 24.425c17.925 30.272 47.178 52.607 86.362 64.41 38.583 11.43 79.569 15.517 119.837 16.546 17.642.461 34.419 2.285 50.644 5.847 22.375 4.925 43.851 13.601 63.551 25.674 59.608 38.625 125.574 63.192 197.194 54.711 84.987-10.113 175.023-68.171 180.309-173.381 5.594-61.406-16.67-110.305-58.977-149.03a226.685 226.685 0 0 0-14.478-12.154c-24.66-19.176-54.383-31.843-83.475-45.507-21.874-10.218-44.745-18.093-66.849-27.783-22.912-10.041-40.046-25.697-49.85-50.032-14.933-37.026-39.342-64.729-70.165-85.567a267.827 267.827 0 0 0-39.24-21.751c-13.39-6.081-27.14-11.722-41.14-16.012-41.631-12.716-73.253-37.361-95.568-75.813-23.321-40.198-56.032-69.789-102.478-78.937Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-269.348-86.647c-26.251-5.637-50.578-5.397-72.261.943-30.581 8.863-55.762 28.474-76.016 58.81-23.783 34.946-39.972 80.858-47.589 128.232-5.973 37.213-6.888 75.294-2.279 109.814 6.68 12.177 12.774 24.811 19.042 37.265 8.383 16.723 17.222 33.108 28.383 47.622 33.797 44.051 50.08 91.436 52.211 147.235a167.384 167.384 0 0 0 7.914 43.676 133.43 133.43 0 0 0 11.42 25.791c18.659 31.997 49.333 55.478 90.611 67.695 40.668 11.854 83.833 16.074 126.214 17.188 18.584.495 36.244 2.457 53.311 6.281 23.592 5.294 46.213 14.569 66.926 27.441 63.03 41.003 132.817 67.12 208.511 58.23 89.856-10.594 185.031-71.983 190.486-183.275 5.444-64.752-18.386-116.088-63.191-156.518a241.299 241.299 0 0 0-15.317-12.701c-26.071-20-57.365-33.176-87.888-47.496-23.054-10.783-47.212-19.071-70.532-29.301-24.167-10.606-42.221-27.145-52.52-52.901-15.668-39.173-41.366-68.466-73.861-90.567a280.832 280.832 0 0 0-41.362-23.035c-14.123-6.437-28.623-12.407-43.39-16.939-43.919-13.435-77.25-39.493-100.737-80.14-24.57-42.513-59.058-73.78-108.086-83.35Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-280.34-108.899c-27.463-5.783-52.884-5.457-75.591 1.199-31.857 9.275-58.241 29.77-79.546 61.492-25.044 36.716-42.119 84.923-50.169 134.767-6.308 39.114-7.254 79.158-2.338 115.446 7.079 12.797 13.535 26.076 20.182 39.169 8.899 17.569 18.245 34.772 30.052 50.044 35.727 46.272 52.861 96.088 54.865 154.806a176.372 176.372 0 0 0 8.017 45.938 140.766 140.766 0 0 0 11.839 27.167c19.319 33.722 51.466 58.318 94.814 70.969 42.749 12.286 88.098 16.638 132.595 17.83 19.524.536 38.062 2.637 55.981 6.719 24.815 5.663 48.585 15.538 70.314 29.21 66.472 43.376 139.987 71.034 219.817 61.735 94.722-11.081 195.052-75.793 200.671-193.205 5.301-68.094-20.091-121.875-67.396-164.034a252.381 252.381 0 0 0-16.153-13.235c-27.477-20.834-60.35-34.522-92.301-49.493-24.283-11.325-49.674-20.05-74.208-30.814-25.431-11.156-44.409-28.595-55.189-55.76-16.408-41.323-43.398-72.236-77.564-95.55A294.294 294.294 0 0 0-15.14 81.184c-14.853-6.78-30.106-13.085-45.645-17.862-46.206-14.168-81.246-41.619-105.9-84.466-25.785-44.818-62.053-77.752-113.654-87.755Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-291.348-131.14c-28.686-5.938-55.198-5.52-78.933 1.458-33.131 9.673-60.724 31.055-83.068 64.165-26.314 38.47-44.286 89.04-52.76 141.28-6.638 41.014-7.617 83.015-2.393 121.072 7.466 13.42 14.291 27.348 21.315 41.074 9.401 18.427 19.286 36.488 31.72 52.466 37.664 48.497 55.6 100.76 57.523 162.389a185.055 185.055 0 0 0 8.137 48.169 147.76 147.76 0 0 0 12.245 28.541c20.049 35.475 53.651 61.224 99.106 74.273 44.828 12.71 92.36 17.189 138.978 18.469 20.454.569 39.882 2.812 58.648 7.153 26.032 6.04 50.947 16.512 73.694 30.975 69.863 45.771 147.185 74.958 231.131 65.243 99.583-11.566 205.067-79.601 210.825-203.112 5.155-71.428-21.807-127.657-71.608-171.535a271.29 271.29 0 0 0-16.997-13.773c-28.876-21.663-63.332-35.861-96.705-51.48-25.487-11.887-52.137-21.034-77.887-32.338-26.691-11.714-46.584-30.024-57.858-58.625C96.617 151.258 68.33 118.731 32.494 94.16a307.831 307.831 0 0 0-45.649-25.59c-15.568-7.154-31.59-13.771-47.9-18.787-48.502-14.89-85.242-43.745-111.069-88.789-26.98-47.104-65.035-81.71-119.224-92.134Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-302.349-153.381c-29.898-6.099-57.506-5.587-82.266 1.702-34.414 10.085-63.281 32.366-86.602 66.84-27.58 40.224-46.436 93.133-55.341 147.81-6.973 42.915-7.986 86.873-2.45 126.71 7.862 14.034 15.035 28.618 22.45 42.98 9.912 19.28 20.321 38.172 33.396 54.892 39.599 50.716 58.364 105.417 60.178 169.966.519 17.08 3.3 34.046 8.268 50.434a154.629 154.629 0 0 0 12.661 29.912c20.743 37.188 55.805 64.089 103.341 77.53 46.909 13.142 96.626 17.754 145.356 19.118 21.397.603 41.708 2.984 61.321 7.585 27.257 6.405 53.324 17.477 77.088 32.742 73.287 48.149 154.393 78.871 242.451 68.749 104.45-12.053 215.074-83.413 220.99-213.022 5.012-74.771-23.517-133.442-75.814-179.038a284.456 284.456 0 0 0-17.834-14.309c-30.287-22.494-66.339-37.197-101.117-53.475-26.691-12.445-54.606-22.012-81.564-33.851-27.952-12.278-48.773-31.5-60.53-61.495-17.893-45.609-47.472-79.756-84.977-105.56a320.893 320.893 0 0 0-47.764-26.877c-16.313-7.505-33.072-14.45-50.15-19.712-50.792-15.616-89.249-45.868-116.237-93.112-28.245-49.393-68.066-85.64-124.854-96.519Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-313.347-175.631c-31.116-6.243-59.795-5.653-85.598 1.971-35.692 10.491-65.735 33.662-90.129 69.515-28.853 41.972-48.597 97.228-57.929 154.334-7.31 44.81-8.353 90.738-2.503 132.341 8.249 14.658 15.8 29.88 23.585 44.891 10.421 20.123 21.352 39.859 35.062 57.309 41.537 52.94 61.124 110.075 62.835 177.543.599 18.901 3.461 36.479 8.379 52.68a162.096 162.096 0 0 0 13.075 31.29c21.454 38.921 57.965 66.958 107.598 80.819 48.988 13.568 100.889 18.311 151.737 19.758 22.335.639 43.529 3.158 63.984 8.02 28.471 6.782 55.68 18.453 80.456 34.511 76.715 50.559 161.599 82.799 253.763 72.252 109.311-12.539 225.082-87.245 231.163-222.929 4.862-78.117-25.229-139.232-80.027-186.545a300.784 300.784 0 0 0-18.678-14.847c-31.692-23.321-69.298-38.538-105.524-55.467-27.891-12.996-57.069-22.997-85.264-35.363-29.215-12.828-50.953-32.947-63.197-64.353-18.634-47.76-49.51-83.52-88.68-110.563A334.036 334.036 0 0 0-9.148 43.382c-17.058-7.856-34.556-15.135-52.4-20.639-53.082-16.34-93.247-48-121.399-97.436-29.434-51.714-71.031-89.617-130.401-100.938Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-324.347-197.873c-32.327-6.403-62.125-5.711-88.93 2.221-36.976 10.903-68.197 34.966-93.661 72.191-30.117 43.73-50.751 101.328-60.51 160.863-7.645 46.712-8.721 94.597-2.571 137.944 8.641 15.28 16.556 31.153 24.726 46.801 10.934 20.996 22.412 41.537 36.74 59.761 43.472 55.158 63.882 114.734 65.491 185.119.551 19.699 3.446 38.03 8.496 54.925a169.886 169.886 0 0 0 13.486 32.663c22.165 40.647 60.121 69.828 111.83 84.09 51.075 13.996 105.162 18.872 158.128 20.402 23.267.676 45.343 3.333 66.652 8.459 29.69 7.151 58.044 19.421 83.832 36.278 80.128 52.908 168.829 86.706 265.074 75.754 114.183-13.027 235.108-91.037 241.335-232.841 4.725-81.461-26.946-145.015-84.232-194.041a316.58 316.58 0 0 0-19.524-15.392c-33.097-24.149-72.258-39.885-109.935-57.458-29.099-13.551-59.537-23.974-88.906-36.868-30.475-13.386-53.13-34.401-65.866-67.218-19.375-49.923-51.527-87.256-92.382-115.56a347.12 347.12 0 0 0-52.04-29.434c-17.803-8.208-36.038-15.814-54.654-21.563-55.373-17.065-97.244-50.126-126.571-101.765-30.67-54.012-74.044-93.572-136.008-105.331Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-335.342-220.117c-33.554-6.551-64.439-5.775-92.266 2.478-38.251 11.307-70.647 36.262-97.189 74.865-31.39 45.48-52.908 105.417-63.098 167.389-7.98 48.612-9.087 98.461-2.622 143.6 9.039 15.9 17.319 32.423 25.86 48.706 11.446 21.831 23.421 43.237 38.429 62.15 45.409 57.383 66.644 119.399 68.149 192.702.502 20.492 3.432 39.587 8.631 57.165a176.49 176.49 0 0 0 13.897 34.034c22.865 42.385 62.278 72.706 116.074 87.377 53.154 14.422 109.423 19.424 164.504 21.045 24.21.71 47.169 3.505 69.325 8.89 30.233 7.373 58.97 19.627 87.22 38.067 83.509 55.287 175.946 90.631 276.346 79.25 119.027-13.507 245.118-94.843 251.507-242.754 4.576-84.8-28.656-150.799-88.445-201.542a330.553 330.553 0 0 0-20.36-15.926c-34.503-24.982-75.265-41.223-114.341-59.45-30.304-14.114-62.006-24.977-92.602-38.407-31.737-13.949-55.318-35.851-68.527-70.053-20.112-52.061-53.574-91.049-96.081-120.531A360.189 360.189 0 0 0-5.075 18.212C-23.588 9.642-42.596 1.713-61.984-4.274c-57.664-17.79-101.24-52.233-131.716-106.096-31.946-56.331-77.098-97.552-141.642-109.747Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-346.345-242.365c-34.776-6.727-66.744-5.836-95.596 2.734-39.53 11.721-73.101 37.569-100.714 77.545-32.663 47.229-55.072 109.507-65.687 173.914-8.34 50.515-9.455 102.319-2.675 149.231 9.426 16.523 18.068 33.691 26.994 50.611 11.952 22.681 24.458 44.922 40.077 64.586 47.344 59.601 69.412 124.086 70.808 200.271.45 21.291 3.391 41.153 8.741 59.424a184.173 184.173 0 0 0 14.31 35.413c23.576 44.111 64.416 75.582 120.32 90.65 55.247 14.85 113.695 19.986 170.884 21.679 25.148.747 48.989 3.679 71.987 9.326 31.405 7.72 61.237 20.547 90.593 39.808 86.968 57.68 183.214 94.558 287.707 82.769 123.905-13.997 255.133-98.651 261.672-252.664 4.457-88.131-30.364-156.578-92.654-209.037a345.075 345.075 0 0 0-21.198-16.467c-35.908-25.81-78.246-42.556-118.753-61.441-31.506-14.671-64.462-25.938-96.28-39.925-33.002-14.505-57.5-37.304-71.206-72.946C132.134 108.91 97.39 68.298 53.193 37.557A373.782 373.782 0 0 0-3.123 5.545C-22.37-3.38-42.127-11.633-62.281-17.868c-59.966-18.53-105.25-54.38-136.906-110.405-33.09-58.575-80.024-101.451-147.158-114.092Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-357.34-264.609c-35.99-6.853-69.059-5.899-98.932 2.991-40.812 12.128-75.562 38.867-104.25 80.216-33.926 48.987-57.219 113.605-68.265 180.449-8.667 52.42-9.822 106.183-2.733 154.863 9.824 17.143 18.828 34.956 28.133 52.515 12.464 23.535 25.494 46.627 41.75 67.007 49.271 61.829 72.158 128.723 73.461 207.855.402 22.09 3.404 42.694 8.847 61.673a191.16 191.16 0 0 0 14.72 36.778c24.287 45.845 66.61 78.441 124.586 93.93 57.314 15.279 117.958 20.544 177.267 22.326 26.082.79 50.806 3.86 74.657 9.765 32.573 8.094 63.503 21.467 93.975 41.579 90.386 60.059 190.411 98.475 299.013 86.272 128.784-14.487 265.152-102.466 271.851-262.572 4.286-91.483-32.079-162.374-96.864-216.551a358.276 358.276 0 0 0-22.035-17.002c-37.319-26.641-81.238-43.904-123.166-63.436-32.695-15.227-66.93-26.916-99.958-41.445-34.259-15.057-59.68-38.75-73.875-75.811-21.591-56.35-57.642-98.571-103.497-130.547C39.289 13.646 19.706 2.633-1.092-7.013c-19.98-9.28-40.487-17.863-61.413-24.337-62.249-19.232-109.246-56.506-142.099-114.726C-238.916-207-287.621-251.534-357.34-264.609Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-368.345-286.852c-37.208-7.008-71.286-5.931-102.236 3.232-42.09 12.533-78.117 40.091-107.775 82.895-35.199 50.737-59.383 117.696-70.849 186.967-8.994 54.311-10.197 110.044-2.793 160.496 10.223 17.763 19.583 36.222 29.275 54.444 12.971 24.391 26.515 48.303 43.42 69.435 51.204 64.081 74.918 133.382 76.117 215.432.353 22.882 3.389 44.245 8.953 63.92a198.014 198.014 0 0 0 15.133 38.157c24.986 47.576 68.754 81.315 128.809 97.211 59.395 15.71 122.224 21.107 183.646 22.974 27.034.853 52.632 4.032 77.33 10.196 33.733 8.426 65.77 22.393 97.326 43.354 93.807 62.426 197.623 102.401 310.333 89.778 133.662-14.978 275.162-106.272 282.017-272.482 4.143-94.825-33.789-168.159-101.042-224.064a375.099 375.099 0 0 0-22.879-17.54c-38.745-27.494-84.232-45.259-127.585-65.431-33.917-15.783-69.393-27.901-103.636-42.963-35.526-15.619-61.864-40.208-76.55-78.673-22.332-58.5-59.676-102.343-107.195-135.553C42.757 1.851 22.461-9.588.886-19.627c-20.707-9.636-41.97-18.541-63.662-25.262-64.539-19.957-113.254-58.648-147.236-119.066-35.545-63.191-86.027-109.381-158.333-122.897Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-672.53 139.594c24.789 43.029 44.872 89.997 75.495 128.181 53.143 66.272 77.676 138.041 78.773 223.009 1.359 105.382 57.549 179.906 157.692 206.19 61.486 16.131 126.491 21.656 190.033 23.612 66.169 2.034 124.1 17.984 180.74 55.743 97.229 64.818 204.839 106.281 321.643 93.275 138.506-15.457 285.189-110.084 292.189-282.395 4.424-108.802-44.576-190.089-128.998-249.63-40.126-28.309-87.204-46.582-131.975-67.395-35.113-16.317-71.852-28.868-107.32-44.481-36.786-16.177-64.021-41.662-79.214-81.539C144.369 59.622 81.963 4.614 2.911-32.226c-21.44-9.991-43.453-19.227-65.888-26.196-66.824-20.684-117.244-60.757-152.404-123.389-36.754-65.479-89.013-113.326-163.909-127.287-90.623-16.905-163.595 12.351-216.9 89.093-65.122 93.615-93.892 240.767-76.34 359.599Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M-115.363 224.78c-9.2-3.496-18.23-4.511-25.58-2.623-12.67 3.144-20.982 10.404-26.615 21.347-6.029 10.445-9.767 23.542-11.396 36.867a215.807 215.807 0 0 0-1.484 30.972c1.198 3.488 2.186 7.045 3.151 10.609a62.593 62.593 0 0 0 4.982 13.684c6.707 12.931 11.445 26.177 15.032 41.139a47.674 47.674 0 0 0 6.208 12.196 35.192 35.192 0 0 0 5.649 6.558 162.494 162.494 0 0 0 31.191 21.772c11.519 5.866 24.122 8.273 36.868 8.189 5.448-.017 10.745.021 15.959.19a104.473 104.473 0 0 1 19.61 2.707c15.148 7.66 31.915 12.223 50.112 9.145 21.743-3.785 44.842-18.633 48.087-44.535 7.478-17.969 5.592-35.084-4.258-51.501a49.853 49.853 0 0 0-3.575-5.149c-6.382-8.394-15.604-14.423-26.159-19.616-6.22-2.976-12.705-5.335-19.01-8.051-6.505-2.786-11.679-6.816-15.141-12.797-5.31-9.101-12.888-15.785-22.023-20.601a106.196 106.196 0 0 0-11.446-5.107c-3.888-1.47-7.866-2.865-11.856-3.991-11.854-3.312-21.252-9.723-28.407-19.598-7.463-10.311-17.089-18.4-29.899-21.806Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
			<path
				d="M-159.364 135.79c-14.067-4.108-27.471-4.765-38.943-1.599-17.792 4.775-30.92 15.564-40.719 32.081-11.082 17.446-18.426 39.924-21.736 62.969-2.619 18.209-3.212 36.679-1.719 53.503 2.765 5.963 5.227 12.122 7.682 18.18 3.279 8.208 6.89 16.222 11.671 23.381 14.431 21.792 22.486 44.824 25.65 71.449a80.005 80.005 0 0 0 6.697 21.212 62.019 62.019 0 0 0 7.303 12.058c11.564 14.704 27.751 26.771 48.164 34.894 19.845 7.577 41.174 10.492 62.398 10.757 8.898.083 17.79.73 26.627 1.936a120.4 120.4 0 0 1 33.165 9.77c28.829 17.19 60.736 27.908 95.37 23.17 41.206-5.725 84.893-33.875 88.768-84.177 6.889-31.356-1.256-58.226-21.089-81.51a98.571 98.571 0 0 0-6.916-7.314c-12.018-11.704-27.53-19.782-43.793-27.581-11.037-5.203-22.557-9.264-33.743-14.114-11.55-5.022-20.407-12.626-25.82-24.26-8.266-17.692-21.026-30.835-36.83-40.576a150.761 150.761 0 0 0-19.995-10.232 194.385 194.385 0 0 0-20.87-7.689c-21.008-6.207-37.242-18.207-49.066-36.901-12.369-19.531-29.103-34.231-52.256-39.407Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
			<path
				d="M-203.355 46.823c-18.942-4.723-36.712-5.02-52.256-.592-22.899 6.414-40.849 20.735-54.842 42.756-16.156 24.456-27.025 56.294-32.075 89.073-3.96 25.813-4.681 52.131-1.942 76.029 4.333 8.451 8.243 17.202 12.232 25.83 5.317 11.608 11.02 22.973 18.377 33.059 22.185 30.721 33.527 63.471 36.279 101.767a114.62 114.62 0 0 0 7.175 30.201 90.454 90.454 0 0 0 8.951 17.552c14.389 21.625 36.39 38.256 65.143 48.014 28.176 9.288 58.236 12.727 87.929 13.331a227.213 227.213 0 0 1 37.297 3.67c16.284 3.083 32.029 8.766 46.652 16.841 42.509 26.715 89.556 43.588 140.655 37.18 60.66-7.675 124.953-49.113 129.447-123.85 6.314-44.697-8.106-81.368-37.934-111.521a153.534 153.534 0 0 0-10.276-9.461c-17.629-15.029-39.463-25.144-61.43-35.551-15.853-7.43-32.423-13.182-48.454-20.19-16.6-7.25-29.134-18.43-36.502-35.71-11.23-26.287-29.16-45.893-51.644-60.575a202.209 202.209 0 0 0-28.542-15.35 258.166 258.166 0 0 0-29.88-11.394c-30.221-9.052-53.31-26.678-69.78-54.141-17.24-28.718-41.077-50.05-74.58-56.968Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
			<path
				d="M-247.348-42.162c-23.79-5.336-45.957-5.266-65.593.436-28.021 8.045-50.792 25.897-68.961 53.462-21.234 31.46-35.65 72.665-42.415 115.176-5.299 33.423-6.153 67.57-2.168 98.55 5.904 10.937 11.266 22.278 16.775 33.455 7.36 15.02 15.153 29.73 25.032 42.768 29.93 39.607 44.565 82.112 46.91 132.098a149.755 149.755 0 0 0 7.654 39.188 118.876 118.876 0 0 0 10.603 23.045c17.215 28.545 45.018 49.738 82.122 61.135 36.497 11.001 75.3 14.961 113.448 15.901 16.704.425 32.598 2.111 47.976 5.414 21.157 4.553 41.487 12.632 60.17 23.909 56.189 36.239 118.376 59.268 185.879 51.216 80.126-9.628 165.012-64.351 170.143-163.47 5.732-58.062-14.957-104.515-54.771-141.528a208.313 208.313 0 0 0-13.633-11.615c-23.256-18.348-51.401-30.505-79.07-43.515-20.671-9.661-42.282-17.114-63.17-26.264-21.647-9.485-37.863-24.238-47.183-47.173-14.19-34.87-37.297-60.943-66.456-80.567a254.875 254.875 0 0 0-37.085-20.477c-12.662-5.724-25.658-11.042-38.89-15.08-39.336-12-69.244-35.239-90.398-71.49-22.132-37.93-53.068-65.89-96.919-74.574Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
			<path
				d="M-291.348-131.14c-28.686-5.938-55.198-5.52-78.933 1.458-33.131 9.673-60.724 31.055-83.068 64.165-26.314 38.47-44.286 89.04-52.76 141.28-6.638 41.014-7.617 83.015-2.393 121.072 7.466 13.42 14.291 27.348 21.315 41.074 9.401 18.427 19.286 36.488 31.72 52.466 37.664 48.497 55.6 100.76 57.523 162.389a185.055 185.055 0 0 0 8.137 48.169 147.76 147.76 0 0 0 12.245 28.541c20.049 35.475 53.651 61.224 99.106 74.273 44.828 12.71 92.36 17.189 138.978 18.469 20.454.569 39.882 2.812 58.648 7.153 26.032 6.04 50.947 16.512 73.694 30.975 69.863 45.771 147.185 74.958 231.131 65.243 99.583-11.566 205.067-79.601 210.825-203.112 5.155-71.428-21.807-127.657-71.608-171.535a271.29 271.29 0 0 0-16.997-13.773c-28.876-21.663-63.332-35.861-96.705-51.48-25.487-11.887-52.137-21.034-77.887-32.338-26.691-11.714-46.584-30.024-57.858-58.625C96.617 151.258 68.33 118.731 32.494 94.16a307.831 307.831 0 0 0-45.649-25.59c-15.568-7.154-31.59-13.771-47.9-18.787-48.502-14.89-85.242-43.745-111.069-88.789-26.98-47.104-65.035-81.71-119.224-92.134Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
			<path
				d="M-335.342-220.117c-33.554-6.551-64.439-5.775-92.266 2.478-38.251 11.307-70.647 36.262-97.189 74.865-31.39 45.48-52.908 105.417-63.098 167.389-7.98 48.612-9.087 98.461-2.622 143.6 9.039 15.9 17.319 32.423 25.86 48.706 11.446 21.831 23.421 43.237 38.429 62.15 45.409 57.383 66.644 119.399 68.149 192.702.502 20.492 3.432 39.587 8.631 57.165a176.49 176.49 0 0 0 13.897 34.034c22.865 42.385 62.278 72.706 116.074 87.377 53.154 14.422 109.423 19.424 164.504 21.045 24.21.71 47.169 3.505 69.325 8.89 30.233 7.373 58.97 19.627 87.22 38.067 83.509 55.287 175.946 90.631 276.346 79.25 119.027-13.507 245.118-94.843 251.507-242.754 4.576-84.8-28.656-150.799-88.445-201.542a330.553 330.553 0 0 0-20.36-15.926c-34.503-24.982-75.265-41.223-114.341-59.45-30.304-14.114-62.006-24.977-92.602-38.407-31.737-13.949-55.318-35.851-68.527-70.053-20.112-52.061-53.574-91.049-96.081-120.531A360.189 360.189 0 0 0-5.075 18.212C-23.588 9.642-42.596 1.713-61.984-4.274c-57.664-17.79-101.24-52.233-131.716-106.096-31.946-56.331-77.098-97.552-141.642-109.747Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
			<path
				d="M-672.53 139.594c24.789 43.029 44.872 89.997 75.495 128.181 53.143 66.272 77.676 138.041 78.773 223.009 1.359 105.382 57.549 179.906 157.692 206.19 61.486 16.131 126.491 21.656 190.033 23.612 66.169 2.034 124.1 17.984 180.74 55.743 97.229 64.818 204.839 106.281 321.643 93.275 138.506-15.457 285.189-110.084 292.189-282.395 4.424-108.802-44.576-190.089-128.998-249.63-40.126-28.309-87.204-46.582-131.975-67.395-35.113-16.317-71.852-28.868-107.32-44.481-36.786-16.177-64.021-41.662-79.214-81.539C144.369 59.622 81.963 4.614 2.911-32.226c-21.44-9.991-43.453-19.227-65.888-26.196-66.824-20.684-117.244-60.757-152.404-123.389-36.754-65.479-89.013-113.326-163.909-127.287-90.623-16.905-163.595 12.351-216.9 89.093-65.122 93.615-93.892 240.767-76.34 359.599Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
		</svg>
	);
};
