import React from 'react';
import { IFormStepProps } from '../SelfSubscribeContainer';
import { BuildPlan } from './BuildPlan';
import { Checkout } from './Checkout';
import { Complete } from './Complete';
import { CreateAccount } from './CreateAccount';
import { CustomForm } from './CustomForm';
import { CustomFormSuccess } from './CustomFormSuccess';
import { TrainingSession } from './TrainingSession';
import { UpdateAccount } from './UpdateAccount';
import { VerifyAccount } from './VerifyAccount';

type TComponentMap = {
	[key: string]: (props: IFormStepProps) => JSX.Element;
};

const componentMap: TComponentMap = {
	buildPlan: (props: IFormStepProps) => <BuildPlan {...props} />,
	customForm: (props: IFormStepProps) => <CustomForm {...props} />,
	createAccount: (props: IFormStepProps) => <CreateAccount {...props} />,
	verifyAccount: (props: IFormStepProps) => <VerifyAccount {...props} />,
	checkout: (props: IFormStepProps) => <Checkout {...props} />,
	updateAccount: (props: IFormStepProps) => <UpdateAccount {...props} />,
	trainingSession: (props: IFormStepProps) => <TrainingSession {...props} />,
	customFormSuccess: (props: IFormStepProps) => (
		<CustomFormSuccess {...props} />
	),
	complete: (props: IFormStepProps) => <Complete {...props} />,
};

export const FormComponent: React.FC<IFormStepProps> = (props) => {
	const component = componentMap[props.currentState];
	const Component = React.createElement(component, props);

	return <>{Component}</>;
};
