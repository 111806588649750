import { isSbLinkValid } from '@/utils/helpers/isSbLinkValid';
import { INumberTile } from '@/utils/interfaces/components';
import { Fragment } from 'react';
import { Icon } from '../Icon';
import { Arrow } from '../Icons/Arrow';
import { SbLink } from '../SbLink';

interface IProps {
	blok: INumberTile;
}

export const NumberTile: React.FC<IProps> = ({ blok }) => {
	function TileContent() {
		return (
			<Fragment>
				<div className="z-0 absolute-fill bg-green-dark">
					<Icon
						className="absolute w-[12px] h-[10px] text-white top-3 right-3"
						icon={<Arrow />}
					/>
				</div>
				<div className="px-8 py-10 relative h-full text-left bg-white shadow-soft group-hover:rounded-tr-[90px] z-10 transition-all lg:pb-[60px] lg:pt-[52px]">
					<div className="flex items-center justify-between mb-4">
						<div className="flex items-center justify-center bg-green-light min-w-11 h-11 lg:h-14 lg:min-w-14">
							<span className="text-xl font-bold leading-10 lg:text-4xl text-green">
								{blok.number}
							</span>
						</div>

						<div>
							{blok.coming_soon_tag && (
								<span className="cta cta--chip cta--primary">
									<span className="cta-label">
										Coming Soon
									</span>
								</span>
							)}
							{blok.feature_tag && (
								<span className="cta cta--chip cta--secondary">
									<span className="cta-label">
										New Feature
									</span>
								</span>
							)}
						</div>
					</div>

					<h4 className="mt-6 text-base lg:mt-10 lg:text-xl">
						{blok.title}
					</h4>
					<span className="block mt-4 text-sm leading-6 opacity-80">
						{blok.description}
					</span>
				</div>
			</Fragment>
		);
	}

	if (isSbLinkValid(blok.link)) {
		return (
			<SbLink link={blok.link}>
				<a className="relative block w-full h-full group">
					<TileContent />
				</a>
			</SbLink>
		);
	} else {
		return (
			<div className="relative block w-full h-full">
				<TileContent />
			</div>
		);
	}
};
