interface IProps {}

export const HouseDark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="m13.423 24.875 14.192-8.188 23.44-13.536 15.74 25.564-23.41 13.525-15.77-25.553"
				fill="#22B473"
			/>
			<path
				d="M43.391 43.391a1.15 1.15 0 0 1-.984-.547L27.224 18.248l-13.242 7.629a1.154 1.154 0 0 1-1.49-1.702c.092-.12.207-.22.338-.296l14.216-8.153L50.479 2.19a1.152 1.152 0 0 1 1.554.392L67.781 28.15a1.151 1.151 0 0 1-.066 1.298 1.15 1.15 0 0 1-.343.302L43.967 43.276a1.153 1.153 0 0 1-.576.115ZM29.222 17.096l14.56 23.606L65.196 28.33 50.663 4.723 29.222 17.096Z"
				fill="#fff"
			/>
			<path
				d="M39.948 61.62c-.201 0-.4-.052-.575-.15l-7.79-4.502a1.15 1.15 0 0 1-.576-.997V40.536a1930.774 1930.774 0 0 0-5.516-3.184v14.106a1.151 1.151 0 0 1-1.727.996l-7.79-4.486a1.15 1.15 0 0 1-.576-1.001V30.846a1.152 1.152 0 0 1 2.303 0v15.447l5.498 3.184V35.359a1.151 1.151 0 0 1 1.728-.996c.201.11.397.225.576.34l7.237 4.18a1.151 1.151 0 0 1 .575 1.002V55.32l6.639 3.829 22.322-12.857v-7.836a1.151 1.151 0 1 1 2.303 0v8.492a1.152 1.152 0 0 1-.576.997L40.524 61.47c-.175.1-.374.151-.576.15Z"
				fill="#22B473"
			/>
			<path
				d="M3.151 55.833a1.152 1.152 0 0 1-.575-2.147l7.323-4.226a1.154 1.154 0 0 1 1.69 1.298 1.152 1.152 0 0 1-.538.7l-7.324 4.22a1.1 1.1 0 0 1-.576.155Z"
				fill="#fff"
			/>
			<path
				d="M41.105 46.903a1.152 1.152 0 0 0-2.303 0v13.565a1.152 1.152 0 0 0 2.303 0V46.903Z"
				fill="#22B473"
			/>
			<path
				d="M76.849 55.833a1.112 1.112 0 0 1-.576-.155l-7.324-4.22a1.153 1.153 0 1 1 1.152-1.998l7.323 4.226a1.151 1.151 0 0 1-.575 2.147ZM40.11 77.516c-.203 0-.401-.054-.576-.155L2.576 55.966a1.15 1.15 0 0 1 .72-2.157c.154.025.3.081.431.165l36.958 21.395a1.151 1.151 0 0 1-.576 2.147Z"
				fill="#fff"
			/>
			<path
				d="M40.11 77.516a1.152 1.152 0 0 1-1.114-1.452 1.151 1.151 0 0 1 .538-.701l36.739-21.395a1.151 1.151 0 0 1 1.151 1.986L40.685 77.355c-.174.104-.373.16-.575.161Z"
				fill="#fff"
			/>
		</svg>
	);
};
