interface IProps {
	strokeColor: string;
}

export const Right: React.FC<IProps> = ({ strokeColor }) => {
	return (
		<svg
			viewBox="0 0 454 472"
			fill="none"
			className="w-full h-full"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M489.775 376.74c-11.47-13.147-18.963-27.161-21.687-41.317-4.155-21.001.116-41.011 12.148-60.905 12.961-22.413 33.808-43.558 57.685-60.505 18.824-13.339 39.273-24.365 59.17-31.78 8.359.002 16.786-.456 25.134-.804 11.224-.54 22.435-.616 33.266 1.075 32.959 5.041 63.29 1.005 94.656-11.727a100.79 100.79 0 0 1 26.844-5.97 79.257 79.257 0 0 1 17.446.34c22.811 3.132 44.801 14.021 64.223 32.712 18.736 18.43 33.502 40.763 45.766 64.033a210.582 210.582 0 0 0 17.593 28.101c8.774 11.669 19.364 21.924 31.392 30.399 37.483 22.875 69.089 53.162 84.129 95.737 17.79 50.623 10.66 117.329-45.923 150.457-32.466 23.233-67.138 26.949-103.079 15.856a132.904 132.904 0 0 1-11.651-4.211c-19.048-7.736-35.913-20.883-53.03-34.2-12.111-9.54-23.22-20.294-35.1-30.146-12.283-10.201-26.025-15.701-42.487-14.746-25.06 1.41-47.521-5.055-67.902-17.024a180.23 180.23 0 0 1-23.305-16.459 231.277 231.277 0 0 1-20.764-19.06c-19.087-20.167-42.091-31.411-70.16-33.634-29.374-2.303-55.588-12.659-74.364-36.222Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M466.113 376.9c-12.144-14.113-20.013-29.127-22.904-44.268-4.351-22.259.156-43.691 12.92-65.052 13.912-24.248 36.378-47.156 62.141-65.509 20.295-14.438 42.409-26.333 63.944-34.244 9.058.07 18.189-.35 27.25-.65 12.169-.445 24.297-.457 36.044 1.487 35.684 5.733 68.448 1.358 102.256-12.712a109.306 109.306 0 0 1 28.806-6.832 86.23 86.23 0 0 1 18.791.102c24.592 2.99 48.193 14.607 68.831 34.921 19.991 20.025 35.806 44.162 49.048 69.201a216.761 216.761 0 0 0 19.115 30.204c9.606 12.552 21.197 23.53 34.345 32.529 40.98 24.766 75.52 57.617 91.97 103.9 19.46 54.972 11.71 127.511-50.04 163.376-35.371 24.538-72.795 27.971-111.271 15.483a146.493 146.493 0 0 1-12.465-4.7c-20.361-8.577-38.338-22.844-56.622-37.099-13.124-10.322-25.128-21.975-37.971-32.628-13.292-11.036-28.189-16.923-46.071-15.793-27.21 1.7-51.6-5.172-73.746-18.036a192.952 192.952 0 0 1-25.313-17.737 246.206 246.206 0 0 1-22.515-20.574c-20.666-21.869-45.581-33.985-76.039-36.246-31.857-2.379-60.25-13.543-80.504-39.123Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M442.438 377.067c-12.788-15.084-21.053-31.071-24.108-47.221-4.554-23.528.186-46.363 13.687-69.204 14.855-26.082 38.935-50.752 66.59-70.51 21.771-15.539 45.527-28.3 68.719-36.703 9.755.132 19.591-.256 29.359-.501 13.116-.381 26.182-.304 38.813 1.842 38.404 6.431 73.6 1.712 109.85-13.697a117.699 117.699 0 0 1 30.769-7.694 93.087 93.087 0 0 1 20.134-.153c26.367 2.85 51.555 15.208 73.439 37.13 21.251 21.619 38.108 47.556 52.328 74.358a224.245 224.245 0 0 0 20.634 32.296c10.44 13.432 23.033 25.131 37.298 34.652 44.48 26.657 81.95 62.069 99.8 112.002 21.14 59.333 12.76 137.678-54.08 176.286-38.293 25.85-78.469 28.994-119.473 15.115a159.562 159.562 0 0 1-13.292-5.181c-21.669-9.431-40.758-24.818-60.199-39.989-14.127-11.106-27.021-23.635-40.843-35.116-14.313-11.87-30.368-18.158-49.661-16.833-29.342 1.987-55.673-5.296-79.59-19.053a205.529 205.529 0 0 1-27.325-19.004c-8.461-6.922-16.759-14.214-24.263-22.094-22.286-23.455-49.123-36.407-81.963-38.735-34.314-2.435-64.879-14.397-86.623-41.993Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M418.773 377.228c-13.445-16.054-22.103-33.038-25.324-50.167-4.756-24.791.221-49.036 14.458-73.362 15.807-27.912 41.501-54.338 71.042-75.507 23.247-16.639 48.673-30.27 73.492-39.168 10.448.207 20.994-.149 31.469-.351 14.058-.304 28.062-.143 41.605 2.229 41.128 7.128 78.757 2.065 117.443-14.682a125.936 125.936 0 0 1 32.738-8.551 99.761 99.761 0 0 1 21.49-.358c28.149 2.714 54.932 15.818 78.06 39.342 22.474 23.207 40.412 50.955 55.609 79.522a231.277 231.277 0 0 0 22.152 34.381c11.274 14.313 24.867 26.731 40.251 36.77 47.972 28.556 88.382 66.531 107.662 120.141 22.8 63.682 13.8 147.843-58.17 189.2-41.2 27.156-84.131 30.023-127.671 14.742a172.241 172.241 0 0 1-14.105-5.665c-22.981-10.266-43.172-26.785-63.795-42.91-15.111-11.899-28.919-25.328-43.713-37.598-15.327-12.698-32.535-19.396-53.24-17.887-31.503 2.285-59.758-5.412-85.434-20.058a217.8 217.8 0 0 1-29.331-20.278c-9.078-7.394-17.973-15.185-26.017-23.619-23.885-25.089-52.655-38.904-87.861-41.28-36.844-2.503-69.588-15.274-92.81-44.886Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M395.11 377.394c-14.096-17.029-23.145-34.953-26.534-53.12-4.959-26.053.251-51.702 15.219-77.512 16.756-29.748 44.064-57.935 75.497-80.51 24.723-17.74 51.803-32.239 78.267-41.632 11.145.274 22.396-.044 33.578-.197 15.005-.238 29.941.012 44.39 2.612 43.864 7.812 83.909 2.418 125.037-15.667a134.108 134.108 0 0 1 34.707-9.415 106.65 106.65 0 0 1 22.833-.612c29.918 2.574 58.302 16.429 82.668 41.556 23.78 24.788 42.72 54.348 58.907 84.676a238.37 238.37 0 0 0 23.664 36.469c12.116 15.195 26.716 28.335 43.227 38.897 51.46 30.44 94.81 70.987 115.47 128.279 24.5 68.034 14.85 158.008-62.26 202.11-44.1 28.466-89.793 31.04-135.859 14.374a184.272 184.272 0 0 1-14.926-6.153c-24.288-11.103-45.597-28.752-67.39-45.832-16.096-12.685-30.809-27.012-46.584-40.081-16.338-13.544-34.708-20.632-56.808-18.935-33.646 2.574-63.831-5.537-91.284-21.075a230.551 230.551 0 0 1-31.336-21.546c-9.689-7.867-19.187-16.156-27.771-25.143-25.488-26.753-56.179-41.398-93.765-43.83-39.33-2.515-74.24-16.085-98.947-47.713Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M371.438 377.562c-14.743-17.983-24.185-36.898-27.744-56.072-5.157-27.323.292-54.376 15.99-81.671 17.702-31.576 46.629-61.521 79.948-85.506 26.205-18.841 54.933-34.207 83.035-44.09 11.85.341 23.81.048 35.694-.048 15.952-.169 31.826.166 47.169 2.994 46.574 8.523 89.061 2.773 132.654-16.655a142.797 142.797 0 0 1 36.669-10.277 113.311 113.311 0 0 1 24.18-.844c31.699 2.431 61.672 17.035 87.287 43.763 25.017 26.379 45.023 57.741 62.177 89.841 7.522 14.049 15.771 26.868 25.177 38.555 12.948 16.073 28.546 29.932 46.166 41.016 54.96 32.339 101.23 75.451 123.31 136.415 26.14 72.394 15.9 168.173-66.35 215.031-47.01 29.772-95.456 32.057-144.056 14.001a198.906 198.906 0 0 1-15.74-6.637c-25.601-11.949-48.022-30.718-70.967-48.715-17.092-13.47-32.705-28.689-49.455-42.563-17.359-14.377-36.881-21.869-60.416-19.979-35.791 2.858-67.91-5.654-97.128-22.086a242.963 242.963 0 0 1-33.343-22.819c-10.305-8.334-20.406-17.127-29.518-26.67-27.084-28.371-59.704-43.891-99.658-46.375-41.802-2.598-78.885-16.971-105.081-50.609Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M347.772 377.722c-15.397-18.975-25.231-38.836-28.954-59.019-5.359-28.585.322-57.042 16.756-85.822 18.669-33.42 49.187-65.117 84.399-90.509 27.669-19.94 58.067-36.175 87.826-46.557 12.548.415 25.213.155 37.803.102 16.894-.097 33.706.327 49.955 3.388 49.293 9.215 94.209 3.098 140.213-17.641a151.09 151.09 0 0 1 38.633-11.133 120.205 120.205 0 0 1 25.529-1.089c33.475 2.297 65.043 17.646 91.895 45.972 26.278 27.973 47.327 61.141 65.465 95.008 7.955 14.833 16.7 28.346 26.69 40.642 13.786 16.953 30.388 31.531 49.118 43.14 58.46 34.23 107.66 79.902 131.14 144.558 27.81 76.743 16.94 178.34-70.44 227.94-49.92 31.088-101.121 33.08-152.249 13.633a216.746 216.746 0 0 1-16.561-7.124c-26.908-12.792-50.441-32.686-74.553-51.616-18.082-14.256-34.594-30.366-52.325-45.045-18.376-15.217-39.059-23.105-63.995-21.026-37.947 3.148-72.013-5.774-102.979-23.103a255.407 255.407 0 0 1-35.351-24.109c-10.921-8.802-21.621-18.099-31.278-28.188-28.678-30.019-63.23-46.396-105.556-48.927-44.245-2.639-83.478-17.824-111.181-53.475Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M324.107 377.889c-16.054-19.949-26.278-40.779-30.171-61.97-5.556-29.855.358-59.715 17.523-89.974 19.579-35.243 51.756-68.716 88.854-95.512 29.146-21.04 61.198-38.142 92.584-49.013 13.239.473 26.615.249 39.913.25 17.841-.026 35.59.482 52.739 3.77 52.019 9.913 99.37 3.474 147.824-18.628a159.644 159.644 0 0 1 40.604-11.978 127.302 127.302 0 0 1 26.869-1.326c35.26 2.183 68.413 18.252 96.516 48.184 27.531 29.568 49.63 64.535 68.745 100.166 8.388 15.622 17.629 29.827 28.203 42.729 14.62 17.831 32.22 33.128 52.07 45.258 61.95 36.126 114.11 84.389 138.99 152.691 29.49 81.098 17.98 188.5-74.5 240.853-52.84 32.394-106.79 34.095-160.443 13.252a230.369 230.369 0 0 1-17.387-7.607c-28.215-13.639-52.855-34.653-78.139-54.52-19.069-15.02-36.485-32.05-55.197-47.534-19.395-16.044-41.225-24.336-67.585-22.072-40.09 3.443-76.063-5.895-108.822-24.108a267.82 267.82 0 0 1-37.355-25.365c-11.538-9.275-22.84-19.07-33.031-29.708-30.272-31.66-66.76-48.889-111.46-51.476-46.752-2.711-88.174-18.692-117.344-56.362Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M300.439 378.061c-16.699-20.92-27.317-42.718-31.38-64.917-5.754-31.118.399-62.382 18.294-94.132 20.547-37.075 54.3-72.287 93.316-100.51 30.622-22.14 64.333-40.111 97.358-51.477 13.937.546 28.018.354 42.023.405 18.783.051 37.446.64 55.519 4.153 54.737 10.605 104.525 3.85 155.412-19.612a168.205 168.205 0 0 1 42.575-12.86 134.209 134.209 0 0 1 28.221-1.558c37.012 2.004 71.747 18.85 101.107 50.36 28.791 31.156 51.938 67.928 72.026 105.328 8.827 16.405 18.563 31.298 29.72 44.816 15.46 18.714 34.06 34.733 55.03 47.386 65.45 38.041 120.52 88.821 146.82 160.822 31.16 85.453 19.03 198.671-78.6 253.786-55.74 33.705-112.44 35.124-168.64 12.892a240.926 240.926 0 0 1-18.201-8.09c-29.528-14.48-55.28-36.62-81.732-57.425-20.057-15.835-38.374-33.728-58.062-50.017-20.405-16.885-43.398-25.579-71.163-23.119-42.24 3.732-80.148-6.012-114.656-25.127a280.703 280.703 0 0 1-39.361-26.634c-12.143-9.748-24.053-20.041-34.784-31.233-31.877-33.294-70.285-51.381-117.358-54.021-49.237-2.757-92.843-19.54-123.484-59.216Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M276.77 378.216c-17.356-21.895-28.364-44.662-32.592-67.875-5.961-32.375.43-65.049 19.062-98.278 21.497-38.91 56.879-75.898 97.755-105.51 32.098-23.236 67.463-42.074 102.126-53.936 14.635.608 29.426.454 44.132.555 19.729.11 39.354.79 58.298 4.536 57.463 11.302 109.679 4.18 163.012-20.598a176.473 176.473 0 0 1 44.527-13.714 140.967 140.967 0 0 1 29.566-1.802c38.818 1.876 75.159 19.467 105.761 52.602 30.045 32.745 54.235 71.322 75.308 110.491 9.252 17.184 19.495 32.774 31.235 46.903 16.3 19.59 35.9 36.329 57.98 49.509 68.94 39.904 126.95 93.272 154.66 168.958 32.83 89.802 20.08 208.836-82.69 266.684-58.64 35.009-118.11 36.14-176.836 12.518a258.413 258.413 0 0 1-19.021-8.578c-30.835-15.316-57.7-38.587-85.312-60.325-21.054-16.62-40.27-35.405-60.939-52.499-21.421-17.719-45.548-26.819-74.748-24.166-44.384 4.022-84.221-6.136-120.517-26.136a293.524 293.524 0 0 1-41.37-27.923c-12.768-10.198-25.273-21.012-36.538-32.758-33.477-34.941-73.809-53.875-123.257-56.572-51.697-2.796-97.494-20.386-129.602-62.086Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M253.105 378.377c-18.011-22.858-29.409-46.6-33.813-70.82-6.158-33.644.451-67.789 19.827-102.435 22.448-40.74 59.444-79.484 102.212-110.508 33.574-24.336 70.592-44.042 106.906-56.4 15.327.682 30.827.542 46.241.703 20.676.181 41.234.952 61.084 4.924 60.183 12 114.832 4.54 170.605-21.582a185.248 185.248 0 0 1 46.514-14.575 147.473 147.473 0 0 1 30.912-2.034c40.575 1.732 78.524 20.074 110.352 54.813 31.304 34.334 56.543 74.716 78.594 115.648 9.681 17.973 20.421 34.256 32.751 48.995 17.14 20.477 37.74 37.94 60.94 51.644 72.43 41.801 133.37 97.734 162.49 177.098 34.51 94.157 21.13 218.997-86.76 279.594-61.56 36.32-123.78 37.162-185.041 12.15a271.494 271.494 0 0 1-19.835-9.061c-32.148-16.163-60.128-40.577-88.904-63.231-22.044-17.405-42.161-37.088-63.803-54.981-22.442-18.552-47.745-28.052-78.339-25.212-46.529 4.305-88.301-6.259-126.362-27.153a306.055 306.055 0 0 1-43.373-29.174c-13.386-10.681-26.487-21.983-38.291-34.278-35.076-36.581-77.335-56.379-129.155-59.122-54.19-2.893-102.142-21.283-135.752-65.003Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M229.435 378.544c-18.657-23.835-30.447-48.522-35.012-73.775-6.361-34.906.504-70.435 20.593-106.586 23.393-42.574 62.008-83.081 106.662-115.51 35.045-25.436 73.728-46.01 111.681-58.86 16.024.745 32.231.655 48.356.853 21.612.253 43.113 1.106 63.857 5.307 62.909 12.697 119.984 4.894 178.199-22.567 16.361-7.591 32.565-12.688 48.476-15.442a154.619 154.619 0 0 1 32.263-2.273c42.357 1.596 81.895 20.685 114.979 57.03 32.558 35.923 58.846 78.11 81.871 120.811 10.12 18.756 21.35 35.733 34.26 51.076 17.99 21.351 39.59 39.53 63.89 53.756 75.96 43.689 139.82 102.191 170.33 185.235 36.18 98.507 22.15 229.166-90.84 292.508-64.47 37.626-129.44 38.185-193.241 11.777a286.087 286.087 0 0 1-20.656-9.549c-33.455-17.005-62.539-42.522-92.491-66.13-23.027-18.192-44.055-38.765-66.677-57.486-23.452-19.393-49.911-29.29-81.917-26.26-48.679 4.596-92.381-6.382-132.212-28.163a318.652 318.652 0 0 1-45.38-30.454c-14.005-11.165-27.707-22.953-40.044-35.796-36.676-38.223-80.866-58.873-135.053-61.668-56.677-2.91-106.8-22.104-141.894-67.834Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M205.767 378.704c-19.312-24.798-31.495-50.482-36.227-76.72-6.561-36.177.559-73.092 21.359-110.744 24.349-44.404 64.577-86.673 111.118-120.507 36.521-26.536 76.858-47.978 116.426-61.32 16.722.813 33.639.754 50.472 1.007 22.576.316 44.996 1.29 66.665 5.686 65.628 13.395 125.135 5.248 185.793-23.552 17.02-7.974 33.879-13.366 50.438-16.304a161.94 161.94 0 0 1 33.609-2.51C849.553-24.8 890.684-4.97 925 32.972c33.818 37.517 61.156 81.508 85.16 125.979 10.55 19.533 22.28 37.203 35.78 53.162 18.82 22.231 41.43 41.128 66.84 55.874 79.43 45.591 146.24 106.676 178.17 193.371 37.84 102.868 23.21 239.334-94.94 305.422-67.38 38.943-135.1 39.202-201.424 11.409a302.216 302.216 0 0 1-21.483-10.036c-34.761-17.847-64.954-44.467-96.077-69.036-24.018-18.984-45.946-40.448-69.52-59.944-24.467-20.227-52.083-30.521-85.502-27.306-50.839 4.893-96.43-6.498-138.056-29.175a330.99 330.99 0 0 1-47.385-31.721c-14.624-11.649-28.921-23.925-41.798-37.322-38.275-39.863-84.39-61.365-140.957-64.217-59.166-2.979-111.462-22.977-148.041-70.729Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M182.105 378.871c-19.964-25.78-32.542-52.427-37.438-79.673-6.762-37.436.613-75.735 22.126-114.895 25.293-46.239 67.136-90.264 115.568-125.51 37.997-27.636 79.993-49.947 121.223-63.787 17.421.886 35.048.858 52.582 1.155 23.506.395 46.877 1.422 69.424 6.101 68.354 14.092 130.294 5.601 193.393-24.537 17.674-8.356 35.199-14.044 52.403-17.15a168.328 168.328 0 0 1 34.955-2.748c45.912 1.313 88.639 21.895 124.199 61.44 35.072 39.106 63.453 84.903 88.44 131.137 10.99 20.322 23.21 38.685 37.29 55.254 19.24 22.598 42 41.83 69.82 57.994 82.9 47.451 152.64 111.057 185.98 201.476 39.51 107.2 24.26 249.494-99.02 318.338-70.29 40.248-140.76 40.224-209.63 11.035a316.207 316.207 0 0 1-22.292-10.52c-36.074-18.688-67.383-46.456-99.663-71.935-25.015-19.769-47.858-42.123-72.416-62.435-25.488-21.059-54.256-31.763-89.058-28.356-52.977 5.18-100.538-6.618-143.877-30.196a343.53 343.53 0 0 1-49.387-32.961c-15.236-12.099-30.14-24.895-43.552-38.847-39.874-41.503-87.898-63.867-146.852-66.745-61.687-3.071-116.162-23.872-154.218-73.635Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M158.431 379.032c-20.637-26.746-33.581-54.365-38.647-82.619-6.958-38.704.675-78.387 22.898-119.048 26.238-48.073 69.693-93.86 120.018-130.512 39.463-28.758 83.122-51.915 125.997-66.246 18.118.949 36.45.953 54.692 1.305 24.447.46 48.757 1.582 72.205 6.46 71.074 14.79 135.475 5.95 200.982-25.516 18.333-8.743 36.516-14.75 54.376-18.025a175.61 175.61 0 0 1 36.306-2.986c47.688 1.178 92.007 22.484 128.807 63.655 36.333 40.705 65.765 88.3 91.715 136.3 11.42 21.106 24.14 40.162 38.81 57.335 20.04 23.448 43.75 43.366 72.75 60.121 86.42 49.373 159.09 115.567 193.84 209.648 41.18 111.567 25.31 259.658-103.11 331.247-73.17 41.573-146.42 41.245-217.81 10.661a329.903 329.903 0 0 1-23.118-11.003c-37.381-19.53-69.797-48.424-103.25-74.84-26.005-20.555-49.73-43.804-75.286-64.917-26.504-21.9-56.429-33-92.672-29.399-55.121 5.47-104.615-6.724-149.75-31.203a356.582 356.582 0 0 1-51.415-34.259c-15.853-12.573-31.354-25.868-45.305-40.367-41.492-43.148-91.446-66.363-152.748-69.319-64.09-3.084-120.738-24.694-160.285-76.473Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M134.769 379.199c-21.266-27.726-34.628-56.309-39.858-85.572-7.161-39.97.725-81.038 23.657-123.204 27.195-49.903 72.265-97.448 124.481-135.51 40.946-29.854 86.258-53.884 130.771-68.71 18.817 1.021 37.853 1.057 56.802 1.459 25.394.53 50.654 1.735 74.985 6.849 73.798 15.476 140.603 6.302 208.581-26.507 18.993-9.127 37.834-15.4 56.337-18.898a182.393 182.393 0 0 1 37.645-3.224c49.47 1.042 95.383 23.129 133.431 65.884 37.585 42.283 68.069 91.695 95.009 141.462 11.85 21.883 25.06 41.632 40.32 59.422 20.86 24.282 45.51 44.902 75.7 62.243 89.92 51.265 165.52 120.019 201.68 217.779 42.86 115.933 26.35 269.83-107.19 344.166-76.1 42.865-152.09 42.269-226.02 10.295-8-3.461-15.98-7.289-23.927-11.486-38.694-20.377-72.228-50.395-106.842-77.745-26.987-21.33-51.621-45.487-78.157-67.399-27.514-22.735-58.596-34.237-96.256-30.446-57.265 5.759-108.691-6.858-155.59-32.221-18.474-9.982-36.252-21.946-53.385-35.532-16.47-13.045-32.573-26.838-47.059-41.891-43.068-44.792-94.971-68.857-158.656-71.892-66.615-3.119-125.435-25.527-166.459-79.322Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M111.1 379.359c-21.92-28.696-35.61-58.193-41.07-88.489-7.363-41.233.673-83.74 24.43-127.357C122.6 111.776 169.282 62.47 223.386 23.008c42.418-30.943 89.386-55.857 135.545-71.175 19.516 1.095 39.255 1.158 58.934 1.605 26.341.596 52.52 1.886 77.77 7.231 76.546 16.158 145.755 6.656 216.175-27.492 19.646-9.508 39.149-16.078 58.298-19.771a188.908 188.908 0 0 1 38.996-3.463c51.244.895 98.751 23.724 138.036 68.076 38.845 43.871 70.37 95.088 98.29 146.618 12.32 22.668 26 43.115 41.84 61.515 21.64 25.128 47.27 46.438 78.65 64.339 93.4 53.164 171.96 124.48 209.52 225.919 44.53 120.3 27.4 279.99-111.27 357.076-79.02 44.175-157.76 43.291-234.22 9.955a359.74 359.74 0 0 1-24.75-11.974c-40.03-21.225-74.664-52.365-110.433-80.655-27.986-22.132-53.516-47.164-81.028-69.882-28.535-23.573-60.774-35.473-99.842-31.497-59.414 6.048-112.775-6.976-161.438-33.227-19.169-10.339-37.615-22.728-55.416-36.807-17.086-13.512-33.787-27.81-48.812-43.411-44.667-46.433-98.513-71.353-164.555-74.408-69.075-3.197-130.068-26.409-172.576-82.221Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M344.042-62.6c47.287 2.677 95.912-.346 141.571 9.378 79.243 16.87 150.906 7.01 223.768-28.477 90.369-44.013 177.948-28.084 243.267 45.97 40.1 45.472 72.672 98.488 101.572 151.787 30.1 55.504 68.52 98.024 124.97 130.097 96.9 55.054 178.35 128.965 217.34 234.057 46.2 124.632 28.45 290.166-115.36 369.99-90.8 50.407-181.06 43.502-267.97-2.903-41.323-22.054-77.072-54.33-113.995-83.554-28.953-22.922-55.395-48.844-83.9-72.37-29.55-24.407-62.938-36.688-103.425-32.538-85.814 8.838-159.431-20.752-224.704-72.313-17.703-13.985-35.007-28.781-50.561-44.908-46.267-48.068-102.021-73.849-170.454-76.958-71.543-3.247-134.708-27.262-178.707-85.089-53.248-69.965-59.6-144.677-17.065-222.988C122.234 60.97 235.275-26.617 344.042-62.599Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeMiterlimit="10"
			/>
			<path
				d="M466.113 376.9c-12.144-14.113-20.013-29.127-22.904-44.268-4.351-22.259.156-43.691 12.92-65.052 13.912-24.248 36.378-47.156 62.141-65.509 20.295-14.438 42.409-26.333 63.944-34.244 9.058.07 18.189-.35 27.25-.65 12.169-.445 24.297-.457 36.044 1.487 35.684 5.733 68.448 1.358 102.256-12.712a109.306 109.306 0 0 1 28.806-6.832 86.23 86.23 0 0 1 18.791.102c24.592 2.99 48.193 14.607 68.831 34.921 19.991 20.025 35.806 44.162 49.048 69.201a216.761 216.761 0 0 0 19.115 30.204c9.606 12.552 21.197 23.53 34.345 32.529 40.98 24.766 75.52 57.617 91.97 103.9 19.46 54.972 11.71 127.511-50.04 163.376-35.371 24.538-72.795 27.971-111.271 15.483a146.493 146.493 0 0 1-12.465-4.7c-20.361-8.577-38.338-22.844-56.622-37.099-13.124-10.322-25.128-21.975-37.971-32.628-13.292-11.036-28.189-16.923-46.071-15.793-27.21 1.7-51.6-5.172-73.746-18.036a192.952 192.952 0 0 1-25.313-17.737 246.206 246.206 0 0 1-22.515-20.574c-20.666-21.869-45.581-33.985-76.039-36.246-31.857-2.379-60.25-13.543-80.504-39.123Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
			<path
				d="M371.438 377.562c-14.743-17.983-24.185-36.898-27.744-56.072-5.157-27.323.292-54.376 15.99-81.671 17.702-31.576 46.629-61.521 79.948-85.506 26.205-18.841 54.933-34.207 83.035-44.09 11.85.341 23.81.048 35.694-.048 15.952-.169 31.826.166 47.169 2.994 46.574 8.523 89.061 2.773 132.654-16.655a142.797 142.797 0 0 1 36.669-10.277 113.311 113.311 0 0 1 24.18-.844c31.699 2.431 61.672 17.035 87.287 43.763 25.017 26.379 45.023 57.741 62.177 89.841 7.522 14.049 15.771 26.868 25.177 38.555 12.948 16.073 28.546 29.932 46.166 41.016 54.96 32.339 101.23 75.451 123.31 136.415 26.14 72.394 15.9 168.173-66.35 215.031-47.01 29.772-95.456 32.057-144.056 14.001a198.906 198.906 0 0 1-15.74-6.637c-25.601-11.949-48.022-30.718-70.967-48.715-17.092-13.47-32.705-28.689-49.455-42.563-17.359-14.377-36.881-21.869-60.416-19.979-35.791 2.858-67.91-5.654-97.128-22.086a242.963 242.963 0 0 1-33.343-22.819c-10.305-8.334-20.406-17.127-29.518-26.67-27.084-28.371-59.704-43.891-99.658-46.375-41.802-2.598-78.885-16.971-105.081-50.609Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
			<path
				d="M276.77 378.216c-17.356-21.895-28.364-44.662-32.592-67.875-5.961-32.375.43-65.049 19.062-98.278 21.497-38.91 56.879-75.898 97.755-105.51 32.098-23.236 67.463-42.074 102.126-53.936 14.635.608 29.426.454 44.132.555 19.729.11 39.354.79 58.298 4.536 57.463 11.302 109.679 4.18 163.012-20.598a176.473 176.473 0 0 1 44.527-13.714 140.967 140.967 0 0 1 29.566-1.802c38.818 1.876 75.159 19.467 105.761 52.602 30.045 32.745 54.235 71.322 75.308 110.491 9.252 17.184 19.495 32.774 31.235 46.903 16.3 19.59 35.9 36.329 57.98 49.509 68.94 39.904 126.95 93.272 154.66 168.958 32.83 89.802 20.08 208.836-82.69 266.684-58.64 35.009-118.11 36.14-176.836 12.518a258.413 258.413 0 0 1-19.021-8.578c-30.835-15.316-57.7-38.587-85.312-60.325-21.054-16.62-40.27-35.405-60.939-52.499-21.421-17.719-45.548-26.819-74.748-24.166-44.384 4.022-84.221-6.136-120.517-26.136a293.524 293.524 0 0 1-41.37-27.923c-12.768-10.198-25.273-21.012-36.538-32.758-33.477-34.941-73.809-53.875-123.257-56.572-51.697-2.796-97.494-20.386-129.602-62.086Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
			<path
				d="M182.105 378.871c-19.964-25.78-32.542-52.427-37.438-79.673-6.762-37.436.613-75.735 22.126-114.895 25.293-46.239 67.136-90.264 115.568-125.51 37.997-27.636 79.993-49.947 121.223-63.787 17.421.886 35.048.858 52.582 1.155 23.506.395 46.877 1.422 69.424 6.101 68.354 14.092 130.294 5.601 193.393-24.537 17.674-8.356 35.199-14.044 52.403-17.15a168.328 168.328 0 0 1 34.955-2.748c45.912 1.313 88.639 21.895 124.199 61.44 35.072 39.106 63.453 84.903 88.44 131.137 10.99 20.322 23.21 38.685 37.29 55.254 19.24 22.598 42 41.83 69.82 57.994 82.9 47.451 152.64 111.057 185.98 201.476 39.51 107.2 24.26 249.494-99.02 318.338-70.29 40.248-140.76 40.224-209.63 11.035a316.207 316.207 0 0 1-22.292-10.52c-36.074-18.688-67.383-46.456-99.663-71.935-25.015-19.769-47.858-42.123-72.416-62.435-25.488-21.059-54.256-31.763-89.058-28.356-52.977 5.18-100.538-6.618-143.877-30.196a343.53 343.53 0 0 1-49.387-32.961c-15.236-12.099-30.14-24.895-43.552-38.847-39.874-41.503-87.898-63.867-146.852-66.745-61.687-3.071-116.162-23.872-154.218-73.635Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
			<path
				d="M344.042-62.6c47.287 2.677 95.912-.346 141.571 9.378 79.243 16.87 150.906 7.01 223.768-28.477 90.369-44.013 177.948-28.084 243.267 45.97 40.1 45.472 72.672 98.488 101.572 151.787 30.1 55.504 68.52 98.024 124.97 130.097 96.9 55.054 178.35 128.965 217.34 234.057 46.2 124.632 28.45 290.166-115.36 369.99-90.8 50.407-181.06 43.502-267.97-2.903-41.323-22.054-77.072-54.33-113.995-83.554-28.953-22.922-55.395-48.844-83.9-72.37-29.55-24.407-62.938-36.688-103.425-32.538-85.814 8.838-159.431-20.752-224.704-72.313-17.703-13.985-35.007-28.781-50.561-44.908-46.267-48.068-102.021-73.849-170.454-76.958-71.543-3.247-134.708-27.262-178.707-85.089-53.248-69.965-59.6-144.677-17.065-222.988C122.234 60.97 235.275-26.617 344.042-62.599Z"
				stroke={strokeColor}
				strokeOpacity=".2"
				strokeWidth="3"
				strokeMiterlimit="10"
			/>
		</svg>
	);
};
