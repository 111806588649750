import { handleEmailClick } from '@/utils/handleMailto';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { IContact } from '@/utils/interfaces/bloks';
import { StoryblokComponent } from '@storyblok/react';
import { pathOr } from 'ramda';
import { useFooter } from '../Providers/FooterProvider';
import { Container } from '../Shared/Layout/Container';
import { SocialLink } from '../Shared/Layout/Footer/SocialLink';
import { SbSection } from '../Shared/Layout/SbSection';
import { LocalePhonenumber } from '../Shared/LocalePhoneNumber';

interface IProps {
	blok: IContact;
	preview: boolean;
}

export const Contact: React.FC<IProps> = ({ blok, preview }) => {
	const classes = ['flex', 'flex-col-reverse'];

	const { social_links } = useFooter();

	const address = pathOr(null, ['location_data', 'content', 'address'], blok);
	const email = pathOr(null, ['location_data', 'content', 'email'], blok);
	const phone = pathOr(
		null,
		['location_data', 'content', 'phone_number'],
		blok,
	);

	if (blok.hide_content) {
		classes.push('lg:flex-row', 'lg:text-center');
	} else {
		classes.push('lg:grid', 'lg:grid-cols-12');
	}

	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative bg-white py-14 lg:py-32"
		>
			<Container>
				<div className={classes.join(' ')}>
					{!blok.hide_content && (
						<div className="border-t border-gray-light lg:col-span-4 lg:border-0">
							<div className="py-8 lg:pt-0">
								<h4>{blok.heading}</h4>

								{address && isStringWithValue(address) && (
									<span className="block mt-3 whitespace-pre-line lg:mt-6">
										{address}
									</span>
								)}

								{phone && isStringWithValue(phone) && (
									<div>
										<LocalePhonenumber
											number={phone}
											classes="mt-3 lg:mt-4 cta cta--link"
										/>
									</div>
								)}

								{email && isStringWithValue(email) && (
									<div>
										<button
											type="button"
											onClick={() => handleEmailClick(email)}
											className="mt-3 lg:mt-4 cta cta--link"
										>
											Email
										</button>
									</div>
								)}
							</div>

							<div className="flex flex-wrap items-center pt-8 border-t border-gray-light lg:flex-col lg:items-start">
								<span className="mr-4 font-bold text-green-dark min-w-min">
									Follow Us
								</span>
								<div>
									{isArrayWithValue(social_links) && (
										<nav className="flex flex-wrap gap-3 mt-3">
											{social_links.map((social, key) => (
												<SocialLink
													blok={social}
													key={key}
												/>
											))}
										</nav>
									)}
								</div>
							</div>
						</div>
					)}
					
					<div className="w-full lg:col-span-6 lg:col-start-7">
						
						{blok.form_heading && isStringWithValue(blok.form_heading) && (
							<span className="text-xl leading-7 text-green">
								{blok.form_heading}
							</span>
						)}

						<div className="pt-6 pb-10">
							{blok.form.map((element, key) => {
								return (
									<StoryblokComponent
										key={key}
										blok={element}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</Container>
		</SbSection>
	);
};
