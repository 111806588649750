import { formatNumber } from '@/utils/helpers/formatNumber';
import { mapLocaleToCurrency } from '@/utils/helpers/mapLocalToCountry';
import { TBillingCycle } from '@/utils/interfaces/cibApiResponse';
import { useRouter } from 'next/router';

interface IProps {
	discount: number;
	cost: number;
	billingCycle: TBillingCycle;
}

export const CostSummary: React.FC<IProps> = ({
	discount,
	cost,
	billingCycle,
}) => {
	const { locale } = useRouter();
	const currency = mapLocaleToCurrency(locale as string);

	return (
		<div className="cost-summary">
			{discount !== 0 && (
				<div className="cost-summary__summary-saving">
					{formatNumber(discount, locale as string)} saving
				</div>
			)}

			<div className="cost-summary__summary-cost">
				<div className="cost-summary__summary-cost-currency">
					<span>{currency}</span>
				</div>
				<div className="cost-summary__summary-cost-amount">
					{formatNumber(cost, locale as string)}
				</div>
				<div className="cost-summary__summary-cost-period">
					<span>/Month</span>
				</div>
			</div>
			<div className="cost-summary__summary-bill-rate">
				{billingCycle == 'annually' && (
					<span>
						Billed annually at{' '}
						{formatNumber(cost * 12, locale as string)}
					</span>
				)}
			</div>
		</div>
	);
};
