import { Icon } from '@/components/Shared/Icon';
import { Helper } from '@/components/Shared/Icons/Helper';
import { formatNumber } from '@/utils/helpers/formatNumber';
import { Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';

interface IProps {
	name: string;
	label: string;
	id: string;
	included: boolean;
	price: number;
	locale: string;
	register: UseFormRegister<any>;
	billingCycle?: string;
}

export const FormCheckbox: React.FC<IProps> = ({
	name,
	label,
	id,
	included,
	price,
	locale,
	billingCycle,
	register,
}) => {
	return (
		<div className="form-checkbox">
			<div className="form-checkbox__info">
				<input
					{...register(name, {})}
					className="form-checkbox__input"
					type="checkbox"
					disabled={included}
					name={name}
					value={id}
					id={id}
				/>
				<label htmlFor={id} className="form-checkbox__label">
					<span className="form-checkbox__name">{label}</span>
				</label>
			</div>

			{included ? (
				<span className="form-checkbox__included">Included</span>
			) : (
				<span className="form-checkbox__price">
					+{formatNumber(price, locale)}{' '}
					{billingCycle && (
						<span className="form-checkbox__billing">
							/
							{billingCycle === 'monthly' ? (
								<>Month</>
							) : (
								<>Year</>
							)}
						</span>
					)}
				</span>
			)}
		</div>
	);
};
