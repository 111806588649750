import {IFooterItem} from '@/utils/interfaces/components';
import {UtilityIcon} from "@/components/Shared/Icons/UtilityIcon";
import React from "react";
import {SbLink} from "@/components/Shared/SbLink";

interface IProps {
    blok: IFooterItem;
}

export const FooterItem: React.FC<IProps> = ({blok}) => {
    return (
        <div className="flex mb-2 md:mb-4 last:mb-0 ">
            <SbLink link={blok.link}>
                <a className="relative after:w-full after:h-0.5 after:scale-x-0 after:bg-gradient-to-r after:from-green to-[#1BD0BC] after:origin-left after:left-0 after:bottom-0 after:absolute hover:after:scale-x-100 after:transition-all">
                    {blok.label}
                </a>
            </SbLink>
        </div>
    )
}