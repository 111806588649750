interface IProps {}

export const LinkedIn: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 36 36"
			 xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.496 25.5h.004v-5.502c0-2.691-.58-4.764-3.726-4.764-1.512 0-2.527.83-2.942 1.617h-.043v-1.366h-2.983V25.5h3.106v-4.96c0-1.305.247-2.567 1.864-2.567 1.593 0 1.617 1.49 1.617 2.652V25.5h3.103ZM10.748 15.485h3.11V25.5h-3.11V15.485ZM12.301 10.5c-.994 0-1.801.807-1.801 1.801 0 .995.807 1.818 1.801 1.818.995 0 1.801-.823 1.801-1.818 0-.994-.807-1.801-1.8-1.801Z"
				fill="currentColor"
			/>
		</svg>
	);
};
