import { getFileExtension } from '@/utils/helpers/getFileExtension';
import { getImageDimensionsFromUrl } from '@/utils/helpers/getImageDimensionsFromUrl';
import { slugify } from '@/utils/helpers/slugify';
import Image, { ImageProps } from 'next/image';

interface IProps extends ImageProps {
	disablePngBg?: boolean;
}

export const SbImage: React.FC<IProps> = ({
	src,
	width,
	height,
	disablePngBg = true,
	...props
}) => {
	const { w, h } = getImageDimensionsFromUrl(src as string);
	const extension = (src as string).split('.').pop();
	const altText = props.alt
		? encodeURIComponent(props.alt as string)
		: 'Canibuild';

	if (extension === 'svg' && width && height) {
		return (
			<Image
				src={src as string}
				layout="intrinsic"
				height={height}
				width={width}
				alt="Canibuild"
			/>
		);
	}

	if (props.layout == 'fill') {
		return (
			<Image
				{...props}
				src={`${src}/m/filters:format(webp)`}
				layout="fill"
				alt={altText}
			/>
		);
	}

	return (
		<Image
			{...props}
			src={`${src}/m/filters:format(webp)`}
			width={width ? width : w}
			height={height ? height : h}
			alt={altText}
		/>
	);
};
