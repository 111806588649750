interface IProps {
    className: string;
}

export const Cutout: React.FC<IProps> = ({className}) => {
    return (

        <svg
            viewBox="0 0 1536 1294"
             className={className}
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <mask id="webwidget"
                  style={{maskType: 'alpha'}}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="1536"
                  height="1294">
                <rect
                    width="1536"
                    height="1294"
                    fill="#224946"/>
            </mask>
            <g mask="url(#webwidget)">
                <path
                    d="M-832.439 809.193L-865.868 740.81L-20.5212 325.743L13.0702 394.229C75.597 521.424 172.39 628.656 292.551 703.85C412.712 779.045 551.469 819.216 693.201 819.842L769.533 820.08L765.645 1761.78L689.552 1761.63C372.311 1760.6 61.6522 1670.85 -207.305 1502.54C-476.263 1334.23 -692.793 1094.07 -832.416 809.208L-832.439 809.193Z"
                    fill="url(#webwidget_gradient)"
                />
                <g opacity="0.3">
                    <path
                        d="M20.4961 952.204C23.3796 964.869 20.1778 972.823 13.0784 978.788C8.4152 982.541 3.2301 985.595 -2.31368 987.854C-8.44464 990.273 -13.7428 994.417 -17.5665 999.784C-23.9501 1009.03 -31.487 1010.96 -41.5694 1005.85C-44.7444 1004.03 -47.6776 1001.82 -50.2996 999.268C-51.9917 997.665 -53.457 995.838 -54.6547 993.839C-57.1431 989.789 -60.4318 986.29 -64.3196 983.555C-68.2075 980.82 -72.6127 978.907 -77.2656 977.933C-88.9787 975.069 -94.9047 967.176 -94.7059 955.146C-94.8154 950.893 -94.0013 946.667 -92.3196 942.759C-88.1832 934.587 -86.8508 926.097 -86.0553 917.129C-85.6306 909.814 -83.2046 902.756 -79.0419 896.725C-74.8793 890.694 -69.14 885.922 -62.4502 882.93C-58.0111 880.64 -52.9886 879.728 -48.0278 880.313C-43.0669 880.897 -38.3942 882.951 -34.6092 886.21C-27.146 891.842 -21.363 899.405 -17.8847 908.082C-13.1716 920.509 -4.14321 928.88 6.65511 935.919C13.9534 940.492 19.1836 946.775 20.4961 952.204Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M-0.284817 896.529C1.70382 900.168 4.05038 903.647 6.39698 907.067C13.1245 916.715 22.3497 924.351 33.0846 929.158C35.0732 930.053 36.9027 930.967 38.8317 931.882C49.3317 937.111 57.3857 944.826 60.7067 953.197C66.6726 969.979 63.7096 982.386 54.0647 992.964C47.3238 1000.1 39.4148 1006.03 30.6783 1010.5C23.5391 1014.28 16.7578 1018.12 10.5533 1022.19C8.0797 1023.3 5.68157 1024.57 3.37432 1025.99C0.431959 1027.95 -2.78489 1029.47 -6.17124 1030.48C-16.6907 1033.46 -27.7379 1034.09 -38.5263 1032.31C-43.7748 1031.58 -48.832 1029.83 -53.4212 1027.18C-56.4952 1025.52 -59.2763 1023.36 -61.6542 1020.8C-64.9156 1017.28 -68.2963 1013.74 -71.7366 1010.1C-78.2384 1003.15 -85.5876 997.048 -93.6116 991.93C-99.4743 989.61 -104.584 985.72 -108.38 980.687C-112.176 975.653 -114.511 969.671 -115.129 963.397C-115.981 958.805 -116.088 954.105 -115.447 949.479C-116.541 938.324 -119.603 925.997 -121.274 911.859C-121.512 909.871 -121.671 907.664 -121.731 905.576C-122.049 884.44 -113.021 864 -91.4838 853.363C-86.1112 850.549 -80.154 849.032 -74.0897 848.936C-68.0254 848.839 -62.0228 850.164 -56.5633 852.806C-51.0491 855.539 -45.9204 858.989 -41.3104 863.066C-37.89 865.949 -34.6883 869.031 -31.3673 872.172C-28.3221 875.191 -24.7232 877.594 -20.7678 879.251C-12.419 882.919 -5.30669 888.919 -0.284817 896.529V896.529Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M17.2742 884.818C20.3566 889.292 23.6179 893.666 26.8196 898.061C35.913 910.516 48.6182 919.874 63.2117 924.864C65.7969 925.798 68.3822 926.852 70.9674 927.826C84.689 933.592 95.5867 942.778 101.075 954.112C109.965 975.009 107.419 991.89 95.2289 1007.04C86.4669 1017.6 75.8278 1026.45 63.8481 1033.15C54.0839 1038.76 44.3395 1044 35.3309 1048.82C31.657 1049.76 28.0331 1050.89 24.473 1052.2C19.8871 1054.05 15.0699 1055.27 10.1548 1055.82C-4.48158 1057.31 -19.0981 1058.32 -35.3453 1058.78C-42.5492 1059.13 -49.7409 1057.88 -56.4049 1055.12C-60.844 1053.4 -64.9491 1050.91 -68.5357 1047.78C-73.5429 1043.37 -78.2842 1038.66 -82.7346 1033.69C-91.3049 1023.95 -100.4 1014.69 -109.979 1005.95C-116.374 1002.29 -121.953 997.361 -126.372 991.466C-130.79 985.57 -133.955 978.833 -135.672 971.669C-137.234 966.623 -138.247 961.423 -138.695 956.16C-145.039 942.043 -152.476 925.877 -156.593 906.571C-157.189 903.727 -157.666 900.844 -158.024 897.941C-160.948 869.289 -150.726 838.828 -120.598 823.776C-103.158 815.047 -86.0754 815.962 -69.8879 824.214C-62.0574 828.58 -54.7629 833.845 -48.1521 839.902C-43.0413 844.276 -38.3481 849.068 -33.4759 853.641C-28.877 858.232 -23.083 861.442 -16.7515 862.907C-3.3481 865.71 7.84803 873.723 17.2742 884.818Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M34.8342 873.266C38.9507 878.575 43.1865 883.844 47.2632 889.173C58.7366 904.464 74.9311 915.547 93.3401 920.708C96.628 921.676 99.8827 922.736 103.104 923.889C120.067 930.192 133.789 940.87 141.366 955.146C153.298 980.159 151.05 1001.45 136.295 1021.24C126.53 1034.52 112.431 1046.39 96.8997 1055.76C84.9353 1062.92 72.6166 1069.48 59.9905 1075.4C55.0985 1076.16 50.2263 1077.21 45.4735 1078.34C39.2818 1080.04 32.9005 1080.94 26.482 1081.03C6.86581 1081.15 -12.7228 1082.52 -32.1631 1085.14C-41.291 1086.55 -50.6203 1085.8 -59.4074 1082.96C-65.1614 1081.15 -70.5483 1078.34 -75.3165 1074.65C-81.969 1069.41 -88.1024 1063.55 -93.6319 1057.15C-104.768 1044.4 -115.348 1031.6 -126.166 1019.85C-139.971 1009.87 -150.383 995.901 -155.996 979.821C-158.266 974.262 -160.179 968.564 -161.723 962.761C-173.317 945.661 -185.109 925.659 -191.732 901.122C-192.71 897.506 -193.506 893.843 -194.118 890.147C-199.686 853.979 -188.251 813.496 -149.552 794.05C-126.643 782.498 -104.231 784.109 -83.0523 795.462C-72.8903 800.89 -63.9017 808.744 -54.7937 816.578C-48.0323 822.424 -41.8278 828.866 -35.305 834.971C-29.13 841.158 -21.1105 845.171 -12.4556 846.404C6.01888 848.889 21.5899 858.83 34.8342 873.266Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M52.3936 861.653C57.5243 867.797 62.7544 873.941 67.6862 880.284C81.5488 898.469 101.267 911.308 123.507 916.631C127.485 917.645 131.462 918.778 135.28 920.051C155.465 926.911 172.01 939.039 181.695 956.278C196.61 985.408 194.701 1011.16 177.419 1035.55C165.885 1052 148.843 1066.85 129.99 1078.54C115.5 1087.52 100.364 1095.42 84.709 1102.16C78.6038 1102.74 72.5187 1103.69 66.513 1104.67C58.7107 1106.2 50.7529 1106.81 42.8083 1106.46C18.7524 1105.15 -5.37234 1106.91 -28.9816 1111.71C-40.0145 1114.15 -51.4725 1113.9 -62.3907 1110.99C-69.5365 1109.16 -76.2798 1106.02 -82.2771 1101.73C-90.588 1095.7 -98.1282 1088.67 -104.729 1080.81C-118.073 1065.26 -130.323 1049.11 -142.513 1033.96C-157.135 1021.48 -168.74 1005.84 -176.459 988.231C-179.561 981.789 -182.644 975.188 -184.911 969.6C-201.715 949.538 -217.902 925.678 -226.99 896.032C-228.316 891.684 -229.437 887.243 -230.351 882.71C-238.544 838.966 -225.897 788.542 -178.647 764.662C-150.249 750.286 -122.547 752.732 -96.3368 767.088C-83.7885 773.967 -72.7913 783.909 -61.5754 793.612C-53.1436 800.929 -45.4475 809.042 -37.2941 816.637C-29.1406 824.233 -19.4957 829.363 -8.31955 830.238C15.385 832.067 35.331 843.937 52.3936 861.653Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M69.9539 850.022C76.0988 857.001 82.3033 863.941 88.1102 871.357C104.38 892.349 127.583 906.874 153.576 912.337C158.256 913.371 162.863 914.577 167.397 915.956C190.804 923.372 210.173 936.992 221.946 957.174C239.843 990.399 238.292 1020.6 218.446 1049.61C205.161 1069.22 185.215 1087.05 163.022 1101.09C145.991 1111.88 128.02 1121.12 109.329 1128.69C102.011 1129.09 94.6925 1129.92 87.4539 1130.68C78.0402 1132.06 68.4985 1132.36 59.0163 1131.57C30.5403 1128.83 1.80442 1130.99 -25.9385 1137.97C-38.8717 1141.44 -52.4561 1141.7 -65.5123 1138.73C-74.0378 1136.84 -82.1326 1133.37 -89.376 1128.49C-99.3185 1121.65 -108.266 1113.47 -115.964 1104.17C-131.476 1085.78 -145.436 1066.39 -159.078 1047.76C-174.504 1032.79 -187.369 1015.38 -197.16 996.245C-201.138 989.226 -205.115 982.128 -208.317 976.063C-230.371 952.998 -250.933 925.321 -262.507 890.564C-264.177 885.474 -265.629 880.245 -266.822 874.896C-277.641 823.716 -263.78 763.191 -207.919 734.897C-174.112 717.718 -141.041 720.76 -109.819 738.317C-94.9045 746.688 -81.9783 758.698 -68.555 770.27C-58.4726 779.058 -49.2453 788.841 -39.4612 797.948C-29.3589 807.333 -18.0037 813.039 -4.36161 813.715C24.7322 815.226 49.0533 829.145 69.9539 850.022Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M87.5136 838.41C94.6926 846.204 101.872 854.138 108.553 862.449C127.227 886.302 153.935 902.555 183.704 908.181C189.073 909.255 194.376 910.54 199.613 912.038C226.261 919.991 248.454 935.083 262.315 958.227C283.235 995.569 282.003 1030.23 259.591 1063.85C244.556 1086.61 221.707 1107.43 196.173 1123.82C176.565 1136.35 155.745 1146.87 134.028 1155.23C125.497 1155.45 116.945 1156.19 108.494 1156.86C97.4779 1158.1 86.3589 1158.1 75.3431 1156.86C42.1328 1152.57 10.6527 1155.25 -22.7565 1164.4C-37.5865 1168.9 -53.2983 1169.66 -68.4952 1166.63C-78.337 1164.67 -87.722 1160.88 -96.1573 1155.45C-107.738 1147.77 -118.09 1138.39 -126.862 1127.61C-144.581 1106.4 -160.211 1083.57 -175.265 1061.64C-191.464 1044.22 -205.628 1025 -217.484 1004.38C-222.177 996.801 -227.03 989.186 -231.405 982.644C-258.689 956.597 -283.626 924.982 -297.686 885.215C-299.759 879.308 -301.518 873.295 -302.956 867.201C-316.399 808.505 -301.345 737.959 -236.913 705.27C-197.578 685.268 -159.257 689.006 -123.004 709.665C-105.703 719.607 -90.6686 733.525 -75.2765 747.045C-63.5038 757.325 -52.785 768.738 -41.3503 779.356C-29.5577 790.332 -16.2337 796.834 -0.145645 797.251C34.0192 798.424 62.7948 814.152 87.5136 838.41Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M105.073 826.778C113.266 835.428 121.44 844.236 128.937 853.521C150.035 880.21 180.258 898.155 213.792 903.906C219.758 905.039 225.724 906.372 231.69 908.002C261.519 916.492 286.596 933.075 302.545 959.162C326.409 1000.62 325.553 1039.71 300.556 1077.95C283.752 1103.79 257.999 1127.65 229.124 1146.4C207.003 1160.78 183.377 1172.71 158.667 1181.96C148.922 1181.96 139.138 1182.59 129.474 1183.13C116.884 1184.2 104.214 1183.9 91.6894 1182.23C53.9053 1176.51 18.1097 1179.59 -19.5551 1190.92C-36.2927 1196.42 -54.1326 1197.69 -71.4785 1194.6C-82.6667 1192.6 -93.3708 1188.48 -103.018 1182.47C-116.249 1174.01 -128.027 1163.47 -137.899 1151.26C-157.785 1127.2 -175.126 1101.03 -191.592 1075.7C-208.559 1055.85 -224.052 1034.79 -237.948 1012.69C-243.436 1004.54 -249.183 996.423 -254.513 989.385C-287.047 960.375 -316.36 925.022 -332.885 879.887C-335.318 873.213 -337.402 866.417 -339.129 859.526C-355.198 793.334 -338.931 712.747 -265.928 675.644C-221.104 652.838 -177.473 657.292 -136.229 681.033C-116.541 692.346 -99.4785 708.532 -81.9586 723.842C-68.5353 735.593 -56.3051 748.676 -43.2397 760.805C-29.7369 773.332 -14.4243 780.688 4.10978 780.907C43.465 781.603 76.5161 799.319 105.073 826.778Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M122.653 815.226C131.84 824.691 141.008 834.394 149.4 844.654C174.318 874.996 205.48 893.229 243.96 899.771C250.701 900.924 257.323 902.336 263.846 904.065C296.957 913.132 324.818 931.186 342.855 960.196C369.781 1005.79 369.204 1049.33 341.701 1092.16C323.147 1121.27 294.491 1148.05 262.255 1169.11C237.598 1185.31 211.136 1198.57 183.406 1208.64C172.468 1208.5 161.531 1208.98 150.553 1209.38C136.331 1210.3 122.051 1209.7 107.957 1207.59C65.4594 1200.41 25.5672 1203.91 -16.4527 1217.41C-35.0768 1223.94 -55.0363 1225.71 -74.521 1222.58C-87.0636 1220.52 -99.094 1216.07 -109.959 1209.47C-124.814 1200.22 -137.993 1188.52 -148.936 1174.86C-171.05 1147.98 -190.041 1118.49 -207.939 1089.78C-226.155 1067.17 -242.501 1044.2 -258.41 1021.02C-264.714 1012.29 -271.317 1003.68 -277.7 996.165C-315.484 964.173 -349.152 924.963 -368.144 874.678C-370.945 867.24 -373.355 859.661 -375.362 851.971C-394.056 778.263 -376.575 687.654 -294.982 646.137C-244.689 620.508 -195.768 625.678 -149.493 652.54C-127.419 665.325 -108.348 683.578 -88.7198 700.757C-73.626 713.98 -59.9045 728.733 -45.1886 742.353C-29.9755 756.471 -12.714 764.603 8.286 764.643C52.8315 764.782 90.2577 784.347 122.653 815.226Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M140.212 803.535C150.433 813.835 160.555 824.433 169.842 835.667C197.325 868.912 231.649 888.736 274.087 895.496C281.505 896.689 288.803 898.18 295.962 900.029C332.274 909.633 362.979 929.159 383.124 961.131C412.954 1010.84 412.775 1058.82 382.746 1106.28C362.422 1138.55 330.863 1168.32 295.246 1191.78C268.046 1209.78 238.748 1224.39 208.004 1235.28C195.854 1234.97 183.604 1235.28 171.493 1235.58C155.666 1236.35 139.802 1235.45 124.163 1232.9C77.0126 1224.27 32.9045 1228.19 -13.391 1243.87C-33.9226 1251.4 -56.0091 1253.67 -77.644 1250.49C-91.5303 1248.38 -104.879 1243.6 -116.959 1236.44C-133.425 1226.4 -148.003 1213.55 -160.033 1198.48C-184.334 1168.77 -204.996 1135.93 -224.306 1103.83C-243.337 1078.74 -260.977 1053.91 -278.934 1029.33C-286.014 1020.05 -293.511 1010.94 -300.809 1002.93C-343.803 967.951 -381.886 924.904 -403.343 869.449C-406.519 861.252 -409.248 852.888 -411.517 844.396C-432.855 763.192 -414.161 662.542 -324.016 616.631C-268.335 588.178 -214.025 594.043 -162.718 624.008C-138.277 638.264 -117.178 658.605 -95.4422 677.693C-78.6978 692.387 -63.4649 708.79 -47.1182 723.942C-30.1949 739.61 -10.9648 748.557 12.541 748.378C62.1774 747.961 103.979 769.495 140.212 803.535Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M157.772 791.923C169.008 803.057 180.124 814.53 190.266 826.738C220.335 862.906 257.88 884.4 304.215 891.28C312.329 892.513 320.303 894.084 328.079 896.052C367.633 906.193 401.142 927.209 423.394 962.125C456.326 1015.95 456.386 1068.38 423.792 1120.44C401.718 1155.87 367.275 1188.62 328.338 1214.35C298.594 1234.15 266.46 1250.1 232.704 1261.83C219.34 1261.33 205.877 1261.59 192.513 1261.69C174.914 1261.93 157.374 1261.43 140.471 1258.11C88.7661 1248.05 40.3626 1252.36 -10.2284 1270.22C-32.6534 1278.77 -56.8643 1281.56 -80.6461 1278.33C-95.8868 1276.16 -110.562 1271.06 -123.859 1263.3C-141.96 1252.48 -157.949 1238.47 -171.05 1221.94C-197.558 1189.39 -219.891 1153.22 -240.652 1117.73C-260.538 1090.17 -279.45 1063.45 -299.416 1037.5C-307.145 1027.74 -315.383 1018.4 -324.095 1009.51C-372.32 971.569 -414.777 924.684 -438.72 864.06C-442.266 855.1 -445.319 845.953 -447.868 836.66C-471.831 747.94 -451.846 637.289 -353.189 586.944C-291.939 555.687 -232.399 562.269 -176.081 595.315C-149.254 611.063 -126.146 633.471 -102.322 654.389C-83.8876 670.574 -67.1631 688.628 -49.1659 705.27C-30.5523 722.509 -9.39315 732.272 16.4592 731.894C71.5445 731.139 117.721 754.562 157.772 791.923Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M175.332 780.291C187.745 792.192 199.546 804.715 210.69 817.811C243.343 856.902 283.991 879.887 334.343 887.085C343.153 888.358 351.784 889.988 360.196 892.056C402.991 902.753 439.324 925.241 463.605 963.119C499.54 1021.04 499.937 1077.95 464.778 1134.61C440.914 1173.21 403.608 1208.94 361.369 1236.99C329.103 1258.6 294.152 1275.9 257.403 1288.45C242.846 1287.78 228.13 1287.93 213.553 1287.88C194.323 1287.88 175.212 1287.2 156.778 1283.4C100.34 1271.89 47.8201 1276.64 -7.08619 1296.66C-33.5948 1306.61 -59.3278 1309.55 -83.6687 1306.23C-100.247 1304.02 -116.24 1298.62 -130.76 1290.32C-150.487 1278.71 -167.884 1263.53 -182.067 1245.56C-210.763 1210.19 -234.785 1170.66 -256.939 1131.81C-277.601 1101.77 -297.845 1073.17 -319.82 1045.81C-328.369 1035.44 -337.512 1025.58 -347.203 1016.27C-400.678 975.328 -447.51 924.605 -473.939 858.85C-477.825 849.092 -481.197 839.136 -484.042 829.025C-510.61 732.789 -489.51 612.117 -382.203 557.358C-315.465 523.298 -250.635 530.575 -189.305 566.743C-160.112 583.942 -134.976 608.498 -109.044 631.225C-88.9585 648.881 -70.7226 668.586 -51.0948 686.759C-30.7709 705.569 -7.58333 716.127 20.7548 715.531C80.9111 714.318 131.462 739.669 175.332 780.291Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M192.891 768.679C206.348 781.394 219.113 794.822 231.133 808.903C266.352 850.897 310.161 875.473 364.471 882.869C373.957 884.182 383.284 885.892 392.312 888.079C438.329 899.333 477.505 923.292 503.934 964.113C542.872 1026.15 543.588 1087.39 505.923 1148.77C480.329 1190.53 440.079 1229.24 394.44 1259.66C359.613 1283.06 321.812 1301.7 282.042 1315.08C266.272 1314.24 250.323 1314.26 234.513 1314.08C213.692 1313.9 192.971 1312.97 173.025 1308.69C111.934 1295.75 55.1978 1300.9 -3.96422 1323.09C-32.6006 1334.1 -60.4019 1337.54 -86.7116 1334.14C-104.644 1331.85 -121.961 1326.1 -137.7 1317.2C-159.048 1304.78 -177.854 1288.44 -193.124 1269.03C-224.027 1230.81 -249.72 1187.96 -273.286 1145.75C-294.763 1113.24 -316.3 1082.76 -340.303 1054.01C-349.68 1043.02 -359.735 1032.64 -370.411 1022.91C-429.096 978.986 -480.323 924.426 -509.218 853.502C-513.482 843.006 -517.173 832.286 -520.275 821.39C-549.488 717.658 -527.175 586.944 -411.277 527.791C-339.09 490.888 -268.931 498.881 -202.59 538.151C-170.99 556.821 -143.845 583.405 -115.805 608.061C-94.0696 627.189 -74.3224 648.563 -53.0439 668.228C-31.0297 688.608 -5.87338 700.041 24.9306 699.186C90.2774 697.496 145.184 724.776 192.891 768.679Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M210.451 757.047C224.925 770.597 238.647 784.928 251.556 799.975C289.34 844.872 336.312 871.039 394.599 878.674C404.661 879.974 414.651 881.786 424.528 884.102C473.767 895.893 515.767 921.324 544.284 965.107C586.231 1031.25 587.146 1097.19 547.028 1162.93C519.685 1207.86 476.531 1249.56 427.551 1282.23C389.031 1307.96 347.25 1328.62 306.761 1341.62C289.778 1340.61 272.596 1340.53 255.573 1340.19C233.141 1339.83 210.809 1338.64 189.352 1333.9C123.627 1319.51 62.6752 1325.08 -0.802239 1349.45C-31.5665 1361.5 -61.4557 1365.36 -89.7342 1361.96C-109.012 1359.62 -127.648 1353.54 -144.601 1344.07C-166.138 1332.02 -186.183 1314.8 -204.161 1292.37C-237.252 1251.33 -264.615 1205.14 -289.672 1159.51C-311.965 1124.51 -334.814 1092.16 -360.826 1062.08C-371.037 1050.48 -381.998 1039.57 -393.638 1029.41C-457.573 982.525 -513.155 924.028 -544.536 848.034C-549.16 836.771 -553.177 825.269 -556.567 813.576C-588.386 702.229 -564.86 561.593 -440.411 498.026C-362.735 458.259 -287.286 467.028 -215.913 509.379C-181.947 529.541 -152.774 558.193 -122.626 584.698C-99.22 605.317 -77.9814 628.441 -55.0126 649.538C-31.2881 671.409 -4.16295 683.677 29.0871 682.663C99.6441 680.675 158.906 709.844 210.451 757.047Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M228.03 745.435C243.524 759.803 258.197 775.031 271.979 791.047C312.368 838.867 362.482 866.604 424.727 874.558C435.461 875.9 446.12 877.785 456.664 880.205C509.125 892.552 553.968 919.475 584.574 966.201C629.517 1036.47 630.909 1106.7 588.113 1177.2C559 1225.28 512.943 1269.98 460.622 1305.05C419.477 1332.61 374.772 1354.76 331.36 1368.42C313.164 1367.23 294.749 1367.03 276.493 1366.55C252.451 1366 228.547 1364.56 205.559 1359.38C135.181 1343.47 70.0129 1349.43 2.24009 1376.06C-30.6321 1389.16 -62.6095 1393.61 -92.8368 1389.98C-113.467 1387.58 -133.43 1381.18 -151.601 1371.13C-174.669 1358.36 -196.107 1340.01 -215.238 1316.13C-250.516 1272.27 -279.59 1222.68 -305.979 1173.7C-329.087 1136.24 -353.229 1102 -381.249 1070.47C-392.298 1058.27 -404.165 1046.84 -416.766 1036.25C-485.951 986.382 -545.909 924.147 -579.835 842.884C-584.831 830.859 -589.173 818.573 -592.841 806.079C-627.304 687.336 -602.545 536.56 -469.545 468.578C-386.38 426.067 -305.621 435.472 -229.238 480.926C-192.885 502.559 -161.624 533.279 -129.468 561.672C-104.391 583.763 -81.7402 608.458 -57.081 631.145C-31.6463 654.667 -2.55255 667.671 33.1435 666.438C108.99 663.853 172.647 694.971 228.03 745.435Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M245.59 733.804C262.117 748.996 277.754 765.128 292.422 782.121C335.377 832.863 388.653 862.191 454.855 870.244C466.263 871.658 477.593 873.649 488.801 876.209C544.483 889.094 592.21 917.507 624.863 967.176C672.829 1041.56 674.579 1116.3 629.179 1191.34C598.315 1242.6 549.355 1290.26 493.693 1327.68C449.943 1357.07 402.315 1380.53 356.119 1395.03C336.709 1393.66 317.082 1393.36 297.593 1392.72C271.939 1391.97 246.425 1390.33 221.925 1384.65C146.914 1367.37 77.53 1373.75 5.46171 1402.54C-29.5185 1416.7 -63.584 1421.63 -95.7999 1417.99C-117.777 1415.51 -139.056 1408.75 -158.442 1398.11C-183.022 1384.63 -205.851 1365.14 -226.235 1339.71C-263.721 1293.01 -294.465 1240.14 -322.266 1187.72C-346.13 1147.8 -371.624 1111.67 -401.673 1078.74C-413.558 1065.92 -426.337 1053.96 -439.914 1042.95C-514.309 990.101 -578.662 924.009 -614.914 837.595C-620.277 824.813 -624.924 811.743 -628.835 798.445C-665.983 672.066 -640.13 511.348 -498.46 438.953C-409.846 393.698 -323.778 403.64 -242.402 452.294C-203.684 475.379 -170.473 508.187 -136.129 538.449C-109.382 562.031 -85.1407 588.377 -58.9503 612.575C-31.8054 637.648 -0.742797 651.467 37.4192 650.035C118.357 647.033 186.388 680.019 245.59 733.804Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M263.15 722.192C280.705 738.205 297.306 755.235 312.866 773.193C358.406 826.878 414.843 857.757 485.002 866.048C497.085 867.459 509.086 869.497 520.957 872.152C579.9 885.594 630.332 915.459 665.173 968.09C716.142 1046.57 718.17 1125.71 670.264 1205.44C637.65 1259.84 585.807 1310.5 526.764 1350.25C480.389 1381.47 429.877 1406.34 380.818 1421.57C360.195 1420.02 339.335 1419.58 318.613 1418.83C291.349 1417.87 264.263 1416 238.232 1409.86C158.567 1391.13 84.9876 1397.93 8.62383 1428.83C-28.4842 1444.04 -64.6377 1449.49 -98.7627 1445.75C-122.087 1443.24 -144.693 1436.18 -165.303 1424.97C-191.413 1410.79 -215.615 1390.16 -237.212 1363.17C-276.985 1313.62 -309.32 1257.43 -338.632 1201.62C-363.371 1159.23 -390.118 1121.21 -422.155 1086.89C-434.901 1073.48 -448.598 1061 -463.141 1049.55C-542.687 993.74 -611.513 923.81 -650.292 832.227C-656.028 818.679 -661.014 804.826 -665.227 790.73C-704.999 656.954 -677.775 486.155 -527.593 409.346C-433.49 361.208 -342.112 372.025 -255.706 423.682C-214.581 448.258 -179.362 483.133 -142.91 515.265C-114.513 540.318 -88.7598 568.334 -60.9188 594.023C-32.0835 620.687 0.947711 635.301 41.5557 633.671C127.723 630.31 200.11 665.186 263.15 722.192Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M280.609 710.56C299.188 727.388 316.739 745.315 333.169 764.246C381.294 820.794 440.874 853.303 515.01 861.794C527.773 863.242 540.452 865.359 552.993 868.136C615.138 882.055 668.453 913.471 705.343 969.045C759.314 1051.64 761.681 1135.23 711.309 1219.58C676.925 1277.24 622.178 1330.78 559.794 1372.9C510.814 1405.92 457.379 1432.21 405.456 1448.2C383.581 1446.47 361.527 1445.93 339.592 1444.99C310.717 1443.84 282.021 1441.75 254.478 1435.05C170.18 1414.87 92.3644 1422.11 11.7051 1455.17C-27.5108 1471.44 -65.7525 1477.36 -101.866 1473.57C-126.542 1470.99 -150.474 1463.6 -172.304 1451.81C-199.926 1436.92 -225.54 1415.15 -248.349 1386.6C-290.111 1334.24 -324.315 1274.69 -354.96 1215.5C-380.514 1170.64 -408.554 1130.76 -442.62 1095.03C-456.218 1080.99 -470.841 1068 -486.37 1056.13C-571.245 997.319 -644.347 923.571 -685.631 826.819C-691.728 812.505 -697.039 797.869 -701.54 782.976C-743.879 641.803 -715.461 460.864 -556.748 379.641C-457.176 328.679 -360.489 340.212 -269.051 394.971C-225.54 421.018 -188.312 457.981 -149.733 491.982C-119.664 518.506 -92.4201 548.173 -62.9484 575.492C-32.4029 603.707 2.67667 619.236 45.6711 617.248C137.069 613.39 213.85 650.294 280.609 710.56Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M298.268 698.948C317.88 716.605 336.396 735.443 353.712 755.357C404.402 814.828 467.064 848.809 545.218 857.617C558.66 859.105 572.015 861.296 585.229 864.179C650.615 878.714 706.755 911.541 745.732 970.078C802.707 1056.79 805.391 1144.83 752.394 1233.75C716.26 1294.52 658.61 1351.06 592.885 1395.56C541.28 1430.42 484.922 1458.1 430.135 1474.84C407.106 1472.85 383.78 1472.27 360.533 1471.2C330.067 1469.85 299.78 1467.54 270.706 1460.44C181.754 1438.83 99.6827 1446.52 14.7876 1481.7C-26.5562 1499.02 -66.866 1505.56 -104.968 1501.58C-130.978 1498.95 -156.22 1491.24 -179.264 1478.89C-208.437 1463.29 -235.423 1440.36 -259.446 1410.26C-303.514 1355.06 -339.29 1292.19 -371.347 1229.6C-397.716 1182.25 -427.029 1140.5 -463.162 1103.38C-477.567 1088.74 -493.056 1075.22 -509.497 1062.91C-599.623 1001.14 -677.1 923.551 -720.85 821.628C-727.259 806.554 -732.842 791.141 -737.575 775.459C-782.538 626.652 -752.947 435.791 -585.603 350.173C-480.563 296.487 -378.565 308.616 -282.116 366.497C-236.238 393.996 -196.983 433.047 -156.335 468.916C-124.517 496.912 -95.8404 528.249 -64.7182 556.98C-32.4824 586.805 4.50634 603.01 50.0064 600.942C146.436 596.508 227.572 635.4 298.268 698.948Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M315.829 687.316C336.447 705.799 355.9 725.541 374.076 746.43C427.352 808.804 493.255 844.495 575.287 853.422C589.403 854.943 603.429 857.206 617.307 860.203C685.915 875.274 744.898 909.593 785.963 971.073C845.941 1061.9 848.963 1154.38 793.421 1247.93C755.517 1311.83 694.963 1371.43 625.898 1418.21C571.667 1454.9 512.406 1483.99 454.755 1501.46C430.514 1499.37 405.954 1498.6 381.573 1497.39C349.477 1495.84 317.599 1493.29 287.013 1485.73C193.408 1462.67 107.201 1470.72 17.9504 1508.12C-25.5213 1526.52 -67.9191 1533.44 -107.97 1529.46C-135.335 1526.79 -161.905 1518.76 -186.164 1505.82C-216.848 1489.49 -245.246 1465.43 -270.462 1433.78C-316.738 1375.76 -354.184 1309.53 -387.653 1243.55C-414.837 1193.75 -445.482 1150.1 -483.604 1111.59C-498.857 1096.35 -515.259 1082.3 -532.664 1069.58C-628 1004.81 -709.872 923.372 -756.088 816.3C-762.945 800.471 -768.92 784.274 -773.986 767.784C-821.574 611.461 -790.79 410.579 -614.894 320.546C-504.366 263.958 -397.079 276.803 -295.598 337.865C-247.334 366.855 -206.11 407.974 -163.314 445.713C-129.905 475.2 -99.6776 508.167 -66.9048 538.409C-32.9587 569.765 5.99877 586.825 53.9648 584.539C155.803 579.747 241.314 620.508 315.829 687.316Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M333.408 675.704C355.061 695.007 375.485 715.647 394.559 737.502C450.42 802.799 519.485 840.06 605.474 849.207C620.264 850.763 634.961 853.106 649.502 856.226C721.352 871.854 783.159 907.624 826.332 972.066C889.293 1067.01 892.653 1163.94 834.545 1262.09C794.892 1329.17 731.434 1391.75 659.008 1440.86C602.173 1479.37 540.008 1509.87 479.494 1528.09C454.039 1525.82 428.246 1524.94 402.633 1523.57C368.945 1521.84 335.476 1519.06 303.36 1511.03C205.121 1486.51 114.678 1494.98 21.1321 1534.57C-24.4475 1553.99 -68.9335 1561.43 -110.953 1557.37C-139.66 1554.63 -167.546 1546.26 -193.024 1532.76C-225.24 1515.72 -255.01 1490.55 -281.439 1457.32C-329.903 1396.46 -368.939 1326.91 -403.94 1257.53C-431.92 1205.26 -463.877 1159.73 -504.008 1119.82C-520.085 1103.98 -537.372 1089.41 -555.713 1076.26C-656.298 1008.51 -742.525 923.153 -791.227 810.991C-798.418 794.394 -804.691 777.414 -810.02 760.129C-860.233 596.31 -828.236 385.406 -643.75 290.88C-527.752 231.488 -415.056 245.148 -308.664 309.193C-258.013 339.654 -214.721 382.762 -169.857 422.528C-134.797 453.487 -103.019 488.144 -68.6351 519.957C-32.9987 552.864 7.94738 570.74 58.3594 568.254C165.169 562.925 255.036 605.615 333.408 675.704Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M350.967 664.112C373.653 684.239 395.04 705.782 415.001 728.614C473.428 796.834 545.655 835.686 635.601 845.051C651.065 846.634 666.432 849.043 681.638 852.269C756.729 868.454 821.34 905.715 866.582 973.1C932.565 1072.14 936.184 1173.53 875.57 1276.3C834.167 1346.53 767.806 1412.11 692.039 1463.54C632.578 1503.91 567.51 1535.78 504.132 1554.75C477.464 1552.3 450.439 1551.31 423.612 1549.8C388.314 1547.81 353.234 1544.87 319.606 1536.36C216.714 1510.41 122.075 1519.28 24.2337 1561.03C-23.4937 1581.51 -70.0279 1589.45 -114.017 1585.31C-144.075 1582.52 -173.287 1573.83 -199.985 1559.74C-233.792 1542 -264.875 1515.68 -292.537 1480.9C-343.187 1417.28 -384.014 1344.3 -420.307 1271.55C-449.122 1216.81 -482.352 1169.39 -524.512 1128.09C-541.46 1111.65 -559.672 1096.55 -578.98 1082.96C-684.796 1012.25 -775.418 923.133 -826.586 805.721C-834.154 788.365 -840.753 770.601 -846.353 752.513C-899.191 581.178 -865.981 360.253 -672.904 261.393C-551.438 199.158 -433.531 213.514 -322.028 280.68C-268.991 312.613 -223.71 357.907 -176.718 399.384C-139.988 431.814 -106.718 468.121 -70.7039 501.366C-33.3374 535.844 9.55758 554.534 62.4156 551.85C174.515 546.104 268.677 590.702 350.967 664.112Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M368.528 652.44C392.239 673.398 414.584 695.852 435.425 719.666C496.437 790.769 571.806 831.212 665.73 840.796C681.874 842.417 697.919 844.906 713.795 848.252C792.088 864.974 859.543 903.727 906.912 974.055C975.898 1077.21 979.895 1183.05 916.696 1290.42C873.523 1363.83 804.278 1432.37 725.15 1486.15C663.085 1528.35 595.073 1561.71 528.852 1581.33C501.011 1578.73 472.732 1577.62 444.653 1575.97C407.744 1573.82 371.073 1570.58 335.934 1561.61C228.408 1534.21 129.553 1543.5 27.4162 1587.46C-22.2998 1608.99 -71.0613 1617.42 -117.019 1613.31C-148.426 1610.43 -178.957 1601.39 -206.866 1586.7C-242.124 1568.25 -274.678 1540.79 -303.533 1504.45C-356.391 1437.94 -398.869 1361.7 -436.593 1285.57C-466.224 1228.34 -500.767 1179.03 -544.934 1136.44C-562.721 1119.34 -581.839 1103.69 -602.108 1089.62C-713.173 1015.93 -808.17 922.974 -861.804 800.393C-869.732 782.271 -876.65 763.725 -882.526 744.838C-938.009 566.007 -903.585 335.041 -701.937 231.846C-574.983 166.807 -451.786 181.879 -335.252 252.147C-279.849 285.551 -232.539 332.914 -183.459 376.259C-145.059 410.061 -110.277 448.118 -72.6124 482.894C-33.5555 518.943 11.3083 538.449 66.5924 535.526C183.882 529.282 282.499 575.81 368.528 652.44Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M386.007 640.829C410.745 662.603 434.048 685.955 455.769 710.739C519.405 784.764 597.897 826.778 695.778 836.581C712.595 838.249 729.31 840.818 745.852 844.276C827.386 861.534 897.665 901.759 947.122 975.049C1019.11 1082.32 1023.45 1192.59 957.682 1304.58C912.758 1381.15 840.61 1452.69 758.122 1508.82C693.451 1552.82 622.536 1587.52 553.451 1607.96C524.357 1605.17 494.885 1603.98 465.593 1602.15C427.073 1599.81 388.811 1596.35 352.141 1586.9C239.962 1558.05 136.891 1567.77 30.4586 1613.86C-21.4648 1636.45 -72.2149 1645.38 -120.121 1641.08C-152.875 1638.18 -184.733 1628.83 -213.866 1613.59C-250.636 1594.42 -284.582 1565.86 -314.65 1527.93C-369.695 1458.57 -413.863 1379 -453 1299.49C-483.446 1239.84 -519.301 1188.62 -565.477 1144.54C-584.112 1126.86 -604.143 1110.73 -625.375 1096.28C-741.551 1019.59 -840.983 922.795 -897.083 795.064C-905.367 776.177 -912.604 756.848 -918.759 737.164C-976.867 550.816 -941.23 309.829 -731.011 202.14C-598.588 134.298 -470.062 150.066 -348.516 223.436C-290.727 258.311 -241.408 307.761 -190.221 352.976C-150.17 388.369 -113.877 427.976 -74.5813 464.323C-33.8142 501.943 13.0182 522.264 70.7882 519.142C193.249 512.461 296.24 560.917 386.007 640.829Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M403.647 629.197C429.419 651.821 453.686 676.104 476.292 701.891C542.454 778.84 624.147 822.444 725.985 832.465C743.477 834.178 760.863 836.833 778.068 840.419C862.824 858.314 935.946 899.95 987.492 976.183C1062.48 1087.53 1067.14 1202.3 998.847 1318.89C952.153 1398.63 877.102 1473.13 791.272 1531.65C723.977 1577.38 650.079 1613.57 578.21 1634.76C547.903 1631.8 517.198 1630.47 486.732 1628.52C446.601 1625.97 406.769 1622.29 368.567 1612.37C251.755 1582.09 144.467 1592.21 33.74 1640.49C-20.3113 1664.13 -73.1295 1673.55 -123.024 1669.18C-157.123 1666.21 -190.299 1656.53 -220.647 1640.71C-258.948 1620.82 -294.226 1591 -325.548 1551.65C-382.8 1479.45 -428.658 1396.56 -469.207 1313.64C-500.468 1251.47 -537.596 1198.42 -585.801 1152.95C-605.286 1134.67 -626.222 1118.01 -648.403 1103.12C-769.929 1023.47 -873.676 922.835 -932.282 789.935C-940.944 770.288 -948.506 750.175 -954.932 729.688C-1015.65 535.824 -978.796 284.816 -760.045 172.713C-622.233 101.889 -488.357 118.352 -361.721 194.863C-301.564 231.21 -250.218 282.748 -196.922 329.832C-155.161 366.696 -117.377 407.954 -76.4704 445.753C-34.0129 484.923 14.8082 506.059 75.0242 502.718C202.615 495.64 309.982 546.025 403.647 629.197Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M421.206 617.585C447.715 641.187 473.587 666.021 496.775 692.884C565.522 772.735 650.377 817.93 756.173 828.091C774.337 829.821 792.394 832.536 810.264 836.223C898.261 854.596 974.207 897.862 1027.84 976.958C1105.84 1092.46 1110.83 1211.58 1039.97 1332.87C991.528 1415.77 913.574 1493.27 824.403 1554.07C754.483 1601.79 677.761 1639.24 602.948 1661.17C571.448 1658.02 539.491 1656.57 507.732 1654.49C465.971 1651.74 424.567 1647.82 384.854 1637.45C263.388 1605.63 151.905 1616.25 36.8421 1666.69C-19.337 1691.39 -74.2433 1701.31 -126.087 1696.86C-161.534 1693.84 -196.035 1683.84 -227.607 1667.45C-267.38 1646.85 -304.15 1615.97 -336.644 1574.97C-396.085 1499.95 -443.613 1413.7 -485.574 1327.4C-517.65 1262.76 -556.091 1207.82 -606.304 1160.96C-626.634 1142.08 -648.476 1124.9 -671.611 1109.58C-798.367 1026.94 -906.509 922.477 -967.58 784.407C-976.605 763.997 -984.486 743.1 -991.185 721.814C-1054.54 520.454 -1016.5 259.405 -789.119 142.908C-645.739 69.3393 -506.613 86.618 -375.045 166.192C-312.482 203.97 -259.127 257.655 -203.724 306.569C-160.351 344.904 -121.056 387.832 -78.4393 427.122C-34.2716 467.883 16.5183 489.814 79.1803 486.275C211.962 478.819 323.703 531.132 421.206 617.585Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M438.786 605.953C466.289 630.41 493.155 656.119 517.138 683.956C588.471 766.73 676.468 813.516 786.241 823.975C805.086 825.73 823.821 828.518 842.36 832.326C933.559 851.235 1012.35 895.953 1068.07 978.032C1149.09 1097.65 1154.4 1221.27 1081 1347.07C1030.78 1433.14 949.926 1513.61 857.315 1576.76C784.789 1626.25 705.124 1665.16 627.468 1687.85C594.755 1684.53 561.584 1682.96 528.593 1680.71C485.26 1677.79 442.226 1673.63 400.982 1662.82C274.862 1629.65 159.163 1640.59 39.8645 1693.22C-18.4425 1719.07 -75.4767 1729.39 -129.17 1724.83C-165.96 1721.76 -201.779 1711.44 -234.568 1694.47C-275.912 1673.16 -314.113 1641.14 -347.722 1598.57C-409.369 1520.73 -458.568 1431.15 -501.941 1341.46C-534.733 1274.31 -574.506 1217.49 -626.807 1169.15C-647.978 1149.67 -670.719 1131.97 -694.799 1116.22C-826.805 1030.6 -939.401 922.279 -1002.86 779.058C-1012.25 757.885 -1020.46 736.205 -1027.44 714.119C-1093.42 505.343 -1054.15 234.193 -818.214 113.262C-669.364 36.9692 -524.929 54.8642 -388.329 137.539C-323.4 176.829 -268.017 232.523 -210.525 283.345C-165.462 323.111 -124.676 367.75 -80.4482 408.61C-34.5902 450.922 18.1685 473.669 83.2965 469.931C221.328 461.997 337.445 516.239 438.786 605.953Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M456.345 594.341C484.882 619.613 512.703 646.217 537.581 675.028C611.479 760.726 702.638 809.082 816.369 819.76C835.885 821.564 855.29 824.424 874.497 828.329C968.937 847.795 1050.53 893.945 1108.36 979.026C1192.38 1102.74 1198.01 1230.79 1122.06 1361.24C1070.1 1450.48 986.358 1533.93 890.465 1599.41C815.335 1650.73 732.766 1691.03 652.246 1714.47C618.32 1710.97 583.917 1709.28 549.692 1706.9C504.749 1703.78 460.203 1699.4 417.348 1688.07C286.615 1653.45 166.779 1664.8 43.086 1719.6C-17.3289 1746.41 -76.3915 1757.38 -132.173 1752.71C-170.343 1749.59 -207.517 1738.94 -241.548 1721.37C-284.403 1699.36 -323.918 1666.2 -358.878 1622.08C-422.713 1541.41 -473.602 1448.47 -518.406 1355.4C-552.094 1285.81 -593.139 1227.11 -647.409 1177.46C-669.435 1157.37 -693.088 1139.14 -718.126 1122.96C-855.342 1034.38 -972.234 922.139 -1038.3 773.829C-1048.06 751.893 -1056.59 729.429 -1063.85 706.543C-1132.46 490.172 -1091.97 209.08 -847.447 83.7348C-693.128 4.63854 -543.384 23.2296 -401.773 109.007C-334.457 149.788 -277.065 207.549 -217.466 260.24C-170.752 301.538 -128.454 347.727 -82.596 390.079C-35.0278 433.981 19.7195 457.543 87.2934 453.566C230.694 445.176 351.166 501.346 456.345 594.341Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M676.926 1741.1C595.631 1732.59 511.651 1734.14 433.636 1713.26C298.25 1677.19 174.139 1688.96 46.2292 1745.95C-112.405 1816.59 -262.229 1781.54 -369.774 1645.52C-435.817 1562.01 -488.377 1465.75 -534.593 1369.28C-582.718 1268.83 -646.056 1190.64 -741.153 1129.54C-904.4 1024.62 -1039.81 887.621 -1099.88 698.789C-1171.12 474.901 -1129.43 183.808 -876.34 54.0489C-716.533 -27.8708 -561.499 -8.56398 -414.857 80.295C-345.254 122.547 -285.754 182.377 -223.947 236.937C-175.564 279.726 -131.774 327.625 -84.2652 371.448C-34.9867 416.902 21.7094 441.279 91.7294 437.063C240.161 428.215 365.008 486.295 474.006 582.57C503.557 608.677 532.372 636.255 558.105 666.081C634.588 754.681 728.909 804.628 846.597 815.505C969.634 826.898 1076.84 874.32 1148.75 979.98C1235.77 1107.81 1241.74 1240.33 1163.23 1375.36C1067.34 1540.22 866.722 1686.12 676.926 1741.1Z"
                        stroke="#224946"
                        strokeMiterlimit="10"/>
                    <path
                        d="M20.4961 952.204C23.3796 964.869 20.1778 972.823 13.0784 978.788C8.4152 982.541 3.2301 985.595 -2.31368 987.854C-8.44464 990.273 -13.7428 994.417 -17.5665 999.784C-23.9501 1009.03 -31.487 1010.96 -41.5694 1005.85C-44.7444 1004.03 -47.6776 1001.82 -50.2996 999.268C-51.9917 997.665 -53.457 995.838 -54.6547 993.839C-57.1431 989.789 -60.4318 986.29 -64.3196 983.555C-68.2075 980.82 -72.6127 978.907 -77.2656 977.933C-88.9787 975.069 -94.9047 967.176 -94.7059 955.146C-94.8154 950.893 -94.0013 946.667 -92.3196 942.759C-88.1832 934.587 -86.8508 926.097 -86.0553 917.129C-85.6306 909.814 -83.2046 902.756 -79.0419 896.725C-74.8793 890.694 -69.14 885.922 -62.4502 882.93C-58.0111 880.64 -52.9886 879.728 -48.0278 880.313C-43.0669 880.897 -38.3942 882.951 -34.6092 886.21C-27.146 891.842 -21.363 899.405 -17.8847 908.082C-13.1716 920.509 -4.14321 928.88 6.65511 935.919C13.9534 940.492 19.1836 946.775 20.4961 952.204Z"
                        stroke="#224946"
                        strokeWidth="3"
                        strokeMiterlimit="10"/>
                    <path
                        d="M52.3936 861.653C57.5243 867.797 62.7544 873.941 67.6862 880.284C81.5488 898.469 101.267 911.308 123.507 916.631C127.485 917.645 131.462 918.778 135.28 920.051C155.465 926.911 172.01 939.039 181.695 956.278C196.61 985.408 194.701 1011.16 177.419 1035.55C165.885 1052 148.843 1066.85 129.99 1078.54C115.5 1087.52 100.364 1095.42 84.709 1102.16C78.6038 1102.74 72.5187 1103.69 66.513 1104.67C58.7107 1106.2 50.7529 1106.81 42.8083 1106.46C18.7524 1105.15 -5.37234 1106.91 -28.9816 1111.71C-40.0145 1114.15 -51.4725 1113.9 -62.3907 1110.99C-69.5365 1109.16 -76.2798 1106.02 -82.2771 1101.73C-90.588 1095.7 -98.1282 1088.67 -104.729 1080.81C-118.073 1065.26 -130.323 1049.11 -142.513 1033.96C-157.135 1021.48 -168.74 1005.84 -176.459 988.231C-179.561 981.789 -182.644 975.188 -184.911 969.6C-201.715 949.538 -217.902 925.678 -226.99 896.032C-228.316 891.684 -229.437 887.243 -230.351 882.71C-238.544 838.966 -225.897 788.542 -178.647 764.662C-150.249 750.286 -122.547 752.732 -96.3368 767.088C-83.7885 773.967 -72.7913 783.909 -61.5754 793.612C-53.1436 800.929 -45.4475 809.042 -37.2941 816.637C-29.1406 824.233 -19.4957 829.363 -8.31955 830.238C15.385 832.067 35.331 843.937 52.3936 861.653Z"
                        stroke="#224946"
                        strokeWidth="3"
                        strokeMiterlimit="10"/>
                    <path
                        d="M122.653 815.226C131.84 824.691 141.008 834.394 149.4 844.654C174.318 874.996 205.48 893.229 243.96 899.771C250.701 900.924 257.323 902.336 263.846 904.065C296.957 913.132 324.818 931.186 342.855 960.196C369.781 1005.79 369.204 1049.33 341.701 1092.16C323.147 1121.27 294.491 1148.05 262.255 1169.11C237.598 1185.31 211.136 1198.57 183.406 1208.64C172.468 1208.5 161.531 1208.98 150.553 1209.38C136.331 1210.3 122.051 1209.7 107.957 1207.59C65.4594 1200.41 25.5672 1203.91 -16.4527 1217.41C-35.0768 1223.94 -55.0363 1225.71 -74.521 1222.58C-87.0636 1220.52 -99.094 1216.07 -109.959 1209.47C-124.814 1200.22 -137.993 1188.52 -148.936 1174.86C-171.05 1147.98 -190.041 1118.49 -207.939 1089.78C-226.155 1067.17 -242.501 1044.2 -258.41 1021.02C-264.714 1012.29 -271.317 1003.68 -277.7 996.165C-315.484 964.173 -349.152 924.963 -368.144 874.678C-370.945 867.24 -373.355 859.661 -375.362 851.971C-394.056 778.263 -376.575 687.654 -294.982 646.137C-244.689 620.508 -195.768 625.678 -149.493 652.54C-127.419 665.325 -108.348 683.578 -88.7198 700.757C-73.626 713.98 -59.9045 728.733 -45.1886 742.353C-29.9755 756.471 -12.714 764.603 8.286 764.643C52.8315 764.782 90.2577 784.347 122.653 815.226Z"
                        stroke="#224946"
                        strokeWidth="3"
                        strokeMiterlimit="10"/>
                    <path
                        d="M192.891 768.679C206.348 781.394 219.113 794.822 231.133 808.903C266.352 850.897 310.161 875.473 364.471 882.869C373.957 884.182 383.284 885.892 392.312 888.079C438.329 899.333 477.505 923.292 503.934 964.113C542.872 1026.15 543.588 1087.39 505.923 1148.77C480.329 1190.53 440.079 1229.24 394.44 1259.66C359.613 1283.06 321.812 1301.7 282.042 1315.08C266.272 1314.24 250.323 1314.26 234.513 1314.08C213.692 1313.9 192.971 1312.97 173.025 1308.69C111.934 1295.75 55.1978 1300.9 -3.96422 1323.09C-32.6006 1334.1 -60.4019 1337.54 -86.7116 1334.14C-104.644 1331.85 -121.961 1326.1 -137.7 1317.2C-159.048 1304.78 -177.854 1288.44 -193.124 1269.03C-224.027 1230.81 -249.72 1187.96 -273.286 1145.75C-294.763 1113.24 -316.3 1082.76 -340.303 1054.01C-349.68 1043.02 -359.735 1032.64 -370.411 1022.91C-429.096 978.986 -480.323 924.426 -509.218 853.502C-513.482 843.006 -517.173 832.286 -520.275 821.39C-549.488 717.658 -527.175 586.944 -411.277 527.791C-339.09 490.888 -268.931 498.881 -202.59 538.151C-170.99 556.821 -143.845 583.405 -115.805 608.061C-94.0696 627.189 -74.3224 648.563 -53.0439 668.228C-31.0297 688.608 -5.87338 700.041 24.9306 699.186C90.2774 697.496 145.184 724.776 192.891 768.679Z"
                        stroke="#224946"
                        strokeWidth="3"
                        strokeMiterlimit="10"/>
                    <path
                        d="M263.15 722.192C280.705 738.205 297.306 755.235 312.866 773.193C358.406 826.878 414.843 857.757 485.002 866.048C497.085 867.459 509.086 869.497 520.957 872.152C579.9 885.594 630.332 915.459 665.173 968.09C716.142 1046.57 718.17 1125.71 670.264 1205.44C637.65 1259.84 585.807 1310.5 526.764 1350.25C480.389 1381.47 429.877 1406.34 380.818 1421.57C360.195 1420.02 339.335 1419.58 318.613 1418.83C291.349 1417.87 264.263 1416 238.232 1409.86C158.567 1391.13 84.9876 1397.93 8.62383 1428.83C-28.4842 1444.04 -64.6377 1449.49 -98.7627 1445.75C-122.087 1443.24 -144.693 1436.18 -165.303 1424.97C-191.413 1410.79 -215.615 1390.16 -237.212 1363.17C-276.985 1313.62 -309.32 1257.43 -338.632 1201.62C-363.371 1159.23 -390.118 1121.21 -422.155 1086.89C-434.901 1073.48 -448.598 1061 -463.141 1049.55C-542.687 993.74 -611.513 923.81 -650.292 832.227C-656.028 818.679 -661.014 804.826 -665.227 790.73C-704.999 656.954 -677.775 486.155 -527.593 409.346C-433.49 361.208 -342.112 372.025 -255.706 423.682C-214.581 448.258 -179.362 483.133 -142.91 515.265C-114.513 540.318 -88.7598 568.334 -60.9188 594.023C-32.0835 620.687 0.947711 635.301 41.5557 633.671C127.723 630.31 200.11 665.186 263.15 722.192Z"
                        stroke="#224946"
                        strokeWidth="3"
                        strokeMiterlimit="10"/>
                    <path
                        d="M333.408 675.704C355.061 695.007 375.485 715.647 394.559 737.502C450.42 802.799 519.485 840.06 605.474 849.207C620.264 850.763 634.961 853.106 649.502 856.226C721.352 871.854 783.159 907.624 826.332 972.066C889.293 1067.01 892.653 1163.94 834.545 1262.09C794.892 1329.17 731.434 1391.75 659.008 1440.86C602.173 1479.37 540.008 1509.87 479.494 1528.09C454.039 1525.82 428.246 1524.94 402.633 1523.57C368.945 1521.84 335.476 1519.06 303.36 1511.03C205.121 1486.51 114.678 1494.98 21.1321 1534.57C-24.4475 1553.99 -68.9335 1561.43 -110.953 1557.37C-139.66 1554.63 -167.546 1546.26 -193.024 1532.76C-225.24 1515.72 -255.01 1490.55 -281.439 1457.32C-329.903 1396.46 -368.939 1326.91 -403.94 1257.53C-431.92 1205.26 -463.877 1159.73 -504.008 1119.82C-520.085 1103.98 -537.372 1089.41 -555.713 1076.26C-656.298 1008.51 -742.525 923.153 -791.227 810.991C-798.418 794.394 -804.691 777.414 -810.02 760.129C-860.233 596.31 -828.236 385.406 -643.75 290.88C-527.752 231.488 -415.056 245.148 -308.664 309.193C-258.013 339.654 -214.721 382.762 -169.857 422.528C-134.797 453.487 -103.019 488.144 -68.6351 519.957C-32.9987 552.864 7.94738 570.74 58.3594 568.254C165.169 562.925 255.036 605.615 333.408 675.704Z"
                        stroke="#224946"
                        strokeWidth="3"
                        strokeMiterlimit="10"/>
                    <path
                        d="M403.647 629.197C429.419 651.821 453.686 676.104 476.292 701.891C542.454 778.84 624.147 822.444 725.985 832.465C743.477 834.178 760.863 836.833 778.068 840.419C862.824 858.314 935.946 899.95 987.492 976.183C1062.48 1087.53 1067.14 1202.3 998.847 1318.89C952.153 1398.63 877.102 1473.13 791.272 1531.65C723.977 1577.38 650.079 1613.57 578.21 1634.76C547.903 1631.8 517.198 1630.47 486.732 1628.52C446.601 1625.97 406.769 1622.29 368.567 1612.37C251.755 1582.09 144.467 1592.21 33.74 1640.49C-20.3113 1664.13 -73.1295 1673.55 -123.024 1669.18C-157.123 1666.21 -190.299 1656.53 -220.647 1640.71C-258.948 1620.82 -294.226 1591 -325.548 1551.65C-382.8 1479.45 -428.658 1396.56 -469.207 1313.64C-500.468 1251.47 -537.596 1198.42 -585.801 1152.95C-605.286 1134.67 -626.222 1118.01 -648.403 1103.12C-769.929 1023.47 -873.676 922.835 -932.282 789.935C-940.944 770.288 -948.506 750.175 -954.932 729.688C-1015.65 535.824 -978.796 284.816 -760.045 172.713C-622.233 101.889 -488.357 118.352 -361.721 194.863C-301.564 231.21 -250.218 282.748 -196.922 329.832C-155.161 366.696 -117.377 407.954 -76.4704 445.753C-34.0129 484.923 14.8082 506.059 75.0242 502.718C202.615 495.64 309.982 546.025 403.647 629.197Z"
                        stroke="#224946"
                        strokeWidth="3"
                        strokeMiterlimit="10"/>
                    <path
                        d="M676.926 1741.1C595.631 1732.59 511.651 1734.14 433.636 1713.26C298.25 1677.19 174.139 1688.96 46.2292 1745.95C-112.405 1816.59 -262.229 1781.54 -369.774 1645.52C-435.817 1562.01 -488.377 1465.75 -534.593 1369.28C-582.718 1268.83 -646.056 1190.64 -741.153 1129.54C-904.4 1024.62 -1039.81 887.621 -1099.88 698.789C-1171.12 474.901 -1129.43 183.808 -876.34 54.0489C-716.533 -27.8708 -561.499 -8.56398 -414.857 80.295C-345.254 122.547 -285.754 182.377 -223.947 236.937C-175.564 279.726 -131.774 327.625 -84.2652 371.448C-34.9867 416.902 21.7094 441.279 91.7294 437.063C240.161 428.215 365.008 486.295 474.006 582.57C503.557 608.677 532.372 636.255 558.105 666.081C634.588 754.681 728.909 804.628 846.597 815.505C969.634 826.898 1076.84 874.32 1148.75 979.98C1235.77 1107.81 1241.74 1240.33 1163.23 1375.36C1067.34 1540.22 866.722 1686.12 676.926 1741.1Z"
                        stroke="#224946"
                        strokeWidth="3"
                        strokeMiterlimit="10"/>
                </g>
            </g>
            <defs>
                <linearGradient
                    id="webwidget_gradient"
                    x1="374.451"
                    y1="572.844"
                    x2="-207.329"
                    y2="1502.53"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#22B573"/>
                    <stop
                        offset="1"
                        stopColor="#1BD0BC"/>
                </linearGradient>
            </defs>
        </svg>

    );
};
