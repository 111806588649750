interface IProps {}

export const Play: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M27 14.268c1.333.77 1.333 2.694 0 3.464L3 31.589c-1.333.77-3-.193-3-1.733V2.144C0 .604 1.667-.358 3 .412l24 13.856Z"
				fill="#fff"
			/>
		</svg>
	);
};
