import { buildParamsWithContentType } from '@/utils/helpers/buildParams';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import {
	ICategoryPage,
	ICollectionApiResponse,
	IInsightPage,
	IStoryBase,
} from '@/utils/interfaces/stories';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import StoryblokClient from 'storyblok-js-client';
import { IInsightsData } from '../Bloks/Insights/InsightsFilter';
import { StoryblokContext } from './StoryblokProvider';

interface IProps {}

export type IContextProps = {
	insights: IStoryBase<IInsightPage>[];
	categories: IStoryBase<ICategoryPage>[];
};

export const InsightsProviderContext = createContext<IContextProps>({
	insights: [],
	categories: [],
});

export const InsightsProvider: React.FC<IProps> = ({ children }) => {
	const { locale } = useRouter();
	const { storyblokClient } = useContext(StoryblokContext);

	const [categories, setCategories] = useState<
		IStoryBase<ICategoryPage>[] | null
	>(null);
	const [insights, setInsights] = useState<IStoryBase<IInsightPage>[] | null>(
		null,
	);

	async function fetchData(params: {}): Promise<
		ICollectionApiResponse<IInsightsData>
	> {
		return new Promise<ICollectionApiResponse<IInsightsData>>(
			async (resolve, reject) => {
				if (storyblokClient) {
					const { data } = await storyblokClient.get(
						`cdn/stories/`,
						params,
					);

					if (data) {
						resolve(data);
					} else {
						reject(null);
					}
				}
			},
		);
	}

	async function fetchDataByParams(params: {}) {
		return new Promise(async (resolve, reject) => {
			const response = await fetchData(params);

			if (
				response &&
				response.stories &&
				isArrayWithValue(response.stories)
			) {
				resolve(response.stories);
			}

			reject(null);
		});
	}

	useEffect(() => {
		const insightsPromise = fetchDataByParams(
			buildParamsWithContentType(
				locale as string,
				'insight',
				`${locale}/resources/`,
			),
		);
		const categoriesPromise = fetchDataByParams(
			buildParamsWithContentType(
				locale as string,
				'category',
				`${locale}/categories/`,
			),
		);
		Promise.all([insightsPromise, categoriesPromise]).then((values) => {
			if (values[0] && isArrayWithValue(values[0])) {
				setInsights(values[0] as IInsightsData);
			}
			if (values[1] && isArrayWithValue(values[1])) {
				setCategories(values[1] as IStoryBase<ICategoryPage>[]);
			}
		});
	}, []);

	if (
		insights &&
		isArrayWithValue(insights) &&
		categories &&
		isArrayWithValue(categories)
	) {
		return (
			<InsightsProviderContext.Provider value={{ insights, categories }}>
				{children}
			</InsightsProviderContext.Provider>
		);
	} else {
		return <>{children}</>;
	}
};
