import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { ICards } from '@/utils/interfaces/bloks';
import { FormattedText } from '../Shared/FormattedText';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';
import { Card } from '../Shared/Tiles/Card';

interface IProps {
	blok: ICards;
	preview: boolean;
}

export const Cards: React.FC<IProps> = ({ blok, preview }) => {
	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative py-14 md:py-32 bg-light"
		>
			<Container align="center">
				<FormattedText tag="h2" text={blok.heading} />

				<span className="block mt-4 lg:text-xl max-w-[800px] mx-auto lg:mt-6">
					{blok.description}
				</span>

				{isArrayWithValue(blok.cards) && (
					<div className="grid grid-cols-12 gap-4 mt-10 md:mt-14 lg:gap-8">
						{blok.cards.map((card, key) => {
							return (
								<div
									key={key}
									className={`col-span-12 sm:col-span-6`}
								>
									<Card blok={card} />
								</div>
							);
						})}
					</div>
				)}
			</Container>
		</SbSection>
	);
};
