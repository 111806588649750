import { SelectData } from '@/utils/interfaces/cibApiResponse';
import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

interface SelectProps {
	label: string;
	name: string;
	placeholder: string;
	options: SelectData[] | undefined;
	disabled: boolean;
	menuPlacement?: 'bottom' | 'auto' | 'top';
	control: any;
}

export const FormSelect = forwardRef<HTMLSelectElement, SelectProps>(
	function SelectControl(
		{
			label,
			options,
			name,
			placeholder,
			disabled,
			control,
			menuPlacement = 'auto',
		},
		ref,
	) {
		const form = useFormContext();
		const state = form.getFieldState(name);

		return (
			<div className="form-input">
				<label className="form-input__label">{label}</label>

				<div className="form-input__control">
					<Controller
						name={name}
						control={control}
						render={({ field: { value, onChange, onBlur } }) => {
							return (
								<Select
									name={name}
									options={options}
									isDisabled={disabled}
									onBlur={onBlur}
									placeholder={placeholder}
									menuPlacement={menuPlacement}
									onChange={(data) => onChange(data)}
									value={options?.filter(
										(option) =>
											value.label === option.label,
									)}
									classNamePrefix="react-select"
								/>
							);
						}}
					/>
				</div>

				{state.error && (
					<span className="form-input__error">
						{state.error.message}
					</span>
				)}
			</div>
		);
	},
);
