interface IProps {}

export const Blockquote: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="32" height="32" rx="16" fill="url(#a)" />
			<path
				d="m9 22 2.938-12h3.39L13.52 22H9Zm7.006 0 2.938-12h3.39l-1.809 12h-4.52Z"
				fill="#fff"
			/>
			<defs>
				<linearGradient
					id="a"
					x1="0"
					y1="16"
					x2="32"
					y2="16"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#22B573" />
					<stop offset="1" stopColor="#1BD0BC" />
				</linearGradient>
			</defs>
		</svg>
	);
};
