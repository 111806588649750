interface IProps {}

export const Left: React.FC<IProps> = () => {
	return (
		<svg
			viewBox="0 0 704 736"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.07095 -137.139L13.1138 -176.526L501.107 -115.726L496.123 -76.2489C486.89 -2.88159 498.338 71.5803 529.177 138.754C560.016 205.928 609.023 263.147 670.679 303.969L703.918 325.907L431.827 735.566L398.674 713.724C260.553 622.514 150.772 494.493 81.742 344.132C12.712 193.772 -12.8138 27.0694 8.05018 -137.129L8.07095 -137.139Z"
				fill="url(#paint0_linear_388_14957)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_388_14957"
					x1="602.519"
					y1="105.041"
					x2="81.7404"
					y2="344.129"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#22B573" />
					<stop offset="1" stopColor="#1BD0BC" />
				</linearGradient>
			</defs>
		</svg>
	);
};
