import { isStringWithValue } from "@/utils/helpers/isStringWithValue";
import { IFormGroup } from "@/utils/interfaces/components";
import { FormattedText } from "../Shared/FormattedText"
import { Container } from "../Shared/Layout/Container"
import { SbSection } from "../Shared/Layout/SbSection"
import { StoryblokComponent } from '@storyblok/react';

interface IProps {
	blok: IFormWithHeading;
	preview: boolean;
}

interface IFormWithHeading {
	heading?: string;
	sub_heading?: string;
	form: IFormGroup[];
}

export const FormWithHeading: React.FC<IProps> = ({ blok, preview }) => {
	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative bg-light py-14 md:py-32"
		>
			<Container align="center">
				{blok.heading && isStringWithValue(blok.heading) && (
					<FormattedText tag="h2" text={blok.heading} />
				)}

				{blok.sub_heading && isStringWithValue(blok.sub_heading) && (
					<span className="block mt-4 lg:text-xl max-w-[800px] mx-auto lg:mt-6">
						{blok.sub_heading}
					</span>
				)}

				<div className="pt-6 pb-10">
					{blok.form.map((element, key) => {
						return (
							<StoryblokComponent
								key={key}
								blok={element}
							/>
						);
					})}
				</div>
			</Container>
		</SbSection>
	)
}