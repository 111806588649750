interface IProps {}

export const WorkerV2Dark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M55.45 16.19a1.151 1.151 0 0 1-1.151-1.152c0-6.223-5.014-10.735-11.922-10.735h-4.754c-6.908 0-11.921 4.512-11.921 10.735a1.151 1.151 0 0 1-2.303 0C23.399 7.48 29.379 2 37.623 2h4.755C50.62 2 56.6 7.48 56.6 15.038a1.151 1.151 0 0 1-1.15 1.151ZM53.17 25.025a3.453 3.453 0 0 1-1.324-.259c-2.412-.973-7.16-2.607-11.846-2.607-4.05.023-8.053.861-11.772 2.463a3.523 3.523 0 0 1-4.57-1.64l-1.324-2.648a1.151 1.151 0 1 1 2.06-1.03l1.324 2.647a1.22 1.22 0 0 0 1.589.576 32.154 32.154 0 0 1 12.664-2.654c5.077 0 10.143 1.727 12.71 2.775a1.191 1.191 0 0 0 1.525-.576l1.37-2.74a1.152 1.152 0 1 1 2.061 1.03l-1.37 2.74a3.489 3.489 0 0 1-3.097 1.923Z"
				fill="#22B573"
			/>
			<path
				d="M49.51 17.375h-.179c-2.124-.328-7.944-1.151-9.33-1.151-1.388 0-7.208.846-9.332 1.151a1.165 1.165 0 1 1-.35-2.302c.8-.121 7.862-1.198 9.681-1.198 1.82 0 8.882 1.077 9.682 1.198a1.153 1.153 0 0 1 .945 1.671 1.154 1.154 0 0 1-1.117.631Z"
				fill="#22B573"
			/>
			<path
				d="M40 45.898a14.851 14.851 0 0 1-14.529-11.41 45.519 45.519 0 0 1-.88-6.331A1.155 1.155 0 0 1 26.892 28c.142 1.993.416 3.974.823 5.93a12.664 12.664 0 0 0 24.574 0 44.95 44.95 0 0 0 .829-5.924 1.154 1.154 0 1 1 2.302.155 45.8 45.8 0 0 1-.886 6.332A14.852 14.852 0 0 1 40 45.898Z"
				fill="#224946"
			/>
			<path
				d="M49.51 50.652a1.152 1.152 0 0 1-1.03-.639c-1.33-2.653-.23-8.41-.098-9.06a1.152 1.152 0 1 1 2.256.455c-.432 2.147-.835 6.107-.098 7.575a1.15 1.15 0 0 1-.518 1.549c-.159.079-.334.12-.512.12ZM30.49 50.652a1.152 1.152 0 0 1-.854-.384A1.15 1.15 0 0 1 29.455 49c1.45-3.022-.035-7.397-.052-7.443a1.15 1.15 0 0 1 2.176-.754c.074.213 1.819 5.308-.047 9.21a1.15 1.15 0 0 1-1.041.64Z"
				fill="#224946"
			/>
			<path
				d="M61.39 53.03c-.146 0-.29-.03-.425-.087L49.078 48.19a1.153 1.153 0 0 1-.421-1.893 1.152 1.152 0 0 1 1.279-.243l11.88 4.755a1.152 1.152 0 0 1-.425 2.222ZM18.61 53.03a1.15 1.15 0 0 1-.426-2.222l11.88-4.755a1.152 1.152 0 0 1 .858 2.136l-11.886 4.754c-.135.057-.28.087-.426.087Z"
				fill="#224946"
			/>
			<path
				d="M40 55.401c-5.63 0-9.014-1.888-11.69-6.516a1.15 1.15 0 1 1 1.99-1.151c2.263 3.914 4.894 5.365 9.7 5.365 4.807 0 7.437-1.45 9.7-5.365a1.15 1.15 0 0 1 1.991 1.151c-2.676 4.628-6.067 6.516-11.69 6.516Z"
				fill="#224946"
			/>
			<path
				d="M67.331 68.514V63.76l2.377-2.378h7.133a10.84 10.84 0 0 0-9.51-5.94 10.69 10.69 0 0 0-9.578 5.952l-35.465.092a10.69 10.69 0 0 0-9.619-6.033 10.822 10.822 0 0 0-9.51 5.93h8.319l2.377 2.377v4.754c-.927.927-1.45 1.445-2.378 2.378H3.16c1.185 3.563 5.307 5.94 9.51 5.94a10.678 10.678 0 0 0 9.578-5.958h35.505a10.683 10.683 0 0 0 9.578 5.958c4.139 0 8.324-2.377 9.51-5.94h-7.132c-.927-.933-1.445-1.451-2.378-2.378Z"
				fill="#22B573"
			/>
			<path
				d="M67.331 77.983a11.772 11.772 0 0 1-10.264-5.94H22.933a11.766 11.766 0 0 1-10.263 5.94c-4.847 0-9.302-2.832-10.603-6.729A1.151 1.151 0 0 1 3.16 69.74H11l1.727-1.727v-3.799L11 62.487H3.16a1.152 1.152 0 0 1-.985-.575 1.152 1.152 0 0 1-.04-1.151 12.053 12.053 0 0 1 10.534-6.563 11.755 11.755 0 0 1 10.315 6.033l34.084-.092a11.766 11.766 0 0 1 10.263-5.94 12.043 12.043 0 0 1 10.534 6.562 1.15 1.15 0 0 1-.04 1.15 1.15 1.15 0 0 1-.985.576h-6.654l-1.727 1.727v3.8l1.727 1.726h6.654a1.152 1.152 0 0 1 1.094 1.514c-1.3 3.897-5.756 6.73-10.603 6.73Zm-9.573-8.254a1.152 1.152 0 0 1 1.025.639 9.498 9.498 0 0 0 8.548 5.313c3.131 0 6.125-1.485 7.639-3.638h-5.262a1.151 1.151 0 0 1-.811-.34l-2.378-2.377a1.151 1.151 0 0 1-.34-.812V63.76c.002-.305.124-.597.34-.812l2.378-2.377c.215-.217.506-.339.811-.34h5.014a9.607 9.607 0 0 0-7.391-3.638 9.499 9.499 0 0 0-8.548 5.313 1.152 1.152 0 0 1-1.203.627l-35.292.093a1.15 1.15 0 0 1-1.036-.65 9.48 9.48 0 0 0-8.583-5.383 9.608 9.608 0 0 0-7.391 3.638h6.2c.304.001.596.123.811.34l2.383 2.377c.214.216.334.508.334.812v4.754a1.152 1.152 0 0 1-.334.835l-2.383 2.377a1.151 1.151 0 0 1-.812.34H5.02c1.502 2.176 4.461 3.638 7.65 3.638a9.486 9.486 0 0 0 8.548-5.319 1.152 1.152 0 0 1 1.215-.622h35.194l.132-.034Z"
				fill="#224946"
			/>
		</svg>
	);
};
