import {
	IFormCheckbox,
	IFormInput,
	IFormTextarea,
} from '@/utils/interfaces/components';
import { useField } from 'formik';

interface IProps {
	blok: IFormCheckbox;
}

export const SbFormCheckbox: React.FC<IProps> = ({ blok }) => {
	const [field, meta, helpers] = useField(blok.name);

	return (
		<div>
			<label className="flex mt-6">
				<div className="w-5 h-5 mr-3">
					<input
						className="w-5 h-5 border appearance-none bg-light border-gray checked:green-gradient checked:border-transparent"
						type="checkbox"
						{...field}
					/>
				</div>
				<span className="mt-[2px] text-xs leading-4 text-grey-dark">
					{blok.message}
				</span>
			</label>
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</div>
	);
};
