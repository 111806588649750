import { Icon } from '@/components/Shared/Icon';
import { HidePassword } from '@/components/Shared/Icons/HidePassword';
import { ShowPassword } from '@/components/Shared/Icons/ShowPassword';
import { forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface InputProps {
	label: string;
	name: string;
	disabled: boolean;
	type: string;
}

export const FormInputPassword = forwardRef<HTMLInputElement, InputProps>(
	function Input({ label, type = 'text', ...props }, ref) {
		const [showPassword, setShowPassword] = useState<boolean>(true);

		const form = useFormContext();
		const state = form.getFieldState(props.name);

		const inputClasses = ['form-input__input'];

		if (state.error) {
			inputClasses.push('form-input__input--error');
		}

		return (
			<div className="form-input">
				<label className="form-input__label">{label}</label>

				<div className="form-input__control">
					<input
						className={inputClasses.join(' ')}
						type={showPassword ? 'password' : 'text'}
						ref={ref}
						{...props}
					/>

					<button
						onClick={() => setShowPassword(!showPassword)}
						className="form-input__toggle"
						type="button"
					>
						{showPassword ? (
							<Icon
								className="form-input__toggle-icon"
								icon={<HidePassword />}
							/>
						) : (
							<Icon
								className="form-input__toggle-icon"
								icon={<ShowPassword />}
							/>
						)}
					</button>
				</div>

				{state.error && (
					<span className="form-input__error">
						{state.error.message}
					</span>
				)}
			</div>
		);
	},
);
