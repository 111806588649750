import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { ICtaCallout } from '@/utils/interfaces/bloks';
import { CTA } from '../Shared/CTA/CallToAction';
import { CtaContainer } from '../Shared/CTA/CtaContainer';
import { FormattedText } from '../Shared/FormattedText';
import { Icon } from '../Shared/Icon';
import { Left } from '../Shared/Icons/BrandBackgrounds/CtaCallout/Left';
import { Right } from '../Shared/Icons/BrandBackgrounds/CtaCallout/Right';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';

interface IProps {
	blok: ICtaCallout;
	preview: boolean;
}

export const CtaCallout: React.FC<IProps> = ({ blok, preview }) => {
	const ctaClasses = ['grid', 'gap-4', 'grid-cols-1', 'w-full', 'md:w-auto'];

	if (blok.ctas && blok.ctas.length > 1) {
		ctaClasses.push('md:grid-cols-2');
	} else if (blok.ctas.length === 1) {
		ctaClasses.push('md:grid-cols-1');
	}
	return (
		<SbSection classNames="" blok={blok} preview={preview}>
			<div className={`${blok.theme === 'dark' ? 'dark' : ''}`}>
				<div className="relative px-4 overflow-hidden text-center bg-gray-light dark:bg-green-dark sm:px-8 py-14 lg:py-28">
					<Icon
						className="absolute bottom-0 left-[-50px] lg:left-0 lg:w-[700px] lg:h-full"
						icon={
							<Left
								strokeColor={`${
									blok.theme === 'dark'
										? '#22B473'
										: '#A9ABAC'
								}`}
							/>
						}
					/>

					<Icon
						className="absolute top-[-150px] right-[-130px] lg:top-0 lg:right-0 lg:w-[454px] lg:h-full"
						icon={
							<Right
								strokeColor={`${
									blok.theme === 'dark'
										? '#22B473'
										: '#A9ABAC'
								}`}
							/>
						}
					/>
					<Container>
						<div className="relative">
							<FormattedText
								tag="h2"
								text={blok.heading}
								className="dark:text-white"
							/>
							{blok.content &&
								isStringWithValue(blok.content) && (
									<span className="block mt-4 lg:text-xl max-w-[800px] mx-auto lg:mt-6 dark:text-white">
										{blok.content}
									</span>
								)}

							{blok.ctas && isArrayWithValue(blok.ctas) && (
								<div className="mx-auto mt-6 sm:max-w-[400px] md:max-w-none">
									<CtaContainer>
										<div className={ctaClasses.join(' ')}>
											{blok.ctas.map((cta, key) => {
												return (
													<CTA blok={cta} key={key} />
												);
											})}
										</div>
									</CtaContainer>
								</div>
							)}
						</div>
					</Container>
				</div>
			</div>
		</SbSection>
	);
};
