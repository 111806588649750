import { ICta } from '@/utils/interfaces/components';
import { CtaPrimary } from './CtaPrimary';
import { CtaSecondary } from './CtaSecondary';
import { CtaTertiary } from './CtaTertiary';
import { CtaWhite } from "@/components/Shared/CTA/CtaWhite";
import { CtaLink } from './CtaLink';

interface IProps {
	blok: ICta;
}

export const CTA: React.FC<IProps> = ({ blok }) => {
	if (blok.variant === 'primary') {
		return <CtaPrimary blok={blok} />;
	} else if (blok.variant === 'secondary') {
		return <CtaSecondary blok={blok} />;
	} else if (blok.variant === 'tertiary') {
		return <CtaTertiary blok={blok} />;
	} else if (blok.variant === 'white') {
		return <CtaWhite blok={blok} />;
	} if (blok.variant === 'link') {
		return <CtaLink blok={blok} />;
	}

	return <div>CallToAction</div>;
};
