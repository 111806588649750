import { Icon } from '@/components/Shared/Icon';
import { Helper } from '@/components/Shared/Icons/Helper';
import { Modal } from '@/components/Shared/Modal/BaseModal';
import { ISearchFormValue, TAddon } from '@/utils/interfaces/cibApiResponse';
import { useRouter } from 'next/router';
import { find, pathOr, propEq } from 'ramda';
import { useEffect, useState } from 'react';

import { z } from 'zod';
import { IFormStepProps } from '../SelfSubscribeContainer';
import { CostSummary } from '../Shared/CostSummary';
import { DropdownChecklist } from '../Shared/DropdownChecklist';
import { FormCheckbox } from '../Shared/FormCheckbox';
import { FormCol } from '../Shared/FormCol';
import { FormFormProvider } from '../Shared/FormFormProvider';
import { FormRadio } from '../Shared/FormRadio';
import { FormRow } from '../Shared/FormRow';
import { FormStepLayout } from '../Shared/FormStepLayout';
import { Skeleton } from '../Shared/Skeleton';
import { useZodForm } from '../Shared/UseZodForm';

const schema = z.object({
	billingCycle: z.string(),
	searches: z.string(),
	addons: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

enum EPlanType {
	MONTHLY = 'monthly',
	ANNUALLY = 'annually',
}

export const BuildPlan: React.FC<IFormStepProps> = ({
	children,
	blok,
	...props
}) => {
	const {
		monthlyData,
		annuallyData,
		searchesPlan,
		setSearchesPlan,
		searchPlansLoading,
		billingCycle,
		setBillingCycle,
		addOnsData,
		addOnsLoading,
	} = props;

	const { locale } = useRouter();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [searchesPlanMonthly, setSearchesPlanMonthly] =
		useState<ISearchFormValue | null>();
	const [searchesPlanAnnually, setSearchesPlanAnnually] =
		useState<ISearchFormValue | null>();
	const [addons, setAddons] = useState<TAddon[]>([]);
	const [cost, setCost] = useState<number>(0);
	const [discount, setDiscount] = useState<number>(0);
	const [activePlanType, setActivePlanType] = useState<EPlanType>(
		EPlanType.MONTHLY,
	);

	const methods = useZodForm({
		schema,
		defaultValues: {
			billingCycle: 'monthly',
			searches: '30',
			addons: [],
		},
	});

	const { register, watch, setValue } = methods;

	const watchFields = watch(['billingCycle', 'searches', 'addons']);

	function onSubmit(data: FormData) {
		if (data.searches && searchesPlan) {
			props.send({
				event: 'NEXT',
				data: {
					planData: {
						searches: data.searches,
						planId: searchesPlan.id,
						addons,
						billingCycle,
						discount,
						cost,
						country: props.country,
					},
				},
			});
		}
	}

	function onDetour() {
		if (searchesPlan) {
			props.send({
				event: 'DETOUR',
				data: {
					planData: {
						searches: searchesPlan.searches.toString(),
						planId: searchesPlan.id,
						addons,
						billingCycle,
						discount,
						cost,
						country: props.country,
					},
				},
			});
		}
	}

	// combines the values of both sets of state to one value to submit
	useEffect(() => {
		if (
			billingCycle === 'annually' &&
			searchesPlanAnnually === undefined &&
			props.monthlyData
		) {
			setSearchesPlanAnnually(monthlyData[0]);
		} else {
			setSearchesPlan(searchesPlanAnnually as ISearchFormValue);
		}

		if (billingCycle === 'monthly') {
			setSearchesPlan(searchesPlanMonthly as ISearchFormValue);
		}
	}, [searchesPlanMonthly, searchesPlanAnnually]);

	// Find the previous selected value after swapping billingCycle
	useEffect(() => {
		if (billingCycle === 'annually' && searchesPlanAnnually) {
			const { searches } = searchesPlanAnnually;

			setValue('searches', searches.toString());
		}

		if (billingCycle === 'monthly' && searchesPlanMonthly) {
			const { searches } = searchesPlanMonthly;

			setSearchesPlan(searchesPlanMonthly);
			setValue('searches', searches.toString());
		}
	}, [billingCycle]);

	useEffect(() => {
		const subscription = watch((value, { name }) => {
			const searchesSelection = value['searches'];

			if (name == 'billingCycle') {
				if (
					value.billingCycle == 'monthly' &&
					monthlyData &&
					searchesSelection
				) {
					const matchedPlan = find(
						propEq(
							'searches',
							parseFloat(searchesSelection.replace(/,/g, '')),
						),
						monthlyData,
					);

					setAddons([]);
					setValue('addons', []);
					setBillingCycle(value.billingCycle);
					setActivePlanType(EPlanType.MONTHLY);
					setSearchesPlanMonthly(
						matchedPlan ? matchedPlan : monthlyData[0],
					);
				}

				if (
					value.billingCycle == 'annually' &&
					annuallyData &&
					searchesSelection
				) {
					const matchedPlan = find(
						propEq(
							'searches',
							parseFloat(searchesSelection.replace(/,/g, '')),
						),
						annuallyData,
					);

					setAddons([]);
					setValue('addons', []);
					setActivePlanType(EPlanType.ANNUALLY);
					setBillingCycle(value.billingCycle);
					setSearchesPlanAnnually(
						matchedPlan ? matchedPlan : annuallyData[0],
					);
				}
			}

			if (name === 'searches' && searchesSelection) {
				const dataToSearch =
					activePlanType === EPlanType.MONTHLY
						? monthlyData
						: annuallyData;

				if (dataToSearch) {
					const matchedPlan = find(
						propEq(
							'searches',
							parseFloat(searchesSelection.replace(/,/g, '')),
						),
						dataToSearch,
					);

					if (matchedPlan && billingCycle === 'monthly') {
						setSearchesPlanMonthly(matchedPlan);
					} else if (matchedPlan && billingCycle === 'annually') {
						setSearchesPlanAnnually(matchedPlan);
					}
				}
			}

			if (name == 'addons') {
				if (value.addons) {
					const selectedAddons = value.addons;
					const selectedAddonsData: TAddon[] = [];

					for (
						let index = 0;
						index < selectedAddons.length;
						index++
					) {
						const addon = selectedAddons[index];
						const addOnData = find(propEq('id', addon), addOnsData);

						if (addOnData) {
							selectedAddonsData.push(addOnData);
						}
					}

					setAddons(selectedAddonsData);
				}
			}
		});

		return () => subscription.unsubscribe();
	}, [watchFields]);

	/* 
		Calculate the price summary, including the discount
	*/
	useEffect(() => {
		if (searchesPlan && searchesPlan.price) {
			const basePriceRaw =
				billingCycle === EPlanType.MONTHLY
					? searchesPlanMonthly?.price
					: searchesPlanAnnually?.price;

			let basePrice = basePriceRaw as number;
			let discount = 0;

			if (addons.length > 0) {
				for (let index = 0; index < addons.length; index++) {
					const addon = addons[index];
					basePrice = basePrice + addon.price;
				}
			}

			if (billingCycle === 'annually') {
				const priceBeforeDiscount = basePrice / (1 - 0.1);
				discount = priceBeforeDiscount - basePrice;

				basePrice = basePrice / 12;
			}

			setCost(basePrice);
			setDiscount(discount);
		}
	}, [billingCycle, searchesPlan, addons]);

	useEffect(() => {
		if (monthlyData) {
			setValue('billingCycle', 'monthly');

			if (monthlyData.length > 0 && 'searches' in monthlyData[0]) {
				setValue('searches', monthlyData[0].searches.toString());
			}
		}
	}, [monthlyData]);

	/* 
		If the user has input data and progressed to the next step, then comes 
		back to the previous step (this one). It will attempt to puse the values and match with the API 
		for the billingFrequency, searches and addons
	*/
	useEffect(() => {
		const billingCycle = pathOr(
			null,
			['planData', 'billingCycle'],
			props.formData,
		);

		const planSelected = pathOr<string | null>(
			null,
			['planData', 'searches'],
			props.formData,
		);

		const addOnsSelected = pathOr<TAddon[]>(
			[],
			['planData', 'addons'],
			props.formData,
		);

		if (billingCycle) {
			setValue('billingCycle', billingCycle);

			setActivePlanType(
				billingCycle === 'monthly'
					? EPlanType.MONTHLY
					: EPlanType.ANNUALLY,
			);
		}

		if (planSelected) {
			const dataToSearch =
				billingCycle === 'monthly' ? monthlyData : annuallyData;
			const matchedPlan = find(
				propEq('searches', parseFloat(planSelected.replace(/,/g, ''))),
				dataToSearch,
			);

			if (matchedPlan && billingCycle === 'monthly') {
				setSearchesPlanMonthly(matchedPlan);
				setValue('searches', planSelected);
			} else if (matchedPlan && billingCycle === 'annually') {
				setSearchesPlanAnnually(matchedPlan);
				setValue('searches', planSelected);
			}
		}

		if (addOnsSelected.length > 0) {
			setValue(
				'addons',
				addOnsSelected.map((addon) => addon.id),
			);
		}
	}, []);

	const heading = pathOr(null, ['buildPlan_heading'], blok);
	const description = pathOr(null, ['buildPlan_description'], blok);
	const media = pathOr(null, ['buildPlan_media'], blok);
	const demoLink = pathOr(null, ['demo_link'], blok);

	return (
		<FormStepLayout media={media} link={demoLink}>
			<div className="form-step">
				<div className="form-step__heading">
					<h4>{heading}</h4>
				</div>

				{description && (
					<div className="form-step__description">
						<span>{description}</span>
					</div>
				)}

				<div className="form-step__summary">
					<CostSummary
						discount={discount}
						cost={cost}
						billingCycle={billingCycle}
					/>
				</div>

				<div className="form-step__form">
					<FormFormProvider
						form={methods}
						onSubmit={(data) => onSubmit(data)}
					>
						<FormRow>
							<FormCol>
								<span className="form-step__form-label">
									Billing frequency
								</span>

								<div className="form-step__form-input form-step__form-input--radio">
									<FormRadio
										name="billingCycle"
										value="monthly"
										label="Pay Monthly"
										register={register}
									/>
									<FormRadio
										value="annually"
										name="billingCycle"
										label="Pay yearly (Save 10%)"
										register={register}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								<span className="form-step__form-label">
									<span>Number of searches</span>
									<button
										type="button"
										className="form-step__form-label-help"
										onClick={() => setIsModalOpen(true)}
									>
										<span className="form-step__form-label-help-label">
											How to choose
										</span>
										<span className="form-step__form-label-help-icon">
											<Icon icon={<Helper />} />
										</span>
									</button>
								</span>

								{searchPlansLoading ? (
									<>
										<div className="h-16 mt-2">
											<Skeleton />
										</div>
									</>
								) : (
									<div className="h-16 mt-2">
										{activePlanType ===
										EPlanType.MONTHLY ? (
											<div className="form-step__form-input form-step__form-input--radio">
												{monthlyData?.map((item) => {
													return (
														<FormRadio
															key={item.id}
															label={item.searches.toLocaleString()}
															value={item.searches.toLocaleString()}
															name="searches"
															register={register}
														/>
													);
												})}

												<button
													type="button"
													onClick={() => onDetour()}
													className="form-radio form-radio--button"
												>
													<span>Custom</span>
												</button>
											</div>
										) : (
											<div className="form-step__form-input form-step__form-input--radio">
												{annuallyData?.map((item) => {
													return (
														<FormRadio
															key={item.id}
															label={item.searches.toLocaleString()}
															value={item.searches.toLocaleString()}
															name="searches"
															register={register}
														/>
													);
												})}

												<button
													type="button"
													onClick={() => onDetour()}
													className="form-radio form-radio--button"
												>
													<span>Custom</span>
												</button>
											</div>
										)}
									</div>
								)}
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol>
								{addOnsLoading ? (
									<>
										<div className="h-10 mt-2">
											<Skeleton />
										</div>
									</>
								) : (
									<>
										{addOnsData.length > 0 && (
											<>
												<span className="form-step__form-label">
													<span>
														Add to your plan
													</span>
												</span>

												<div className="form-step__form-input form-step__form-input--checkbox">
													{addOnsData.map((item) => {
														return (
															<FormCheckbox
																key={item.id}
																name="addons"
																label={
																	item.name
																}
																id={item.id}
																included={
																	item.included
																}
																price={
																	item.price
																}
																locale={
																	locale as string
																}
																billingCycle={
																	item.billingCycle
																}
																register={
																	register
																}
															/>
														);
													})}
												</div>
											</>
										)}
									</>
								)}
							</FormCol>
						</FormRow>

						{searchesPlan && (
							<FormRow>
								<FormCol>
									<DropdownChecklist
										label="View full inclusion list"
										addOns={addons}
										searchesAmount={searchesPlan.searches.toLocaleString()}
									/>
								</FormCol>
							</FormRow>
						)}

						<FormRow>
							<FormCol submit={true}>
								<div className="form-step__form-row form-step__form-row--submit">
									<button
										className="cta cta--primary cta--large"
										type="submit"
									>
										<span className="cta-label">
											Continue
										</span>
									</button>
								</div>
							</FormCol>
						</FormRow>
					</FormFormProvider>
				</div>
			</div>

			<Modal
				active={isModalOpen}
				type="subscribe_modal"
				onClose={() => setIsModalOpen(false)}
				title="How many searches do I need?"
			>
				<div className="relative">
					<p>1 search = 1 unique property lookup</p>
					<p>
						This address can be searched 5 times by 5 seperate team
						members and it only counts as 1 search. Use the below
						guide to help you decide the number of searches for your
						team.
					</p>
					<p>Need more help? Chat with our team</p>
				</div>
			</Modal>
		</FormStepLayout>
	);
};
