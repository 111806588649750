export function isStringWithValue(value: string): boolean {
	if (typeof value !== 'string') {
		return false;
	}

	if (value.length > 0) {
		return true;
	}

	return false;
}
