import { storyblokEditable } from '@storyblok/react';

interface IProps {
	preview: boolean;
	classNames: string;
	blok: any;
}

export const SbSection: React.FC<IProps> = ({
	blok,
	preview,
	classNames,
	children,
}) => {
	if (preview) {
		return (
			<section {...storyblokEditable(blok)} className={classNames}>
				{children}
			</section>
		);
	} else {
		return <section className={classNames}>{children}</section>;
	}
};
