import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { ILocations } from '@/utils/interfaces/bloks';
import {
	ICollectionApiResponse,
	ILocationsPage,
	IStoryBase,
} from '@/utils/interfaces/stories';
import { useRouter } from 'next/router';
import { Fragment, useContext, useEffect, useState } from 'react';
import { StoryblokContext } from '../Providers/StoryblokProvider';
import { FormattedText } from '../Shared/FormattedText';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';
import { LocationGroup } from '../Shared/Location/LocationsGroup';
import { SbInfoBox } from '../Shared/Map/SbInfoBox';
import { SbMap } from '../Shared/Map/SbMap';

interface IProps {
	blok: ILocations;
	preview: boolean;
}

interface ICountries {
	name: string;
	coords: { lat: number; lng: number };
}

const COUNTRIES_TRIGGER: ICountries[] = [
	{
		name: 'Australia & NZ',
		coords: { lat: -31.085341, lng: 144.2510418 },
	},
	{
		name: 'United States',
		coords: { lat: 37.5822, lng: -100.2635 },
	},
];

export const Locations: React.FC<IProps> = ({ blok, preview }) => {
	const { locale } = useRouter();
	const { storyblokClient } = useContext(StoryblokContext);

	const [center, setCenter] = useState<ICountries>(COUNTRIES_TRIGGER[0]);
	const [locations, setLocations] = useState<IStoryBase<ILocationsPage>[]>();
	const [activeLocation, setActiveLocation] = useState<number | null>(null);

	async function fetchData(params: {}): Promise<
		ICollectionApiResponse<ILocationsPage>
	> {
		return new Promise<ICollectionApiResponse<ILocationsPage>>(
			async (resolve, reject) => {
				if (storyblokClient) {
					const { data } = await storyblokClient.get(
						`cdn/stories/`,
						params,
					);

					if (data) {
						resolve(data);
					} else {
						reject(null);
					}
				}
			},
		);
	}

	async function fetchLocations() {
		return new Promise(async (resolve, reject) => {
			const params = {
				version: 'published',
				starts_with: 'locations/',
			};
			const response = await fetchData(params);

			if (
				response &&
				response.stories &&
				isArrayWithValue(response.stories)
			) {
				resolve(response.stories);
			}

			reject(null);
		});
	}

	useEffect(() => {
		fetchLocations().then((data) => {
			setLocations(data as IStoryBase<ILocationsPage>[]);
		});
	}, []);

	useEffect(() => {
		if (locale == 'en-us') {
			setCenter(COUNTRIES_TRIGGER[1]);
		}
	}, [locations]);

	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative bg-white py-0 md:py-8"
		>
			<Container type="x-wide">
				<div className="relative mb-10">
					{locations && isArrayWithValue(locations) && (
						<Fragment>
							<div className="top-0 left-0 z-20 flex justify-center w-full my-4 space-x-4 relativez lg:absolute lg:top-8 lg:my-0">
								{COUNTRIES_TRIGGER.map((country, key) => {
									return (
										<button
											className="cta cta--alt cta--medium"
											disabled={
												country.name === center.name
											}
											key={key}
											onClick={() => setCenter(country)}
										>
											<span className="cta-label">
												{country.name}
											</span>
										</button>
									);
								})}
							</div>
							<div className="">
								<div className="h-[328px] sm:h-[428px] lg:h-[950px]">
									<SbMap
										activeLocation={activeLocation}
										setActiveLocation={setActiveLocation}
										locations={locations}
										zoom={4}
										center={center.coords}
									/>
								</div>

								<div className="block sm:hidden">
									{locations.map((location, key) => {
										if (location.id === activeLocation) {
											return (
												<div key={key} className="mt-4">
													<SbInfoBox
														location={location}
													/>
												</div>
											);
										}
									})}
								</div>
							</div>
						</Fragment>
					)}
				</div>
			</Container>
			<Container>
				<div className="mt-16 md:mt-30">
					<FormattedText tag="h2" text={blok.heading} />
				</div>

				{blok.location_groups &&
					isArrayWithValue(blok.location_groups) && (
						<div className="mt-8 lg:mt-18">
							{blok.location_groups.map((location, key) => {
								return (
									<div
										className="mb-8 lg:mb-20 last:mb-0"
										key={key}
									>
										<LocationGroup blok={location} />
									</div>
								);
							})}
						</div>
					)}
			</Container>
		</SbSection>
	);
};
