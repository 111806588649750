interface IProps {}

export const CalculatorDark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M60.18 76.6a1.15 1.15 0 0 1-.65-.208l-1.479-1.019-7.706-2.302a6.993 6.993 0 0 1-1.203-.483 22.826 22.826 0 0 1-8.236-7.586l-7.16-11.39c-.73-1.105-2.48-3.74.23-5.542 1.117-.737 2.666-.478 3.84-.092l-8.19-12.714a4.408 4.408 0 0 1-.616-3.453 4.69 4.69 0 0 1 2.003-2.877 4.605 4.605 0 0 1 6.377 1.197l6.774 10.244a4.703 4.703 0 0 1 7.251-1.473c.37-.804.956-1.49 1.693-1.98a4.65 4.65 0 0 1 5.64.345 4.748 4.748 0 0 1 5.145-2.682 4.443 4.443 0 0 1 2.924 1.917l5.18 7.862a36.66 36.66 0 0 1 5.335 13.548c.115.602.15 1.218.103 1.83l-.23 2.878.766 3.079a1.152 1.152 0 0 1-2.245.518l-.742-3.23a1.187 1.187 0 0 1 0-.35l.241-3.05c.032-.404.01-.81-.063-1.21a34.595 34.595 0 0 0-4.996-12.718l-5.214-7.862a2.135 2.135 0 0 0-1.422-.921 2.365 2.365 0 0 0-1.76.368 2.405 2.405 0 0 0-1.03 1.485 2.129 2.129 0 0 0 .293 1.663l.932 1.405a1.153 1.153 0 0 1-1.922 1.272l-2.417-3.655a2.303 2.303 0 0 0-3.189-.547 2.406 2.406 0 0 0-1.03 1.485 2.176 2.176 0 0 0 .293 1.663l.875 1.318a1.152 1.152 0 1 1-1.922 1.272l-2.36-3.568a2.302 2.302 0 0 0-3.188-.575c-.521.34-.89.87-1.025 1.479a2.14 2.14 0 0 0 .294 1.669l1.588 2.394a1.152 1.152 0 1 1-1.922 1.272l-10.504-15.85a2.302 2.302 0 0 0-3.188-.576 2.4 2.4 0 0 0-1.024 1.485 2.13 2.13 0 0 0 .293 1.663l15.54 24.052a1.15 1.15 0 0 1-1.923 1.26L40.59 52.3a7.01 7.01 0 0 0-2.705-1.882c-1.416-.616-2.302-.575-2.55-.443-.512.34-.794.524.432 2.377l7.096 11.39a20.438 20.438 0 0 0 7.367 6.791c.257.13.524.238.8.323l7.879 2.365c.115.034.224.086.322.155L60.86 74.5a1.151 1.151 0 0 1-.656 2.1h-.023Z"
				fill="#22B573"
			/>
			<path
				d="M28.066 61.152H5.528A3.534 3.534 0 0 1 2 57.642V6.527A3.534 3.534 0 0 1 5.528 3h38.02a3.534 3.534 0 0 1 3.528 3.528v24.915h-2.302V6.528a1.226 1.226 0 0 0-1.226-1.226H5.528a1.232 1.232 0 0 0-1.226 1.226v51.113a1.232 1.232 0 0 0 1.226 1.226h22.538v2.285Z"
				fill="#fff"
			/>
			<path
				d="M37.608 12.473h-26.14v9.508h26.14v-9.508Z"
				fill="#22B573"
			/>
			<path
				d="M37.608 23.11h-26.14a1.151 1.151 0 0 1-1.152-1.152v-9.485a1.152 1.152 0 0 1 1.151-1.15h26.141a1.152 1.152 0 0 1 1.151 1.15v9.485a1.151 1.151 0 0 1-1.15 1.151Zm-24.99-2.303h23.84v-7.183h-23.84v7.183ZM13.844 35.011a3.528 3.528 0 1 1 0-7.055 3.528 3.528 0 0 1 0 7.055Zm0-4.754a1.226 1.226 0 1 0 0 2.453 1.226 1.226 0 0 0 0-2.453ZM23.352 35.011a3.528 3.528 0 1 1 0-7.055 3.528 3.528 0 0 1 0 7.055Zm0-4.754a1.226 1.226 0 1 0 0 2.452 1.226 1.226 0 0 0 0-2.452ZM13.844 44.513a3.528 3.528 0 1 1 0-7.055 3.528 3.528 0 0 1 0 7.055Zm0-4.748a1.226 1.226 0 1 0 0 2.452 1.226 1.226 0 0 0 0-2.452ZM23.352 44.513a3.528 3.528 0 1 1 0-7.055 3.528 3.528 0 0 1 0 7.055Zm0-4.748a1.226 1.226 0 1 0 0 2.452 1.226 1.226 0 0 0 0-2.452ZM13.844 54.021a3.528 3.528 0 1 1 3.528-3.528 3.534 3.534 0 0 1-3.528 3.528Zm0-4.754a1.226 1.226 0 1 0 0 2.452 1.226 1.226 0 0 0 0-2.452ZM23.352 54.021a3.529 3.529 0 1 1 3.528-3.528 3.534 3.534 0 0 1-3.528 3.528Zm0-4.754a1.226 1.226 0 1 0 0 2.452 1.226 1.226 0 0 0 0-2.452Z"
				fill="#fff"
			/>
		</svg>
	);
};
