import { Breadcrumbs } from '@/components/Shared/Breadcrumbs';
import { CTA } from '@/components/Shared/CTA/CallToAction';
import { FormattedText } from '@/components/Shared/FormattedText';
import { Container } from '@/components/Shared/Layout/Container';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { IHeroBlok } from '@/utils/interfaces/bloks';

interface IProps {
	blok: IHeroBlok;
	isHome?: boolean;
}

export const HeroContent: React.FC<IProps> = ({ blok, isHome }) => {
	const headingClasses = ['text-inherit mb-0'];
	if (isHome) {
		headingClasses.push('text-7xl lg:text-9xl');
	}
	return (
		<div className="flex items-center justify-between w-full">
			<Container>
				<div className="py-6 lg:py-16 lg:max-w-lg lg:w-1/2 pb-14">
					{!isHome && <Breadcrumbs className="mb-3" />}
					{isStringWithValue(blok.heading) && (
						<div className="w-3/4 md:w-full">
							<FormattedText
								tag="h1"
								text={blok.heading}
								className={headingClasses.join(' ')}
							/>
						</div>
					)}
					{isStringWithValue(blok.body) && (
						<div className="mt-6 lg:text-lg xl:text-xl">
							{blok.body}
						</div>
					)}
					{isArrayWithValue(blok.cta) && (
						<div className="mt-6 lg:mt-10">
							<div className="flex flex-wrap gap-2">
								{blok.cta.map((cta, key) => {
									return (
										<div className="inline-flex" key={key}>
											<CTA blok={cta} />
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</Container>
		</div>
	);
};
