import { Caption } from '@/components/Shared/Caption';
import { WysiwygContainer } from '@/components/Shared/Layout/WysiwygContainer';
import { SbImage } from '@/components/Shared/Image/SbImage';
import { IWysiwygImage } from '@/utils/interfaces/bloks';

interface IProps {
	blok: IWysiwygImage;
}

export const WysiwygImage: React.FC<IProps> = ({ blok }) => {
	return (
		<WysiwygContainer narrow={false}>
			<div className="text-center">
				<figure className="inline-block text-none">
					<SbImage
						src={blok.image.filename}
						alt={blok.image.alt}
						layout="intrinsic"
					/>

					{blok.caption && <Caption caption={blok.caption} />}
				</figure>
			</div>
		</WysiwygContainer>
	);
};
