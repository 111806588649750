import { IIBuildListItem} from '@/utils/interfaces/bloks';

interface IProps {
	blok: IIBuildListItem;
	count: number;
}

export const IBuildListItem: React.FC<IProps> = ({ blok,count }) => {
	return (
		<div className="flex items-start gap-2 mb-4 lg:mb-9 last:mb-0 lg:gap-5">
			<span className="flex items-center justify-center w-6 h-6 bg-gradient-to-r from-green to-teal rounded-full text-white shrink-0 font-bold lg:text-xl lg:w-9 lg:h-9">{count}</span>
			<div>
				<h6 className="text-base lg:text-xl mb-1">{blok.heading}</h6>
				<p>{blok.content}</p>
			</div>
		</div>
	);
};
