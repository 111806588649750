interface IProps {}

export const Measurements: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M58.725 22.95v17.968H40.757V58.88H22.795V76.85H76.687V22.95H58.725Z"
				fill="#22B573"
			/>
			<path
				d="M76.687 78H22.79a1.15 1.15 0 0 1-1.151-1.151V58.88a1.151 1.151 0 0 1 1.151-1.15h16.817V40.917a1.151 1.151 0 0 1 1.151-1.151h16.817V22.95a1.151 1.151 0 0 1 1.151-1.151h17.962a1.151 1.151 0 0 1 1.152 1.151V76.85A1.152 1.152 0 0 1 76.687 78Zm-52.746-2.303h51.595V24.102h-15.66v16.816a1.151 1.151 0 0 1-1.15 1.152H41.908v16.81a1.151 1.151 0 0 1-1.152 1.152H23.941v15.665Z"
				fill="#224946"
			/>
			<path
				d="M67.102 10.388H16.537a1.151 1.151 0 1 1 0-2.303h50.565a1.151 1.151 0 1 1 0 2.303ZM9.082 68.409a1.152 1.152 0 0 1-1.152-1.152V16.52a1.151 1.151 0 1 1 2.303 0v50.738a1.152 1.152 0 0 1-1.151 1.152Z"
				fill="#22B573"
			/>
			<path
				d="M67.102 15.927a1.15 1.15 0 0 1-.997-.577 1.152 1.152 0 0 1-.155-.575V3.733a1.151 1.151 0 0 1 1.727-1.002l9.586 5.539a1.152 1.152 0 0 1 0 1.992L67.677 15.8a1.151 1.151 0 0 1-.575.127Zm1.151-10.23v7.086l6.131-3.546-6.13-3.54ZM9.081 78a1.151 1.151 0 0 1-1.002-.576l-5.532-9.591a1.152 1.152 0 0 1 .996-1.727H14.59a1.153 1.153 0 0 1 .996 1.727l-5.51 9.591a1.151 1.151 0 0 1-.996.576Zm-3.546-9.591 3.546 6.137 3.54-6.137H5.536Z"
				fill="#22B573"
			/>
			<path
				d="M23.952 31.01A1.152 1.152 0 0 1 22.8 29.86V22.23l-.242.305a1.154 1.154 0 0 1-1.802-1.44l2.303-2.878a1.152 1.152 0 0 1 2.05.72v10.91a1.153 1.153 0 0 1-1.157 1.162Z"
				fill="#224946"
			/>
			<path
				d="M26.105 31.01h-4.254a1.151 1.151 0 0 1 0-2.303h4.254a1.151 1.151 0 1 1 0 2.303ZM34.903 31.01H29.9a1.151 1.151 0 0 1-1.152-1.151c0-2.925 1.727-4.14 3.08-5.113 1.238-.869 1.883-1.381 1.923-2.625.04-1.243-.616-2.026-1.612-2.026a1.636 1.636 0 0 0-1.307.668 1.151 1.151 0 0 1-1.865-1.348 3.926 3.926 0 0 1 3.172-1.623c2.303 0 3.915 1.796 3.915 4.37-.08 2.48-1.64 3.586-2.879 4.467-.91.64-1.629 1.151-1.94 2.073h3.69a1.152 1.152 0 0 1 0 2.302l-.022.006ZM42.444 31.131a4.145 4.145 0 0 1-4.14-4.14v-5.18a4.139 4.139 0 1 1 8.274 0v5.18a4.145 4.145 0 0 1-4.134 4.14Zm0-11.151a1.836 1.836 0 0 0-1.837 1.836v5.181a1.837 1.837 0 1 0 3.668 0v-5.18a1.836 1.836 0 0 0-1.83-1.837Z"
				fill="#224946"
			/>
			<path
				d="M9.858 17.717a7.859 7.859 0 1 1 7.859-7.853 7.864 7.864 0 0 1-7.859 7.853Zm0-13.408a5.556 5.556 0 1 0 0 11.111 5.556 5.556 0 0 0 0-11.111Z"
				fill="#22B573"
			/>
			<path
				d="M40.843 71.408a1.152 1.152 0 0 1-.811-1.963l29.252-29.258a1.152 1.152 0 0 1 1.629 1.63L41.649 71.09c-.217.207-.506.32-.806.317ZM56.549 71.408a1.153 1.153 0 0 1-.818-1.963l13.553-13.552a1.152 1.152 0 1 1 1.629 1.629L57.36 71.092c-.22.207-.51.32-.812.316Z"
				fill="#224946"
			/>
		</svg>
	);
};
