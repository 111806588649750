import { ICategoryPage, IStoryBase } from '@/utils/interfaces/stories';
import { IFilter } from './InsightsFilter';

interface IProps {
	filter: IFilter | null;
	resultsCount: number;
	categories: IStoryBase<ICategoryPage>[] | null;
	setFilter: (value: IFilter | null) => void;
}

export const InsightsFilterBar: React.FC<IProps> = ({
	filter,
	resultsCount,
	categories,
	setFilter,
}) => {
	return (
		<div className="flex w-full">
			<div className="w-full overflow-hidden sm:container">
				<div className="flex justify-between px-4 text-xs sm:px-0 sm:text-sm lg:hidden">
					<span className="">Filter:</span>
					{resultsCount > 0 && (
						<span className="">
							Showing {resultsCount} results
						</span>
					)}
				</div>

				<div className="items-center lg:flex">
					<span className="hidden mr-4 lg:block">Filter:</span>
					<div className="flex items-center mt-2 space-x-2 overflow-x-auto overflow-y-hidden lg:flex-grow lg:mt-0 flex-nowrap whitespace-nowrap scrollbar-hide">
						<button
							onClick={() => setFilter(null)}
							className={`filter-pill ${
								filter === null ? 'filter-pill--active' : ''
							}`}
						>
							<span className="">All</span>
						</button>
						{categories?.map((category, key) => {
							const { name, uuid, slug } = category;

							return (
								<button
									className={`filter-pill ${
										filter?.uuid === category.uuid
											? 'filter-pill--active'
											: ''
									}`}
									key={key}
									disabled={filter?.uuid === category.uuid}
									onClick={() =>
										setFilter({
											uuid,
											slug,
											name,
										})
									}
								>
									<span className="">{category.name}</span>
								</button>
							);
						})}
					</div>
					{resultsCount > 0 && (
						<div className="hidden lg:block">
							<span className="">
								Showing {resultsCount} results
							</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
