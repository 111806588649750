import { createContext, useContext, useEffect, useState } from 'react';
import { StoryblokContext } from '@/components/Providers/StoryblokProvider';
import { ICta, IMenuItem } from '@/utils/interfaces/components';
import { buildParams } from '@/utils/helpers/buildParams';
import { useRouter } from 'next/router';

interface IProps {}

export type IMenuContext = {
	menu: IMenuItem[];
	menu_cta: ICta[];
};

const MenuContext = createContext<IMenuContext>({ menu: [], menu_cta: [] });
const MenuActiveContext = createContext<boolean>(false);
const MenuToggleContext = createContext<() => void>(() => {});

export function useMenu() {
	return useContext(MenuContext);
}

export function useMenuState() {
	return useContext(MenuActiveContext);
}

export function useMenuToggle() {
	return useContext(MenuToggleContext);
}

export const HeaderClientProvider: React.FC<IProps> = ({ children }) => {
	const { locale, asPath } = useRouter();

	const { storyblokClient } = useContext(StoryblokContext);
	const [menuContent, setMenuContent] = useState<IMenuContext>({
		menu: [],
		menu_cta: [],
	});
	const [menuActive, setMenuActive] = useState<boolean>(false);

	function toggleMenu() {
		setMenuActive((prevMenuState) => !prevMenuState);
	}

	async function fetchHeader() {
		if (storyblokClient) {
			const { data } = await storyblokClient.get(
				`cdn/stories/${locale}/global/header`,
				buildParams(locale),
			);
			return data;
		}
		return null;
	}

	useEffect(() => {
		setMenuActive(false);

		return () => {};
	}, [asPath]);

	useEffect(() => {
		const fetchData = async () => {
			const data = await fetchHeader();
			setMenuContent(data.story.content);
		};

		// call the function
		fetchData()
			// make sure to catch any error
			.catch(console.error);
	}, [locale]);

	return (
		<MenuContext.Provider value={menuContent}>
			<MenuActiveContext.Provider value={menuActive}>
				<MenuToggleContext.Provider value={toggleMenu}>
					{children}
				</MenuToggleContext.Provider>
			</MenuActiveContext.Provider>
		</MenuContext.Provider>
	);
};
