import React from "react";

interface IProps {
    before: string;
    after: string;
    handleClick: () => void;
    isActive: boolean;
}

export const Toggle: React.FC<IProps> = ({before, after, handleClick, isActive}) => {
    return (
        <div className="flex justify-center items-center gap-4 mt-12 md:mt-14">
            <p className="text-green-dark md:text-lg">{before}</p>
            <div
                className={`w-16 h-8 flex items-center rounded-full cursor-pointer ${isActive ? "bg-green" : "bg-gray"}`}
                onClick={handleClick}>
                <div
                    className={`w-8 h-8 p-[3px] rounded-full transition-transform ${isActive ? "translate-x-full" : ""}`}>
                    <div className="h-full w-full bg-white rounded-full"/>
                </div>
            </div>
            <p className="text-green-dark md:text-lg">{after}</p>
        </div>
    )
};
