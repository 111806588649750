import { Icon } from '@/components/Shared/Icon';
import { Arrow } from '@/components/Shared/Icons/Arrow';
import { BrandLogo } from '@/components/Shared/Icons/BrandLogo';
import { SbLink } from '@/components/Shared/SbLink';
import { ISbBase, ISbFile, ISbLink } from '@/utils/interfaces/common';
import { StoryblokComponent } from '@storyblok/react';
import Link from 'next/link';

interface IProps {
	media?: ISubscribeImage[] | null;
	link: ISbLink | null;
}

interface ISubscribeImage extends ISbBase {
	image: ISbFile;
	heading: string;
	description: string;
}

export const FormStepLayout: React.FC<IProps> = ({ media, children, link }) => {
	return (
		<div className="form-step-layout">
			<div className="form-step-layout__child form-step-layout__child--form">
				<div className="form-step-layout__helpers">
					<div className="form-step-layout__back">
						<Link href="/">
							<a className="form-step-layout__back-trigger">
								<div>
									<Icon
										className="form-step-layout__back-trigger-icon"
										icon={<Arrow />}
									/>
								</div>
								<span>Back to site</span>
							</a>
						</Link>
					</div>
					<div className="form-step-layout__help">
						{link && (
							<span>
								Need more help?{' '}
								<SbLink link={link}>Book a free demo</SbLink>
							</span>
						)}
					</div>
				</div>

				<div className="form-step-layout__container">
					<div className="form-step-layout__logo">
						<Icon
							className="w-[135px] lg:w-[180px] h-auto transition-all duration-200"
							icon={<BrandLogo />}
						/>
					</div>

					<div className="form-step-layout__form">{children}</div>
				</div>
			</div>

			<div className="form-step-layout__child form-step-layout__child--media">
				{media && media.length && (
					<>
						{media.map((blok) => {
							return (
								<StoryblokComponent
									blok={blok}
									key={blok._uid}
								/>
							);
						})}
					</>
				)}
			</div>
		</div>
	);
};
