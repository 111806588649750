interface IProps {
	color?: string;
}

export const Arrow: React.FC<IProps> = ({ color = 'currentColor' }) => {
	return (
		<svg
			width="12"
			height="10"
			viewBox="0 0 12 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.12369 0.542893C6.73317 0.152369 6.1 0.152369 5.70948 0.542893C5.31895 0.933417 5.31895 1.56658 5.70948 1.95711L7.12369 0.542893ZM10.1666 5L10.8737 5.70711C11.2642 5.31658 11.2642 4.68342 10.8737 4.29289L10.1666 5ZM5.70948 8.04289C5.31895 8.43342 5.31895 9.06658 5.70948 9.45711C6.1 9.84763 6.73317 9.84763 7.12369 9.45711L5.70948 8.04289ZM1.83325 4C1.28097 4 0.833252 4.44772 0.833252 5C0.833252 5.55228 1.28097 6 1.83325 6V4ZM5.70948 1.95711L9.45948 5.70711L10.8737 4.29289L7.12369 0.542893L5.70948 1.95711ZM9.45948 4.29289L5.70948 8.04289L7.12369 9.45711L10.8737 5.70711L9.45948 4.29289ZM10.1666 4H1.83325V6H10.1666V4Z"
				fill={color}
			/>
		</svg>
	);
};
