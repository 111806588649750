import Script from 'next/script';
import { useEffect, useState } from 'react';

interface IProps {
	blok: {
		portal_id: string;
		form_id: string;
	};
}

export const FormHubspot: React.FC<IProps> = ({ blok }) => {
	const [hbsptLoaded, setHbsptLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (!hbsptLoaded) {
			setTimeout(() => {
				if (window !== undefined && 'hbspt' in window) {
					(window as any).hbspt.forms.create({
						region: 'na1',
						portalId: blok.portal_id,
						formId: blok.form_id,
						target: '#hubstop-form',
					});
					setHbsptLoaded(true);
				}
			}, 100);
		}
	}, []);

	if (!hbsptLoaded) {
		return null;
	}

	return (
		<div>
			<Script type="text/javascript" src="//js.hsforms.net/forms/v2.js" />

			<div id="hubstop-form"></div>
		</div>
	);
};
