import { createContext, useState } from 'react';

interface IProps {}

export enum AlertStatus {
	NONE = 'NONE',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

export type IAlertContext = {
	alert: AlertStatus;
	alertText: null | string;
	success: (text: string, timeout: number) => void;
	error: (text: string, timeout: number) => void;
	clear: () => void;
};

export const AlertContext = createContext<IAlertContext>({
	alert: AlertStatus.NONE,
	alertText: '',
	success: () => {},
	error: () => {},
	clear: () => {},
});

export const AlertProvider: React.FC<IProps> = ({ children }) => {
	const [alert, setAlert] = useState(AlertStatus.NONE);
	const [alertText, setAlertText] = useState<null | string>(null);

	return (
		<AlertContext.Provider
			value={{
				alert: alert,
				alertText: alertText,
				success: (text: string, timeout: number) => {
					setAlertText(text);
					setAlert(AlertStatus.SUCCESS);
					setTimeout(() => {
						setAlert(AlertStatus.NONE);
					}, timeout * 1000 || 10000);
				},
				error: (text: string, timeout: number) => {
					setAlertText(text);
					setAlert(AlertStatus.ERROR);
					setTimeout(() => {
						setAlert(AlertStatus.NONE);
					}, timeout * 1000 || 10000);
				},
				clear: () => setAlert(AlertStatus.NONE),
			}}
		>
			{children}
		</AlertContext.Provider>
	);
};
