interface IProps {}

export const ShowPassword: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.69 7.448c-1.323 1-2.437 2.258-3.22 3.267-.624.804-.72.975-.72 1.285 0 .31.096.48.72 1.285.783 1.009 1.897 2.267 3.22 3.267 1.328 1.004 2.799 1.698 4.31 1.698 1.511 0 2.982-.694 4.31-1.698 1.323-1 2.437-2.258 3.22-3.267.624-.804.72-.975.72-1.285 0-.31-.096-.48-.72-1.285-.783-1.009-1.897-2.267-3.22-3.267C14.982 6.444 13.511 5.75 12 5.75c-1.511 0-2.982.694-4.31 1.698Zm-.905-1.196C8.246 5.147 10.032 4.25 12 4.25s3.754.897 5.215 2.002c1.467 1.11 2.674 2.48 3.5 3.544l.075.096c.515.662.96 1.234.96 2.108 0 .874-.445 1.446-.96 2.108l-.075.096c-.826 1.065-2.033 2.434-3.5 3.544-1.461 1.105-3.247 2.002-5.215 2.002s-3.754-.897-5.215-2.002c-1.467-1.11-2.674-2.48-3.5-3.544l-.075-.096c-.515-.662-.96-1.234-.96-2.108 0-.874.445-1.446.96-2.108l.075-.096c.826-1.065 2.033-2.434 3.5-3.544ZM12 10.125c-.972 0-1.798.818-1.798 1.875s.826 1.875 1.798 1.875c.972 0 1.798-.818 1.798-1.875s-.826-1.875-1.798-1.875ZM8.702 12c0-1.843 1.456-3.375 3.298-3.375s3.298 1.532 3.298 3.375-1.456 3.375-3.298 3.375S8.702 13.843 8.702 12Z"
				fill="#224946"
			/>
		</svg>
	);
};
