import { ComponentProps, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';

interface InputProps extends ComponentProps<'input'> {
	label: string;
	name: string;
	type: string;
	disabled: boolean;
	placeholder?: string;
}

export const FormInput = forwardRef<HTMLInputElement, InputProps>(
	function Input(
		{ label, type = 'text', disabled, placeholder, ...props },
		ref,
	) {
		const form = useFormContext();
		const state = form.getFieldState(props.name);

		const inputClasses = ['form-input__input'];

		if (state.error) {
			inputClasses.push('form-input__input--error');
		}

		return (
			<div className="form-input">
				<label className="form-input__label">{label}</label>

				<div className="form-input__control">
					<input
						className={inputClasses.join(' ')}
						type={type}
						ref={ref}
						disabled={disabled}
						placeholder={placeholder}
						{...props}
					/>
				</div>

				{state.error && (
					<span className="form-input__error">
						{state.error.message}
					</span>
				)}
			</div>
		);
	},
);
