import React from 'react';
import { Blueprint } from './BrandIcons/Blueprint';
import { BlueprintDark } from './BrandIcons/BlueprintDark';
import { BrickLayer } from './BrandIcons/BrickLayer';
import { BrickLayerDark } from './BrandIcons/BrickLayerDark';
import { Calculator } from './BrandIcons/Calculator';
import { CalculatorDark } from './BrandIcons/CalculatorDark';
import { Carpenter } from './BrandIcons/Carpenter';
import { CarpenterDark } from './BrandIcons/CarpenterDark';
import { Chart } from './BrandIcons/Chart';
import { ChartDark } from './BrandIcons/ChartDark';
import { Compass } from './BrandIcons/Compass';
import { CompassDark } from './BrandIcons/CompassDark';
import { DesktopApp } from './BrandIcons/DesktopApp';
import { DesktopAppDark } from './BrandIcons/DesktopAppDark';
import { Electrician } from './BrandIcons/Electrician';
import { ElectricianDark } from './BrandIcons/ElectricianDark';
import { Fire } from './BrandIcons/Fire';
import { FireDark } from './BrandIcons/FireDark';
import { House } from './BrandIcons/House';
import { HouseDark } from './BrandIcons/HouseDark';
import { Measurements } from './BrandIcons/Measurements';
import { MeasurementsDark } from './BrandIcons/MeasurementsDark';
import { PlanMeasurements } from './BrandIcons/PlanMeasurements';
import { PlanMeasurementsDark } from './BrandIcons/PlanMeasurementsDark';
import { PlanningPermissions } from './BrandIcons/PlanningPermissions';
import { PlanningPermissionsDark } from './BrandIcons/PlanningPermissionsDark';
import { Plans } from './BrandIcons/Plans';
import { PlansDark } from './BrandIcons/PlansDark';
import { Plumbing } from './BrandIcons/Plumbing';
import { PlumbingDark } from './BrandIcons/PlumbingDark';
import { Protractor } from './BrandIcons/Protractor';
import { ProtractorDark } from './BrandIcons/ProtractorDark';
import { Ruler } from './BrandIcons/Ruler';
import { RulerDark } from './BrandIcons/RulerDark';
import { SitePlans } from './BrandIcons/SitePlans';
import { SitePlansDark } from './BrandIcons/SitePlansDark';
import { Tools } from './BrandIcons/Tools';
import { ToolsDark } from './BrandIcons/ToolsDark';
import { Weather } from './BrandIcons/Weather';
import { WeatherDark } from './BrandIcons/WeatherDark';
import { Wind } from './BrandIcons/Wind';
import { WindDark } from './BrandIcons/WindDark';
import { WorkerV1 } from './BrandIcons/WorkerV1';
import { WorkerV1Dark } from './BrandIcons/WorkerV1Dark';
import { WorkerV2 } from './BrandIcons/WorkerV2';
import { WorkerV2Dark } from './BrandIcons/WorkerV2Dark';

type MapKindToComponentT = {
	[key: string]: React.FC<any>;
};

const iconMap: MapKindToComponentT = {
	'brick-layer': BrickLayer,
	'brick-layer--dark': BrickLayerDark,
	'desktop-app': DesktopApp,
	'desktop-app--dark': DesktopAppDark,
	'planning-permissions': PlanningPermissions,
	'planning-permissions--dark': PlanningPermissionsDark,
	'site-plans': SitePlans,
	'site-plans--dark': SitePlansDark,
	'worker-v1': WorkerV1,
	'worker-v1--dark': WorkerV1Dark,
	'worker-v2': WorkerV2,
	'worker-v2--dark': WorkerV2Dark,
	blueprint: Blueprint,
	'blueprint--dark': BlueprintDark,
	calculator: Calculator,
	'calculator--dark': CalculatorDark,
	carpenter: Carpenter,
	'carpenter--dark': CarpenterDark,
	chart: Chart,
	'chart--dark': ChartDark,
	compass: Compass,
	'compass--dark': CompassDark,
	electrician: Electrician,
	'electrician--dark': ElectricianDark,
	fire: Fire,
	'fire--dark': FireDark,
	house: House,
	'house--dark': HouseDark,
	measurements: Measurements,
	'measurements--dark': MeasurementsDark,
	plans: Plans,
	'plans--dark': PlansDark,
	'plan-measurements': PlanMeasurements,
	'plan-measurements--dark': PlanMeasurementsDark,
	plumbing: Plumbing,
	'plumbing--dark': PlumbingDark,
	protractor: Protractor,
	'protractor--dark': ProtractorDark,
	ruler: Ruler,
	'ruler--dark': RulerDark,
	tools: Tools,
	'tools--dark': ToolsDark,
	weather: Weather,
	'weather--dark': WeatherDark,
	wind: Wind,
	'wind--dark': WindDark,
};

interface IProps {
	label: string;
	dark: boolean;
}

export const BrandIcon: React.FC<IProps> = ({ label, dark }) => {
	if (label && typeof iconMap[label] !== 'undefined') {
		const Component = dark
			? iconMap[`${label}--dark`]
			: iconMap[`${label}`];

		return <Component />;
	}

	return null;
};
