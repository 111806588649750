import { AlertContext } from '@/components/Providers/AlertProvider';
import {
	UpdateUserPaymentSubscriptionRequest,
	UserPaymentSubscriptionRequest,
	UserPaymentSubscriptionResponse,
} from '@/utils/interfaces/cibApiResponse';
import axios from 'axios';
import { pathOr } from 'ramda';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { BASE_URL, IFormStepProps } from '../SelfSubscribeContainer';
import { CostSummary } from '../Shared/CostSummary';
import { DropdownChecklist } from '../Shared/DropdownChecklist';
import { FormCol } from '../Shared/FormCol';
import { FormRow } from '../Shared/FormRow';
import { FormStepLayout } from '../Shared/FormStepLayout';
import { Skeleton } from '../Shared/Skeleton';

async function sendCheckoutData(data: UserPaymentSubscriptionRequest) {
	const { data: response } = await axios.post(`${BASE_URL}/signup`, data);
	return response.data;
}

async function updateCheckoutData(data: UpdateUserPaymentSubscriptionRequest) {
	const { data: response } = await axios.post(`${BASE_URL}/signup`, data);
	return response.data;
}

export const Checkout: React.FC<IFormStepProps> = ({
	children,
	blok,
	...props
}) => {
	const alert = useContext(AlertContext);
	const [chargeBee, setChargeBee] =
		useState<UserPaymentSubscriptionResponse | null>(null);

	const [subscriptionKey, setSubscriptionKey] = useState<string | null>(null);

	const {
		status: updateCheckoutStatus,
		mutateAsync: updateCheckoutMutationAsync,
		data: updateCheckoutResponse,
		isLoading: updateCheckoutLoading,
	} = useMutation(updateCheckoutData, {
		onSuccess: (data: UserPaymentSubscriptionResponse) => {
			// Add subscription key to global state
			setSubscriptionKey(data.subscriptionKey);
		},
		onError: (error) => {
			const errMessage = pathOr(
				'',
				['response', 'data', 'message'],
				error,
			);

			alert.error(errMessage, 10);
		},
	});

	const {
		status: sendCheckoutStatus,
		mutate: sendCheckoutMutation,
		data: sendCheckoutResponse,
		isLoading: sendCheckoutLoading,
		isSuccess: sendCheckoutIsSuccess,
		error: sendCheckoutError,
		isError: sendCheckoutIsError,
	} = useMutation(sendCheckoutData, {
		onSuccess: (data: UserPaymentSubscriptionResponse) => {
			// Add subscription key to global state
			setSubscriptionKey(data.subscriptionKey);
		},
		onError: (error) => {
			console.log('there was an error on getting the checkout', error);
		},
	});

	useEffect(() => {
		const sessionId = pathOr(null, ['formData', 'sessionId'], props);
		const email = pathOr(null, ['formData', 'userData', 'email'], props);

		if (props.formData && sessionId && email) {
			const data = {
				step: 'subscription',
				data: {
					email,
					sessionId,
					verified: true,
				},
			};

			sendCheckoutMutation(data);
		}
	}, [sendCheckoutMutation]);

	useEffect(() => {
		if (sendCheckoutStatus === 'success') {
			setChargeBee(sendCheckoutResponse);
		}
	}, [sendCheckoutStatus, sendCheckoutResponse]);

	function handleNext() {
		if (subscriptionKey == null) {
			return;
		}

		// props.send('NEXT');
		const payload = {
			step: 'update_subscription',
			data: {
				email: pathOr('', ['formData', 'userData', 'email'], props),
				sessionId: pathOr('', ['formData', 'sessionId'], props),
				subscriptionKey: subscriptionKey,
			},
		};

		return new Promise((_) => {
			updateCheckoutMutationAsync(payload).then((_) => {
				props.send({
					event: 'NEXT',
					data: {
						subscriptionKey,
					},
				});
			});
		});
	}

	const heading = pathOr(null, ['checkout_heading'], blok);
	const description = pathOr(null, ['checkout_description'], blok);
	const media = pathOr(null, ['checkout_media'], blok);
	const demoLink = pathOr(null, ['demo_link'], blok);

	return (
		<FormStepLayout media={media} link={demoLink}>
			<div className="form-step">
				<div className="form-step__heading">
					<h4>{heading}</h4>
				</div>

				{description && (
					<div className="form-step__description">
						<span>{description}</span>
					</div>
				)}

				{props.formData.planData?.searches && (
					<div className="form-step__dropdown">
						<DropdownChecklist
							addOns={props.formData.planData?.addons}
							label="Plan summary"
							searchesAmount={props.formData.planData?.searches}
						>
							<CostSummary
								discount={props.formData.planData?.discount}
								cost={props.formData.planData?.cost}
								billingCycle={
									props.formData.planData?.billingCycle
								}
							/>
						</DropdownChecklist>
					</div>
				)}

				<div className="form-step__form">
					<FormRow>
						{sendCheckoutLoading ? (
							<div className="flex flex-col w-full">
								<div className="w-full h-[550px] mt-2">
									<Skeleton />
								</div>
							</div>
						) : (
							<>
								{sendCheckoutIsError ? (
									<div>
										An error occurred: {sendCheckoutError}
									</div>
								) : null}
								{sendCheckoutIsSuccess &&
								pathOr(false, ['url'], chargeBee) ? (
									<span className="w-full">
										<iframe
											className="form-step__iframe"
											src={chargeBee?.url}
										></iframe>
									</span>
								) : (
									<></>
								)}
							</>
						)}
					</FormRow>

					<FormRow>
						<FormCol submit={true}>
							<div className="form-step__form-row form-step__form-row--submit">
								{sendCheckoutIsSuccess && (
									<button
										onClick={() => handleNext()}
										className="cta cta--primary cta--large"
									>
										<span className="cta-label">
											Confirm details
										</span>
									</button>
								)}

								{props.previousEnabled && (
									<button
										className="cta cta--form-nav"
										onClick={() =>
											props.send({
												event: 'PREVIOUS',
												data: {},
											})
										}
									>
										<span className="cta-label">
											Previous
										</span>
									</button>
								)}
							</div>
						</FormCol>
					</FormRow>
				</div>
			</div>
		</FormStepLayout>
	);
};
