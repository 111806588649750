interface IProps {}

export const CompassDark: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M45.217 56.177h-3.252a1.152 1.152 0 1 1 0-2.303h3.252a1.151 1.151 0 1 1 0 2.303ZM35.355 56.177h-20.26a1.151 1.151 0 1 1 0-2.303h20.26a1.151 1.151 0 1 1 0 2.303ZM8.49 56.177H5.239a1.152 1.152 0 1 1 0-2.303H8.49a1.152 1.152 0 1 1 0 2.303Z"
				fill="#fff"
			/>
			<path d="M28.33 14.476V3.151h-6.206v11.325h6.207Z" fill="#22B573" />
			<path
				d="M28.33 15.628h-6.177a1.152 1.152 0 0 1-1.151-1.152V3.151A1.152 1.152 0 0 1 22.152 2h6.207a1.152 1.152 0 0 1 1.151 1.151v11.325a1.154 1.154 0 0 1-1.18 1.152Zm-5.054-2.303h3.903V4.303h-3.875l-.028 9.022ZM3.85 77.998a1.15 1.15 0 0 1-1.082-1.543l1.347-3.702a1.152 1.152 0 1 1 2.165.789l-1.347 3.696a1.15 1.15 0 0 1-1.083.76ZM46.605 77.998a1.151 1.151 0 0 1-1.083-.76l-1.347-3.696a1.153 1.153 0 0 1 2.165-.789l1.347 3.702a1.152 1.152 0 0 1-1.082 1.543Z"
				fill="#fff"
			/>
			<path
				d="M25.227 30.448a8.096 8.096 0 0 1-8.06-8.09v-7.882a1.151 1.151 0 0 1 1.151-1.151h13.876a1.152 1.152 0 0 1 1.151 1.151v7.882a8.1 8.1 0 0 1-8.118 8.09Zm-5.757-14.82v6.73a5.786 5.786 0 1 0 11.572 0v-6.73H19.47Z"
				fill="#fff"
			/>
			<path
				d="M47.112 69.172 31.296 25.715a6.908 6.908 0 0 1-5.32 3.54l15.303 42.03a3.102 3.102 0 1 0 5.833-2.119v.006Z"
				fill="#22B573"
			/>
			<path
				d="M44.193 74.492a4.272 4.272 0 0 1-3.996-2.804l-15.304-42.03a1.152 1.152 0 0 1 .962-1.537 5.803 5.803 0 0 0 4.433-2.953 1.152 1.152 0 0 1 2.09.167l15.816 43.451a4.267 4.267 0 0 1-4.03 5.717l.029-.011ZM27.496 30.113 42.339 70.9a1.958 1.958 0 0 0 2.498 1.152 1.969 1.969 0 0 0 1.106-1.014 1.942 1.942 0 0 0 .063-1.49L30.91 28.092a8.1 8.1 0 0 1-3.391 2.02h-.023Z"
				fill="#fff"
			/>
			<path
				d="M19.16 25.715 3.343 69.172a3.103 3.103 0 1 0 5.832 2.119l15.298-42.03a6.91 6.91 0 0 1-5.315-3.546Z"
				fill="#22B573"
			/>
			<path
				d="M6.251 74.486a4.232 4.232 0 0 1-3.852-2.458 4.22 4.22 0 0 1-.138-3.253l15.816-43.452a1.15 1.15 0 0 1 2.09-.167A5.757 5.757 0 0 0 24.6 28.11a1.153 1.153 0 0 1 1.02 1.024c.017.174-.005.35-.064.513l-15.304 42.03a4.255 4.255 0 0 1-4.001 2.81Zm13.242-46.4L4.397 69.54a1.952 1.952 0 1 0 3.668 1.336L22.907 30.09a8.06 8.06 0 0 1-3.385-2.003h-.029ZM25.227 58.567a1.151 1.151 0 0 1-1.151-1.152v-4.784a1.152 1.152 0 1 1 2.303 0v4.784a1.151 1.151 0 0 1-1.152 1.152ZM25.228 24.287a1.929 1.929 0 1 1 0-3.857 1.929 1.929 0 0 1 0 3.857Zm0-2.303a.374.374 0 1 0 0 .749.374.374 0 0 0 0-.749Z"
				fill="#fff"
			/>
			<path
				d="M76.849 76.45h-5.142a1.152 1.152 0 0 1-1.151-1.152V3.15A1.152 1.152 0 0 1 71.707 2h5.142A1.152 1.152 0 0 1 78 3.151v72.147a1.152 1.152 0 0 1-1.151 1.151Zm-3.99-2.304h2.838V4.303H72.86v69.843ZM65.869 4.303h-3.794a1.151 1.151 0 0 1 0-2.303h3.794a1.151 1.151 0 1 1 0 2.303ZM57.043 4.303h-3.8a1.151 1.151 0 1 1 0-2.303h3.794a1.152 1.152 0 0 1 0 2.303h.006ZM48.223 4.303h-3.8a1.152 1.152 0 1 1 0-2.303h3.8a1.152 1.152 0 0 1 0 2.303ZM39.426 4.303h-3.794a1.152 1.152 0 1 1 0-2.303h3.794a1.151 1.151 0 1 1 0 2.303ZM65.869 40.373h-3.794a1.151 1.151 0 1 1 0-2.303h3.794a1.151 1.151 0 1 1 0 2.303ZM57.043 40.373h-3.8a1.151 1.151 0 1 1 0-2.303h3.794a1.152 1.152 0 1 1 0 2.303h.006ZM48.223 40.373h-3.8a1.151 1.151 0 1 1 0-2.303h3.8a1.151 1.151 0 1 1 0 2.303ZM65.869 76.45h-3.794a1.152 1.152 0 0 1 0-2.304h3.794a1.151 1.151 0 1 1 0 2.303ZM57.043 76.45h-3.8a1.151 1.151 0 1 1 0-2.304h3.794a1.152 1.152 0 0 1 0 2.303h.006Z"
				fill="#22B573"
			/>
		</svg>
	);
};
