interface IProps {
    color?: string;
}

export const Check: React.FC<IProps> = ({color = 'currentColor'}) => {
    return (
        <svg
            viewBox="0 0 9 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.69294 7.67341C3.31141 8.10886 2.68533 8.10886 2.30381 7.67341L0.278804 5.33985C-0.0929348 4.9044 -0.0929348 4.21214 0.278804 3.78786L0.288587 3.77669C0.670109 3.34124 1.28641 3.34124 1.65815 3.77669L2.99837 5.30635L7.33207 0.326588C7.71359 -0.108863 8.32989 -0.108863 8.71141 0.326588L8.7212 0.337753C9.09293 0.773203 9.09293 1.46546 8.7212 1.88974L3.69294 7.67341Z"
                fill={color}
            />
        </svg>

    );
};
