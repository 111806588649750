interface IProps {
	rounded: 'sm' | 'md' | 'lg' | 'xl' | boolean;
	shadow: 'sm' | 'md' | 'lg' | 'xl' | boolean;
	whiteBg?: boolean;
}

export const SbImageWrapper: React.FC<IProps> = ({
	children,
	shadow,
	rounded,
	whiteBg = false,
}) => {
	const classes = [
		'text-none',
		'overflow-hidden',
		'relative',
		'w-full',
		'h-full',
	];

	if (rounded === 'sm') {
		classes.push('rounded-sm');
	} else if (rounded === 'md') {
		classes.push('rounded-md');
	} else if (rounded === 'lg') {
		classes.push('rounded-lg');
	} else if (rounded === 'xl') {
		classes.push('rounded-xl');
	}

	if (shadow) {
		classes.push(`shadow-hard`);
	}

	if (whiteBg) {
		classes.push(`bg-white`);
	}

	return <div className={classes.join(' ')}>{children}</div>;
};
