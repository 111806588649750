interface IProps {}

export const Fire: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M70.146 48.676v20.1H43.351v-20.1L56.749 35.28l13.397 13.397ZM16.55 69.928a1.209 1.209 0 0 1-.42-.08C3.726 64.923 2 58.371 2 52.027c0-6.553 3.817-11.965 10.214-14.475a1.152 1.152 0 0 1 1.468 1.543c-1.244 2.792-1.693 7.116-.069 9.62.933 1.434 2.482 2.16 4.606 2.16 2.027 0 3.506-.76 4.399-2.263 1.503-2.539 1.077-6.736-1.094-10.686a22.35 22.35 0 0 1-1.393-18.35c1.825-5.043 5.25-8.872 9.39-10.495a1.151 1.151 0 0 1 1.549 1.324c-2.522 11.187 2.913 17.008 8.176 22.639 2.39 2.556 4.646 4.968 5.976 7.76a1.15 1.15 0 1 1-2.078.991c-1.152-2.47-3.317-4.756-5.58-7.18-5.055-5.406-10.732-11.515-9.177-22.362a16.26 16.26 0 0 0-6.091 8.112 20.034 20.034 0 0 0 1.249 16.45c2.59 4.727 2.994 9.696 1.053 12.972-1.295 2.188-3.558 3.39-6.379 3.39-3.685 0-5.59-1.726-6.54-3.206-1.659-2.55-1.728-6.103-1.002-9.12a12.57 12.57 0 0 0-6.374 11.175c0 6.397 2.153 11.515 12.667 15.678a1.152 1.152 0 0 1-.426 2.223h.005Z"
				fill="#22B573"
			/>
			<path
				d="M75.173 54.854a1.152 1.152 0 0 1-.812-.34L56.749 36.909 39.136 54.515a1.151 1.151 0 0 1-1.623-1.63l18.424-18.424a1.151 1.151 0 0 1 1.623 0l18.425 18.424a1.151 1.151 0 0 1-.812 1.97Z"
				fill="#224946"
			/>
			<path
				d="M70.147 69.928H43.35a1.152 1.152 0 0 1-1.152-1.152v-20.1a1.152 1.152 0 0 1 2.303 0v18.949h24.493V48.676a1.152 1.152 0 0 1 2.303 0v20.1a1.152 1.152 0 0 1-1.151 1.152ZM65.126 44.802a1.152 1.152 0 0 1-1.151-1.152V33.603a1.151 1.151 0 1 1 2.303 0V43.65a1.152 1.152 0 0 1-1.152 1.152Z"
				fill="#224946"
			/>
			<path
				d="M76.848 69.928H24.926a1.151 1.151 0 1 1 0-2.303h51.922a1.151 1.151 0 1 1 0 2.303Z"
				fill="#224946"
			/>
		</svg>
	);
};
