import { buildParams } from '@/utils/helpers/buildParams';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { IInsightPage, IStoryBase } from '@/utils/interfaces/stories';
import { Ring } from '@uiball/loaders';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { StoryblokContext } from '../Providers/StoryblokProvider';
import { Container } from '../Shared/Layout/Container';
import { InsightFeatureCard } from '../Shared/Tiles/InsightFeatureCard';

interface IProps {
	blok: IInsightPage;
	story: IStoryBase<IInsightPage>;
}

export const InsightsRelated: React.FC<IProps> = ({ blok, story }) => {
	const { storyblokClient } = useContext(StoryblokContext);
	const { locale } = useRouter();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [insights, setInsights] = useState<IStoryBase<IInsightPage>[]>([]);

	async function fetchInsights() {
		if (storyblokClient) {
			const filterParams = {
				per_page: 2,
				excluding_slugs: story.full_slug,
				filter_query: {
					categories: {
						in: blok.categories.uuid,
					},
				},
			};

			const params = Object.assign(
				filterParams,
				buildParams(locale, `${locale}/resources/`),
			);

			const { data } = await storyblokClient.get('cdn/stories', params);

			return data;
		}

		return null;
	}

	useEffect(() => {
		const fetchData = async () => {
			const data = await fetchInsights();

			if (isLoading) {
				setInsights(data.stories);
				setIsLoading(false);
			}
		};

		// call the function
		fetchData()
			// make sure to catch any error
			.catch(console.error);
	}, []);

	if (insights.length === 0 || !insights) {
		return null;
	}

	return (
		<section className="relative py-14 md:py-32 bg-light">
			<Container>
				<div className="flex justify-start">
					<h2>Related Posts</h2>
				</div>

				{isLoading ? (
					<div className="flex min-h-[200px] items-center justify-center">
						<Ring size={48} lineWeight={5} speed={2} color="gray" />
					</div>
				) : (
					<div className="mt-8 lg:mt-12">
						{insights && isArrayWithValue(insights) && (
							<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-8">
								{insights.map((insight, key) => {
									return (
										<InsightFeatureCard
											blok={insight}
											key={key}
										/>
									);
								})}
							</div>
						)}
					</div>
				)}
			</Container>
		</section>
	);
};
