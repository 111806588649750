interface IProps {
	className: string;
}

export const CutoutDesktop: React.FC<IProps> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 220 858"
			className={className}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="a"
				style={{ maskType: 'alpha' }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="220"
				height="858"
			>
				<path fill="#C4C4C4" d="M0 0h220v858H0z" />
			</mask>
			<g mask="url(#a)">
				<path
					d="m-462.732 1110.92 30.661 49.08 609.993-377.091-30.638-49.234c-56.899-91.518-87.045-197.056-87.045-304.73 0-107.675 30.146-213.212 87.045-304.731L178 74.969-432.105-302l-30.66 49.068C-590.373-48.232-658 187.978-658 428.994c0 241.017 67.627 477.227 195.235 681.926h.033Z"
					fill="#224946"
					stroke="#EFF0EF"
					strokeWidth="60"
					strokeMiterlimit="10"
				/>
			</g>
		</svg>
	);
};
