import { createYupSchema } from '@/utils/helpers/createYupSchema';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import {
	IFormGroup,
	IFormItems,
	ISbFormValidations,
} from '@/utils/interfaces/components';
import { StoryblokComponent } from '@storyblok/react';
import { Form, Formik } from 'formik';
import { Fragment, useState } from 'react';
import * as yup from 'yup';

interface IProps {
	blok: IFormGroup;
}

export const SbForm: React.FC<IProps> = ({ blok }) => {
	const deepCopyFormElements = JSON.parse(JSON.stringify(blok.form_rows));

	const [message, setMessage] = useState<string>(''); // This will be used to show a message if the submission is successful
	const [submitted, setSubmitted] = useState<boolean>(false);

	const flattenedFormFields = deepFlattenArray(deepCopyFormElements);
	const yepSchema = buildFormSchema(flattenedFormFields);
	const validateSchema = yup.object().shape(yepSchema);

	return (
		<Formik
			initialValues={{}}
			validationSchema={validateSchema}
			onSubmit={(values, actions) => {
				alert(JSON.stringify(values, null, 2));
				actions.setSubmitting(false);
			}}
		>
			{({ values, isValid, errors }) => {
				return (
					<Form>
						{blok.form_rows && isArrayWithValue(blok.form_rows) && (
							<Fragment>
								{blok.form_rows.map((row, key) => {
									return (
										<StoryblokComponent
											key={key}
											blok={row}
										/>
									);
								})}
							</Fragment>
						)}

						<div className="flex justify-end mt-10">
							<button
								type="submit"
								disabled={!isValid}
								className="cta cta--primary cta--medium"
							>
								<span className="cta-label">
									{blok.submit_label}
								</span>
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

function buildFormSchema(formFields: IFormItems[]) {
	const formData = formFields.map((field) => {
		const newSchema = {
			id: field.name,
			validationType: getValidationType(field),
			validations: buidValidations(field.validations),
		};

		return newSchema;
	});

	return formData.reduce(createYupSchema, {});
}

function getValidationType(field: IFormItems) {
	if ('type' in field && field.type) {
		if (field.type === 'number') {
			return field.type;
		}

		return 'string';
	}

	return 'string';
}

function buidValidations(validations: ISbFormValidations[]) {
	if (!isArrayWithValue(validations)) {
		return [];
	}

	return validations.map((validator) => {
		const params: any[] = [];

		if ('param' in validator && validator.param) {
			params.push(parseInt(validator.param as string));
		}

		params.push(validator.error_message);

		return {
			type: validator.component,
			params,
		};
	});
}

function deepFlattenArray(arr: any[]): any {
	const finalArray: any = [];
	// Loop through the array contents
	for (let i = 0; i < arr.length; i++) {
		for (const [key] of Object.entries(arr[i])) {
			if (Array.isArray(arr[i][key])) {
				finalArray.push(...deepFlattenArray(arr[i][key]));
			} else {
				if (
					arr[i][key] === 'form_input' ||
					arr[i][key] === 'form_textarea' ||
					arr[i][key] === 'form_select' ||
					arr[i][key] === 'form_checkbox'
				) {
					finalArray.push(arr[i]);
				}
			}
		}
	}

	return finalArray;
}
