import { useRouter } from 'next/router';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { useMenu } from '@/components/Providers/HeaderProvider';
import { MenuItem } from '@/components/Shared/Menu/MenuItem';
import { useEffect, useState } from 'react';

interface IProps {}

export const Menu: React.FC<IProps> = () => {
	const menuContext = useMenu();
	const { menu } = menuContext;
	const [activeMenuItem, setActiveMenuItem] = useState(0);
	const { asPath } = useRouter();

	useEffect(() => {
		setActiveMenuItem(0);
	}, [asPath]);

	return (
		<>
			{isArrayWithValue(menu) && (
                menu.map((menu_item, key) => (
                    <MenuItem
                        blok={menu_item}
                        key={key}
                        isActive={activeMenuItem === key + 1}
                        handleClick={() =>
                            setActiveMenuItem(key + 1)
                        }
                        handleClose={() => setActiveMenuItem(0)}
                    />
                ))
			)}
		</>
	);
};
