interface IProps {}

export const Facebook: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 36 36"
			 xmlns="http://www.w3.org/2000/svg">
			<path
				d="m21.857 9.753-2.083-.003c-2.34 0-3.852 1.594-3.852 4.06v1.873h-2.095c-.18 0-.327.15-.327.337v2.712c0 .186.147.337.328.337h2.094v6.845c0 .185.146.336.327.336h2.733c.18 0 .327-.15.327-.336v-6.845h2.45c.18 0 .327-.15.327-.337V16.02a.342.342 0 0 0-.095-.238.323.323 0 0 0-.232-.1h-2.45v-1.586c0-.763.177-1.15 1.145-1.15h1.403c.18 0 .327-.152.327-.337v-2.52a.332.332 0 0 0-.327-.336Z"
				fill="currentColor"
			/>
		</svg>
	);
};
