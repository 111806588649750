import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { ISbLink } from '@/utils/interfaces/common';
import { IFeatureTab } from '@/utils/interfaces/components';
import { Tab } from '@headlessui/react';
import Link from 'next/link';
import { Fragment } from 'react';
import { CTA } from '../CTA/CallToAction';
import { FormattedText } from '../FormattedText';
import { Icon } from '../Icon';
import { Arrow } from '../Icons/Arrow';
import { BrandIcon } from '../Icons/BrandIcon';
import { SbImage } from '../Image/SbImage';
import { SbImageWrapper } from '../Image/SbImageWrapper';

interface IProps {
	tabs: IFeatureTab[];
	cta: ISbLink;
	ctaLabel: string;
	preview: boolean;
}

type MapKindToComponentT = {
	[key: string]: string;
};

const lengthMap: MapKindToComponentT = {
	'1': 'w-1/2',
	'2': 'w-1/3',
	'3': 'w-1/4',
	'4': 'w-1/5',
	'5': 'w-1/6',
};

export const FeatureTabsDesktop: React.FC<IProps> = ({
	tabs,
	cta,
	ctaLabel,
}) => {
	const length: string = tabs.length.toString();
	const width = lengthMap[length];

	const tabClassNames = [
		'flex',
		'flex-col',
		'items-center',
		'justify-center',
		'w-full',
		'bg-gray-light',
		'p-4',
		'transition-colors',
		'duration-200',
		`${width}`,
	];

	const flexControlClasses = [
		'relative',
		'flex',
		'w-full',
		'lg:flex-row',
		'justify-between',
		'md:flex-col',
	];
	const textContainerClasses = [
		'justify-center',
		'text-left',
		'flex',
		'flex-col',
		'flex-1',
		'max-w-[494px]',
		'lg:py-[92px]',
	];
	const imageContainerClasses = [
		'md:flex',
		'md:flex-col',
		'lg:min-h-[500px]',
		'lg:min-w-[560px]',
		'md:justify-center',
		'xl:min-h-[600px]',
		'xl:min-w-[660px]',
	];

	return (
		<div className="">
			<Tab.Group>
				<Tab.List>
					<div className="flex w-full space-x-[2px]">
						{tabs.map((tab, key) => {
							return (
								<Tab as={Fragment} key={key}>
									{({ selected }) => (
										<button
											className={`${tabClassNames.join(
												' ',
											)} ${
												selected
													? 'bg-green-dark text-white '
													: 'bg-gray-light text-green-dark '
											}`}
										>
											<div>
												<div className="flex justify-center">
													<span className="w-10 h-10">
														<BrandIcon
															dark={selected}
															label={tab.icon}
														/>
													</span>
												</div>
												<span className="block mt-2 text-sm font-medium leading-6 text-inherit">
													{tab.label}
												</span>
											</div>
										</button>
									)}
								</Tab>
							);
						})}

						<Link href={cta.cached_url}>
							<a className={tabClassNames.join(' ')}>
								<div>
									<div className="text-green">
										<Icon
											className={`w-3 h-3`}
											icon={<Arrow />}
										/>
									</div>
								</div>
								<span className="text-base font-medium leading-6 text-green-dark">
									{ctaLabel}
								</span>
							</a>
						</Link>
					</div>
				</Tab.List>
				<div className="relative lg:mt-16">
					<div className="absolute hidden w-screen h-full -translate-y-1/2 lg:block bg-gray-light top-1/2 left-1/4"></div>
					<Tab.Panels>
						{tabs.map((tab, key) => {
							return (
								<Tab.Panel key={key}>
									<div
										className={flexControlClasses.join(' ')}
									>
										<div
											className={imageContainerClasses.join(
												' ',
											)}
										>
											<div className="max-w-[290px] mx-auto py-20 sm:max-w-[490px] lg:py-0 xl:max-w-none">
												<div
													className={`relative z-20`}
												>
													<SbImageWrapper
														shadow={false}
														rounded="xl"
													>
														<SbImage
															src={
																tab.image
																	.filename
															}
															alt={tab.image.alt}
															layout="intrinsic"
															objectFit="contain"
														/>
													</SbImageWrapper>
												</div>
											</div>
										</div>
										<div
											className={textContainerClasses.join(
												' ',
											)}
										>
											{tab.sub_heading &&
												isStringWithValue(
													tab.sub_heading,
												) && (
													<span className="text-sm font-bold text-green">
														{tab.sub_heading}
													</span>
												)}

											<FormattedText
												tag="h4"
												text={tab.heading}
												className="mt-2"
											/>

											<span className="block mt-6 text-base opacity-80">
												{tab.description}
											</span>

											<div className="mt-4">
												{tab.ctas.map((cta, key) => {
													return (
														<CTA
															blok={cta}
															key={key}
														/>
													);
												})}
											</div>
										</div>
									</div>
								</Tab.Panel>
							);
						})}
					</Tab.Panels>
				</div>
			</Tab.Group>
		</div>
	);
};
