import {Icon} from "@/components/Shared/Icon";
import {Arrow} from "@/components/Shared/Icons/Arrow";
import {Ref} from "react";

interface IProps {
    arrowRef: Ref<HTMLDivElement>;
    isPrev?: boolean;
}

export const SwiperArrow: React.FC<IProps> = ({arrowRef, isPrev}) => {
    return (
        <div ref={arrowRef}
             className="h-6 w-6 md:h-8 md:w-8 rounded-full text-white bg-green-dark flex items-center justify-center cursor-pointer scale-90 transition-transform hover:scale-100">
            <Icon className={isPrev ? '-scale-100' : ''} icon={<Arrow/>}/>
        </div>
    );
};
