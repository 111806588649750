interface IProps {}

export const Carpenter: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28.11 48.256a1.152 1.152 0 0 1-1.15-1.151v-5.982H21a1.151 1.151 0 0 1-1.151-1.152v-7.133a1.152 1.152 0 0 1 2.303 0v5.982h5.976a1.152 1.152 0 0 1 1.151 1.151v7.134a1.15 1.15 0 0 1-1.168 1.151ZM54.71 48.256a1.151 1.151 0 0 1-.978-.54L42.59 29.865a1.153 1.153 0 1 1 1.952-1.22l11.14 17.849a1.15 1.15 0 0 1-.972 1.761ZM56.64 77.971a1.151 1.151 0 0 1-1.152-1.151v-4.79H49.51a1.151 1.151 0 0 1-1.151-1.152v-5.982h-5.982a1.152 1.152 0 0 1-1.152-1.152V57.82a1.151 1.151 0 1 1 2.303 0v4.79h5.982a1.152 1.152 0 0 1 1.152 1.151v5.983h5.976a1.151 1.151 0 0 1 1.152 1.151v4.497l12.413-2.879-9.442-14.072a1.152 1.152 0 1 1 1.911-1.278l10.364 15.454a1.152 1.152 0 0 1-.697 1.767l-15.442 3.587a1.32 1.32 0 0 1-.259 0Z"
				fill="#224946"
			/>
			<path
				d="M44.923 25.042 33.885 4.97a3.57 3.57 0 0 0-3.097-1.848h-1.457a3.564 3.564 0 0 0-3.564 3.564v.575a2.379 2.379 0 0 1-2.878 2.303l-.576-.126a11.883 11.883 0 0 0-11.86 4.18l-.127.155h-3.57a3.564 3.564 0 0 0-3.564 3.564v1.192a3.564 3.564 0 0 0 3.564 3.564l1.151 3.385a10.692 10.692 0 0 0 10.151 7.313h19.627l6.604-4.716a2.378 2.378 0 0 0 .634-3.034ZM34.323 24l-1.094.8a4.79 4.79 0 0 1-2.798.91H19.434a3.576 3.576 0 0 1-3.057-1.728l-.87-1.456a3.558 3.558 0 0 1 .916-4.687l.282-.207a7.117 7.117 0 0 1 4.278-1.428h6.96a4.763 4.763 0 0 1 3.962 2.119l2.706 4.065A1.186 1.186 0 0 1 34.323 24Z"
				fill="#22B573"
			/>
			<path
				d="M37.622 33.99H17.995A11.837 11.837 0 0 1 6.75 25.887l-.887-2.671A4.727 4.727 0 0 1 2 18.577v-1.193a4.727 4.727 0 0 1 4.715-4.715h3.023A13.11 13.11 0 0 1 22.52 8.362l.576.127a1.232 1.232 0 0 0 1.491-1.198v-.576A4.721 4.721 0 0 1 29.302 2h1.486a4.721 4.721 0 0 1 4.134 2.441l11.043 20.077a3.529 3.529 0 0 1-1.042 4.571l-6.65 4.687c-.19.135-.417.21-.65.213Zm-30.907-19A2.418 2.418 0 0 0 4.303 17.4v1.192a2.418 2.418 0 0 0 2.412 2.413 1.15 1.15 0 0 1 1.094.788l1.152 3.38a9.529 9.529 0 0 0 9.057 6.53h19.259l6.298-4.497a1.227 1.227 0 0 0 .363-1.59L32.901 5.542a2.419 2.419 0 0 0-2.113-1.267H29.33a2.418 2.418 0 0 0-2.412 2.413v.575a3.53 3.53 0 0 1-4.295 3.455l-.576-.127a10.794 10.794 0 0 0-10.71 3.777l-.126.156a1.15 1.15 0 0 1-.898.431l-3.599.035Zm23.716 11.888H19.434a4.756 4.756 0 0 1-4.03-2.302l-.87-1.457a4.704 4.704 0 0 1 1.198-6.178l.276-.207a8.342 8.342 0 0 1 4.975-1.659h6.96a5.9 5.9 0 0 1 4.918 2.632l2.711 4.064a2.344 2.344 0 0 1-.575 3.19l-1.094.795a5.838 5.838 0 0 1-3.472 1.105v.017ZM21 17.35a6.04 6.04 0 0 0-3.593 1.197l-.276.208a2.407 2.407 0 0 0-.622 3.172l.875 1.457a2.418 2.418 0 0 0 2.067 1.151h10.997c.762 0 1.504-.242 2.119-.69l1.094-.795v-.052l-2.712-4.065a3.587 3.587 0 0 0-3-1.606L21 17.35Z"
				fill="#224946"
			/>
			<path
				d="M76.849 58.971H3.151A1.151 1.151 0 0 1 2 57.82V47.105a1.152 1.152 0 0 1 1.152-1.152h73.697A1.151 1.151 0 0 1 78 47.105V57.82a1.151 1.151 0 0 1-1.151 1.151ZM4.302 56.668h71.394v-8.412H4.303v8.412Z"
				fill="#22B573"
			/>
		</svg>
	);
};
