import { CTA } from '@/components/Shared/CTA/CallToAction';
import { Icon } from '@/components/Shared/Icon';
import { Bottom } from '@/components/Shared/Icons/BrandBackgrounds/PricingPanel/Bottom';
import { Right } from '@/components/Shared/Icons/BrandBackgrounds/PricingPanel/Right';
import { PricingPanel } from '@/components/Shared/PricingPanel';
import { Toggle } from '@/components/Shared/Toggle';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { IPricingPanels } from '@/utils/interfaces/bloks';
import React, { useState } from 'react';
import { FormattedText } from '../Shared/FormattedText';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';

interface IProps {
	blok: IPricingPanels;
	preview: boolean;
}

export const PricingPanels: React.FC<IProps> = ({ blok, preview }) => {
	const [toggle, setToggle] = useState(false);

	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative bg-gray-lightest py-14 md:py-32"
		>
			<Container align="center">
				{blok.heading && isStringWithValue(blok.heading) && (
					<FormattedText tag="h2" text={blok.heading} />
				)}
				{blok.sub_heading && isStringWithValue(blok.sub_heading) && (
					<p className="mt-4 lg:text-xl max-w-[800px] mx-auto text-gray-dark/80">
						{blok.sub_heading}
					</p>
				)}
				<Toggle
					before="Monthly"
					after="Yearly"
					handleClick={() => {
						setToggle((prevToggleState) => !prevToggleState);
					}}
					isActive={toggle}
				/>
				{blok.description && isStringWithValue(blok.description) && (
					<p className="mt-2 font-bold text-gray">
						{blok.description}
					</p>
				)}
				<div className="flex gap-4 pb-8 mt-10 overflow-scroll snap-x lg:gap-8 md:mt-12 lg:overflow-visible">
					{blok.panels.map((panel, key) => (
						<PricingPanel
							blok={panel}
							showYearly={toggle}
							key={key}
						/>
					))}
				</div>

				{blok.callout_heading &&
					isStringWithValue(blok.callout_heading) && (
						<div className="relative px-5 py-10 overflow-hidden bg-green-dark md:text-left md:py-12 md:px-24 drop-shadow">
							<div className="relative z-10 items-center md:grid md:grid-cols-3">
								<div className="col-span-2">
									<h4 className="text-white">
										{blok.callout_heading}
									</h4>
									{blok.callout_body &&
										isStringWithValue(
											blok.callout_body,
										) && (
											<p className="mt-4 text-green-light/80 md:text-lg">
												{blok.callout_body}
											</p>
										)}
								</div>
								<div className="pt-24 md:text-right md:pt-0">
									<div className="inline-flex">
										{blok.callout_cta &&
											isArrayWithValue(
												blok.callout_cta,
											) &&
											blok.callout_cta.map((cta, key) => (
												<CTA blok={cta} key={key} />
											))}
									</div>
								</div>
							</div>
							<Icon
								className="absolute inset-y-0 right-0 hidden left-2/3 md:block"
								icon={<Right className="h-full min-w-full" />}
							/>
							<Icon
								className="absolute inset-x-0 bottom-0 top-1/2 md:hidden"
								icon={
									<Bottom className="min-w-full min-h-full" />
								}
							/>
						</div>
					)}
			</Container>
		</SbSection>
	);
};
