import { IInsightPage, IStoryBase } from '@/utils/interfaces/stories';
import Link from 'next/link';
import { pathOr } from 'ramda';
import { SbImage } from '../Image/SbImage';
import { InsightCardMeta } from './InsightCardMeta';

interface IProps {
	blok: IStoryBase<IInsightPage>;
}

export const InsightCard: React.FC<IProps> = ({ blok }) => {
	
	/* 
		hacky fix to remove duplicate locale slug in full slug
	*/
	const fullSlugSplit = blok.full_slug.split('/');

	if (fullSlugSplit[0] === fullSlugSplit[1]) {
		fullSlugSplit.shift();
	}

	return (
		<Link href={`/${fullSlugSplit.join('/')}`}>
			<a className="relative block group">
				<div className="flex w-full py-6 lg:py-12 resource_card-container">
					{pathOr(
						false,
						['content', 'thumbnail_image', 'filename'],
						blok,
					) && (
						<div className="relative min-w-[136px] h-[98px] lg:min-w-[176px] lg:h-[110px] shadow-hard">
							<SbImage
								src={blok.content.thumbnail_image.filename}
								alt={blok.content.thumbnail_image.alt}
								layout="fill"
								width={1600}
								height={900}
								objectFit="cover"
								className="transition-transform duration-200 shadow-hard group-hover:scale-110 group-focus:scale-110"
							/>
						</div>
					)}

					<div className="px-4 lg:px-7 lg:pt-1">
						<div className="flex space-x-2">
							<InsightCardMeta blok={blok} />
						</div>
						<h5 className="mt-2 lg:leading-8 lg:text-xl group-hover:underline group-focus:underline">
							{blok.name}
						</h5>
					</div>
				</div>

				<div className="absolute bottom-0 left-0 w-full h-[1px] bg-gray-light group-hover:green-gradient group-hover:h-[2px] transition-all"></div>
			</a>
		</Link>
	);
};
