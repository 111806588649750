interface IProps {}

export const Helper: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#a)">
				<path
					d="M1.333 8a6.667 6.667 0 1 0 13.334 0A6.667 6.667 0 0 0 1.333 8Z"
					stroke="#22B573"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="square"
				/>
				<path
					d="M7.326 9.383c0-.404.05-.726.15-.966.1-.24.298-.503.592-.789.298-.288.486-.493.565-.615.122-.185.183-.386.183-.601 0-.286-.072-.503-.214-.652-.14-.152-.347-.228-.62-.228-.262 0-.473.074-.634.223-.158.146-.237.345-.237.597H6.004c.006-.538.188-.963.547-1.276.361-.313.838-.47 1.43-.47.611 0 1.087.156 1.427.466.344.31.515.742.515 1.298 0 .496-.23.983-.693 1.463l-.56.552c-.2.228-.304.56-.31.998H7.326ZM7.248 10.8c0-.18.056-.323.169-.433a.616.616 0 0 1 .455-.168c.195 0 .348.057.46.173a.58.58 0 0 1 .17.428.577.577 0 0 1-.165.42c-.11.112-.264.168-.465.168-.2 0-.355-.056-.464-.169a.586.586 0 0 1-.16-.419Z"
					fill="#22B573"
				/>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h16v16H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};
