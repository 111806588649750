import {IIBuildContent} from '@/utils/interfaces/bloks';
import {render} from 'storyblok-rich-text-react-renderer';
import {CTA} from "@/components/Shared/CTA/CallToAction";
import {CtaContainer} from "@/components/Shared/CTA/CtaContainer";
import {isArrayWithValue} from "@/utils/helpers/isArrayWithValue";

interface IProps {
    blok: IIBuildContent;
}

export const IBuildContent: React.FC<IProps> = ({blok}) => {
    return (
        <div>
            <div className="wyswiyg-content">{render(blok.content)}</div>
            {
                blok.ctas && isArrayWithValue(blok.ctas) && (
                    <CtaContainer align="left">
                        {blok.ctas.map((cta, key) => {
                            return <CTA blok={cta} key={key}/>;
                        })}
                    </CtaContainer>
                )
            }

        </div>
    );
};
