interface IProps {
}

export const Youtube: React.FC<IProps> = () => {
    return (
        <svg viewBox="0 0 40 40"
             xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.8252 13.4283C28.6819 13.659 29.3574 14.3344 29.5879 15.1912C30.0164 16.7561 29.9999 20.0181 29.9999 20.0181C29.9999 20.0181 29.9999 23.2635 29.5881 24.8286C29.3574 25.6852 28.682 26.3607 27.8252 26.5912C26.2602 27.0032 20 27.0032 20 27.0032C20 27.0032 13.7561 27.0032 12.1747 26.5749C11.3179 26.3442 10.6425 25.6687 10.4118 24.8121C10 23.2635 10 20.0016 10 20.0016C10 20.0016 10 16.7561 10.4118 15.1912C10.6424 14.3345 11.3344 13.6425 12.1745 13.412C13.7396 13 19.9998 13 19.9998 13C19.9998 13 26.2602 13 27.8252 13.4283ZM23.2126 20.0016L18.0068 23V17.0033L23.2126 20.0016Z"
                fill="currentColor"
            />
        </svg>
    );
};
