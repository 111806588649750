import { IFooterColumn } from '@/utils/interfaces/components';
import React, { Fragment } from 'react';
import { StoryblokComponent } from '@storyblok/react';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';

interface IProps {
	blok: IFooterColumn;
}

export const FooterColumn: React.FC<IProps> = ({ blok }) => {
	return (
		<div>
			<span className="block mb-3 text-base font-bold text-green-dark md:mb-5 lg:text-lg">
				{blok.heading}
			</span>

			{blok.items && isArrayWithValue(blok.items) && (
				<Fragment>
					{blok.items.map((item, key) => (
						<StoryblokComponent key={key} blok={item} />
					))}
				</Fragment>
			)}
		</div>
	);
};
