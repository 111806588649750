import {
	AlertContext,
	AlertStatus,
} from '@/components/Providers/AlertProvider';
import { Icon } from '@/components/Shared/Icon';
import { Close } from '@/components/Shared/Icons/Close';
import { useContext } from 'react';

interface IProps {}

export const Alert: React.FC<IProps> = () => {
	const alert = useContext(AlertContext);
	const classNames = ['alert-bar'];

	if (alert.alert === AlertStatus.SUCCESS) {
		classNames.push('alert-bar--success');
	}

	if (alert.alert !== 'NONE') {
		return (
			<div className={classNames.join(' ')}>
				<div className="alert-bar__text">{alert.alertText}</div>
				<div className="alert-bar__close-container">
					<button
						onClick={() => {
							alert.clear();
						}}
						className="alert-bar__close"
					>
						<Icon icon={<Close />} />
					</button>
				</div>
			</div>
		);
	} else {
		return null;
	}
};
