import { SbImage } from '@/components/Shared/Image/SbImage';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { ITestimonialPage, IStoryBase } from '@/utils/interfaces/stories';

interface IProps {
	blok: IStoryBase<ITestimonialPage>;
	isLarge?: boolean;
}

export const Testimonial: React.FC<IProps> = ({ blok, isLarge = false }) => {
	const testimonialWrap = ['w-full flex'];
	const testimonialCopy = ['leading-normal'];
	const testimonialContent = ['flex items-center bg-white grow'];
	const testimonialImage = ['relative w-full'];
	const testimonialImageWrap = ['relative shrink-0 grow-0'];
	if (isLarge) {
		testimonialWrap.push('flex-col md:flex-row');
		testimonialCopy.push('text-lg lg:text-2xl');
		testimonialContent.push('p-4 md:p-12 lg:p-20 text-green-dark');
		testimonialImage.push('h-60');
		testimonialImageWrap.push('md:w-60 flex items-center justify-center');
	} else {
		testimonialCopy.push('lg:text-lg');
		testimonialContent.push('pl-4 md:pl-8 text-green-dark/80');
		testimonialImage.push('h-24 md:h-32');
		testimonialImageWrap.push('w-24 md:w-32');
	}
	return (
		<div className={testimonialWrap.join(' ')}>
			{isStringWithValue(blok.content.avatar.filename) && (
				<div className={testimonialImageWrap.join(' ')}>
					<span className="absolute inset-x-0 bottom-0 bg-white md:top-0 h-1/2 md:h-auto md:left-auto md:w-1/2" />
					<div className={testimonialImage.join(' ')}>
						<SbImage
							src={blok.content.avatar.filename}
							alt={blok.content.avatar.alt}
							layout="fill"
							objectFit="contain"
						/>
					</div>
				</div>
			)}
			<div className={testimonialContent.join(' ')}>
				<div className="w-full">
					<p className={testimonialCopy.join(' ')}>
						{blok.content.content}
					</p>
					<div className="items-center justify-between mt-6 md:flex">
						<div className="flex flex-wrap text-sm md:text-base">
							<span className="font-bold text-green">
								{blok.content.author}
							</span>
							{isStringWithValue(blok.content.company) && (
								<>
									<span className="h-6 mx-3 border-l border-gray" />
									<span className="text-green-dark">
										{blok.content.company}
									</span>
								</>
							)}
						</div>
						{isLarge &&
							isStringWithValue(
								blok.content.company_logo.filename,
							) && (
								<div className="relative h-16 w-28">
									<SbImage
										src={blok.content.company_logo.filename}
										layout="fill"
										objectFit="contain"
									/>
								</div>
							)}
					</div>
				</div>
			</div>
		</div>
	);
};
