import { IIBuildTab } from '@/utils/interfaces/components';
import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { StoryblokComponent } from '@storyblok/react';

interface IProps {
	tabs: IIBuildTab[];
}

export const IBuildTabsDesktop: React.FC<IProps> = ({ tabs }) => {
	const tabClassNames = [
		'block',
		'w-full',
		'p-4',
		'transition-colors',
		'duration-200',
		'rounded-full',
		'text-lg',
		'basis-0',
		'grow',
		'shrink',
		'cursor-pointer',
	];

	return (
		<Tab.Group>
			<Tab.List>
				<div className="flex w-full space-x-8">
					{tabs.map((tab, key) => {
						return (
							<Tab as={Fragment} key={key}>
								{({ selected }) => (
									<button
										className={`${tabClassNames.join(
											' ',
										)} ${
											selected
												? 'bg-gradient-to-r from-green to-teal text-white'
												: 'bg-green-light text-green'
										}`}
									>
										{tab.label}
									</button>
								)}
							</Tab>
						);
					})}
				</div>
			</Tab.List>
			<div className="relative">
				<Tab.Panels>
					{tabs.map((tab, key) => {
						return (
							<Tab.Panel key={key}>
								<div className="gap-32 columns-2">
									{tab.body.map((nestedBlok, key) => (
										<div
											key={key}
											className="py-8 border-b break-inside-avoid lg:py-14 border-gray-dark/20 last:border-b-0"
										>
											<StoryblokComponent
												blok={nestedBlok}
												key={nestedBlok._uid}
											/>
										</div>
									))}
								</div>
							</Tab.Panel>
						);
					})}
				</Tab.Panels>
			</div>
		</Tab.Group>
	);
};
