import { pathOr } from 'ramda';
import { z } from 'zod';
import { IFormStepProps } from '../SelfSubscribeContainer';
import { FormCol } from '../Shared/FormCol';
import { FormFormProvider } from '../Shared/FormFormProvider';
import { FormInput } from '../Shared/FormInput';
import { FormRow } from '../Shared/FormRow';
import { FormStepLayout } from '../Shared/FormStepLayout';
import { useZodForm } from '../Shared/UseZodForm';

const schema = z.object({
	verifyCode: z.string().min(1, 'Verfiy code is required'),
});

type FormData = z.infer<typeof schema>;

export const VerifyAccount: React.FC<IFormStepProps> = ({
	children,
	blok,
	...props
}) => {
	const methods = useZodForm({
		schema,
		mode: 'onChange',
		defaultValues: {
			verifyCode: '',
		},
	});

	const {
		register,
		setError,
		formState: { isSubmitting },
	} = methods;

	function onSubmit(data: FormData) {
		if (props.formData && props.formData.verifyCode) {
			const stateVerifyCode = data.verifyCode;
			const formVerifyCode = props.formData.verifyCode;
			const convertedFormVerifyCode = Buffer.from(
				formVerifyCode.toString(),
				'base64',
			).toString('ascii');

			if (stateVerifyCode === convertedFormVerifyCode) {
				props.send({ event: 'NEXT', data: {} });
			} else {
				setError('verifyCode', {
					type: 'doesNotMatch',
					message: 'Code is incorrect',
				});
			}
		} else {
			console.warn('No verify code is set - Please contact Admin');
		}
	}

	const heading = pathOr(null, ['verifyAccount_heading'], blok);
	const description = pathOr(null, ['verifyAccount_description'], blok);
	const media = pathOr(null, ['verifyAccount_media'], blok);
	const demoLink = pathOr(null, ['demo_link'], blok);

	return (
		<FormStepLayout media={media} link={demoLink}>
			<div className="form-step">
				<div className="form-step__heading">
					<h4>{heading}</h4>
				</div>

				{description && (
					<div className="form-step__description">
						<span>{description}</span>
					</div>
				)}

				<div className="form-step__form">
					<FormFormProvider
						form={methods}
						onSubmit={(data) => onSubmit(data)}
					>
						<FormRow>
							<FormCol>
								<div className="form-step__form-input form-step__form-input--text">
									<FormInput
										type="verifyCode"
										label="Enter code"
										disabled={isSubmitting}
										placeholder="XXXXXX"
										{...register('verifyCode', {
											required: true,
										})}
									/>
								</div>
							</FormCol>
						</FormRow>

						<FormRow>
							<FormCol submit={true}>
								<div className="form-step__form-row form-step__form-row--submit">
									<button
										className="cta cta--primary cta--large"
										disabled={isSubmitting}
										type="submit"
									>
										<span className="cta-label">
											Submit
										</span>
									</button>

									{props.previousEnabled && (
										<button
											className="cta cta--form-nav"
											onClick={() =>
												props.send({
													event: 'PREVIOUS',
													data: {},
												})
											}
										>
											<span className="cta-label">
												Previous
											</span>
										</button>
									)}
								</div>
							</FormCol>
						</FormRow>
					</FormFormProvider>
				</div>
			</div>
		</FormStepLayout>
	);
};
