import { HeroContent } from '@/components/Bloks/Hero/HeroContent';
import { SbSection } from '@/components/Shared/Layout/SbSection';
import { IHeroBlok } from '@/utils/interfaces/bloks';

interface IProps {
	blok: IHeroBlok;
	preview: boolean;
}

export const HeroSimple: React.FC<IProps> = ({ blok, preview }) => {
	return (
		<SbSection
			blok={blok}
			preview={preview}
			classNames="relative lg:flex items-end bg-green-dark text-white bg-no-repeat pt-12 pb-3 lg:pt-18 lg:pb-6"
		>
			<div className="absolute inset-y-0 right-0 left-2/3">
				<img
					className="object-cover object-left-bottom w-full h-full"
					src="/brand/backgrounds/heroSimpleBackground.svg"
					alt="background image showcasing virtual siting and sales software for builders and contractors"
				/>

			</div>
			<HeroContent blok={blok} />
		</SbSection>
	);
};
