import { z } from 'zod';

export type TAddon = {
	id: string;
	name: string;
	price: number;
	included: boolean;
	billingCycle: string;
	country: string;
	description: string;
};

export type ISearchFormValue = {
	addon_applicability: string;
	billingCycle: string;
	id: string;
	name: string;
	price: number;
	searches: number;
};

export interface ICibApiResponse<TResponse> {
	success: boolean;
	message: string;
	data: TResponse;
}

export interface IPlansResponse {
	success: boolean;
	message: string;
	data: ISearchFormValue[];
}

export interface IBuildTypesResponse {
	success: boolean;
	message: string;
	data: IBuildType[];
}

export interface ISpecialisationResponse {
	success: boolean;
	message: string;
	data: string[];
}

export interface IBuildType {
	id: number;
	name: string;
	houselandPackage: boolean;
	multiLevel: boolean;
}

export interface IAddonsResponse {
	success: boolean;
	message: string;
	data: TAddon[];
}

export type TBillingCycle = 'monthly' | 'annually';

/* Step Two - Account Creation */
export type UserAccountCreateValues = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	password: string;
	passwordConfirm: string;
};

export type UserAccontApiRequest = {
	step: string;
	data: {
		addons?: string[];
		billingCycle?: string;
		country?: string;
		email: string;
		mobile: string;
		name: string;
		password: string;
		planId: any;
		searches: number;
	};
};

export type UserAccontApiResponse = {
	id: number;
	searches: number;
	billingCycle: string;
	addons: string;
	email: string;
	password: string;
	name: string;
	mobile: string;
	subscriptionKey: null | string;
	tradingName: null | string;
	billingName: string | null;
	companyAddress: string | null;
	companyWebsite: string | null;
	buildType: string | null;
	specialisation: string | null;
	subSpecialisation: string | null;
	buildsPerYear: string | null;
	created: string;
	updated: string;
	emailValidated: boolean;
	sessionId: string;
	currentStep: string;
	planId: string;
};

/* Step 4 - Checkout */
export type UserPaymentSubscriptionRequest = {
	step: string;
	data: {
		email: string;
		sessionId: string;
	};
};

export type UpdateUserPaymentSubscriptionRequest = {
	step: string;
	data: {
		email: string;
		sessionId: string;
		subscriptionKey: string;
	};
};

export type UserPaymentSubscriptionResponse = {
	url: string;
	subscriptionKey: string;
};

/* Step 5 - Update account info */

export type UserUpdateAccountFormValues = {
	tradingName: string;
	billingName: string;
	phone: string;
	companyAddress: string;
	companyWebsite: string;
	buildType: string;
	specialisation: string;
	subSpecialisation: string;
	buildsPerYear: string;
};

export type UserUpdateApiRequest = {
	step: string;
	data: {
		tradingName: string;
		billingName: string;
		phone: string;
		sessionId: string;
		companyAddress: string;
		companyWebsite: string;
		buildType: string[];
		specialisation: string[];
		subSpecialisation: string[];
		buildsPerYear: string;
		email: string;
	};
};

// Zod

export const selectSchema = z.object({
	label: z.string(),
	value: z.string(),
});

export type SelectData = z.infer<typeof selectSchema>;
