import { SbImage } from '@/components/Shared/Image/SbImage';
import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { ISelfSubscribeImage } from '@/utils/interfaces/bloks';

interface IProps {
	blok: ISelfSubscribeImage;
}

export const SelfSubscribeImage: React.FC<IProps> = ({ blok }) => {
	return (
		<div className="self-subscribe-media self-subscribe-media--image">
			<div className="self-subscribe-media__image">
				<SbImage
					src={blok.image.filename}
					alt={blok.image.alt}
					disablePngBg={true}
					layout="intrinsic"
					objectFit="contain"
				/>
			</div>

			<div className="self-subscribe-media__content">
				{blok.heading && isStringWithValue(blok.heading) && (
					<span className="self-subscribe-media__heading">
						{blok.heading}
					</span>
				)}

				{blok.description && isStringWithValue(blok.description) && (
					<span className="self-subscribe-media__description">
						{blok.description}
					</span>
				)}
			</div>
		</div>
	);
};
