//@ts-nocheck

import { Caption } from '@/components/Shared/Caption';
import { PlayButton } from '@/components/Shared/CTA/Play';
import { WysiwygContainer } from '@/components/Shared/Layout/WysiwygContainer';
import { Modal } from '@/components/Shared/Modal/BaseModal';
import { IResourceYoutube } from '@/utils/interfaces/bloks';
import Youtube from '@u-wave/react-youtube';
import { useState } from 'react';

interface IProps {
	blok: IResourceYoutube;
}

export const WysiwygYoutube: React.FC<IProps> = ({ blok }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	return (
		<WysiwygContainer narrow={false}>
			<div>
				<div className="relative">
					<div className="pointer-events-none aspect-w-16 aspect-h-9">
						<Youtube
							video={blok.youtube_id}
							autoplay={false}
							controls={false}
							disableKeyboard={true}
							allowFullscreen={false}
							modestBranding={true}
							showRelatedVideos={false}
							showInfo={false}
						/>
					</div>

					<PlayButton onClick={() => setIsModalOpen(true)} />
				</div>

				{blok.caption && <Caption caption={blok.caption} />}
			</div>

			<Modal
				active={isModalOpen}
				type="video_modal"
				onClose={() => setIsModalOpen(false)}
			>
				<div className="relative">
					<div className="aspect-w-16 aspect-h-9">
						<Youtube
							video={blok.youtube_id}
							autoplay={true}
							controls={false}
						/>
					</div>
				</div>
			</Modal>
		</WysiwygContainer>
	);
};
