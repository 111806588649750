interface IProps {
    className: string;
}

export const Right: React.FC<IProps> = ({className}) => {
    return (
        <svg
            viewBox="0 0 421 200"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M393,597.2L370.7,633L-73.7,358l22.3-35.9C-9.9,255.4,12,178.5,12,100c0-78.5-22-155.5-63.4-222.2l-22.4-35.9 L370.7-433l22.3,35.8C486-248,535.3-75.7,535.3,100S486,447.9,393,597.2L393,597.2z"
                fill="url(#b)"/>
            <defs>
                <linearGradient
                    id="b"
                    x1="-73.748" y1="102" x2="535.252" y2="102"
                    gradientTransform="matrix(1 0 0 -1 0 202)">
                    <stop stopColor="#22B573"/>
                    <stop offset="1" stopColor="#1BD0BC"/>
                </linearGradient>
            </defs>
        </svg>

    );
};
