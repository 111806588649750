interface IProps {
	className: string;
}

export const CutoutTablet: React.FC<IProps> = ({ className }) => {
	return (
		<svg
			viewBox="0 0 960 140"
			className={className}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="a"
				style={{ maskType: 'alpha' }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="960"
				height="140"
			>
				<path
					transform="matrix(-1 0 0 1 960 0)"
					fill="#224946"
					d="M0 0h960v140H0z"
				/>
			</mask>
			<g mask="url(#a)">
				<path
					d="m-197.148 232.328-50.19 32.342 397.989 623.654 50.351-32.322c93.595-60.025 202.044-92.663 313.14-94.237 111.096-1.575 220.428 27.976 315.686 85.325l51.259 30.961 380.023-634.795-51.07-30.906C996.97 83.724 752.266 17.425 503.591 20.95 254.917 24.473 12.19 97.68-197.149 232.292l.001.035Z"
					fill="#224946"
					stroke="#EFF0EF"
					strokeWidth="22"
					strokeMiterlimit="10"
				/>
			</g>
		</svg>
	);
};
