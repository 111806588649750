import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { IWorkableWidget } from '@/utils/interfaces/bloks';

import Script from 'next/script';
import { useEffect, useState } from 'react';
import { CTA } from '../Shared/CTA/CallToAction';
import { Container } from '../Shared/Layout/Container';
import { SbSection } from '../Shared/Layout/SbSection';

interface IProps {
	blok: IWorkableWidget;
}

export const WorkableWidget: React.FC<IProps> = ({ blok }) => {
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (!isLoaded) {
			setTimeout(() => {
				if (
					window !== undefined &&
					'whr' in window &&
					'whr_embed' in window
				) {
					(window as any).whr(document).ready(function () {
						(window as any).whr_embed(
							process.env.NEXT_PUBLIC_WORKABLE_ID,
							{
								detail: 'titles',
								base: 'locations',
								zoom: 'country',
							},
						);
					});

					setIsLoaded(true);
				}
			}, 400);
		}
	}, []);

	return (
		<SbSection
			blok={blok}
			preview={false}
			classNames="relative bg-white py-14 md:py-32"
		>
			<Container>
				<div className="flex items-center justify-center w-full sm:justify-between">
					<div className="">
						<h2>{blok.heading}</h2>
					</div>

					{blok.cta && isArrayWithValue(blok.cta) && (
						<div className="hidden sm:block">
							{blok.cta.map((cta, key) => {
								return <CTA blok={cta} key={key} />;
							})}
						</div>
					)}
				</div>

				<div className="mt-10">
					<Script
						src="https://www.workable.com/assets/embed.js"
						type="text/javascript"
					/>

					<div id="whr_embed_hook"></div>
				</div>

				{blok.cta && isArrayWithValue(blok.cta) && (
					<div className="flex justify-center mt-10 sm:hidden">
						{blok.cta.map((cta, key) => {
							return <CTA blok={cta} key={key} />;
						})}
					</div>
				)}
			</Container>
		</SbSection>
	);
};
