import { Caption } from '@/components/Shared/Caption';
import { PlayButton } from '@/components/Shared/CTA/Play';
import { WysiwygContainer } from '@/components/Shared/Layout/WysiwygContainer';
import { Modal } from '@/components/Shared/Modal/BaseModal';
import { IWysiwygVimeo } from '@/utils/interfaces/bloks';
import Vimeo from '@u-wave/react-vimeo';
import { useState } from 'react';

interface IProps {
	blok: IWysiwygVimeo;
}

export const WysiwygVimeo: React.FC<IProps> = ({ blok }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	return (
		<WysiwygContainer narrow={false}>
			<div>
				<div className="relative">
					<div className="pointer-events-none aspect-w-16 aspect-h-9">
						<Vimeo
							className="pointer-events-none aspect-w-16 aspect-h-9"
							video={blok.vimeo_id}
							autoplay={false}
							controls={false}
						/>
					</div>

					<PlayButton onClick={() => setIsModalOpen(true)} />
				</div>

				{blok.caption && <Caption caption={blok.caption} />}
			</div>

			<Modal
				active={isModalOpen}
				type="video_modal"
				onClose={() => setIsModalOpen(false)}
			>
				<div className="relative">
					<Vimeo
						className="aspect-w-16 aspect-h-9"
						video={blok.vimeo_id}
						autoplay
					/>
				</div>
			</Modal>
		</WysiwygContainer>
	);
};
