import { IIBuildStatistic } from '@/utils/interfaces/bloks';

interface IProps {
	blok: IIBuildStatistic;
}

export const IBuildStatistic: React.FC<IProps> = ({ blok }) => {
	return (
		<div className="grow shrink basis-0">
			<h6 className="text-6xl lg:text-7xl xl:text-8xl leading-none font-bold text-transparent bg-clip-text bg-gradient-to-r from-green to-teal">{blok.value}</h6>
			<p className="lg:text-lg w-10/12">{blok.description}</p>
		</div>
	);
};
