import React from "react";
import {IPricingPanelFeature} from "@/utils/interfaces/components";
import {Check} from "@/components/Shared/Icons/Check";
import {Icon} from "@/components/Shared/Icon";
import {isStringWithValue} from "@/utils/helpers/isStringWithValue";

interface IProps {
    blok: IPricingPanelFeature;
}

export const PricingPanelFeature: React.FC<IProps> = ({blok}) => {
    return (
        <li className="flex items-center gap-2 text-xs md:text-sm mb-1.5 last:mb-0">
            <Icon className="h-3 w-3 text-green" icon={<Check/>}/>
            {blok.feature}
            {blok.flag && isStringWithValue(blok.flag) && (
                <>
                    <span className="text-orange">&bull;</span>
                    <div className="text-orange font-bold">
                        {blok.flag}
                    </div>
                </>
            )}
        </li>
    );
};
