import Script from 'next/script'
import {SbSection} from "@/components/Shared/Layout/SbSection";
import {IThirdPartyScript} from "@/utils/interfaces/bloks";
import {isStringWithValue} from "@/utils/helpers/isStringWithValue";
import { SbImage } from '../Shared/Image/SbImage';

interface IProps {
    blok: IThirdPartyScript;
    preview: boolean;
}

export const ThirdPartyScript: React.FC<IProps> = ({blok, preview}) => {
    const sectionClasses = ['relative'];

    if (blok.full_height) {
        sectionClasses.push('h-[calc(100vh-4rem)] lg:h-[calc(100vh-105px)]');
    }

    return (
        <SbSection
            blok={blok}
            preview={preview}
            classNames={sectionClasses.join(' ')}
        >
            {blok.image &&
				isStringWithValue(blok.image.filename) && (
                <div className='relative z-10'>
                    <SbImage
                        src={blok.image.filename}
                        alt={blok.image.alt}
                        disablePngBg={true}
                        layout="fill"
                        objectFit="cover"
                    />
                </div>
            )}

            {blok.id && isStringWithValue(blok.id) && (
                <div
                    className="relative z-20 h-full"
                    id={blok.id}
                />
            )}
            <Script src={blok.script} strategy="lazyOnload"></Script>
        </SbSection>
    );
};
