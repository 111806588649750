interface IProps {}

export const NZ: React.FC<IProps> = () => {
	return (
		<svg viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#a)">
				<path d="M0 0h36v24H0V0Z" fill="#0052B4" />
				<path
					d="m29.904 10.435.238.733h.771l-.624.454.238.733-.623-.453-.624.453.238-.733-.623-.454h.77l.239-.733Zm-2.756 5.279.358 1.1h1.156l-.936.68.358 1.099-.936-.68-.935.68.357-1.1-.936-.68h1.157l.357-1.1Zm.104-8.638.298.917h.964l-.78.566.298.917-.78-.567-.78.567.298-.917-.78-.566h.964l.298-.917Zm-2.651 3.327.357 1.1h1.156l-.935.68.357 1.1-.935-.68-.936.68.357-1.1-.935-.68h1.156l.358-1.1Z"
					fill="#D80027"
				/>
				<path
					d="M18 0v2.149l-3.177 1.764H18v4.173h-4.156L18 10.396v1.603h-1.877l-5.167-2.87v2.87H7.043V8.584L.896 12H0V9.851l3.176-1.765H0V3.913h4.156L0 1.604V0h1.876L7.043 2.87V0h3.913v3.415L17.105 0H18Z"
					fill="#fff"
				/>
				<path
					d="M10.125 0h-2.25v4.875H0v2.25h7.875V12h2.25V7.125H18v-2.25h-7.875V0Z"
					fill="#D80027"
				/>
				<path
					d="M10.956 8.087 18 12v-1.107l-5.052-2.806h-1.992Z"
					fill="#0052B4"
				/>
				<path
					d="M10.956 8.087 18 12v-1.107l-5.052-2.806h-1.992Z"
					fill="#fff"
				/>
				<path
					d="M10.957 8.087 18 12v-1.107l-5.052-2.806h-1.992Zm-5.905 0L0 10.893V12l7.043-3.913H5.052Z"
					fill="#D80027"
				/>
				<path
					d="M7.043 3.913 0 0v1.106l5.052 2.807h1.991Z"
					fill="#0052B4"
				/>
				<path
					d="M7.043 3.913 0 0v1.106l5.052 2.807h1.991Z"
					fill="#fff"
				/>
				<path
					d="M7.043 3.913 0 0v1.106l5.052 2.807h1.991Zm5.905 0L18 1.106V0l-7.043 3.913h1.991Z"
					fill="#D80027"
				/>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h36v24H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};
