import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Icon } from '../Icon';
import { Close } from '../Icons/Close';

export type TModalType = 'video_modal' | 'subscribe_modal';

interface IProps {
	active: boolean;
	type: TModalType;
	title?: string;
	onClose: () => void;
}

/* 
	Resource: https://headlessui.dev/react/dialog
*/
export const Modal: React.FC<IProps> = (props) => {
	// useDisableBodyScroll(props.active)

	return (
		<Transition appear={true} show={props.active} as={Fragment}>
			<Dialog
				onClose={() => props.onClose()}
				className="fixed inset-0 z-40 overflow-y-auto"
			>
				<Dialog.Overlay
					as="div"
					className="flex items-start justify-center min-h-screen"
				>
					<div className="fixed inset-0 pointer-events-none bg-gray-dark opacity-80" />

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className={`modal modal--${props.type}`}>
							<div className="modal__header">
								<div className="modal__header-label-container">
									<span className="modal__header-label">
										{props.title}
									</span>
								</div>
								<button
									className="modal__header-close"
									onClick={props.onClose}
								>
									<Icon icon={<Close />} />
								</button>
							</div>
							<div className="modal__children">
								{props.children}
							</div>
						</div>
					</Transition.Child>
				</Dialog.Overlay>
			</Dialog>
		</Transition>
	);
};
