import { Icon } from '../Icon';
import { BrandLogo } from '../Icons/BrandLogo';
import { Menu } from '@/components/Shared/Menu/Menu';
import { useMenu, useMenuState } from '@/components/Providers/HeaderProvider';
import { isArrayWithValue } from '@/utils/helpers/isArrayWithValue';
import { CtaContainer } from '@/components/Shared/CTA/CtaContainer';
import { CTA } from '@/components/Shared/CTA/CallToAction';
import { Hamburger } from '@/components/Shared/Menu/Hamburger';
import { LanguageSelect } from '../LanguageSelect';
import { Fragment } from 'react';
import Link from 'next/link';

interface IProps {}

export const Header: React.FC<IProps> = () => {
	const menuContext = useMenu();
	const isMenuActive = useMenuState();
	const { menu_cta } = menuContext;
	return (
		<Fragment>
			<header
				className={`${
					isMenuActive ? 'overflow-visible' : ''
				} sticky top-0 z-30 flex items-center lg:overflow-visible justify-between h-16 px-4 bg-white border-b shadow-lg lg:h-auto lg:px-8 border-gray-light`}
			>
				<Link href="/">
					<a className="text-none">
						<Icon
							className="w-[118px] xl:w-[179px] h-auto transition-all duration-200"
							icon={<BrandLogo />}
						/>
					</a>
				</Link>
				<div
					className={`${
						isMenuActive ? 'h-[calc(100vh_-_4rem)]' : 'h-0'
					} bg-inherit absolute top-full left-0 bg-white right-0 border-t border-gray-light lg:border-t-0 lg:static lg:ml-auto transition-all lg:h-auto overflow-hidden`}
				>
					<div
						className={`${
							isMenuActive ? 'block' : 'hidden'
						} h-screen p-4 lg:flex lg:justify-end lg:gap-x-3 xl:gap-x-6 lg:p-0 lg:h-[104px]`}
					>
						<Menu />
						{menuContext && isArrayWithValue(menu_cta) && (
							<div className="mt-10 lg:mt-0 lg:flex">
								<CtaContainer>
									{menu_cta.map((cta, key) => (
										<CTA blok={cta} key={key} />
									))}
								</CtaContainer>
							</div>
						)}
					</div>
				</div>
				<div className="flex items-center lg:hidden">
					<Hamburger />
				</div>
			</header>
		</Fragment>
	);
};
