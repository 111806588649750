import { Caption } from '@/components/Shared/Caption';
import { SbImage } from '@/components/Shared/Image/SbImage';
import { SbImageWrapper } from '@/components/Shared/Image/SbImageWrapper';
import { IIBuildImage } from '@/utils/interfaces/bloks';

interface IProps {
	blok: IIBuildImage;
}

export const IBuildImage: React.FC<IProps> = ({ blok }) => {
	return (
		<figure className="inline-block text-none">
			<SbImageWrapper shadow={false} rounded="md">
				<SbImage
					src={blok.image.filename}
					alt={blok.image.alt}
					layout="intrinsic"
				/>
			</SbImageWrapper>

			{blok.caption && <Caption caption={blok.caption} />}
		</figure>
	);
};
