import { isStringWithValue } from '@/utils/helpers/isStringWithValue';
import { IInsightPage, IStoryBase } from '@/utils/interfaces/stories';
import Link from 'next/link';
import { pathOr } from 'ramda';
import { SbImage } from '../Image/SbImage';
import { InsightCardMeta } from './InsightCardMeta';

interface IProps {
	blok: IStoryBase<IInsightPage>;
}

export const InsightFeatureCard: React.FC<IProps> = ({ blok }) => {
	return (
		<Link href={`/${blok.full_slug}`}>
			<a className="group">
				<div className="h-full bg-white shadow-hard">
					{pathOr(
						false,
						['content', 'thumbnail_image', 'filename'],
						blok,
					) && (
						<div className="h-[216px] w-full relative lg:h-[300px] xl:h-[360px] transition-all">
							<SbImage
								src={blok.content.thumbnail_image.filename}
								alt={blok.content.thumbnail_image.alt}
								layout="fill"
								width={1600}
								height={900}
								objectFit="cover"
								className="transition-transform duration-700 group-hover:scale-110 group-focus:scale-110"
							/>
						</div>
					)}

					<div className="relative px-4 py-8 lg:px-8 lg:pb-9">
						{pathOr(false, ['content', 'categories'], blok) && (
							<div className="absolute top-0 -translate-y-1/2 left-4 lg:left-8">
								<span className="cta cta--secondary cta--chip">
									<span className="cta-label">
										{blok.content.categories.name}
									</span>
								</span>
							</div>
						)}

						<div className="flex space-x-2">
							<InsightCardMeta blok={blok} />
						</div>

						<h4 className="mt-4 lg:leading-8 lg:text-2xl group-hover:underline group-focus:underline">
							{blok.name}
						</h4>

						{blok.content &&
							blok.content.excerpt &&
							isStringWithValue(blok.content.excerpt) && (
								<span className="block mt-4 text-base lg:text-lg lg:mt-3">
									{blok.content.excerpt}
								</span>
							)}
					</div>
				</div>
			</a>
		</Link>
	);
};
